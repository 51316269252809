import React from 'react'
import {Navigate} from 'react-router-dom';

export default function UserLogout() {
    localStorage.clear()
    return (
        <>
        <Navigate to="/" />
        </>
    )
}
