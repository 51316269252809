/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Card, Image, Modal, Form, Table, Alert, Button, Spinner } from 'react-bootstrap'
import './componentCss/cart.css'
import TopHeader from './Top_Header'
import Navigation from './Navigation'
import { FaTrashAlt, FaPlus } from "react-icons/fa";
import { useQuery, useMutation } from '@apollo/client'
import { QUERY_CHECK_PENDING_CART_PRODUCT_FOR_USER, MUTATION_INCREASE_CART_PRODUCT, MUTATION_DECREASE_CART_PRODUCT, MUTATION_REMOVE_CART_ITEM, QUERY_PRODUCT_BY_ID } from './Queries'
import { useDispatch, useSelector } from 'react-redux'
import { increase, decrease, getCart, getCartTotal, remove } from '../redux/actions'
import { Link, useNavigate } from "react-router-dom";
import Emptycart from '../img/emptyCart.jpg'
import AWS from 'aws-sdk'
import uniqid from 'uniqid';

const ID = 'AKIAYSBXIAITVZOA3FGO';
const SECRET = '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';
const BUCKET_NAME = 'bharatpharmauserprescription';

const s3 = new AWS.S3({
    accessKeyId: ID,
    secretAccessKey: SECRET
});


export default function Cart() {

    const userId = localStorage.getItem('userId')
    const userToken = localStorage.getItem('userToken')

    const [medicineDetect, setMedicineDetect] = useState(true)
    const [selectedImage, setSelectedImage] = useState()
    const [imageKey, setImageKey] = useState('')

    const [validated, setValidated] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (medicineDetect) {
            handleShow()
        }
    }, [])



    const { data: pendingCartProductForUserData, loading: pendingCartProductForUserLoading } = useQuery(QUERY_CHECK_PENDING_CART_PRODUCT_FOR_USER, {
        variables: {
            userId: `${userId}`
        },
    });

    const [increaseCartAmount, { loading: incLoading }] = useMutation(MUTATION_INCREASE_CART_PRODUCT, {
        refetchQueries: [
            QUERY_CHECK_PENDING_CART_PRODUCT_FOR_USER,
            QUERY_PRODUCT_BY_ID
        ]
    });
    const [decreaseCartAmount, { loading: decLoading }] = useMutation(MUTATION_DECREASE_CART_PRODUCT, {
        refetchQueries: [
            QUERY_CHECK_PENDING_CART_PRODUCT_FOR_USER,
            QUERY_PRODUCT_BY_ID
        ]
    });
    const [removeCartItem] = useMutation(MUTATION_REMOVE_CART_ITEM, {
        refetchQueries: [
            QUERY_CHECK_PENDING_CART_PRODUCT_FOR_USER,
            QUERY_PRODUCT_BY_ID
        ]
    });

    const dispatch = useDispatch();
    const { cart, totalAmount } = useSelector(state => state.cart)
    console.log("cart", cart)
    useEffect(() => {
        if (userToken) {
            if (pendingCartProductForUserData) {
                dispatch(getCart(pendingCartProductForUserData))
            }
        }
    }, [pendingCartProductForUserData])

    useEffect(() => {
        dispatch(getCartTotal())
    }, [cart])

    const navigate = useNavigate();
    const handleClick = (id) => navigate('/productDetails/' + id);

    function incCart(id) {
        increaseCartAmount({
            variables: {
                increaseCartAmountId: `${id}`
            }
        })
    }
    function decCart(id) {
        decreaseCartAmount({
            variables: {
                decreaseCartAmountId: `${id}`
            }
        })
    }
    function removeCart(id) {
        removeCartItem({
            variables: {
                removeCartItemId: `${id}`
            }
        })
    }


    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setValidated(false);
            event.preventDefault();
            try {
                const uniqueId = uniqid();

                var regex = new RegExp('[^.]+$');
                var extension = selectedImage.name.match(regex);
                var uniqueFileName = uniqueId + "." + extension[0];
                const params = {
                    Bucket: BUCKET_NAME,
                    Key: uniqueFileName,
                    Body: selectedImage,
                };
                s3.upload(params, function (err, data) {
                    if (err) {
                        throw err;
                    }
                    else {
                        setImageKey(uniqueFileName)
                        setMedicineDetect(false)
                        handleClose()
                    }
                });
            }
            catch (err) {
                console.log(err);
            }
        }
    };

    return (
        <div>
            <TopHeader />
            <Navigation />
            <Container style={{ marginTop: '20px' }}>
                {
                    cart.length === 0 ?
                        <>
                            <Image src={Emptycart} style={{ width: '270px', height: '270px' }} className='mx-auto d-block' />
                            <h1 style={{ fontSize: '25px', fontFamily: 'Dm Sans', textAlign: 'center', fontWeight: 'bold' }}>Cart is empty!</h1>
                        </>
                        :
                        <>
                            <Row>
                                <Col><h1 style={{ fontFamily: 'DM Sans', fontSize: '25px', fontWeight: 'bold' }}>Order Summary</h1></Col>
                            </Row>
                            <Row>
                                <Col>
                                    {
                                        pendingCartProductForUserLoading ?
                                            <Spinner animation="border" role="status" className="mx-auto d-block">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner> :
                                            <Card className="summaryCard" md={8}>
                                                <Card.Body>
                                                    <h1 style={{ fontFamily: 'DM Sans', fontSize: '14px', color: 'rgba(21,27,57,.6)', fontWeight: 'bold', marginBottom: '20px' }}>PRODUCTS</h1>
                                                    {
                                                        cart && cart.map((item) => {
                                                            return (
                                                                <Container key={item.id}>
                                                                    <Row>
                                                                        <Col md={2}>
                                                                            <Image src={`https://bharatpharmamain.s3.us-east-2.amazonaws.com/${item.image[0]}`} onClick={() => handleClick(item.productId)} style={{ width: '60px', height: '60px', cursor: 'pointer' }} />
                                                                        </Col>
                                                                        <Col md={10}>
                                                                            <p style={{ fontSize: '15px', fontFamily: 'Dm Sans', color: '#151b39', cursor: 'pointer' }} onClick={() => handleClick(item.productId)}>{item.name}</p>
                                                                            <p style={{ color: 'rgba(21,27,57,.6)', fontSize: '12px', fontFamily: 'Dm sans' }}>Mfr: {item.manufacturer}</p>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col md={2}></Col>
                                                                        <Col md={5}>
                                                                            <p style={{ fontSize: '16px', fontFamily: 'Dm Sans', color: '#ef4281' }}>Rs. {item.mrp}</p>
                                                                        </Col>
                                                                        <Col md={5}>
                                                                            {
                                                                                incLoading ?
                                                                                    <Button className="incBtn" variant="success" disabled><Spinner
                                                                                        as="span"
                                                                                        animation="border"
                                                                                        size="sm"
                                                                                        role="status"
                                                                                        aria-hidden="true"
                                                                                    /></Button> :
                                                                                    item.amount === parseInt(item.stockAvailable) ? <Button className="incBtn" variant="success" disabled>+</Button> :
                                                                                        <Button onClick={() => { dispatch(increase(item.id)); incCart(item.id) }} className="incBtn" variant="success">+</Button>
                                                                            }
                                                                            <span style={{ fontSize: '15px', fontFamily: 'Dm Sans', fontWeight: 'bold' }}>{item.amount}</span>
                                                                            {
                                                                                decLoading ?
                                                                                    <Button className="incBtn" variant="success" disabled><Spinner
                                                                                        as="span"
                                                                                        animation="border"
                                                                                        size="sm"
                                                                                        role="status"
                                                                                        aria-hidden="true"
                                                                                    /></Button> :
                                                                                    item.amount === 1 ? <Button className="decBtn" variant="danger" disabled>-</Button> :
                                                                                        <Button onClick={() => { dispatch(decrease(item.id)); decCart(item.id) }} className="decBtn" variant="danger">-</Button>
                                                                            }

                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col md={2}></Col>
                                                                        <Col md={8} style={{ fontSize: '12px', color: '#151b39', fontFamily: 'DM Sans' }}>Delivery between JANUARY 8-JANUARY 9</Col>
                                                                        <Col md={2} style={{ fontSize: '15px', color: '#151b39', fontFamily: 'DM Sans', cursor: 'pointer' }}><FaTrashAlt style={{ color: '#c0392b' }} onClick={() => { dispatch(remove(item.id)); removeCart(item.id) }} /></Col>
                                                                    </Row>
                                                                    <hr />
                                                                </Container>
                                                            )
                                                        })
                                                    }
                                                    <hr />
                                                    <Row>
                                                        <Col md={12}>
                                                            <Link to="/" style={{ fontFamily: 'DM Sans', fontSize: '13px', color: '#16a085', fontWeight: 'bold', cursor: 'pointer', textDecoration: 'none' }}>ADD MORE ITEMS <FaPlus style={{ marginTop: '-3px' }} /></Link>
                                                        </Col>

                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                    }
                                </Col>
                                <Col md={4}>
                                    <Card className="summaryCard" md={6}>
                                        <Card.Body>
                                            {
                                                cart.length !== 0 ?
                                                    <>
                                                        <h1 style={{ fontFamily: 'DM Sans', fontSize: '14px', color: 'rgba(21,27,57,.6)', fontWeight: 'bold', marginBottom: '20px' }}>PAYMENT DETAILS</h1>
                                                        <Container>
                                                            <Row>
                                                                <Col>
                                                                    <Table style={{ fontSize: '13px', fontFamily: 'DM Sans' }}>
                                                                        <tbody>
                                                                            <tr style={{ fontWeight: 'bold' }}>
                                                                                <td>Total Amount</td>
                                                                                <td>Rs. {totalAmount}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </Table>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={12}>
                                                                    {
                                                                        medicineDetect === false ?
                                                                            <h1 style={{ fontFamily: 'DM sans', fontSize: 12, textAlign: 'center', color: '#2ecc71', fontWeight: 'bold' }}>Prescription uploaded successfully</h1>
                                                                            :
                                                                            ""
                                                                    }
                                                                    <Link to='/checkout' state={{ cartData: pendingCartProductForUserData.checkPendingCartProductForUser, totalAmount: totalAmount, cart: cart, imageKey: imageKey }} style={{ textDecoration: 'none' }}><Button className='proceedBtn'>Proceed</Button></Link>
                                                                </Col>
                                                            </Row>
                                                        </Container> </> : ""
                                            }
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                }

            </Container>

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <h1 style={{ fontFamily: 'DM Sans', fontSize: 20, fontWeight: 'bold', textAlign: 'center', color: '#34495e' }}>Upload Prescription</h1>
                                <h1 style={{ fontFamily: 'DM Sans', fontSize: 12, textAlign: 'center', color: '#7f8c8d' }}>There is some medicine in your cart!!!</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                                            <Form.Control required type="file" style={{ fontFamily: 'DM sans', fontSize: 12 }} onChange={(event) => { setSelectedImage(event.target.files[0]); }} />
                                        </Form.Group>
                                    </Row>
                                    <Button type="submit" className='mx-auto d-block' size="sm" style={{ fontFamily: 'DM sans', fontSize: 12 }}>Upload</Button>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}
