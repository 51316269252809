import React, { useState } from 'react'
import { Container, Col, Row, Table, Button, Spinner, Modal, Carousel } from 'react-bootstrap'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { QUERY_PENDING_PRODUCT, QUERY_PRODUCT_BY_ID, MUTATION_ACTIVE_VANDOR_PRODUCT, QUERY_ACTIVE_PRODUCT } from '../Queries'
import Moment from 'react-moment';
import { SRLWrapper } from "simple-react-lightbox";

export default function ProductRequest() {


    const [productIdState, setProductIdState] = useState('')

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showConfirm, setShowConfirm] = useState(false);
    const handleCloseConfirm = () => setShowConfirm(false);
    const handleShowConfirm = () => setShowConfirm(true);

    const { data: pendingProductData, loading: pendingProductLoading } = useQuery(QUERY_PENDING_PRODUCT)
    const [productByIdLazyQuery, { data: productByIdData, loading: productByIdLoading }] = useLazyQuery(QUERY_PRODUCT_BY_ID);
    const [activeVendorProduct] = useMutation(MUTATION_ACTIVE_VANDOR_PRODUCT, {
        refetchQueries: [
            QUERY_PENDING_PRODUCT,
            'getPendingProduct',
            QUERY_ACTIVE_PRODUCT,
            'getActiveProduct',
        ],
    });

    function viewHandel(id) {
        handleShow()
        productByIdLazyQuery({
            variables: {
                productId: `${id}`
            }
        })
    }

    function activeHandle(id) {
        setProductIdState(id)
        handleShowConfirm()
    }

    function activeHandleYes() {
        activeVendorProduct({
            variables: {
                productId: `${productIdState}`
            }
        })
        handleCloseConfirm()
    }
    return (
        <div>
            <Container className="EditContainer" style={{ marginTop: '60px' }}>
                <Row>
                    <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '30px', marginTop: '15px' }}>Product Requests</p>
                    <Col md={12}>
                        <Table striped bordered hover responsive style={{ fontFamily: 'DM Sans', textAlign: 'center', marginTop: '25px' }}>
                            <thead>
                                <tr >
                                    <th>Medicine Name</th>
                                    <th>Upload Date </th>
                                    <th>Edit</th>
                                    <th>Active</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    pendingProductLoading ?
                                        <Spinner animation="border" role="status" className="mx-auto d-block">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                        :
                                        pendingProductData && pendingProductData.getPendingProduct.slice().reverse().map(pendingData =>
                                            <tr>
                                                <td>{pendingData.name}</td>
                                                <td><Moment format="DD-MM-YYYY, hh:mm A">{pendingData.uploadingDateTime}</Moment></td>
                                                <td><Button className="MedicineEdit" onClick={() => viewHandel(pendingData.id)}>View</Button></td>
                                                <td><Button className="MedicineDelete" onClick={() => activeHandle(pendingData.id)}>Active</Button></td>
                                            </tr>
                                        )
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Body>
                    <Container>
                        <Row>
                            {
                                productByIdLoading ?
                                    <Spinner animation="border" role="status" className='mx-auto d-block'>
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                    :
                                    <>
                                        <Col md={4}></Col>
                                        <Col md={4}>
                                            <SRLWrapper>
                                                <Carousel style={{ width: '100%', height: '200px' }}>
                                                    {
                                                        productByIdData && productByIdData.getProductById.image.map(productImage => {
                                                            return (
                                                                <Carousel.Item>
                                                                    <img
                                                                        className="d-block w-100"
                                                                        src={`https://bharatpharmamain.s3.us-east-2.amazonaws.com/${productImage}`}
                                                                        alt="Medicines Images"
                                                                        style={{ width: '100%', height: '200px' }}
                                                                    />
                                                                </Carousel.Item>
                                                            )
                                                        })
                                                    }
                                                </Carousel>
                                            </SRLWrapper>
                                        </Col>
                                        <Col md={4}></Col>
                                        <Row>
                                            <Table style={{ marginTop: '20px', fontSize: '12px', fontFamily: 'Dm Sans' }} responsive>
                                                <tbody>
                                                    <tr>
                                                        <td><strong>Medicine Name</strong></td>
                                                        <td>{productByIdData && productByIdData.getProductById.name}</td>
                                                        <td><strong>Brand Name</strong></td>
                                                        <td>{productByIdData && productByIdData.getProductById.brandName}</td>
                                                        <td><strong>Manufacturer</strong></td>
                                                        <td>{productByIdData && productByIdData.getProductById.manufacturer}</td>
                                                    </tr>

                                                    <tr>
                                                        <td><strong>Storage Container</strong></td>
                                                        <td>{productByIdData && productByIdData.getProductById.storageContainer}</td>
                                                        <td><strong>Expiration Time</strong></td>
                                                        <td>{productByIdData && productByIdData.getProductById.expireTime}</td>
                                                        <td><strong>Is Heat Sensitive?</strong></td>
                                                        <td>{productByIdData && productByIdData.getProductById.isHeatSensitive}</td>
                                                    </tr>

                                                    <tr>
                                                        <td><strong>Variation</strong></td>
                                                        <td>{productByIdData && productByIdData.getProductById.variation}</td>
                                                        <td><strong>SKU Number</strong></td>
                                                        <td>{productByIdData && productByIdData.getProductById.skuNo}</td>
                                                        <td><strong>Country Of Origin</strong></td>
                                                        <td>fdsff</td>
                                                    </tr>

                                                    <tr>
                                                        <td><strong>TAX Code</strong></td>
                                                        <td>{productByIdData && productByIdData.getProductById.taxCode}</td>
                                                        <td><strong>Shipping</strong></td>
                                                        <td>{productByIdData && productByIdData.getProductById.shipping}</td>
                                                        <td><strong>Your Price</strong></td>
                                                        <td>fdsff</td>
                                                    </tr>

                                                    <tr>
                                                        <td><strong>MRP</strong></td>
                                                        <td>{productByIdData && productByIdData.getProductById.mrp}</td>
                                                        <td><strong>Stock Available</strong></td>
                                                        <td>{productByIdData && productByIdData.getProductById.stockAvailable}</td>
                                                        <td><strong>HSN Code</strong></td>
                                                        <td>{productByIdData && productByIdData.getProductById.hsnCode}</td>
                                                    </tr>

                                                    <tr>
                                                        <td><strong>Is Product Contain Battery?</strong></td>
                                                        <td>{productByIdData && productByIdData.getProductById.isYourProductContaineBattery}</td>
                                                        <td><strong>Composition</strong></td>
                                                        <td>{productByIdData && productByIdData.getProductById.composition}</td>
                                                        <td><strong>Description</strong></td>
                                                        <td>{productByIdData && productByIdData.getProductById.description}</td>
                                                    </tr>

                                                    <tr>
                                                        <td><strong>Uses</strong></td>
                                                        <td>{productByIdData && productByIdData.getProductById.uses.join()}</td>
                                                        <td><strong>Safety Warning</strong></td>
                                                        <td>{productByIdData && productByIdData.getProductById.safetyWarning}</td>
                                                        <td><strong>Product Dimension</strong></td>
                                                        <td>{productByIdData && productByIdData.getProductById.productDimension}</td>
                                                    </tr>

                                                    <tr>
                                                        <td><strong>Product Weight</strong></td>
                                                        <td>{productByIdData && productByIdData.getProductById.productWeight}</td>
                                                        <td><strong>Dosage</strong></td>
                                                        <td>{productByIdData && productByIdData.getProductById.dosage}</td>
                                                        <td><strong>Flavour</strong></td>
                                                        <td>{productByIdData && productByIdData.getProductById.flavour}</td>
                                                    </tr>

                                                    <tr>
                                                        <td><strong>Medicine Form</strong></td>
                                                        <td>{productByIdData && productByIdData.getProductById.medicineForm}</td>
                                                        <td><strong>Uploading Date/Time</strong></td>
                                                        <td><Moment format="DD-MM-YYYY, hh:mm A">{productByIdData && productByIdData.getProductById.uploadingDateTime}</Moment></td>
                                                        <td><strong>Status</strong></td>
                                                        <td>{productByIdData && productByIdData.getProductById.status}</td>
                                                    </tr>

                                                </tbody>
                                            </Table>
                                        </Row>
                                    </>
                            }
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose} size="sm">Close</Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showConfirm} onHide={handleCloseConfirm} centered>
                <Modal.Body>
                    <Container>
                        <h1 style={{ fontSize: '25px', textAlign: 'center', fontFamily: 'DM Sans', fontWeight: 'bold' }}>Are you sure to active this product ?</h1>
                        <br />
                        <Row>
                            <Col><Button variant="success" onClick={activeHandleYes} size="sm" className="mx-auto d-block" style={{ fontFamily: 'DM Sans', width: '100px', borderRadius: '0px' }}>Yes</Button></Col>
                            <Col><Button variant="danger" onClick={handleCloseConfirm} size="sm" className="mx-auto d-block" style={{ fontFamily: 'DM Sans', width: '100px', borderRadius: '0px' }}>No</Button></Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}
