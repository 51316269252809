import React, { useState } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import ChooseCategory from './ChooseCategory'
import { FaArrowAltCircleLeft } from "react-icons/fa";

import Covid from './forms/Covid'
import BeautyPersonalCare from './forms/BeautyPersonalCare'
import Surgical from './forms/Surgical'
import Fitness from './forms/Fitness'
import BabyCare from './forms/BabyCare'
import Devices from './forms/Devices'
import SexualWellness from './forms/SexualWellness'
import Ayush from './forms/Ayush'
import WomenCorner from './forms/WomenCorner'
import Medicine from './forms/Medicine'
import Diabetes from './forms/Diabetes'

export default function ChooseSubCategory(props) {



    const [categoryState, setCategoryState] = useState(null)
    const [backClick, setBackClick] = useState(false)
    const [proceedClick, setProceedClick] = useState(false)

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            setProceedClick(true);
        }

        setValidated(true);
    };

    if (backClick) {
        return <ChooseCategory />
    }
    if (proceedClick) {
        if (categoryState === 'Covid-Personal & Home Essential') {
            return <Covid cat={'Covid-Personal & Home Essential'} />
        }
        if (categoryState === 'Mask,Gloves & Protective Equipment') {
            return <Covid cat={'Mask,Gloves & Protective Equipment'} />
        }
        if (categoryState === 'Immunity Booster') {
            return <Covid cat={'Immunity Booster'} />
        }
        if (categoryState === 'Other Essential') {
            return <Covid cat={'Other Essential'} />
        }




        if (categoryState === 'Beauty & Personal Care_ElderlyCare_Adult diapers') {
            return <BeautyPersonalCare cat={'Beauty & Personal Care_ElderlyCare_Adult diapers'} />
        }
        if (categoryState === 'Beauty & Personal Care_ElderlyCare_Bone and Joint Health') {
            return <BeautyPersonalCare cat={'Beauty & Personal Care_ElderlyCare_Bone and Joint Health'} />
        }
        if (categoryState === 'Beauty & Personal Care_ElderlyCare_Living and Safety Aids') {
            return <BeautyPersonalCare cat={'Beauty & Personal Care_ElderlyCare_Living and Safety Aids'} />
        }
        if (categoryState === 'Beauty & Personal Care_ElderlyCare_Orthopedic Support') {
            return <BeautyPersonalCare cat={'Beauty & Personal Care_ElderlyCare_Orthopedic Support'} />
        }
        if (categoryState === 'Beauty & Personal Care_HairCare_Hair Styling') {
            return <BeautyPersonalCare cat={'Beauty & Personal Care_HairCare_Hair Styling'} />
        }
        if (categoryState === 'Beauty & Personal Care_HairCare_Hair Oil') {
            return <BeautyPersonalCare cat={'Beauty & Personal Care_HairCare_Hair Oil'} />
        }

        if (categoryState === 'Beauty & Personal Care_HairCare_Hair Shampoo') {
            return <BeautyPersonalCare cat={'Beauty & Personal Care_HairCare_Hair Shampoo'} />
        }

        if (categoryState === 'Beauty & Personal Care_HairCare_Hair Colour') {
            return <BeautyPersonalCare cat={'Beauty & Personal Care_HairCare_Hair Colour'} />
        }
        if (categoryState === 'Beauty & Personal Care_HairCare_Self Treatment') {
            return <BeautyPersonalCare cat={'Beauty & Personal Care_HairCare_Self Treatment'} />
        }
        if (categoryState === 'Beauty & Personal Care_HairCare_Hairs Tools & Accessories') {
            return <BeautyPersonalCare cat={'Beauty & Personal Care_HairCare_Hairs Tools & Accessories'} />
        }
        if (categoryState === 'Beauty & Personal Care_SkinCare_Creams') {
            return <BeautyPersonalCare cat={'Beauty & Personal Care_SkinCare_Creams'} />
        }
        if (categoryState === 'Beauty & Personal Care_SkinCare_Cleanser') {
            return <BeautyPersonalCare cat={'Beauty & Personal Care_SkinCare_Cleanser'} />
        }
        if (categoryState === 'Beauty & Personal Care_SkinCare_Masks') {
            return <BeautyPersonalCare cat={'Beauty & Personal Care_SkinCare_Masks'} />
        }
        if (categoryState === 'Beauty & Personal Care_SkinCare_Moisturizer') {
            return <BeautyPersonalCare cat={'Beauty & Personal Care_SkinCare_Moisturizer'} />
        }
        if (categoryState === 'Beauty & Personal Care_SkinCare_Sun’s Cream') {
            return <BeautyPersonalCare cat={'Beauty & Personal Care_SkinCare_Sun’s Cream'} />
        }
        if (categoryState === 'Beauty & Personal Care_SkinCare_Eye Care') {
            return <BeautyPersonalCare cat={'Beauty & Personal Care_SkinCare_Eye Care'} />
        }
        if (categoryState === 'Beauty & Personal Care_SkinCare_Toners & Serums') {
            return <BeautyPersonalCare cat={'Beauty & Personal Care_SkinCare_Toners & Serums'} />
        }
        if (categoryState === 'Beauty & Personal Care_SkinCare_Face Skin') {
            return <BeautyPersonalCare cat={'Beauty & Personal Care_SkinCare_Face Skin'} />
        }
        if (categoryState === 'Beauty & Personal Care_SkinCare_Bath & Showers') {
            return <BeautyPersonalCare cat={'Beauty & Personal Care_SkinCare_Bath & Showers'} />
        }
        if (categoryState === 'Beauty & Personal Care_SkinCare_Makeup_Lips') {
            return <BeautyPersonalCare cat={'Beauty & Personal Care_SkinCare_Makeup_Lips'} />
        }
        if (categoryState === 'Beauty & Personal Care_SkinCare_Makeup_Eyes') {
            return <BeautyPersonalCare cat={'Beauty & Personal Care_SkinCare_Makeup_Eyes'} />
        }
        if (categoryState === 'Beauty & Personal Care_SkinCare_Makeup_Nails') {
            return <BeautyPersonalCare cat={'Beauty & Personal Care_SkinCare_Makeup_Nails'} />
        }
        if (categoryState === 'Beauty & Personal Care_SkinCare_Makeup_Face Makeup') {
            return <BeautyPersonalCare cat={'Beauty & Personal Care_SkinCare_Makeup_Face Makeup'} />
        }
        if (categoryState === 'Beauty & Personal Care_SkinCare_Makeup_Makeup Tools & Brushes') {
            return <BeautyPersonalCare cat={'Beauty & Personal Care_SkinCare_Makeup_Makeup Tools & Brushes'} />
        }
        if (categoryState === 'Beauty & Personal Care_MensGrooming_Shaving') {
            return <BeautyPersonalCare cat={'Beauty & Personal Care_MensGrooming_Shaving'} />
        }
        if (categoryState === 'Beauty & Personal Care_MensGrooming_Beard Care') {
            return <BeautyPersonalCare cat={'Beauty & Personal Care_MensGrooming_Beard Care'} />
        }





        if (categoryState === 'Surgical_Dressing') {
            return <Surgical cat={'Surgical_Dressing'} />
        }
        if (categoryState === 'Surgical_G.I. Care') {
            return <Surgical cat={'Surgical_G.I. Care'} />
        }
        if (categoryState === 'Surgical_I.V. Infection') {
            return <Surgical cat={'Surgical_I.V. Infection'} />
        }
        if (categoryState === 'Surgical_Respiratory Supplies') {
            return <Surgical cat={'Surgical_Respiratory Supplies'} />
        }
        if (categoryState === 'Surgical_Surgical Consumable') {
            return <Surgical cat={'Surgical_Surgical Consumable'} />
        }
        if (categoryState === 'Surgical_Instruments') {
            return <Surgical cat={'Surgical_Instruments'} />
        }
        if (categoryState === 'Surgical_Urinary Care') {
            return <Surgical cat={'Surgical_Urinary Care'} />
        }
        if (categoryState === 'Surgical_Support&Braces_Neck & Shoulder Support') {
            return <Surgical cat={'Surgical_Support&Braces_Neck & Shoulder Support'} />
        }
        if (categoryState === 'Surgical_Support&Braces_Knee & Leg Support') {
            return <Surgical cat={'Surgical_Support&Braces_Knee & Leg Support'} />
        }
        if (categoryState === 'Surgical_Support&Braces_Back & Abdomen Support') {
            return <Surgical cat={'Surgical_Support&Braces_Back & Abdomen Support'} />
        }
        if (categoryState === 'Surgical_Support&Braces_Ankle & Foot Support') {
            return <Surgical cat={'Surgical_Support&Braces_Ankle & Foot Support'} />
        }
        if (categoryState === 'Surgical_Support&Braces_Hand & Wrist Braces') {
            return <Surgical cat={'Surgical_Support&Braces_Hand & Wrist Braces'} />
        }
        if (categoryState === 'Surgical_Support&Braces_Arm & Elbow support') {
            return <Surgical cat={'Surgical_Support&Braces_Arm & Elbow support'} />
        }
        if (categoryState === 'Surgical_Support&Braces_Cervical Pillow') {
            return <Surgical cat={'Surgical_Support&Braces_Cervical Pillow'} />
        }
        if (categoryState === 'Surgical_Support&Braces_Compression Support & Sleeves') {
            return <Surgical cat={'Surgical_Support&Braces_Compression Support & Sleeves'} />
        }
        if (categoryState === 'Surgical_Support&Braces_Heels Support') {
            return <Surgical cat={'Surgical_Support&Braces_Heels Support'} />
        }




        if (categoryState === 'Fitness_Vitamin & Suppliments') {
            return <Fitness cat={'Fitness_Vitamin & Suppliments'} />
        }
        if (categoryState === 'Fitness_Family & Nutrition') {
            return <Fitness cat={'Fitness_Family & Nutrition'} />
        }
        if (categoryState === 'Fitness_Health Food & Drink') {
            return <Fitness cat={'Fitness_Health Food & Drink'} />
        }
        if (categoryState === 'Fitness_Ayurvedic Supplements') {
            return <Fitness cat={'Fitness_Ayurvedic Supplements'} />
        }
        if (categoryState === 'Fitness_Sports Suppliments') {
            return <Fitness cat={'Fitness_Sports Suppliments'} />
        }
        if (categoryState === 'Fitness_Smoking Cessation Support') {
            return <Fitness cat={'Fitness_Smoking Cessation Support'} />
        }
        if (categoryState === 'Fitness_Weight Management') {
            return <Fitness cat={'Fitness_Weight Management'} />
        }
        if (categoryState === 'Fitness_Immunity Booster') {
            return <Fitness cat={'Fitness_Immunity Booster'} />
        }




        if (categoryState === 'Baby Care_Lotion') {
            return <BabyCare cat={'Baby Care_Lotion'} />
        }
        if (categoryState === 'Baby Care_Supplement') {
            return <BabyCare cat={'Baby Care_Supplement'} />
        }
        if (categoryState === 'Baby Care_Diaper & Wipes') {
            return <BabyCare cat={'Baby Care_Diaper & Wipes'} />
        }
        if (categoryState === 'Baby Care_Baby Safety') {
            return <BabyCare cat={'Baby Care_Baby Safety'} />
        }
        if (categoryState === 'Baby Care_BabySkinCare_Creams') {
            return <BabyCare cat={'Baby Care_BabySkinCare_Creams'} />
        }
        if (categoryState === 'Baby Care_BabySkinCare_Lotions') {
            return <BabyCare cat={'Baby Care_BabySkinCare_Lotions'} />
        }
        if (categoryState === 'Baby Care_BabySkinCare_Oil') {
            return <BabyCare cat={'Baby Care_BabySkinCare_Oil'} />
        }
        if (categoryState === 'Baby Care_BabySkinCare_Bathing Essential') {
            return <BabyCare cat={'Baby Care_BabySkinCare_Bathing Essential'} />
        }



        if (categoryState === 'Devices_Mask') {
            return <Devices cat={'Devices_Mask'} />
        }
        if (categoryState === 'Devices_B.P. Monitor') {
            return <Devices cat={'Devices_B.P. Monitor'} />
        }
        if (categoryState === 'Devices_Nebulizer') {
            return <Devices cat={'Devices_Nebulizer'} />
        }
        if (categoryState === 'Devices_Vaporizer') {
            return <Devices cat={'Devices_Vaporizer'} />
        }
        if (categoryState === 'Devices_Oximeter') {
            return <Devices cat={'Devices_Oximeter'} />
        }
        if (categoryState === 'Devices_Pedometers') {
            return <Devices cat={'Devices_Pedometers'} />
        }
        if (categoryState === 'Devices_Vital signs monitors & wearable') {
            return <Devices cat={'Devices_Vital signs monitors & wearable'} />
        }
        if (categoryState === 'Devices_Oxygen concentrator') {
            return <Devices cat={'Devices_Oxygen concentrator'} />
        }
        if (categoryState === 'Devices_Weigh Scale') {
            return <Devices cat={'Devices_Weigh Scale'} />
        }
        if (categoryState === 'Devices_Thermometers') {
            return <Devices cat={'Devices_Thermometers'} />
        }
        if (categoryState === 'Devices_Body Massagers') {
            return <Devices cat={'Devices_Body Massagers'} />
        }
        if (categoryState === 'Devices_Diabetic Monitor') {
            return <Devices cat={'Devices_Diabetic Monitor'} />
        }
        if (categoryState === 'Devices_Exercise Equipments') {
            return <Devices cat={'Devices_Exercise Equipments'} />
        }




        if (categoryState === 'Sexual Wellness_Lubricants') {
            return <SexualWellness cat={'Sexual Wellness_Lubricants'} />
        }
        if (categoryState === 'Sexual Wellness_Massager/Vibrator') {
            return <SexualWellness cat={'Sexual Wellness_Massager/Vibrator'} />
        }
        if (categoryState === 'Sexual Wellness_Sprays/Gels') {
            return <SexualWellness cat={'Sexual Wellness_Sprays/Gels'} />
        }
        if (categoryState === 'Sexual Wellness_Condom') {
            return <SexualWellness cat={'Sexual Wellness_Condom'} />
        }
        if (categoryState === 'Sexual Wellness_Sexual Health Supplements') {
            return <SexualWellness cat={'Sexual Wellness_Sexual Health Supplements'} />
        }




        if (categoryState === 'Ayush_Homeopathic') {
            return <Ayush cat={'Ayush_Homeopathic'} />
        }
        if (categoryState === 'Ayush_Ayurvedic') {
            return <Ayush cat={'Ayush_Ayurvedic'} />
        }
        if (categoryState === 'Ayush_Yunani') {
            return <Ayush cat={'Ayush_Yunani'} />
        }




        if (categoryState === 'Women Corner_Female Hygiene') {
            return <WomenCorner cat={'Women Corner_Female Hygiene'} />
        }
        if (categoryState === 'Women Corner_Maternity Care') {
            return <WomenCorner cat={'Women Corner_Maternity Care'} />
        }
        if (categoryState === 'Women Corner_Maternity accessories') {
            return <WomenCorner cat={'Women Corner_Maternity accessories'} />
        }
        if (categoryState === 'Women Corner_Other') {
            return <WomenCorner cat={'Women Corner_Other'} />
        }


        if (categoryState === 'Medicines_ANTI DIABETIC') {
            return <Medicine cat={'Medicines_ANTI DIABETIC'} />
        }
        if (categoryState === 'Medicines_ANTI INFECTIVES') {
            return <Medicine cat={'Medicines_ANTI INFECTIVES'} />
        }
        if (categoryState === 'Medicines_ANTI MALARIALS') {
            return <Medicine cat={'Medicines_ANTI MALARIALS'} />
        }
        if (categoryState === 'Medicines_ANTI NEOPLASTICS') {
            return <Medicine cat={'Medicines_ANTI NEOPLASTICS'} />
        }
        if (categoryState === 'Medicines_BLOOD RELATED') {
            return <Medicine cat={'Medicines_BLOOD RELATED'} />
        }
        if (categoryState === 'Medicines_CARDIAC') {
            return <Medicine cat={'Medicines_CARDIAC'} />
        }
        if (categoryState === 'Medicines_DERMA') {
            return <Medicine cat={'Medicines_DERMA'} />
        }
        if (categoryState === 'Medicines_GASTRO INTESTINAL') {
            return <Medicine cat={'Medicines_GASTRO INTESTINAL'} />
        }
        if (categoryState === 'Medicines_GYNAECOLOGICAL') {
            return <Medicine cat={'Medicines_GYNAECOLOGICAL'} />
        }
        if (categoryState === 'Medicines_HORMONES') {
            return <Medicine cat={'Medicines_HORMONES'} />
        }
        if (categoryState === 'Medicines_INSOMNIA JET LEG') {
            return <Medicine cat={'Medicines_INSOMNIA JET LEG'} />
        }
        if (categoryState === 'Medicines_NEURO CNS') {
            return <Medicine cat={'Medicines_NEURO CNS'} />
        }
        if (categoryState === 'Medicines_OBESITY') {
            return <Medicine cat={'Medicines_OBESITY'} />
        }
        if (categoryState === 'Medicines_OPHTHAL') {
            return <Medicine cat={'Medicines_OPHTHAL'} />
        }
        if (categoryState === 'Medicines_OPHTHAL OTOLOGICALS') {
            return <Medicine cat={'Medicines_OPHTHAL OTOLOGICALS'} />
        }
        if (categoryState === 'Medicines_OTOLOGICALS') {
            return <Medicine cat={'Medicines_OTOLOGICALS'} />
        }
        if (categoryState === 'Medicines_PAIN ANALGESICS') {
            return <Medicine cat={'Medicines_PAIN ANALGESICS'} />
        }
        if (categoryState === 'Medicines_RESPIRATORY') {
            return <Medicine cat={'Medicines_RESPIRATORY'} />
        }
        if (categoryState === 'Medicines_SEX STIMULANTS REJUVENATORS') {
            return <Medicine cat={'Medicines_SEX STIMULANTS REJUVENATORS'} />
        }
        if (categoryState === 'Medicines_STOMATOLOGICALS') {
            return <Medicine cat={'Medicines_STOMATOLOGICALS'} />
        }
        if (categoryState === 'Medicines_UROLOGY') {
            return <Medicine cat={'Medicines_UROLOGY'} />
        }
        if (categoryState === 'Medicines_VACCINES') {
            return <Medicine cat={'Medicines_VACCINES'} />
        }
        if (categoryState === 'Medicines_VITAMINS MINERALS NUTRIENTS') {
            return <Medicine cat={'Medicines_VITAMINS MINERALS NUTRIENTS'} />
        }


        if (categoryState === 'Diabetes_Glucometer') {
            return <Diabetes cat={'Diabetes_Glucometer'} />
        }
        if (categoryState === 'Diabetes_Test strips & Lancets') {
            return <Diabetes cat={'Diabetes_Test strips & Lancets'} />
        }
        if (categoryState === 'Diabetes_Sugar Substitutes') {
            return <Diabetes cat={'Diabetes_Sugar Substitutes'} />
        }
        if (categoryState === 'Diabetes_Diabetic Management Suppliment') {
            return <Diabetes cat={'Diabetes_Diabetic Management Suppliment'} />
        }
        if (categoryState === 'Diabetes_diabetesCare_Ayurvedic') {
            return <Diabetes cat={'Diabetes_diabetesCare_Ayurvedic'} />
        }
    }

    return (
        <>
            <Container style={{ marginTop: '100px' }}>
                <Row>
                    <Col>
                        <FaArrowAltCircleLeft style={{ fontSize: '25px', color: '#d63031' }} onClick={() => setBackClick(true)} />
                        <h1 style={{ fontFamily: 'DM Sans', fontSize: '30px', fontWeight: 'bold', marginBottom: '30px' }}>Choose Sub Category</h1>

                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="4">
                                    <Form.Control required as="select" onChange={(e) => setCategoryState(e.target.value)} style={{ borderRadius: '0px', fontFamily: 'DM Sans', fontSize: '15px' }}>
                                        <option value="" selected="selected" disabled="disabled" style={{ fontFamily: 'DM Sans' }}>Choose Sub Category</option>
                                        {
                                            props.categoryState === 'Covid-19' ?
                                                <>
                                                    <option value="Covid_Personal & Home Essential">Personal & Home Essential</option>
                                                    <option value="Covid_Mask,Gloves & Protective Equipment">Mask,Gloves & Protective Equipment</option>
                                                    <option value="Covid_Immunity Booster">Immunity Booster</option>
                                                    <option value="Covid_Other Essential">Other Essential</option> </> :
                                                props.categoryState === 'Beauty & Personal Care' ?
                                                    <>
                                                        <optgroup label="Elderly Care">
                                                            <option value="Beauty & Personal Care_ElderlyCare_Adult diapers">Adult diapers</option>
                                                            <option value="Beauty & Personal Care_ElderlyCare_Bone and Joint Health">Bone and Joint Health</option>
                                                            <option value="Beauty & Personal Care_ElderlyCare_Living and Safety Aids">Living and Safety Aids</option>
                                                            <option value="Beauty & Personal Care_ElderlyCare_Orthopedic Support">Orthopedic Support</option>
                                                        </optgroup>
                                                        <optgroup label="Hair Care">
                                                            <option value="Beauty & Personal Care_HairCare_Hair Styling">Hair Styling</option>
                                                            <option value="Beauty & Personal Care_HairCare_Hair Oil">Hair Oil</option>
                                                            <option value="Beauty & Personal Care_HairCare_Hair Shampoo">Hair Shampoo</option>
                                                            <option value="Beauty & Personal Care_HairCare_Hair Colour">Hair Colour</option>
                                                            <option value="Beauty & Personal Care_HairCare_Self Treatment">Self Treatment</option>
                                                            <option value="Beauty & Personal Care_HairCare_Hairs Tools & Accessories">Hairs Tools & Accessories</option>
                                                        </optgroup>
                                                        <optgroup label="Skin Care">
                                                            <option value="Beauty & Personal Care_SkinCare_Creams">Creams</option>
                                                            <option value="Beauty & Personal Care_SkinCare_Cleanser">Cleanser/Facewash</option>
                                                            <option value="Beauty & Personal Care_SkinCare_Masks">Masks</option>
                                                            <option value="Beauty & Personal Care_SkinCare_Moisturizer">Moisturizer</option>
                                                            <option value="Beauty & Personal Care_SkinCare_Sun’s Cream">Sun’s Cream</option>
                                                            <option value="Beauty & Personal Care_SkinCare_Eye Care">Eye Care</option>
                                                            <option value="Beauty & Personal Care_SkinCare_Toners & Serums">Toners & Serums</option>
                                                            <option value="Beauty & Personal Care_SkinCare_Face Skin">Face Skin</option>
                                                            <option value="Beauty & Personal Care_SkinCare_Bath & Showers">Bath & Showers</option>
                                                        </optgroup>
                                                        <optgroup label="Makeup">
                                                            <option value="Beauty & Personal Care_SkinCare_Makeup_Lips">Lips</option>
                                                            <option value="Beauty & Personal Care_SkinCare_Makeup_Eyes">Eyes</option>
                                                            <option value="Beauty & Personal Care_SkinCare_Makeup_Nails">Nails</option>
                                                            <option value="Beauty & Personal Care_SkinCare_Makeup_Face Makeup">Face Makeup</option>
                                                            <option value="Beauty & Personal Care_SkinCare_Makeup_Makeup Tools & Brushes">Makeup Tools & Brushes</option>
                                                        </optgroup>
                                                        <optgroup label="Men's Grooming">
                                                            <option value="Beauty & Personal Care_MensGrooming_Shaving">Shaving</option>
                                                            <option value="Beauty & Personal Care_MensGrooming_Beard Care">Beard Care</option>
                                                        </optgroup>
                                                    </> :
                                                    props.categoryState === 'Surgical' ?
                                                        <>
                                                            <optgroup label="Surgical">
                                                                <option value="Surgical_Dressing">Dressing</option>
                                                                <option value="Surgical_G.I. Care">G.I. Care</option>
                                                                <option value="Surgical_I.V. Infection">I.V. Infection</option>
                                                                <option value="Surgical_Respiratory Supplies">Respiratory Supplies</option>
                                                                <option value="Surgical_Surgical Consumable">Surgical Consumable</option>
                                                                <option value="Surgical_Instruments">Instruments</option>
                                                                <option value="Surgical_Urinary Care">Urinary Care</option>
                                                            </optgroup>
                                                            <optgroup label="Support & Braces">
                                                                <option value="Surgical_Support&Braces_Neck & Shoulder Support">Neck & Shoulder Support</option>
                                                                <option value="Surgical_Support&Braces_Knee & Leg Support">Knee & Leg Support</option>
                                                                <option value="Surgical_Support&Braces_Back & Abdomen Support">Back & Abdomen Support</option>
                                                                <option value="Surgical_Support&Braces_Ankle & Foot Support">Ankle & Foot Support</option>
                                                                <option value="Surgical_Support&Braces_Hand & Wrist Braces">Hand & Wrist Braces</option>
                                                                <option value="Surgical_Support&Braces_Arm & Elbow support">Arm & Elbow support</option>
                                                                <option value="Surgical_Support&Braces_Cervical Pillow">Cervical Pillow</option>
                                                                <option value="Surgical_Support&Braces_Compression Support & Sleeves">Compression Support & Sleeves</option>
                                                                <option value="Surgical_Support&Braces_Heels Support">Heels Support</option>
                                                            </optgroup>
                                                        </>

                                                        :
                                                        props.categoryState === 'Fitness' ?
                                                            <>
                                                                <option value="Fitness_Vitamin & Suppliments">Vitamin & Suppliments</option>
                                                                <option value="Fitness_Family & Nutrition">Family & Nutrition</option>
                                                                <option value="Fitness_Health Food & Drink">Health Food & Drink</option>
                                                                <option value="Fitness_Ayurvedic Supplements">Ayurvedic Supplements</option>
                                                                <option value="Fitness_Sports Suppliments">Sports Suppliments</option>
                                                                <option value="Fitness_Smoking Cessation Support">Smoking Cessation Support</option>
                                                                <option value="Fitness_Weight Management">Weight Management</option>
                                                                <option value="Fitness_Immunity Booster">Immunity Booster</option>
                                                            </>
                                                            :
                                                            props.categoryState === 'Baby Care' ?
                                                                <>
                                                                    <optgroup label="Baby Care">
                                                                        <option value="Baby Care_Lotion">Lotion</option>
                                                                        <option value="Baby Care_Supplement">Supplement</option>
                                                                        <option value="Baby Care_Diaper & Wipes">Diaper & Wipes</option>
                                                                        <option value="Baby Care_Baby Safety">Baby Safety</option>
                                                                    </optgroup>
                                                                    <optgroup label="Baby Skin Care">
                                                                        <option value="Baby Care_BabySkinCare_Creams">Creams</option>
                                                                        <option value="Baby Care_BabySkinCare_Lotions">Lotions</option>
                                                                        <option value="Baby Care_BabySkinCare_Oil">Oil</option>
                                                                        <option value="Baby Care_BabySkinCare_Bathing Essential">Bathing Essential</option>
                                                                    </optgroup>


                                                                </>
                                                                :

                                                                props.categoryState === 'Devices' ?
                                                                    <>
                                                                        <option value="Devices_Mask">Mask</option>
                                                                        <option value="Devices_B.P. Monitor">B.P. Monitor</option>
                                                                        <option value="Devices_Nebulizer">Nebulizer</option>
                                                                        <option value="Devices_Vaporizer">Vaporizer</option>
                                                                        <option value="Devices_Oximeter">Oximeter</option>
                                                                        <option value="Devices_Pedometers">Pedometers</option>
                                                                        <option value="Devices_Vital signs monitors & wearable">Vital signs monitors & wearable</option>
                                                                        <option value="Devices_Oxygen concentrator">Oxygen concentrator</option>
                                                                        <option value="Devices_Weigh Scale">Weigh Scale</option>
                                                                        <option value="Devices_Thermometers">Thermometers</option>
                                                                        <option value="Devices_Body Massagers">Body Massagers</option>
                                                                        <option value="Devices_Diabetic Monitor">Diabetic Monitor</option>
                                                                        <option value="Devices_Exercise Equipments">Exercise Equipments</option>
                                                                    </> :
                                                                    props.categoryState === 'Sexual Wellness' ?
                                                                        <>
                                                                            <option value="Sexual Wellness_Lubricants">Lubricants</option>
                                                                            <option value="Sexual Wellness_Massager/Vibrator">Massager/Vibrator</option>
                                                                            <option value="Sexual Wellness_Sprays/Gels">Sprays/Gels</option>
                                                                            <option value="Sexual Wellness_Condom">Condom</option>
                                                                            <option value="Sexual Wellness_Sexual Health Supplements">Sexual Health Supplements</option>
                                                                        </> :
                                                                        props.categoryState === 'Ayush' ?
                                                                            <>
                                                                                <option value="Ayush_Homeopathic">Homeopathic</option>
                                                                                <option value="Ayush_Ayurvedic">Ayurvedic</option>
                                                                                <option value="Ayush_Yunani">Yunani</option>
                                                                            </> :
                                                                            props.categoryState === 'Women Corner' ?
                                                                                <>
                                                                                    <option value="Women Corner_Female Hygiene">Female Hygiene</option>
                                                                                    <option value="Women Corner_Maternity Care">Maternity Care</option>
                                                                                    <option value="Women Corner_Maternity accessories">Maternity accessories</option>
                                                                                    <option value="Women Corner_Other">Other</option>
                                                                                </> :
                                                                                props.categoryState === 'Medicine' ?
                                                                                    <>
                                                                                        <option value="Medicines_ANTI DIABETIC">ANTI DIABETIC</option>
                                                                                        <option value="Medicines_ANTI INFECTIVES">ANTI INFECTIVES</option>
                                                                                        <option value="Medicines_ANTI MALARIALS">ANTI MALARIALS</option>
                                                                                        <option value="Medicines_ANTI NEOPLASTICS">ANTI NEOPLASTICS</option>
                                                                                        <option value="Medicines_BLOOD RELATED">BLOOD RELATED</option>
                                                                                        <option value="Medicines_CARDIAC">CARDIAC</option>
                                                                                        <option value="Medicines_DERMA">DERMA</option>
                                                                                        <option value="Medicines_GASTRO INTESTINAL">GASTRO INTESTINAL</option>
                                                                                        <option value="Medicines_GYNAECOLOGICAL">GYNAECOLOGICAL</option>
                                                                                        <option value="Medicines_HORMONES">HORMONES</option>
                                                                                        <option value="Medicines_INSOMNIA JET LEG">INSOMNIA JET LEG</option>
                                                                                        <option value="Medicines_NEURO CNS">NEURO CNS</option>
                                                                                        <option value="Medicines_OBESITY">OBESITY</option>
                                                                                        <option value="Medicines_OPHTHAL">OPHTHAL</option>
                                                                                        <option value="Medicines_OPHTHAL OTOLOGICALS">OPHTHAL OTOLOGICALS</option>
                                                                                        <option value="Medicines_OTOLOGICALS">OTOLOGICALS</option>
                                                                                        <option value="Medicines_PAIN ANALGESICS">PAIN ANALGESICS</option>
                                                                                        <option value="Medicines_RESPIRATORY">RESPIRATORY</option>
                                                                                        <option value="Medicines_SEX STIMULANTS REJUVENATORS">SEX STIMULANTS REJUVENATORS</option>
                                                                                        <option value="Medicines_STOMATOLOGICALS">STOMATOLOGICALS</option>
                                                                                        <option value="Medicines_UROLOGY">UROLOGY</option>
                                                                                        <option value="Medicines_VACCINES">VACCINES</option>
                                                                                        <option value="Medicines_VITAMINS MINERALS NUTRIENTS">VITAMINS MINERALS NUTRIENTS</option>
                                                                                    </> :
                                                                                    props.categoryState === 'Diabetes' ?
                                                                                        <>
                                                                                            <optgroup label="Diabetes">
                                                                                                <option value="Diabetes_Glucometer">Glucometer</option>
                                                                                                <option value="Diabetes_Test strips & Lancets">Test strips & Lancets</option>
                                                                                                <option value="Diabetes_Sugar Substitutes">Sugar Substitutes</option>
                                                                                                <option value="Diabetes_Diabetic Management Suppliment">Diabetic Management Suppliment</option>
                                                                                            </optgroup>
                                                                                            <optgroup label="Diabetes Care">
                                                                                                <option value="Diabetes_diabetesCare_Ayurvedic">Ayurvedic</option>
                                                                                            </optgroup>
                                                                                        </>

                                                                                        : ""
                                        }

                                    </Form.Control>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">Please choose any Catagory.</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Button type="submit" style={{ fontFamily: 'DM Sans', fontSize: '15px', borderRadius: '0px', background: '#6c5ce7', borderColor: '#6c5ce7' }}>Proceed</Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

