import React, { useEffect, useState } from 'react'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from 'react-bootstrap'
import { getData } from './mockData'



export default function ExportCsv() {

    const [sheetData, setSheetData] = useState(null)

    useEffect(() => {
        setSheetData(getData());
    }, [])
    function handleOnExport() {
        var wb = XLSX.utils.book_new(),
        ws = XLSX.utils.json_to_sheet(sheetData)

        XLSX.utils.book_append_sheet(wb, ws, "Sheet");
        XLSX.writeFile(wb, "MyExcel.xlsx")
    }

    return (
        <>
            <div>ExportCsv</div>
            <Button variant="warning" onClick={handleOnExport}>Export</Button>
        </>
    )
}
