import React, { useState } from 'react'
import Top_Header from '../Top_Header'
import Navigation from '../Navigation'
import { Container, Col, Row, Form, Button, Spinner } from 'react-bootstrap'
import { useMutation } from '@apollo/client'
import { MUTATION_USER_SIGNUP, QUERY_GET_ALL_USER } from '../Queries'
import AWS from 'aws-sdk'
import swal from 'sweetalert';
import uniqid from 'uniqid';
import { useNavigate } from 'react-router-dom'

let stateArray = ['Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jammu and Kashmir', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttarakhand', 'Uttar Pradesh', 'West Bengal', 'Andaman and Nicobar Islands', 'Chandigarh', 'Dadra and Nagar Haveli', 'Daman and Diu', 'Delhi', 'Lakshadweep', 'Puducherry'];

const ID = 'AKIAYSBXIAITVZOA3FGO';
const SECRET = '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';
const BUCKET_NAME = 'bharatpharmamain';

const s3 = new AWS.S3({
    accessKeyId: ID,
    secretAccessKey: SECRET
});

export default function Signup() {
    let navigate = useNavigate();
    const [signupUser, { loading }] = useMutation(MUTATION_USER_SIGNUP, {
        refetchQueries: [
            QUERY_GET_ALL_USER
        ]
    });
    const [spin, setSpin] = useState(false);
    const [name, setName] = useState('');
    const [contact, setContact] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [pincode, setPincode] = useState('');
    const [userImg, setUserImg] = useState(null);
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        }
        else {
            event.preventDefault();
            setValidated(false);

            if (userImg === null) {
                signupUser({
                    variables: {
                        "userInput": {
                            "fullName": `${name}`,
                            "age": `${age}`,
                            "gender": `${gender}`,
                            "contact": `${contact}`,
                            "email": `${email}`,
                            "address": `${address}`,
                            "city": `${city}`,
                            "state": `${state}`,
                            "pincode": `${pincode}`,
                            "userImg": null
                        }
                    }
                })
                swal({ title: "Successfully!", text: "Signup Successfully!", icon: "success" });
                setValidated(false)
                event.target.reset()
                setSpin(true)
                navigate('/userLogin')
            }
            else {
                setSpin(true)
                try {
                    const uniqueId = uniqid()

                    var regex = new RegExp('[^.]+$');

                    var extension = userImg.name.match(regex);

                    var uniqueFileName = "userImg" + "-" + uniqueId + "." + extension[0];

                    const params = {
                        Bucket: BUCKET_NAME,
                        Key: uniqueFileName,
                        Body: userImg,
                    };
                    s3.upload(params, function (err, data) {
                        if (err) {
                            throw err;
                        }
                        else {
                            signupUser({
                                variables: {
                                    "userInput": {
                                        "fullName": `${name}`,
                                        "age": `${age}`,
                                        "gender": `${gender}`,
                                        "contact": `${contact}`,
                                        "email": `${email}`,
                                        "address": `${address}`,
                                        "city": `${city}`,
                                        "state": `${state}`,
                                        "pincode": `${pincode}`,
                                        "userImg": uniqueFileName
                                    }
                                }
                            })
                            swal({ title: "Successfully!", text: "Signup Successfully!", icon: "success" });
                            setValidated(false)
                            event.target.reset()
                            setSpin(true)
                            navigate('/userLogin')
                        }
                    });
                    setName('');
                    setContact('');
                    setEmail('');
                    setAddress('');
                    setCity('');
                    setState('');
                    setPincode('');
                    setUserImg('');
                    setAge('');
                    setGender('');
                }
                catch (err) {
                    console.log(err);
                    setSpin(true)
                }
            }




        }

    };

    return (
        <>
            <Top_Header />
            <Navigation />
            <Container>
                <Row>
                    <Col>
                        <h1 style={{ fontSize: '25px', fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'center', marginTop: '20px' }}>Signup</h1>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="4">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>Full Name</Form.Label>
                                    <Form.Control type="text" placeholder="Full Name" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={e => setName(e.target.value)} value={name} />
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>Contact</Form.Label>
                                    <Form.Control type="number" placeholder="Contact" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={e => setContact(e.target.value)} value={contact} />
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Email" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={e => setEmail(e.target.value)} value={email} />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="4">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>Address</Form.Label>
                                    <Form.Control as="textarea" placeholder="Address" rows={1} style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={e => setAddress(e.target.value)} value={address} />
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>City</Form.Label>
                                    <Form.Control type="text" placeholder="City" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={e => setCity(e.target.value)} value={city} />
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>State</Form.Label>
                                    <Form.Select name="state" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={e => setState(e.target.value)} value={state}>
                                        <option value="" selected="selected" disabled="disabled">Select State</option>
                                        {stateArray.map((d) => (
                                            <option value={d}>{d}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>

                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="4">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>Pincode</Form.Label>
                                    <Form.Control type="text" placeholder="Pincode" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={e => setPincode(e.target.value)} value={pincode} />
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>Age</Form.Label>
                                    <Form.Control type="number" placeholder="Age" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={e => setAge(e.target.value)} value={age} />
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>Gender</Form.Label>
                                    <Form.Select name="state" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={e => setGender(e.target.value)} value={gender}>
                                        <option value="" selected="selected" disabled="disabled">Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="4">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>Image</Form.Label>
                                    <Form.Control type="file" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(event) => { setUserImg(event.target.files[0]); }} />
                                </Form.Group>
                            </Row>
                            {
                                loading ?
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner> :
                                    spin ?
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner> :
                                        <Button type="submit">Signup</Button>
                            }
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
