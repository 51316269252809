import React from 'react'
import Navigation from './Navigation'
import Top_Header from './Top_Header'
import Footer from './Footer'
import { Container, Row, Col } from 'react-bootstrap'

export default function Terms() {
    return (
        <>
            {
                window.scrollTo(0, 0)
            }
            <Top_Header />
            <Navigation />
            <Container fluid>
                <Row style={{ textAlign: 'center' }}>
                    <Col md={12}>
                        <u>
                            <h1 style={{ fontSize: 36, color: '#2980b9' }}>
                                TERMS OF USE BY   </h1>
                        </u>

                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <p>TERMS OF USE BY</p>
                        <p>&nbsp;</p>
                        <p>CONTINUING TO ACCESS AND USE THIS WEBSITE/MOBILE APPLICATION YOU CONFIRM THAT YOU ACCEPT OUR TERMS OF USE SET OUT BELOW. IF YOU DO NOT ACCEPT THE TERMS OF USE, YOU MUST LEAVE THIS WEBSITE/MOBILE APPLICATION.</p>
                        <p>&nbsp;</p>
                        <p>About Us and General Terms</p>
                        <p>&nbsp;</p>
                        <p>www.bharatpharma.in, an internet based portal and &ldquo;Bharat Pharma&rdquo;, the mobile application (hereinafter together be referred to as &ldquo;Platform&rdquo;) is owned and operated by Nanopharm Healthcare Solutions Private Limited (hereinafter &ldquo;Bharat Pharma&rdquo; or &ldquo;We&rdquo; or &ldquo;Our&rdquo; or &ldquo;Us&rdquo; or &ldquo;Company&rdquo;), a company incorporated under the Companies Act, 2013 and having its licensed premises at Sagar, and any other premises, which may be added in the sole discretion of Bharat Pharma (&ldquo;Licensed Premises&rdquo;). If You need any information or have a complaint about this website/mobile application or any of our services, please contact Us by writing to Us at nanopharmhealthcaresolutions@gmail.com</p>
                        <p>&nbsp;</p>
                        <p>The Platform facilitates the online sale of conventional &amp; alternative pharmaceuticals products, excluding any and all drugs listed under Schedule X of the Drugs &amp; Cosmetics Act, 1940 and all its corresponding rules (hereinafter referred to as &ldquo;Products&rdquo;). The Platform is a platform that facilitates (i) online sale and purchase of Products procured by Bharat Pharma &nbsp;from licensed distributors and manufacturers to You (hereinafter referred to as &ldquo;You&rdquo; or &ldquo;Your&rdquo; or the &ldquo;User&rdquo;) in accordance with the terms of use specified herein (hereinafter referred to as the Terms of Use&rdquo;);</p>
                        <p>(ii) online medical teleconsultation services being offered by third party independent registered medical practitioners (&ldquo;Third Party RMP/s&rdquo;); and</p>
                        <p>(iv) delivery of the online pharmaceutical products ordered by You by independent third party delivery partners (&ldquo;Third Party Delivery Partners&rdquo;). The online sale and purchase of Products, facilitation of teleconsultation with Third Party RMPs and facilitation of delivery by Third Party Delivery Partners are together referred to as &ldquo;Services&rdquo;. Bharat Pharma has procured the applicable licenses required under the Drugs and Cosmetics Act, 1940 and the Food Safety and Standards Act, 2006 to carry out its Services.</p>
                        <p>&nbsp;</p>
                        <p><br /> The arrangement between the Third Party Delivery Partners, Third Party RMPs (hereinafter together to be referred as &ldquo;Third Party Service Providers&rdquo;), You and Us shall be governed in accordance with these Terms of Use. The Services would be made available to such natural persons who have agreed to use the Platform after obtaining due registration, in accordance with the procedure as determined by Us, from time to time.</p>
                        <p>&nbsp;</p>
                        <p>You agree and acknowledge that the Platform which You and Third Party Service Providers utilize to meet and interact with another for their respective transactions. Bharat Pharma is not and cannot be a party to, or, save as except as may be provided in these Terms of Use, control in any manner, any transaction between You and the Third Party Service Providers.</p>
                        <p>&nbsp;</p>
                        <p>Your access or use of the Platform, transaction on the Platform and use of Services hosted or managed remotely through the Platform , are governed by these Terms of Use, including the applicable policies which are incorporated herein by way of reference. These Terms of Use constitute a legal and binding contract between You on one part and Bharat Pharma on the other Part. By accessing or using the Platforms, you hereby confirm that You have the capacity to enter into a legally binding contract under Indian law, and in particular, the Indian Contract Act, 1872. Further, You represent that You are duly authorized as per applicable law by any third party (including a person under the age of 18 years) whose information you share with us. You hereby agree that Bharat Pharma shall be acting as per your representation of authority and shall not make any independent enquiries to ascertain the genuineness of your authorization. Any liability in relation to your acts and omissions in this regard or resulting from consequent actions taken by Us pursuant to your acts and omissions in accordance with this Terms of Use or Privacy Policy shall also solely be borne by you.</p>
                        <p>&nbsp;</p>
                        <p>By clicking on &ldquo;I Accept&rdquo; or accessing, browsing or in any way transacting on the Platform, or availing any Services, You signify Your agreement to be bound by these Terms of Use. Further, by impliedly or expressly accepting these Terms of Use, You also accept and agree to be bound by Our policies, including the Privacy Policy, and such other rules, guidelines, policies, terms and conditions as are relevant under the applicable law(s) in India and other jurisdictions for the purposes of accessing, browsing or transacting on the Platform , or availing any of the Services, and such rules, guidelines, policies, terms and conditions shall be deemed to be incorporated into, and considered as part and parcel of these Terms of Use. However, if You navigate away from the Platform to a third party website, the same shall be governed as per clause 10 of the Privacy Policy.</p>
                        <p>&nbsp;</p>
                        <p>These Terms of Use are effective from 08th March, 2022.</p>
                        <p>&nbsp;</p>
                        <p>Professional Standards</p>
                        <p>&nbsp;</p>
                        <p>Bharat Pharma&rsquo; Services (defined below) are rendered from its Licensed Premises and dispensation of drugs are carried out under the personal supervision of its registered pharmacists. All pharmacists are bound by codes of professional ethics and conduct.</p>
                        <p>&nbsp;</p>
                        <p>Privacy</p>
                        <p>&nbsp;</p>
                        <p>Your privacy and that of any other person whose information You provide to Us are important to us. Please see our Privacy Policy for details of what information We collect and how We will use and protect it.</p>
                        <p>&nbsp;</p>
                        <p>Registration</p>
                        <p>&nbsp;</p>
                        <p>By registering and using the services You agree that You are eighteen years or above and You are not debarred by any law to contract and You agree to have read and accepted the Terms of Use provided herein. Visitors to this website/mobile application are required to register in order to use its facilities/ services. We are not under any obligation to accept a request for registration and reserve the right to suspend or terminate access at any time if your continued use is believed to prejudice Us or other users. By registering to use this website /mobile application You confirm that the information You provide during the registration process is accurate and complete. You agree to update your registration details promptly if there are any changes. All registration information You provide will be kept secure and processed in accordance with our privacy policy</p>
                        <p>&nbsp;</p>
                        <p>Username and password</p>
                        <p>&nbsp;</p>
                        <p>During the registration process, You may be allocated, or invited to choose, your own username and password which will be unique to you or One-Time Password (&ldquo;OTP&rdquo;), which will be sent to your mobile phone number via SMS. You are responsible for keeping your username and password and OTP confidential and for ensuring that they are not used by any other person, for the purposes of availing the Services. As part of the registration process, Bharat Pharma may collect certain personal information from You including:</p>
                        <ol>
                            <li>Name of the patient;</li>
                            <li>Delivery Address (including country and ZIP/ postal code);</li>
                            <li>Gender;</li>
                            <li>Age;</li>
                            <li>Phone number;</li>
                            <li>Relationship to the person ordering (if applicable); and</li>
                            <li>Mode of Payment</li>
                        </ol>
                        <p>&nbsp;</p>
                        <p>For further details regarding the information We collect, please refer to the Privacy Policy.</p>
                        <p>&nbsp;</p>
                        <p>Prescriptions for persons other than yourself</p>
                        <p>&nbsp;</p>
                        <p>If the prescription is of a person other than yourself, You will need to have the authority of the person whose prescription it is to have it dispensed by us. By placing an order to dispense another person&rsquo;s prescription You confirm that You have their authority to do so.</p>
                        <p>&nbsp;</p>
                        <p>Age Restrictions</p>
                        <p>&nbsp;</p>
                        <p>We accept orders of Products, after a valid prescription has been uploaded/ generated in accordance with the Terms of Use (&ldquo;Prescription Orders&rdquo;) for Users who are 18 years old or over and for Prescription Orders for customers below the age of 18 years, the User so ordering must have the requisite authority to undertake such an order in accordance with applicable laws. By placing an order, You confirm that You are at least 18 years and legally competent to contract.</p>
                        <p>&nbsp;</p>
                        <p>Residence</p>
                        <p>&nbsp;</p>
                        <p>We accept Orders from and dispatch orders to addresses in selected locations in India.</p>
                        <p>&nbsp;</p>
                        <p>Binding agreement</p>
                        <p>&nbsp;</p>
                        <p>No order of Products placed by You will be binding on Us until We have confirmed the order to You by phone, email or in writing, in accordance with this Terms of Use. We reserve the right to reject any order. All orders are delivered subject to receipt of valid prescription, the delivery by our Third Party Delivery Partners to the relevant pin code entered by you, verification of the prescription by registered pharmacists, and the ascertainment of available stock with the licensed distributors of Bharat Pharma.</p>
                        <p>&nbsp;</p>
                        <p>Non-Receipt of Prescription</p>
                        <p>&nbsp;</p>
                        <p>If You place a Prescription Order and We do not receive the relevant original prescription, We will not have any liability to You and it will be your responsibility to contact Us within 7 days regarding your prescription order.</p>
                        <p>&nbsp;</p>
                        <p>Verification of prescription</p>
                        <p>&nbsp;</p>
                        <p>You will upload a legible, valid and legal medical prescription written by a medical practitioner registered in India under the Indian Medical Council Act, 1956, having a valid Certificate of Practice as on the date of uploading of the prescription on the Platform. A valid prescription issued by your medical expert/ doctor or by a Third Party RMP, pursuant to an online consultation, is required for placing an order for Products, in accordance with the Drugs and Cosmetics Act, 1940 and the rules made thereunder, as amended from time to time. If the prescription contains unusual quantities of medicines or Schedule X Drugs, Bharat Pharma reserves its right to reject the order.</p>
                        <p>&nbsp;</p>
                        <p>Upon uploading of the prescription, our pharmacist will verify it against the information provided to Us at the time the order was placed. In the event that the information does not match with your original order as per the prescription, We may try to contact You using the information provided by You during your registration. If We cannot contact You and We are unable to dispense the item(s) on your Prescription Order We reserve the right to return your prescription to you. Our pharmacist may, if required or if deemed necessary in its judgment, contact the medical practitioner on behalf of the patient to verify that the prescription has been read, translated/transcribed and ordered correctly.</p>
                        <p>&nbsp;</p>
                        <p>You represent and warrant that You will furnish to Us for placing the order only the original or an electronic copy of the original prescription issued by a medical practitioner. You further represent and warrant:</p>
                        <p>&nbsp;</p>
                        <p>(a) that such prescription, whether the original or an electronic copy of the prescription, shall not be furnished to any other pharmacist if it is stated thereon by the prescriber, directly or impliedly, that the prescription drugs can be dispensed only once against that specific prescription; and</p>
                        <p>&nbsp;</p>
                        <p>(b) that if the prescription directs that the prescription drugs may be dispensed a certain number of times or at stated intervals, the User shall not exceed these stated limits in furnishing the original or the electronic copy of the prescription to Bharat Pharma and placing orders with it.</p>
                        <p>&nbsp;</p>
                        <p>You undertake and warrant not to upload/email/submit any false, illegal, lapsed, tampered, doctored, falsified or incorrect prescription(s), under any circumstances, whether under active knowledge or erroneously under any mistaken beliefs or assumptions. In case You are in breach of any of the obligations mentioned herein, Bharat Pharma shall assume no liability whatsoever for the Product(s) sold. Further, all prescriptions so uploaded may be archived by Bharat Pharma in Your account, subject to the Privacy Policy.</p>
                        <p>&nbsp;</p>
                        <p>You represent and warrant that You shall purchase Products only for bona fide and legitimate patients and not for the purpose of re-sale or any other activity not permissible under applicable laws. You declare yourself to be a bona fide consumer of the ordered medicines, which are intended to be used as prescribed and in no case shall the ordered medicines be allowed to be misused or put to any unauthorized use.</p>
                        <p>&nbsp;</p>
                        <p>Notwithstanding anything contained in this Agreement, Bharat Pharma reserves its right to use all means, including contacting the medical practitioners and Users, to determine the authenticity of the prescriptions and the orders placed by Users.</p>
                        <p>&nbsp;</p>
                        <p>You understand and acknowledge that Bharat Pharma is not the manufacturer of the Products and accordingly, shall not be liable in any manner whatsoever for any liability, loss, damage, compensation etc. that is legally attributable to the manufacturer of the Product or is due to any manufacturing fault/lapse/error.</p>
                        <p>&nbsp;</p>
                        <p><br /> Online Consultation with Third Party RMPs</p>
                        <p>&nbsp;</p>
                        <p>Whenever We use the words &ldquo;your physician&rdquo; or &ldquo;your doctor&rdquo; or similar words on the Platform, including in these Terms of Use, We mean the registered medical practitioner You otherwise consult who is not the Third Party RMP.</p>
                        <p>&nbsp;</p>
                        <p>The Company is a facilitator of healthcare services that provides healthcare products and services to the Users. The Third Party RMPs engaged by the Company are not related to Company but merely provide medical consultation to You with your consent. You acknowledge that the Third Party RMPs engaged by Bharat Pharma are independent contractors, whose services are being availed by You upon your implied/ explicit consent. Hence, the Company, in no event, shall be directly or vicariously liable for any advice or medical consultancy or any loss arising from the Third Party RMPs.</p>
                        <p>&nbsp;</p>
                        <p>The opinions, statements, answers and tele-consultations (collectively &ldquo;Consultation&rdquo;) provided by the Third Party RMPs through the Platform are solely the individual and independent opinions and statements of such Third Party RMPs and do not reflect the opinions of Bharat Pharma . Bharat Pharma does not recommend or endorse any specific tests, physicians, products, procedures, opinions, or other information that may be mentioned on the Platform.</p>
                        <p>&nbsp;</p>
                        <p>You understand and agree that Bharat Pharma, through its Platform, merely facilitates the online requisition of Consultation services being offered by Third Party RMPs to the User. All commercial and contractual terms with respect to Consultation offered shall be agreed to between the Third Party RMPs and the Users. You further understand and agree that the Company, on Your behalf, shall pay the Third Party RMPs fees for the Consultation offered and the amount of fees paid to the Third Party RMPs shall be determined as per the policies of the Company. Further, by opting for the Consultation services, You explicitly consent to its initiation under applicable law.</p>
                        <p>&nbsp;</p>
                        <p>The Company does not recommend any specific registered medical practitioner(s), tests, products, opinions or other information that may be mentioned on the Platform. Reliance on any information provided on the Platform is solely at Your own risk. You should follow the advice of your doctor at all times. Further, it is Your responsibility for ensuring that the information submitted is accurate and the Company shall not make any effort to verify the accuracy of any information provided by You for using the Services.</p>
                        <p>&nbsp;</p>
                        <p>The inclusion of professionals, specialists and/ or Third Party RMPs on the Platform does not imply recommendation or endorsement of such specialists and/ or Third Party RMPs nor is such information intended as a tool for verifying the credentials, qualifications, or abilities of any specialists and/ or Third Party RMPs contained therein. Such information is provided on an &lsquo;as-is&rsquo; basis and Bharat Pharma disclaims all warranties, either express or implied, including but not limited to the implied warranties of merchantability and fitness for particular purpose.</p>
                        <p>&nbsp;</p>
                        <p>Bharat Pharma (the owners and the employee staff of Bharat Pharma), and Third Party RMPs accept no responsibility for any medical, legal or financial events or outcomes related to the Services availed through the use of the Platform.</p>
                        <p>&nbsp;</p>
                        <p>The Company makes no warranty that the Consultation will meet your requirements, or that the Service(s) will be uninterrupted, timely, secure, or error free. Subject to the Privacy Policy and applicable law, this includes loss of data or any service interruption caused by the Company employees, or any other transmission errors and corruption of data.</p>
                        <p>&nbsp;</p>
                        <p>Notwithstanding anything to the contrary contained herein, You alone shall be liable for your dealings and interaction with Third Party RMPs contacted or managed through the Platform and Bharat Pharma shall have no liability or responsibility in this regard. Bharat Pharma does not guarantee or make any representation with respect to the correctness, completeness or accuracy of the Information or detail provided by such client, patient, User, Third Party RMPs or any third party through the Platform.</p>
                        <p>&nbsp;</p>
                        <p>The exchanges between Third Party RMPs and the patient over the telephone (or any other platform as determined by Bharat Pharma) may be supervised, recorded and stored by Bharat Pharma, for the purposes of monitoring the quality of the consultation. You are advised to consult the Privacy Policy in this regard and note that by accepting or using Our Services to initiate Consultation, You are hereby providing explicit consent for such supervision, recording or storage.</p>
                        <p>&nbsp;</p>
                        <p>Bharat Pharma may, at its sole discretion, suspend User&rsquo;s or Third Party RMPs&rsquo; ability to use or access the Platform at any time while Bharat Pharma investigates complaints or alleged violations of these Terms of Use, or for any other reason.</p>
                        <p>&nbsp;</p>
                        <p>Substitute Medicines</p>
                        <p>&nbsp;</p>
                        <p>You acknowledge and accept that the order for a substitute of a Prescription Drug would be processed if your doctor has himself/ herself permitted for any other equivalent generic drug to be dispensed in place of the drug prescribed in your prescription or if the prescription solely lists the salt names instead of a specific brand name.</p>
                        <p>&nbsp;</p>
                        <p>You further acknowledge and accept that a substitute of a Prescription Drug may also be processed if the Third Party RMPs, after undertaking an independent and professional examination through teleconsultation, determine that a substitute of Prescription Drug may be prescribed. The Third Party RMPs shall in such a situation issue a prescription pursuant to an online consultation with the patient prescribing the aforesaid substitute drug. Such issuance of prescription by the Third Party RMPs for a substitute of a Prescription Drug shall be undertaken with your consent.</p>
                        <p>&nbsp;</p>
                        <p>The Company shall not, in any event, held responsible or liable for any loss or damages arising from the sale of substitute drugs to the Users.</p>
                        <p>&nbsp;</p>
                        <p>Disclaimer</p>
                        <p>&nbsp;</p>
                        <p><br /> The User understands and agrees Bharat Pharma is providing a technology service as an intermediary and that any complaint of health issues, consultation, unsatisfactory services, etc. in connection with the services provided to the User directly by the Third Party RMP, under this Terms of Use are between the User and the Third Party RMP and that at no point in time Bharat Pharma shall be held responsible for the same.</p>
                        <p>&nbsp;</p>
                        <p>Further, notwithstanding anything contained herein, Bharat Pharma shall not be liable for: any medical negligence, prescription of any wrong medication or treatment on part of the Third Party RMP; any type of inconvenience suffered by the User due to a failure on the part of the Third Party RMP in providing the agreed Services or to make himself/herself available at the appointed time, inappropriate treatment, or similar difficulties; any misconduct or inappropriate behaviour by the Third Party RMP; cancellation or rescheduling of booked appointment; any medical eventualities that might occur subsequent to using the services of the Third Party RMP.</p>
                        <p>&nbsp;</p>
                        <p>Subscription</p>
                        <p>&nbsp;</p>
                        <p>Under the subscription model, User can subscribe for his/her/its medicine requirement by choosing a subscription plan that best suit the Users needs and the User can customise the same according to the Users requirement. All orders under the subscription plan shall be automatically placed in accordance with the chosen subscription plan. All orders under the subscription model shall be subject to all the other applicable terms and conditions mentioned herein along with all statutory requirements for processing an order.</p>
                        <p>Clubbing of offers</p>
                        <p>&nbsp;</p>
                        <p>Any ongoing offer or promotional scheme cannot be clubbed with the orders placed under the subscription plan. All orders under the subscription plan shall be processed based on the standard discount and promotional scheme available at the time of processing any order under the subscription plan. Bharat Pharma shall have the sole discretion to decide with regard to the offers or scheme that shall be applicable to the orders placed under the subscription plans.</p>
                        <p>&nbsp;</p>
                        <p>Discount on Selected Products</p>
                        <p>&nbsp;</p>
                        <p>Discounts provided on the website/mobile application are on selected products. Discounts may be changed on a product to product basis at the sole discretion of Bharat Pharma&amp;Bharat Pharma will not be held liable for any issues/ inconvenience caused to users, resulting from these changes.</p>
                        <p>&nbsp;</p>
                        <p>Non-Availability / Suitability of Products</p>
                        <p>&nbsp;</p>
                        <p>If any of the items on your prescription are not available or are not suitable for dispensing through this service, We will try to contact You using the information provided by You to inform You about the same.</p>
                        <p>&nbsp;</p>
                        <p>Sale of prescription drugs</p>
                        <p>&nbsp;</p>
                        <p>The User confirms and acknowledges that notwithstanding anything contained in this Terms of Use, that the property and title in the Products ordered by the user shall stand immediately transferred to You upon the dispensation of Products and the raising of the invoice at the Licensed Premises. Accordingly, the sale of Products shall be concluded at the Licensed Premises, irrespective of the User&rsquo;s delivery location. Such sale shall be final, binding and conclusive irrespective of the mode of payment and delivery opted by the User. The User shall have an option to pick the most suitable payment and delivery mode from amongst the options given on the Platform. Once a sale invoice is raised and the User shall authorize a Third Party Delivery Partner as the User&rsquo;s agents, who shall dispatch the relevant Products ordered, on behalf of the User, to the delivery location as chosen by the User.</p>
                        <p>Prescription items can only be dispensed once We have received your original paper prescription or online prescription from the Third Party RMP. All items are delivered to the address provided by You in your registration or to an alternative address if directed by you. You acknowledge and accept that items will need to be signed for on delivery and authorise any person at your chosen delivery address to sign for the items as your authorised representative. In the event that the packaging is opened after delivery, We will have no liability to You for lost or damaged items or for what third parties find out about You as a result.</p>
                        <p>&nbsp;</p>
                        <p>Delivery of Drugs</p>
                        <p>&nbsp;</p>
                        <p>You agree to appoint a Third Party Delivery Partner who shall act in the capacity of Your agent and collect the medicines as requested by You from Licensed Premises on Your behalf (&ldquo;User Agent&rdquo;). You accept and acknowledge that the User Agent shall be responsible to collect the medicines ordered by You from the Licensed Premises and to carry it to the address notified by You.</p>
                        <p>&nbsp;</p>
                        <p>You further agree and acknowledge that the User Agent acts as Your agent for collecting the medicines from the Licensed Premises. The services are being undertaken by User Agent with Your consent and therefore the Company is merely facilitating for You and Users Agent to connect.</p>
                        <p>&nbsp;</p>
                        <p>You agree and acknowledge that the Third-Party Delivery Partners are independent contractors, and not agents or employees of the Company, whose services are being availed by You upon your implied/ explicit consent solely for the delivery of the Products ordered by You through the Platform .</p>
                        <p>&nbsp;</p>
                        <p>All drugs and cosmetics will be delivered through a delivery agent without disclosing your personal information or prescriptions in compliance with the applicable laws either directly to the patient or its authorised person.</p>
                        <p>&nbsp;</p>
                        <p>No Warranties</p>
                        <p>&nbsp;</p>
                        <p>Bharat Pharma makes no warranty that:</p>
                        <p>(i) the application will meet your requirements;</p>
                        <p>(ii) the application will be available on an uninterrupted, timely, secure, or error-free basis; or that (iii) any results that may be obtained from the use of the application or any services offered through the Platform will be accurate or reliable. Bharat Pharma disclaims all warranties as to the accuracy, completeness or adequacy of such information.</p>
                        <p>&nbsp;</p>
                        <p>Bharat pharma disclaims all liabilities arising from manufacturing defects and the claims, if any, with regard to a defect in manufacturing or otherwise shall be made directly on the manufacturer whose information is provided on the product. Bharat Pharma may at its sole discretion assist User in providing any information that may be required with regard to the claim.</p>
                        <p>&nbsp;</p>
                        <p>Acceptance of Orders</p>
                        <p>&nbsp;</p>
                        <p>All orders are subject to acceptance and confirmation of delivery date and time. Mere receipt of the prescription or order either directly from You or from the Third Party RMP does not amount to acceptance of your order nor guarantee that the drugs will be delivered as per your requirement within a specific time. All orders are subject to availability of stocks. Bharat Pharma reserves the right to share your prescription with its associated pharmacies, distributors and/ or manufacturers for fulfilling your prescription, who will dispense the drugs as per the prescription and issue a valid sales receipt. All orders made by the customer shall be subject to various laws, including the Drugs and Cosmetics Act (as may be amended from time to time). We do not accept orders for certain drugs and cosmetics unless the same is prescribed by the registered medical practitioner licensed by the Medical Council of India. We do not verify the authenticity of the orders placed by you, where the prescriptions are received directly from a registered medical practitioner licensed by the Medical Council of India or your doctor, who has placed the order on your behalf after your authorization.</p>
                        <p>&nbsp;</p>
                        <p>Payment of Price</p>
                        <p>&nbsp;</p>
                        <p>On delivery of the relevant Products to the Users, Bharat Pharma shall be entitled to full payment for the same. You agree that the prices mentioned on our website or mobile app are approximate estimates of the actual/exact price of the drugs. The actual/exact price of the drug will depend upon its batch number and will be mentioned in the invoice dispatched to You along with your order. In case a pre-payment has been made by You basis the price estimate at the time of placing the order, should there be a shortfall upon generation of the invoice, You undertake to pay the shortfall through any of the payment modes made available to You by us. In case the payment made by You basis the price estimate at the time of placing the order is more than the price as per the generated invoice, the excess payment will be refunded back to You in your bank account, credit card, debit card, wallet or the Bharat Pharmacredit account which may have been used by You at the time of making the payment.</p>
                        <p>&nbsp;</p>
                        <p>At present, Bharat Pharma supports the following payment methods, as may be revised from time to time:</p>
                        <ol>
                            <li>Debit Cards</li>
                            <li>Credit Cards</li>
                            <li>Prepaid Cards</li>
                            <li>Net Banking</li>
                            <li>UPI</li>
                            <li>Wallets</li>
                            <li>Cardless EMI</li>
                            <li>EMI on card</li>
                            <li>Pay later</li>
                        </ol>
                        <p>10.Cash on delivery</p>
                        <p>&nbsp;</p>
                        <p><br /> We as a merchant shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the declining of authorization for any transaction, on account of the cardholder having exceeded the preset limit mutually agreed by Us with our acquiring bank from time to time.</p>
                        <p>&nbsp;</p>
                        <p>Notification of errors</p>
                        <p>&nbsp;</p>
                        <p>You should check the items dispensed to You carefully promptly upon receipt. If You believe there may have been a dispensing error, You should contact Us immediately and should not take or use any of the items.</p>
                        <p>&nbsp;</p>
                        <p>Delivery of fulfilled prescriptions</p>
                        <p>&nbsp;</p>
                        <p>Prescription medicines will require a doorstep signature. Prescription items can only be dispensed once We have received your original paper prescription or online prescription from Third Party RMP. All items are delivered to the address provided by You in your registration or to an alternative address if directed by you. You acknowledge and accept that items will need to be signed for on delivery and authorize any person at your chosen delivery address to sign for the items as your authorized representative. In the event that the packaging is opened after delivery, We will have no liability to You for lost or damaged items or for what third parties find out about You as a result.</p>
                        <p>&nbsp;</p>
                        <p>Cancellation, Return and Refund</p>
                        <p>&nbsp;</p>
                        <p>We understand that there will be genuine reasons when You decide to cancel or return an order. To process a refund, please raise a ticket on the Platform for any issue within 15 days of delivery. No refunds will be processed beyond 15 days from the date of delivery. Upon receiving your return/refund request, Bharat Pharma shall verify the authenticity and the nature of the request. If Bharat Pharma finds that the request is genuine, it will initiate the return and/ or refund process outlined below.</p>
                        <p>&nbsp;</p>
                        <p>In case of missing or damaged medicines or delivery of medicines that do not match your order or delivery of medicines to the wrong address, a return request can be generated by raising a ticket on the Platform for any issue within 15 days of delivery. A return pickup will be done from our end for the medicines to be returned. On receipt, returned medicines will be verified and a refund will be processed back to your original payment source (amount will be credited to Bharat Pharma Credit in case of a cash on delivery payment) within 48 hours of verifying the genuineness of the complaint. A return pickup will be done from our end for the incorrectly delivered medicines.</p>
                        <p>&nbsp;</p>
                        <p>In case an error has been made by the customer while ordering, a return request can be generated by raising a ticket on the Platform for any issue within 15 days of delivery. A return pickup will be done from our end for the medicines to be returned. On receipt, Returned medicines will be verified and a refund will be processed back to the customers Bharat Pharma Credit account or your original payment source within 48 hours of Bharat Pharma receiving the returned medicines at our licensed location.</p>
                        <p>&nbsp;</p>
                        <p>Non-Returnable Products: All electronic products &amp; products requiring cold chain maintenance/ refrigeration come under non-returnable and non-refundable policy. Bharat Pharma does not accept returns or give refunds for these products.</p>
                        <p>&nbsp;</p>
                        <p>Acceptance of the Goods</p>
                        <p>&nbsp;</p>
                        <p>We request You to verify the complete details and the documents provided to You at delivery before acceptance of the Goods. Any complaints with regard to shortage of Goods, defects or otherwise will not be entertained by Bharat Pharma once the same are accepted at the time of delivery.</p>
                        <p>&nbsp;</p>
                        <p>WARNING</p>
                        <p>&nbsp;</p>
                        <p>YOU MUST CHECK ALL ITEMS DISPENSED TO YOU AND SHOULD NOT TAKE ANY MEDICATION THAT APPEARS TO HAVE BEEN TAMPERED WITH OR WHICH MAY HAVE BEEN DISPENSED IN ERROR. FAILURE TO ABIDE BY THIS WARNING COULD SERIOUSLY DAMAGE YOUR HEALTH AND BHARAT PHARMA SHALL NOT BE HELD LIABLE FOR THE SAME.</p>
                        <p>&nbsp;</p>
                        <p>Display of Information You agree and acknowledge that the content available on the Platform in relation to the Products, including without limitation, text, copy, audio, video graphics etc is for general information purposes only and does not constitute either an advertisement or promotion of any drug being offered for sale by the Company on the Platform or any professional medical advice, diagnosis, treatment or recommendation of any kind.</p>
                        <p>&nbsp;</p>
                        <p>Offers, promotions, software, and downloads</p>
                        <p>&nbsp;</p>
                        <p>Bharat Pharma and other third parties with whom We have a business relationship may occasionally promote their goods or services on the Platform or through other direct marketing initiatives or may make software and other materials available for You to purchase or download. Whilst We try to encourage third parties to offer good quality products, and services and materials at competitive prices We have no control over them or other third parties. We do not also endorse the products or services they offer or give You any assurance that they will be suitable for your needs. It is your responsibility to satisfy yourself in this regard and We have no liability in connection with the same. All promotions are for a limited period and subject to special terms and conditions, which are in addition and not in substitution of the Terms of Use stated herein.</p>
                        <p>&nbsp;</p>
                        <p>BHARAT Flat 25% off will be given on the MRP value of your original brand of medicines. The discount is not valid on Bharat Pharma recommended brands as they are already sold at discounted prices. No minimum order value is required to redeem this coupon and the total discount that can be redeemed is capped at a maximum value of Rs. 5000. This code can only be redeemed by placing an order through the Bharat Pharma android application. Bharat Pharma has the right to discontinue the offer code without prior intimation. The coupon code cannot be applied/modified/removed after placing the order from the Bharat Pharma application. Only 1 coupon code can be applied at a time for one order. If any products that were originally purchased using the additional discounts through this coupon code are returned, the refund amount will be calculated by considering the discount percentage applied at the time of sale of the product.</p>
                        <p>&nbsp;</p>
                        <p>Surveys, Contests &amp; Referrals</p>
                        <p>&nbsp;</p>
                        <p>From time-to-time, our site requests information from users via surveys or contests. Participation in these surveys, contests or referrals programs is completely voluntary and You have a choice whether or not to disclose any information requested. Information requested may include contact information (such as name and delivery address), and demographic information (such as postcode, age level). Contact information will be used to notify the winners and award prizes. Survey information will be used for purposes of monitoring or improving the functionality of the Platform. The terms and conditions for each survey and contest may differ or otherwise be amended and cancelled at the sole discretion of Bharat Pharma.</p>
                        <p>&nbsp;</p>
                        <p>Bharat Pharma offers a referral program whereby pursuant to a referral the User may receive certain referral bonus. The referred friend should be a new customer and not an existing or returning customer of Bharat Pharma. The referred friend must place a valid order for the User to get a referral bonus in their Bharat Pharma cash account. Referral reward shall be credited to the Users account after successful delivery of the referred friends order. The referred friends order should not be cancelled or returned.</p>
                        <p>&nbsp;</p>
                        <p>Bharat Pharma Cash can be utilized in every order placed by the customer however users can only use upto INR 500 every month as a Cash discount. Bharat Pharma Cash can be used to get up to 25% off (Maximum INR 100) every order. Bharat Pharma Cash cannot be clubbed with any other offers / promotions. Notwithstanding anything contained herein, these terms are in addition to and not in derogation to any other terms as stipulated by Bharat Pharma from time to time. Bharat Pharma reserves the right in its sole discretion at any time, without any prior notice to suspend/cancel this program or amend these terms.</p>
                        <p>&nbsp;</p>
                        <p>Communication with the User</p>
                        <p>&nbsp;</p>
                        <p>Please refer to our Privacy Policy to understand the circumstances in which we shall communicate with You.</p>
                        <p>&nbsp;</p>
                        <p>Ownership of materials and license terms</p>
                        <p>&nbsp;</p>
                        <p>This Platform, the materials and software on it are protected by copyright, trademark and other intellectual property rights and laws throughout the world, and are owned by or are licensed to Bharat Pharma and/or third parties. You are permitted to display the materials on this mobile application on a computer screen/mobile screen and, save for restricted access documents, to download and print a hard copy for your personal use or for obtaining products or services from Us provided You do not alter or remove any of the content or any part of the Platform without our express permission to do so and that You do not change or delete any copyright, trademark or other proprietary notices.</p>
                        <p>&nbsp;</p>
                        <p><br /> You agree not to copy, reproduce, store (in any medium or format), distribute, transmit, modify, create derivative works from all or any part of this website/mobile application or the materials or software on it, or provided to You through it without our prior written consent (which may be given or withheld in our absolute discretion) use this website/mobile application or any of the materials or software on it, or provided to You through it, for: any unlawful purpose or in contravention of applicable law commercial exploitation without our prior written consent any purpose or in any manner that may give a false or misleading impression of us, our staff or our services use, upload or transmit any material that is defamatory, offensive, obscene or otherwise unlawful, or which may cause offence or distress, or which may affect or infringe the rights of any other person any device, software, file or mechanism which may interfere with the proper operation of this website or our systems establish a link to this mobile application from any other website, intranet or extranet site without our prior written consent decompile, disassemble or reverse engineer (or attempt to do any of them) any of the software or other materials provided on or through this website/mobile application do anything that may interfere with or disrupt this website/mobile application or our service encourage or permit others to do any of the above. In the event that You do not comply with the above restrictions, any person affected by your actions may bring a claim against You and/or Bharat Pharma. We will pursue a claim against You for any losses and costs (including legal costs) We may suffer as a result of your actions.</p>
                        <p>&nbsp;</p>
                        <p>Suitability of materials</p>
                        <p>&nbsp;</p>
                        <p>We do not give any assurance that the materials provided or available to You on or through the Platform are suitable for your requirements or that they will be secure, error or virus free and We will have no liability in respect of those materials.</p>
                        <p>&nbsp;</p>
                        <p>Mobile application availability</p>
                        <p>&nbsp;</p>
                        <p>This mobile application is provided free of charge and We make no guarantee that it will be uninterrupted or error-free. We reserve the right to modify, suspend or withdraw the whole or any part of the website/mobile application or any of its content at any time without notice and without incurring any liability.</p>
                        <p>&nbsp;</p>
                        <p>Third Party Links</p>
                        <p>&nbsp;</p>
                        <p>We may, from time to time, provide links from this Platform to other websites that are owned and controlled by third parties. These links are provided only for your convenience and We have no control over and will have no liability in respect of those websites. For further information on these aspects, please refer to our Privacy Policy.</p>
                        <p>&nbsp;</p>
                        <p>Monitoring</p>
                        <p>&nbsp;</p>
                        <p>We may monitor activity and content on the Platform and may take any action We consider appropriate if We suspect You may be in breach of these Terms of Use including suspending, attaching conditions to or terminating your access and/or notifying the authorities or relevant regulators of your activities.</p>
                        <p>&nbsp;</p>
                        <p>Security and other Information Technology Aspects</p>
                        <p>&nbsp;</p>
                        <p>Please refer to the Privacy Policy to understand our security policy regarding the data We collect, as well as Your obligations with respect to the information You provide.</p>
                        <p>&nbsp;</p>
                        <p>Accuracy of Information</p>
                        <p>&nbsp;</p>
                        <p>We take reasonable care to ensure that all information available on this Platform about our business, services and any products mentioned is accurate. However, these are continually developing and, occasionally, the information may be out of date. Medical, commercial and legal practice change frequently and the content on this website/mobile application, in any newsletters and in other items offering guidance have been prepared for general interest only and are not a substitute for specific medical, legal or other professional advice and should not be read or used as such. For accurate up-to-date information, You should contact Us and/or your doctor directly.</p>
                        <p>&nbsp;</p>
                        <p>Disclaimer</p>
                        <p>&nbsp;</p>
                        <p>BHARAT PHARMA DOES NOT WARRANT OR REPRESENT THAT THE MATERIAL ON THIS PLATFORM IS ACCURATE, COMPLETE OR CURRENT OR THAT THE WEBSITE WILL BE FREE OF DEFECTS OR VIRUSES. NOTHING CONTAINED IN THE PAGES OF THIS PLATFORM SHOULD BE CONSTRUED AS MEDICAL, COMMERCIAL, LEGAL OR OTHER PROFESSIONAL ADVICE. DETAILED PROFESSIONAL ADVICE SHOULD BE OBTAINED BEFORE TAKING OR REFRAINING FROM ANY ACTION BASED ON ANY OF THE INFORMATION OR MATERIAL CONTAINED IN THIS PLATFORM OR ANY COMMUNICATIONS PROVIDED TO YOU AS A RESULT OF YOUR REGISTRATION. Liability</p>
                        <p>&nbsp;</p>
                        <p>WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE (IN CONTRACT, NEGLIGENCE OR OTHERWISE) WHERE: THERE IS NO BREACH OF A LEGAL DUTY OF CARE OWED TO YOU BY US; THE LOSS OR DAMAGE IS NOT A REASONABLY FORESEEABLE RESULT OF ANY SUCH BREACH; OR ANY LOSS OR DAMAGE OR INCREASE IN LOSS OR DAMAGE RESULTS FROM A BREACH BY YOU OF THESE TERMS OF USE.THE MAXIMUM LIABILITY OF BHARAT PHARMA SHALL BE LIMITED TO THE COST OF THE PRODUCTS PURCHASED OR SERVICES AVAILED BY THE CUSTOMER, BHARAT PHARMA OR IT&rsquo;S DIRECTORS SHALL NOT BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL OR OTHER DAMAGES CAUSED TO THE USER</p>
                        <p>&nbsp;</p>
                        <p>Third-party rights</p>
                        <p>&nbsp;</p>
                        <p>Nothing in these Terms of Use is intended to nor shall it confer a benefit on any third party under the Contracts and a person who is not a party to these Terms of Use has no rights to enforce them.</p>
                        <p>&nbsp;</p>
                        <p>Compliances</p>
                        <p>&nbsp;</p>
                        <p>Certain laws require to maintain data with respect to the services, Goods and other personal information in a prescribed format and Bharat Pharma will use all the information to the extent required in compliance with the applicable laws and as may be directed or amended from time to time.</p>
                        <p>&nbsp;</p>
                        <p>Force Majeure</p>
                        <p>&nbsp;</p>
                        <p>Bharat Pharma shall not be liable for lack of performance or delay in performance if any of its obligations are prevented, restricted, delayed or interfered with due to circumstances beyond its reasonable control and without the fault or negligence of such Party, including but not limited to change in legislation, fire, flood, explosion, epidemic, accident, act of God, war, riot, strike, lockout, traffic or other concerted act of workmen and/or act of Government. Bharat Pharma may at its sole discretion withdraw the services or Goods if a Force Majeure event occurs.</p>
                        <p>&nbsp;</p>
                        <p><br /> Governing Law and Jurisdiction</p>
                        <p>&nbsp;</p>
                        <p><br /> The Terms of Use are governed by and constructed in accordance with the laws of India, without reference to conflict of laws principles and You irrevocably and unconditionally submit to the exclusive jurisdiction of the courts located in Bhopal, India.</p>
                        <p>&nbsp;</p>
                        <p>Acceptance of Terms of Use</p>
                        <p>&nbsp;</p>
                        <p>By Clicking on the I have read and accept the terms and conditions box at the bottom of the Registration Form, You indicate your acceptance of the above Terms of Use and the Privacy Policy and You agree to be bound by these Terms of Use and Privacy Policy including any additional guidelines and future modifications. If at any time You do not agree to these Terms of Use or You wish to terminate your registration as an Affiliate, You may not access or use the information and notify your intention to block your registration.</p>
                        <p>&nbsp;</p>
                        <p>Waiver</p>
                        <p>&nbsp;</p>
                        <p>No delay or decision not to enforce rights under these Terms of Use will constitute a waiver of the right to do so and will not affect rights in relation to any subsequent breach.</p>
                        <p>&nbsp;</p>
                        <p>WE RESERVE THE RIGHT TO CHANGE OR CHANGE THESE TERMS OF USE OR ANY POLICY OR GUIDELINE OF THE PLATFORM INCLUDING THE PRIVACY POLICY AT ANY TIME, AT ITS SOLE DISCRETION. THE NEW VERSION WILL BE POSTED ON THIS PLATFORM AND WILL TAKE EFFECT IMMEDIATELY UPON POSTING AND YOU WAIVE ANY RIGHT YOU MAY HAVE TO RECEIVE NOTICE OF SUCH CHANGES OR MODIFICATIONS. IF YOU USE THE PLATFORM APPLICATION AFTER THE NEW TERMS OF USE HAVE COME INTO EFFECT, YOU WILL BE INDICATING YOUR AGREEMENT TO BE BOUND BY THE NEW TERMS OF USE. THEREFORE, YOU SHOULD FREQUENTLY REVIEW THESE TERMS OF USE AND APPLICABLE POLICIES TO UNDERSTAND THE TERMS OF USE THAT APPLY TO YOUR USE OF THE WEBSITE.</p>
                        <p>&nbsp;</p>
                        <p>Customer Support Policy</p>
                        <p>&nbsp;</p>
                        <p>At Bharat Pharma, the customer is our top most priority and hence We believe in providing the best experience to You. We look forward to any feedback which will help Us improve further. You may contact Us through our help pages by clicking here https://www.bharatpharma.in/help that aim at providing solutions to all frequently asked questions. You can also reach out to our support team via our mobile number at 8085587055 (Between 9 am - 9 pm) or email Us at nanopharmhealthcaresolutions@gmail.com. For every complaint lodged, We raise a ticket through which You can track the status of Your complaint.</p>
                        <p>&nbsp;</p>
                        <p>If your query remains unresolved for a period of 7 days after You have reached out to our direct Customer support lines, You can escalate the matter to our Grievance Officer. Bharat Pharma has designated a grievance officer (&ldquo;Grievance Officer&rdquo;) for this purpose. The Grievance Officer shall acknowledge the consumer grievances within 48 hours and shall try to resolve the same within one month from the date of receipt of the email. You can contact the Grievance Officer through the below-mentioned contact details.</p>
                        <p>&nbsp;</p>
                        <p>Name: Chandrasekhar Swaminathan Email:nanopharmhealthcaresolutions@gmail.com</p>
                        <p>Address: 216/8 Besides Bhuteshwar Mandir Sant Ravidas Ward Sagar 470002</p>
                        <p>&nbsp;</p>
                        <p>Grievance Officer working window: Monday to Friday (9:00 a.m. to 6:00 p.m.). 26th January, 15th August, 2nd October, and major festivals in India will be considered non-business days.</p>
                        <p>&nbsp;</p>
                        <p>Note: For grievances with respect to matters covered under the Privacy Policy address the same to nanopharmhealthcaresolutions@gmail.com or reach out to us 8085587055. The details regarding the designated Grievance Officer for complaints regarding matters under the Privacy Policy are provided here.</p>
                        <p>&nbsp;</p>
                        <p>The details regarding the designated Grievance Officer for complaints regarding matters under the Privacy Policy are provided here.</p>
                        <p>&nbsp;</p>
                        <p>The Company DOES NOT solicit any confidential information like your OTP/CVV/PIN/Card Number either through Calls/SMS/WhatsApp/Surveys/mail/Link or any other means. Please report such suspicious activities to nanopharmhealthcaresolutions@gmail.com</p>
                        <p>&nbsp;</p>
                        <p>Grievances, where customers have shared their details to unauthorised people leading to financial leaks/ losses, are not covered by the policy (Scam webpages, Numbers, Callers, etc.).</p>
                        <p>&nbsp;</p>
                        <p>The Company relies on its Third Party Delivery Partners and payment partners. Thus, in rare cases, You might see a delay in resolution, due to reasons beyond our control, once the investigation is passed on to them. However, please be rest assured that We will try our best not to exceed resolution timelines in these cases.</p>
                        <p>&nbsp;</p>
                        <p>Any grievances related to non-returnable products will be adhered to as per the Companys return policy.</p>
                        <p>&nbsp;</p>
                        <p>The Company will review the customer support policy from time to time and modify/amend the policy, in line with regulatory guidelines and other applicable laws, to suit the business needs.</p>
                        <p>&nbsp;</p>
                        <p>Information to be disclosed under the Consumer Protection (E-Commerce) Rules, 2020</p>
                        <p>&nbsp;</p>
                        <p>Name of the e-commerce entity: Nanopharm Healthcare Solutions Private Limited</p>
                        <p>Address: 216/8 Besides Bhuteshwar Mandir Sant ravidas Ward Website: www.bharatpharma.in Contact:8085587055</p>
                        <p>&nbsp;</p>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}
