import React from 'react'
import TopHeader from './Top_Header';
import CarouselHeader from './Carosuel_Header';
import BannerItems from './BannerItems';
import HelthcareProducts from './HelthcareProducts';
import HealthIssues from './HealthIssues';
import NewArrivals from './NewArrivals';
import TopBrands from './TopBrands';
import Footer from './Footer'
import ShopByCategory from './ShopByCategory';
import SingleProduct from './Single_Product';
import OTCProduct from './OTCProduct'
import PersonalCare from './PersonalCare'
import Navigation from './Navigation';
import MedicineTopBrands from './MedicineTopBrands';
import HealthTreatment from './HealthTreatment';
import {FloatingWhatsApp} from 'react-floating-whatsapp';


export default function Home() {
    return (
        <div>
            <TopHeader />
            <Navigation />
            <CarouselHeader />
            <OTCProduct />
            <ShopByCategory />
            {/* <TopBrands /> */}
            {/* <NewArrivals /> */}
            <PersonalCare />
            <HealthIssues />
            <MedicineTopBrands />
            <HealthTreatment />
            <Footer />
            <FloatingWhatsApp
                phoneNumber="918085587055"
                accountName="Bharat Pharma"
                allowClickAway
                notification
                notificationDelay={60000}
            />

            {/* <SingleProduct /> */}
                {/* <BannerItems /> */}
                {/* <HelthcareProducts />  */}

        </div>
    )
}
