import React from 'react'
import Top_Header from './Top_Header'
import Navigation from './Navigation'
import { Container, Col, Row } from 'react-bootstrap'
import Footer from './Footer'



export default function About() {

    return (
        <>
            {
                window.scrollTo(0, 0)
            }
            <Top_Header />
            <Navigation />
            <Container>
                <Row>
                    <Col>
                        <h1 style={{ fontSize: '25px', fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'center', marginTop: '20px' }}>ABOUT US</h1>
                        <h1 style={{ fontSize: '15px', fontFamily: 'DM Sans' }}>
                            If you are buying medicines regularly and stressed of both the rising prices and inconvenience of buying them
                            every month after standing in the long queue at medical stores, choose Bharat Pharma - a one-stop online chemist
                            store that offers the best discount to their regular customers, with the very convenient home delivery services.
                            <br></br><br></br>
                            Bharat Pharma is one of the best online medical stores offering genuine medicines at very affordable rates.
                            Started in June 2021, this online pharmacy is serving over all the major cities and towns in India.
                            <br></br><br></br>
                            Whether you're looking for prescription drugs or over the counter (OTC) medicines, Bharat Pharma is the best
                            online pharmacy in India to go. The store hosts medicines, daily wellness aids, herbal supplements, healthcare
                            supplements, and healthcare devices from popular and authentic brands. You can easily place your order through the website
                            or use our Android app. Ordering your medicines is as simple as 123. For prescription drugs, we require a photocopy of your
                            prescription, however, you can buy any OTC/non-prescription drugs without a prescription from your doctor.
                        </h1>


                        <h1 style={{ fontSize: '25px', fontFamily: 'DM Sans', fontWeight: 'bold', marginTop: '20px' }}>Why order medicines online from us? </h1>

                        <h1 style={{ fontSize: '15px', fontFamily: 'DM Sans' }}>
                            <span style={{ fontSize: '15px', fontFamily: 'DM Sans', fontWeight: 'bold', marginTop: '20px' }}>
                                Authentic:
                            </span> {" "}
                            All the chemists registered at our website are authentic, licensed, and properly inspected to offer safe dispensing practices.
                            Every product on Bharat Pharma is checked regularly and we assure that you get 100% genuine medicines from us.
                        </h1>

                        <h1 style={{ fontSize: '15px', fontFamily: 'DM Sans' }}>
                            <span style={{ fontSize: '15px', fontFamily: 'DM Sans', fontWeight: 'bold', marginTop: '20px' }}>
                                Easy to access:
                            </span> {" "}
                            This online portal provides you the utmost convenience to purchase medicines online without negotiating on time and money.
                            Designed keeping user's ergonomics in mind, our web portal or app design promises you easy and hassle-free purchases.
                        </h1>

                        <h1 style={{ fontSize: '15px', fontFamily: 'DM Sans' }}>
                            <span style={{ fontSize: '15px', fontFamily: 'DM Sans', fontWeight: 'bold', marginTop: '20px' }}>
                                Huge savings:
                            </span> {" "}
                            We are offering attractive discounts and offers for our regular customers on every purchase.
                            Buying medicines and other healthcare products from our shop is affordable and come with savings.
                        </h1>

                        <h1 style={{ fontSize: '15px', fontFamily: 'DM Sans' }}>
                            We provide different payment methods like Credit-Debit Card, Internet Banking, UPI and Cash On delivery, assuring your
                            information is safe and confidential.
                        </h1>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}
