import React, { useState } from 'react'
import { Container, Row, Col, Table, Button, Spinner, Modal, Form } from 'react-bootstrap'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { QUERY_ALL_ORDER, QUERY_ORDER_BY_ID, MUTATION_CHANGE_ORDER_STATUS, QUERY_DELIVERY_BOY, MUTATION_ASSIGN_DELIVERY_BOY, QUERY_DELIVERY_BOY_BY_ID } from '../Queries'
import Moment from 'react-moment'

export default function UserOrders() {
    let count = 1
    const [orderStatus, setOrderStatus] = useState('')
    const [orderId, setOrderId] = useState('')
    const [deliveryBoyId, setDeliveryBoyId] = useState('')
    const { data: orderData, loading: orderLoading } = useQuery(QUERY_ALL_ORDER, {
        pollInterval: 300
    });

    const [orderById, { data: orderByIdData, loading: orderByIdLoading }] = useLazyQuery(QUERY_ORDER_BY_ID, {
        pollInterval: 300
    });
    const [dbById, { data: dbByIdData, loading: dbByIdLoading }] = useLazyQuery(QUERY_DELIVERY_BOY_BY_ID);

    const [changeStatus, { loading: changeStatusLoading }] = useMutation(MUTATION_CHANGE_ORDER_STATUS, {
        refetchQueries: [
            QUERY_ALL_ORDER
        ]
    });

    const [assignDb, { loading: assgnDbLoading }] = useMutation(MUTATION_ASSIGN_DELIVERY_BOY, {
        refetchQueries: [
            QUERY_ALL_ORDER
        ]
    })

    const { data: getDbData, loading: getDbLoading } = useQuery(QUERY_DELIVERY_BOY)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const showViewHandel = (id, data) => {
        setOrderStatus(data.status)
        handleShow();
        setOrderId(id)
        orderById({
            variables: {
                "orderId": `${id}`
            }
        })
    }

    const changeStatusHandel = () => {
        changeStatus({
            variables: {
                "orderId": `${orderId}`,
                "status": `${orderStatus}`
            }
        })
        handleClose()
    }

    function setAssignOrderHandel(dbId) {
        console.log(dbId)
        setDeliveryBoyId(dbId)
        dbById({
            variables: {
                "deliveryId": `${dbId}`
            }
        })
    }

    function assignDbDoneHandel() {
        assignDb({
            variables: {
                "orderId": `${orderId}`,
                "deliveryBoyId": `${deliveryBoyId}`,
                "deliveryBoyName": `${dbByIdData.getDeliveryBoyById.fName}`
            }
        })
        handleClose()
    }

    console.log("dbByIdData", dbByIdData)

    return (
        <>
            <Container style={{ marginTop: '60px' }}>
                <Row>
                    <Col>
                        <Table bordered responsive style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Order No</th>
                                    <th>Customer Name</th>
                                    <th>Contact</th>
                                    <th>Status</th>
                                    <th>Assign DB</th>
                                    <th>View</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    orderLoading ?
                                        <Spinner animation="border" role="status" className="mx-auto d-block">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                        :
                                        orderData && orderData.getAllOrders.slice().reverse().map(oData =>
                                            <tr>
                                                <td>{count++}</td>
                                                <td>{oData.orderId}</td>
                                                <td>{oData.fullName}</td>
                                                <td>{oData.contact}</td>
                                                {
                                                    oData.status === 'pending' ?
                                                        <td style={{ color: 'red' }}>pending</td> :
                                                        oData.status === 'shipped' ?
                                                            <td style={{ color: 'blue' }}>Shipped</td> :
                                                            oData.status === 'delivered' ?
                                                                <td style={{ color: 'green' }}>Delivered</td> : <td>{oData.status}</td>
                                                }
                                                <td>{oData.deliveryName}</td>
                                                <td><Button variant='info' size="sm" style={{ fontSize: '10px' }} onClick={() => showViewHandel(oData.id, oData)}>View</Button></td>
                                            </tr>
                                        )
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Body>
                    <Container>
                        {
                            orderByIdLoading ?
                                <Spinner animation="border" role="status" className="mx-auto d-block">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                                :
                                <Col>
                                    <Row>
                                        <Table style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ fontWeight: 'bold' }}>Full Name</td>
                                                    <td>{orderByIdData && orderByIdData.getOrderById.fullName}</td>
                                                    <td style={{ fontWeight: 'bold' }}>Email</td>
                                                    <td>{orderByIdData && orderByIdData.getOrderById.email}</td>
                                                    <td style={{ fontWeight: 'bold' }}>Contact</td>
                                                    <td>{orderByIdData && orderByIdData.getOrderById.contact}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: 'bold' }}>Address</td>
                                                    <td>{orderByIdData && orderByIdData.getOrderById.fullAddress}</td>
                                                    <td style={{ fontWeight: 'bold' }}>City</td>
                                                    <td>{orderByIdData && orderByIdData.getOrderById.city}</td>
                                                    <td style={{ fontWeight: 'bold' }}>Order No</td>
                                                    <td>{orderByIdData && orderByIdData.getOrderById.orderId}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: 'bold' }}>Payment Id</td>
                                                    <td>{orderByIdData && orderByIdData.getOrderById.paymentId}</td>
                                                    <td style={{ fontWeight: 'bold' }}>Data/Time</td>
                                                    <td><Moment format="DD/MM/YYYY, hh:mm a">{orderByIdData && orderByIdData.getOrderById.createdDateTime}</Moment></td>
                                                    <td style={{ fontWeight: 'bold' }}>Status</td>
                                                    {
                                                        orderByIdData && orderByIdData.getOrderById.status === 'pending' ?
                                                            <td style={{ color: 'red' }}>Pending</td> :
                                                            orderByIdData && orderByIdData.getOrderById.status === 'shipped' ?
                                                                <td style={{ color: 'blue' }}>Shipped</td> :
                                                                orderByIdData && orderByIdData.getOrderById.status === 'delivered' ?
                                                                    <td style={{ color: 'green' }}>Delivered</td> : <td>Error</td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: 'bold' }}>Download</td>
                                                    {
                                                        orderByIdData && orderByIdData.getOrderById.prescriptionImage ?
                                                            <td><a href={`https://bharatpharmauserprescription.s3.amazonaws.com/${orderByIdData && orderByIdData.getOrderById.prescriptionImage}`} style={{ fontFamily: 'DM Sans', fontSize: 12, textDecoration: 'none', fontWeight: 'bold' }}>Download Prescription</a></td>
                                                            :
                                                            ""
                                                    }
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <h1 style={{ fontFamily: 'DM Sans', fontSize: '15px', textAlign: 'center', fontWeight: 'bold' }}>Product Details</h1>
                                        <Table style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>
                                            <thead>
                                                <tr>
                                                    <th>Product Name</th>
                                                    <th>Product Price</th>
                                                    <th>Quantity</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    orderByIdData && orderByIdData.getOrderById.productDetails.map(pData =>
                                                        <tr>
                                                            <td>{pData.productName}</td>
                                                            <td>{pData.productPrice}</td>
                                                            <td>{pData.quantity}</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                                            <Form.Label style={{ fontFamily: "DM Sans", fontSize: "15px", fontWeight: 'bold' }}>Change Status</Form.Label>
                                            <Form.Select required style={{ fontFamily: 'DM Sans', fontSize: '12px' }} value={orderStatus.status} onChange={(e) => setOrderStatus(e.target.value)}>
                                                <option value="" selected="selected" disabled="disabled">Choose Status</option>
                                                <option value="pending" >Pending</option>
                                                <option value="packed">Packed</option>
                                                <option value="shipped" >Shipped</option>
                                                <option value="delivered" >Delivered</option>
                                                <option value="cancelled" >Cancelled</option>
                                                <option value="returned" >Returned</option>
                                            </Form.Select>
                                        </Form.Group>
                                        {
                                            changeStatusLoading ?
                                                <Spinner animation="border" role="status" className="mx-auto d-block">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner> :
                                                <Button variant='primary' size="sm" style={{ fontFamily: 'DM Sans', fontSize: '12px', marginTop: '10px', width: '10%' }} onClick={() => changeStatusHandel()} className="mx-auto d-block">Change</Button>
                                        }
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                                            <Form.Label style={{ fontFamily: "DM Sans", fontSize: "15px", fontWeight: 'bold' }}>Assign Delivery Boy</Form.Label>
                                            <Form.Select required style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setAssignOrderHandel(e.target.value)}>
                                                <option value="" selected="selected" disabled="disabled">Choose Delivery Boy</option>
                                                {
                                                    getDbData && getDbData.getDeliveryBoy.map(dbD =>
                                                        <option value={dbD.id}>({dbD.uniqueId}) {dbD.fName} {dbD.lName}</option>
                                                    )
                                                }
                                                `</Form.Select>
                                        </Form.Group>
                                        {
                                            assgnDbLoading ?
                                                <Spinner animation="border" role="status" className="mx-auto d-block">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner> :
                                                dbByIdLoading ?
                                                    <Spinner animation="border" role="status" className="mx-auto d-block">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner> :
                                                    <Button variant='warning' size="sm" style={{ fontFamily: 'DM Sans', fontSize: '12px', marginTop: '10px', width: '10%' }} onClick={() => assignDbDoneHandel()} className="mx-auto d-block">Assign</Button>
                                        }
                                    </Row>
                                </Col>
                        }
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    )
}
