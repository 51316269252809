import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox'
import { Provider } from 'react-redux'
import store from './redux/store'

import '../node_modules/react-image-gallery/styles/css/image-gallery.css'
import '../node_modules/react-image-gallery/styles/scss/image-gallery.scss'

ReactDOM.render(

  <Router>
    <SimpleReactLightbox>
      <Provider store={store}>
        <App />
      </Provider>
    </SimpleReactLightbox>
  </Router>
  ,
  document.getElementById('root')
);

reportWebVitals();
