
import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import './componentCss/ShopByCategory.css'
import Himalaya from '../img/himalaya.png'
import Mama from '../img/mama.png'
import Vlcc from '../img/vlcc.png'
import Bio from '../img/bio.png'
import Emami from '../img/emami.png'
import Nivea from '../img/nivea.png'
import sunpharma from '../img/sun-pharma.png'
import drReaddy from '../img/Dr_reddys.png'
import cipla from '../img/Cipla_Logo.png'
import torrent from '../img/torrent.png'
import lupin from '../img/lupin.png'
import divi from '../img/DIVIS.png'
import zdus from '../img/zydus.png'
import abott from '../img/abott.png'
import alkem from '../img/Alkem.png'
import mankind from '../img/mankind.png'
import loreal from '../img/Loreal.png'
import gartner from '../img/gartner.png'
import { useNavigate } from 'react-router-dom'

export default function MedicineTopBrands() {

  const navigate = useNavigate();
  const handleClick = (id) => navigate('/productByBrandName/' + id);

  return (
    <>
      <Container fluid>
        <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#0b1219', fontSize: '27px', marginTop: '10px' }}> Medicines Top Brands </p>
        <Row>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12} xs={12}>
                <Card className="CategoryCard" onClick={() => handleClick('HIMALAYA')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={sunpharma} className="categoryImg" /></center>
                    <Card.Text className="CardText">
                      Sun Pharma
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12} xs={12}>
                <Card className="CategoryCard" onClick={() => handleClick('MAMAEARTH')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={drReaddy} className="categoryImg" /></center>
                    <Card.Text className="CardText">
                      Dr. Reddy
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12} xs={12}>
                <Card className="CategoryCard" onClick={() => handleClick('VLCC')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={divi} className="categoryImg" /></center>
                    <Card.Text className="CardText">
                      Divi's
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12} xs={12}>
                <Card className="CategoryCard" onClick={() => handleClick('BIOTIQUE')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={cipla} className="categoryImg" /></center>
                    <Card.Text className="CardText">
                      Cipla
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12} xs={12}>
                <Card className="CategoryCard" onClick={() => handleClick('EMAMI')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={torrent} className="categoryImg" /></center>
                    <Card.Text className="CardText">
                      Torrent
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12} xs={12}>
                <Card className="CategoryCard" onClick={() => handleClick('NIVEA')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={lupin} className="categoryImg" /></center>
                    <Card.Text className="CardText">
                      Lupin
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12} xs={12}>
                <Card className="CategoryCard" onClick={() => handleClick('HIMALAYA')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={zdus} className="categoryImg" /></center>
                    <Card.Text className="CardText">
                      Zydus
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12} xs={12}>
                <Card className="CategoryCard" onClick={() => handleClick('MAMAEARTH')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={abott} className="categoryImg" /></center>
                    <Card.Text className="CardText">
                      Abbott
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12} xs={12}>
                <Card className="CategoryCard" onClick={() => handleClick('VLCC')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={alkem} className="categoryImg" /></center>
                    <Card.Text className="CardText">
                      Alkem 
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12} xs={12}>
                <Card className="CategoryCard" onClick={() => handleClick('BIOTIQUE')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={mankind} className="categoryImg" /></center>
                    <Card.Text className="CardText">
                      Mankind 
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12} xs={12}>
                <Card className="CategoryCard" onClick={() => handleClick('EMAMI')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={loreal} className="categoryImg" /></center>
                    <Card.Text className="CardText">
                      Loreal 
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12} xs={12}>
                <Card className="CategoryCard" onClick={() => handleClick('NIVEA')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={gartner} className="categoryImg" /></center>
                    <Card.Text className="CardText">
                      Garnter
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

