import React, { useState } from 'react'
import { Container, Row, Col, Button, Alert, Card, Spinner } from 'react-bootstrap'
import './css/adminLogin.css'
import Navigation from '../Navigation'
import TopHeader from '../Top_Header';
import Footer from '../Footer';
import { FaExclamationTriangle, FaRegCheckCircle } from "react-icons/fa";

import { useMutation, useLazyQuery } from '@apollo/client'
import { MUTATION_ADMIN_OTP_LOGIN, MUTATION_SEND_OTP } from '../Queries'

import { Navigate } from 'react-router-dom'

export default function AdminLogin() {

    const adminToken = localStorage.getItem('adminToken')

    const [contact, setContact] = useState('')
    const [contactError, setContactError] = useState(false)
    const [otpSendClick, setOtpSendClick] = useState(false)
    const [otpState, setOtpState] = useState('')
    const [otpError, setOtpError] = useState(false)
    const [otpResend, setOtpResend] = useState(false)

    const [adminNotFound, setAdminNotFound] = useState(false)
    const [adminLoginDone, setAdminLoginDone] = useState(false)

    const [adminLoginMutation, { data: adminLoginData, loading: adminLoginLoading }] = useMutation(MUTATION_ADMIN_OTP_LOGIN, {
        onError: (e) => {
            setAdminNotFound(true)
            setContact('')
            setOtpState('')
            setOtpSendClick(false)
        }
    });
    const [sendOtpLoginLazyQuery] = useMutation(MUTATION_SEND_OTP);

    if (adminLoginDone) {
        return <Navigate to='/adminDashboard/userOrders' />
    }

    if (adminToken) {
        return <Navigate to='/adminDashboard/userOrders' />
    }

    function generateAdminOtp() {
        if (contact === '' || contact.length < 10 || contact.length > 10) {
            setContactError(true)
        }
        else {
            const otp = Math.floor(1000 + Math.random() * 9000);
            setContactError(false)
            localStorage.removeItem("adminOtp");
            localStorage.setItem('adminOtp', otp);

            sendOtpLoginLazyQuery({
                variables: {
                    contact: `${contact}`,
                    otp: `${otp}`
                }
            })

            setOtpSendClick(true);
        }
    }

    async function LoginHandle() {
        const otp = localStorage.getItem('adminOtp');
        if (otp === otpState) {
            await adminLoginMutation({
                variables: {
                    contact: `${contact}`,
                }
            })
        }
        else {
            setOtpError(true)
        }
    }

    function contactEnterAgain() {
        localStorage.removeItem("adminOtp")
        setAdminNotFound(false)
        setOtpError(false)
        setContact('')
        setOtpState('')
        setOtpSendClick(false)
    }

    function otpSendAgain() {
        setOtpResend(true)
        setOtpError(false)
        localStorage.removeItem("adminOtp")
        setOtpState('')
        generateAdminOtp()
    }

    if (adminLoginData) {
        localStorage.setItem('adminId', adminLoginData.adminOtpLogin.adminId)
        localStorage.setItem('adminToken', adminLoginData.adminOtpLogin.adminToken)
        localStorage.setItem('adminTokenExpiration', adminLoginData.adminOtpLogin.adminTokenExpiration)
        localStorage.removeItem("adminOtp")
        setAdminLoginDone(true)
    }



    return (
        <div>
            <TopHeader />
            <Navigation />
            <Container>
                <Row>
                    <Col md={3}></Col>
                    <Col md={6}>
                        <Card style={{ backgroundColor: '#ecf0f1', borderRadius: '20px', marginTop: '30px' }}>
                            <Card.Body>
                                <h4 style={{ textAlign: 'center', fontFamily: 'DM Sans', color: '#2c3e50', fontWeight: '500', fontSize: '30px' }}>Admin Login</h4>
                                <p style={{ fontFamily: 'Dm Sans', textAlign: 'center', fontSize: '15px' }}>Please Enter Admin Contact Number !!!</p>
                                {
                                    adminNotFound ?
                                        <Alert variant="danger" style={{ textAlign: 'center', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px' }}>
                                            This contact number is not related to Admin. <span style={{ color: '#2980b9', fontWeight: 'bold', cursor: 'pointer' }} onClick={contactEnterAgain}>Enter Again</span>
                                        </Alert> : ""
                                }
                                {
                                    otpSendClick ?
                                        <>
                                            <input type="text" name="contact" placeholder="Enter OTP" style={{ borderRadius: '20px', textAlign: 'center', lineHeight: 2, border: '1px solid black', width: '50%', fontFamily: 'DM Sans' }} className="mx-auto d-block" onChange={e => setOtpState(e.target.value)} value={otpState} />
                                            {
                                                otpError ?
                                                    <>
                                                        <h1 style={{ fontSize: '12px', fontFamily: 'Dm Sans', marginTop: '10px', textAlign: 'center' }}><FaExclamationTriangle style={{ color: 'red', marginTop: '-3px' }} /> Invalid OTP</h1>
                                                        <Row>
                                                            <Col style={{ textAlign: 'center', fontSize: '13px', fontFamily: 'DM Sans', fontWeight: 'bold', cursor: 'pointer', color: '#2c3e50' }} onClick={otpSendAgain}>Send Again</Col>
                                                            <Col style={{ textAlign: 'left', fontSize: '13px', fontFamily: 'DM Sans', fontWeight: 'bold', cursor: 'pointer', color: '#2c3e50' }} onClick={contactEnterAgain}>Enter Contact Again</Col>
                                                        </Row>
                                                    </>
                                                    :
                                                    otpResend ?
                                                        <h1 style={{ fontSize: '12px', fontFamily: 'Dm Sans', marginTop: '10px', textAlign: 'center' }}><FaRegCheckCircle style={{ color: 'green', marginTop: '-3px' }} /> OTP Sent Successfully.</h1>
                                                        :
                                                        ''
                                            }
                                            {
                                                adminLoginLoading ?
                                                    <Spinner animation="border" role="status" className="mx-auto d-block">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner> :
                                                    <Button id="login_btn" className="mx-auto d-block" onClick={LoginHandle}>Login</Button>

                                            }

                                        </>
                                        :
                                        <>
                                            <input type="text" name="contact" placeholder="Enter Your Contact" style={{ borderRadius: '20px', textAlign: 'center', lineHeight: 2, border: '1px solid black', width: '50%', fontFamily: 'DM Sans' }} className="mx-auto d-block" onChange={e => setContact(e.target.value)} value={contact} />
                                            {contactError ? <h1 style={{ fontSize: '12px', fontFamily: 'Dm Sans', marginTop: '10px', textAlign: 'center' }}><FaExclamationTriangle style={{ color: 'red', marginTop: '-3px' }} /> Invalid Contact Number</h1> : ''}
                                            <Button id="login_btn" className="mx-auto d-block" onClick={generateAdminOtp}>Send OTP</Button>
                                        </>
                                }

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}></Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )
}

