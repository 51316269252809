import React from 'react'
import './componentCss/HealthIssue.css'
import Depression from '../img/depression.jpg';
import Headche from '../img/Headche.jpg';
import stomach from '../img/stomach.jpg';
import hairloss from '../img/hairloss.jpg'
import { Container, Row, Col, Image, Card } from 'react-bootstrap'


export default function HealthIssues() {
  return (
    <div>
      <Container fluid style={{ marginTop: '30px' }} className="d-none d-lg-block">
        <Container fluid>
          <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#0b1219', fontSize: '27px', marginTop: '10px' }}>Shop by Health Concerns</p>
          <Row style={{ marginTop: '10px', marginLeft: '5px', marginRight: '5px', marginBottom: '20px' }}>
            <Col md={3} xs={3} style={{ marginTop: '10px' }}>
              <Card style={{ borderRadius: '10px', boxShadow: '5px 0px 5px 3px rgba(238, 228, 228, 0.897)' }}>
                <Image id="image" src={Depression} style={{ width: '100%', height: '200px', borderRadius: '10px' }} className="d-none d-lg-block" />
                <div id="imageTxt" style={{ color: '#2c3e50', fontSize: '20px', fontWeight: 'bold', fontFamily: 'Dm Sans', marginTop: '30px', marginLeft: '10px' }}>Depression</div>
                <div id="imageTxt2" style={{ color: '#7f8c8d', fontSize: '16px', fontWeight: 'bold', fontFamily: 'Dm Sans', marginTop: '-10px', marginLeft: '10px' }} >Shop Now</div>
              </Card>
            </Col>
            <Col md={3} xs={3} style={{ marginTop: '10px' }}>
              <Card style={{ borderRadius: '10px', boxShadow: '5px 0px 5px 3px rgba(238, 228, 228, 0.897)' }}>
                <Image id="image" src={Headche} style={{ width: '100%', height: '200px', borderRadius: '10px' }} className="d-none d-lg-block" />
                <div id="imageTxt" style={{ color: '#2c3e50', fontSize: '20px', fontWeight: 'bold', fontFamily: 'Dm Sans', marginTop: '30px', marginLeft: '10px' }}>Headache</div>
                <div id="imageTxt2" style={{ color: '#7f8c8d', fontSize: '16px', fontWeight: 'bold', fontFamily: 'Dm Sans', marginTop: '-10px', marginLeft: '10px' }} >Shop Now</div>
              </Card>
            </Col>

            <Col md={3} xs={3} style={{ marginTop: '10px' }}>
              <Card style={{ borderRadius: '10px', boxShadow: '5px 0px 5px 3px rgba(238, 228, 228, 0.897)' }}>
                <Image id="image" src={stomach} style={{ width: '100%', height: '200px', borderRadius: '10px' }} className="d-none d-lg-block" />
                <div id="imageTxt" style={{ color: '#2c3e50', fontSize: '20px', fontWeight: 'bold', fontFamily: 'Dm Sans', marginTop: '30px', marginLeft: '10px' }}>Stomach Pain</div>
                <div id="imageTxt2" style={{ color: '#7f8c8d', fontSize: '16px', fontWeight: 'bold', fontFamily: 'Dm Sans', marginTop: '-10px', marginLeft: '10px' }} >Shop Now</div>
              </Card>
            </Col>

            <Col md={3} xs={3} style={{ marginTop: '10px' }}>
              <Card style={{ borderRadius: '10px', boxShadow: '5px 0px 5px 3px rgba(238, 228, 228, 0.897)' }}>
                <Image id="image" src={hairloss} style={{ width: '100%', height: '200px', borderRadius: '10px' }} className="d-none d-lg-block" />
                <div id="imageTxt" style={{ color: '#2c3e50', fontSize: '20px', fontWeight: 'bold', fontFamily: 'Dm Sans', marginTop: '30px', marginLeft: '10px' }}>Hair Loss </div>
                <div id="imageTxt2" style={{ color: '#7f8c8d', fontSize: '16px', fontWeight: 'bold', fontFamily: 'Dm Sans', marginTop: '-10px', marginLeft: '10px' }} >Shop Now</div>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  )
}
