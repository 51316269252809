import { gql } from '@apollo/client'

export const GET_BANK_DETAILS = gql`
  query BankGetVendorById($vendorId: ID!) {
    getVendorById(vendorId: $vendorId) {
      id
      bankName
      bankAccountNo
      bankBeneficiaryName
      bankIfsc
      accountType
    }
  }
`
export const GET_COMPANY_DETAILS = gql`
  query CompanyGetVendorById($vendorId: ID!) {
    getVendorById(vendorId: $vendorId) {
      id
      contact
      fullName
      email
      companyName
      companyAddress
      companyCity
      companyCountry
      companyPincode
    }
  }
`

export const GET_BUSINESS_DETAILS = gql`
  query BusinessGetVendorById($vendorId: ID!) {
    getVendorById(vendorId: $vendorId) {
      id
      typeOfBusiness
      cityMedicalLocate
      totalYearInBusiness
      coldStorage
      typeOfParter
      powerBackup
      drugLicenseNo
    }
  }
`

export const GET_DOCUMENT_NAMES = gql`
  query DocumentsGetVendorById($vendorId: ID!) {
    getVendorById(vendorId: $vendorId) {
      id
      gstCertificateImg
      panCardImg
      cancelledChequeImg
      drugLicenseImg
      electricityBillImg
      shopImage
    }
  }
`
