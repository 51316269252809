import React, { useState } from 'react'
import * as XLSX from 'xlsx'
import { Container, Row, Col, Table, Form, Button, Spinner } from 'react-bootstrap'
import { useMutation } from '@apollo/client'
import { MUTATION_CREATE_PRODUCT, QUERY_MEDICINE } from '../Queries'
import swal from 'sweetalert';
import JSZip from 'jszip'

export default function ImportExcel() {
    const adminId = localStorage.getItem('adminId')
    let count = 1;

    const [validated, setValidated] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [items, setItems] = useState([]);
    const [spin, setSpin] = useState(false);
    const [uploadSpin, setUploadSpin] = useState(false);
    const [uploadCount, setUploadCount] = useState(0);

    const [createProductMutation] = useMutation(MUTATION_CREATE_PRODUCT, {
        refetchQueries: [
            QUERY_MEDICINE,
            'getMedicine',
        ],
    });

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            setUploadSpin(true);
            const promise = new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(selectedFile)
                fileReader.onload = (e) => {
                    const bufferArray = e.target.result;
                    const wb = XLSX.read(bufferArray, { type: 'buffer' });
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    const data = XLSX.utils.sheet_to_json(ws);
                    resolve(data);
                };
                fileReader.onerror = ((error) => {
                    reject(error);
                })
            });
            promise.then((d) => {
                console.log(d);
                setItems(d)
                setUploadSpin(false);
            })
        }
        setValidated(true);
    };
    const [zipData, setZipData] = useState([])
    const [zipDataU, setZipDataU] = useState([])

    function clickLoadZip(data) {
        JSZip.loadAsync(data).then(function (zip) {
            let objValue = Object.values(zip.files)

            objValue.map(data => {
                setZipData(current => [...current, data.name.split("/").pop()])
                setZipDataU(currentA => [...currentA, data.name.split("/").pop().split('_')[0]])
            })
        });
    }

    function findValueData() {
        const zipDataFilter = zipDataU.filter(item => {
            return item === '9'
        })
        let array = []
        for (let i = 1; i <= zipDataFilter.length; i++) {
            array.push('1' + '_' + i + '.jpg')
        }
        console.log(array)
    }

    const [completeCount, setCompleteCount] = useState(0)

    const handleUpload = async () => {
        if (items.length === 0) {
            alert('Choose excel file first!!!')
        }
        else if (zipDataU.length === 0) {
            alert('Choose zip file first!!!')
        }
        else {
            setSpin(true)
            for (let i = 0; i < items.length; i++) {

                let array = []
                const sideEffectsArray = items[i].sideEffects.split('|')

                let stringText = items[i].productExcelId.toString()
                const zipDataFilter = zipDataU.filter(item => {
                    return item === stringText
                })

                for (let i = 1; i <= zipDataFilter.length; i++) {
                    array.push(stringText + '_' + i + '.jpg')
                }
                console.log("array", array)

                createProductMutation({
                    variables: {
                        productInput: {
                            vendorId: `${adminId}`,
                            productExcelId: `${items[i].productExcelId}`,
                            name: `${items[i].name}`,
                            manufacturer: `${items[i].manufacturer}`,
                            saltComposition: `${items[i].saltComposition}`,
                            medicineType: `${items[i].medicineType}`,
                            stock: `${items[i].stock}`,
                            introduction: `${items[i].introduction}`,
                            benefit: `${items[i].benefit}`,
                            description: `${items[i].description}`,
                            howToUse: `${items[i].howToUse}`,
                            safetyAdvise: `${items[i].safetyAdvise}`,
                            packaging: `${items[i].packaging}`,
                            packagingType: `${items[i].packagingType}`,
                            mrp: `${items[i].mrp}`,
                            bestPrice: `${items[i].bestPrice}`,
                            prescriptionRequired: `${items[i].prescriptionRequired}`,
                            therapeuticClass: `${items[i].therapeuticClass}`,
                            storage: `${items[i].storage}`,
                            image: array,
                            sideEffects: sideEffectsArray,
                            manufacturerAddress: `${items[i].manufacturerAddress}`,
                            countryOfOrigin: `${items[i].countryOfOrigin}`,
                            uploader: "admin",
                            catagory: "medicine"
                        }
                    }
                }).then(() => {
                    setCompleteCount(preCount => preCount + 1)
                })
            }
        }
        setSpin(false);
            setItems('');
        // swal({ title: "Completed!", text: "Your medicine is uploaded successfully!", icon: "success" }).then(() => {
            
        // })
    }

    console.log("zipDataU",zipDataU)

    return (
        <>
            <Container style={{ marginTop: '80px' }}>
                <Row>
                    <Col>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6" controlId="validationCustom03">
                                    <Form.Label style={{ fontSize: '13px', fontFamily: 'Dm Sans', fontWeight: 'bold' }}>Choose Excel File <span style={{ color: 'red', fontSize: '12px' }}>("xlxs" format only)</span></Form.Label>
                                    <Form.Control type="file" placeholder="City" required onChange={(e) => setSelectedFile(e.target.files[0])} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6" controlId="validationCustom03">
                                    <Form.Label style={{ fontSize: '13px', fontFamily: 'Dm Sans', fontWeight: 'bold' }}>Choose Image Zip File <span style={{ color: 'red', fontSize: '12px' }}>("zip" format only)</span></Form.Label>
                                    <Form.Control type="file" placeholder="City" required onChange={(e) => clickLoadZip(e.target.files[0])} accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed" />
                                </Form.Group>
                            </Row>
                            {
                                uploadSpin ?
                                    <Button variant="success" disabled style={{ fontSize: '12px', fontFamily: 'Dm Sans' }} size="sm">
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                        <span className="visually-hidden">Loading...</span>
                                    </Button> :
                                    <Button type="submit" size="sm" variant="success" style={{ fontSize: '12px', fontFamily: 'Dm Sans' }}>Submit form</Button>
                            }
                        </Form>
                        {/* {
                            zipData && zipData.map(data =>
                                <h6>{(data.name).split("/").pop()}</h6>
                            )
                        } */}
                    </Col>
                    <h1 style={{ fontSize: 15, marginTop: 50, fontWeight: 'bold' }}>Uploading</h1>
                    <h1 style={{ fontSize: 15 }}>{items.length}/{completeCount}</h1>
                </Row>
                {
                    spin ?
                        <>
                            <br />
                            <Button variant="primary" disabled style={{ marginTop: '30px', fontFamily: 'DM Sans', fontSize: '15px', marginBottom: '10px' }}>
                                <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                Uploading...
                            </Button>
                        </>
                        :
                        <>
                            <br />
                            <Button style={{ marginTop: '30px', fontFamily: 'DM Sans', fontSize: '15px', marginBottom: '10px' }} onClick={() => handleUpload()}>Upload</Button>
                        </>
                }
                <Row>
                    <Col>
                        <Table bordered responsive>
                            <thead>
                                <tr style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>
                                    <th>#</th>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Manufacturer</th>
                                    <th>Salt Composition</th>
                                    <th>Medicine Type</th>
                                    <th>Stock</th>
                                    <th>Introduction</th>
                                    <th>Benefit</th>
                                    <th>Description</th>
                                    <th>How To Use</th>
                                    <th>Safety Advise</th>
                                    <th>Packaging</th>
                                    <th>Packaging Type</th>
                                    <th>MRP</th>
                                    <th>Best Price</th>
                                    <th>Prescription Required</th>
                                    <th>Therapeutic Class</th>
                                    <th>Primary Use</th>
                                    <th>Storage</th>
                                    <th>Side Effects</th>
                                    <th>Manufacturer Address</th>
                                    <th>Country Of Origin</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    items && items.map(data => {
                                        return (
                                            <tr style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>
                                                <td>{count++}</td>
                                                <td>{data.productExcelId}</td>
                                                <td>{data.name}</td>
                                                <td>{data.manufacturer}</td>
                                                <td>{data.saltComposition}</td>
                                                <td>{data.medicineType}</td>
                                                <td>{data.stock}</td>
                                                <td>{data.introduction}</td>
                                                <td>{data.benefit}</td>
                                                <td>{data.description}</td>
                                                <td>{data.howToUse}</td>
                                                <td>{data.safetyAdvise}</td>
                                                <td>{data.packaging}</td>
                                                <td>{data.packagingType}</td>
                                                <td>{data.mrp}</td>
                                                <td>{data.bestPrice}</td>
                                                <td>{data.prescriptionRequired}</td>
                                                <td>{data.therapeuticClass}</td>
                                                <td>{data.primaryUse}</td>
                                                <td>{data.storage}</td>
                                                <td>{data.sideEffects}</td>
                                                <td>{data.manufacturerAddress}</td>
                                                <td>{data.countryOfOrigin}</td>
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </Table>
                    </Col>
                </Row>

            </Container>


        </>
    )
}
