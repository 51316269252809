import React, { useState } from 'react'
import { Container, Row, Col, Button, Card, Spinner } from 'react-bootstrap'
import './VendorDashboardcss/VendorLogin.css'
import Navigation from '../Navigation'
import TopHeader from '../Top_Header';
import Footer from '../Footer';
import { FaExclamationTriangle, FaRegCheckCircle } from "react-icons/fa";
import { Navigate } from 'react-router-dom'

import { useMutation, useLazyQuery } from '@apollo/client'
// eslint-disable-next-line no-unused-vars
import { MUTATION_VENDOR_OTP_LOGIN, MUTATION_SEND_OTP } from '../Queries'

export default function VendorLogin() {

    const vendorToken = localStorage.getItem('vendorToken')

    const [contact, setContact] = useState('')
    const [contactError, setContactError] = useState(false)
    const [otpSendClick, setOtpSendClick] = useState(false)
    const [otpState, setOtpState] = useState('')
    const [otpError, setOtpError] = useState(false)
    const [otpResend, setOtpResend] = useState(false)

    const [vendorLoginDone, setVendorLoginDone] = useState(false)

    const [vendorLoginMutation, { data: vendorLoginData, loading: vendorLoginLoading }] = useMutation(MUTATION_VENDOR_OTP_LOGIN);
    const [sendOtpLoginLazyQuery, { loading: sendOtpLoading }] = useMutation(MUTATION_SEND_OTP);

    if (vendorLoginDone) {
        return <Navigate to='/vendorDashboard' />
    }

    if (vendorToken) {
        return <Navigate to='/vendorDashboard' />
    }

    function generateVendorOtp() {
        if (contact === '' || contact.length < 10 || contact.length > 10) {
            setContactError(true)
        }
        else {
            const otp = Math.floor(1000 + Math.random() * 9000);
            setContactError(false)
            localStorage.removeItem("vendorOtp");
            localStorage.setItem('vendorOtp', otp);
            sendOtpLoginLazyQuery({
                variables: {
                    contact: `${contact}`,
                    otp: `${otp}`
                }
            })
            setOtpSendClick(true);
        }
    }

    async function LoginHandle() {
        const otp = localStorage.getItem('vendorOtp');
        if (otp === otpState) {
            await vendorLoginMutation({
                variables: {
                    contact: `${contact}`,
                }
            })
        }
        else {
            setOtpError(true)
        }
    }

    function contactEnterAgain() {
        localStorage.removeItem("vendorOtp")
        setOtpError(false)
        setContact('')
        setOtpState('')
        setOtpSendClick(false)
    }

    function otpSendAgain() {
        setOtpResend(true)
        setOtpError(false)
        localStorage.removeItem("vendorOtp")
        setOtpState('')
        generateVendorOtp()
    }


    if (vendorLoginData) {
        localStorage.setItem('vendorId', vendorLoginData.vendorOtpLogin.vendorId)
        localStorage.setItem('vendorToken', vendorLoginData.vendorOtpLogin.vendorToken)
        localStorage.setItem('vendorTokenExpiration', vendorLoginData.vendorOtpLogin.vendorTokenExpiration)
        localStorage.removeItem("vendorOtp")
        setVendorLoginDone(true)
    }

    return (
        <div>
            <TopHeader />
            <Navigation />
            <Container>
                <Row>
                    <Col md={3}></Col>
                    <Col md={6}>
                        <Card style={{ backgroundColor: '#ecf0f1', borderRadius: '20px', marginTop: '30px' }}>
                            <Card.Body>
                                <h4 style={{ textAlign: 'center', fontFamily: 'DM Sans', color: '#2c3e50', fontWeight: '500', fontSize: '30px' }}>Vendor Login</h4>
                                <p style={{ fontFamily: 'Dm Sans', textAlign: 'center', fontSize: '15px' }}>Please enter your contact number !!!</p>

                                {
                                    otpSendClick ?
                                        <>
                                            <input type="text" name="contact" placeholder="Enter OTP" style={{ borderRadius: '20px', textAlign: 'center', lineHeight: 2, border: '1px solid black', width: '50%', fontFamily: 'DM Sans' }} className="mx-auto d-block" onChange={e => setOtpState(e.target.value)} value={otpState} />
                                            {
                                                otpError ?
                                                    <>
                                                        <h1 style={{ fontSize: '12px', fontFamily: 'Dm Sans', marginTop: '10px', textAlign: 'center' }}><FaExclamationTriangle style={{ color: 'red', marginTop: '-3px' }} /> Invalid OTP</h1>
                                                        <Row>
                                                            <Col style={{ textAlign: 'center', fontSize: '13px', fontFamily: 'DM Sans', fontWeight: 'bold', cursor: 'pointer', color: '#2c3e50' }} onClick={otpSendAgain}>Send Again</Col>
                                                            <Col style={{ textAlign: 'left', fontSize: '13px', fontFamily: 'DM Sans', fontWeight: 'bold', cursor: 'pointer', color: '#2c3e50' }} onClick={contactEnterAgain}>Enter Contact Again</Col>
                                                        </Row>
                                                    </>
                                                    :
                                                    otpResend ?
                                                        <h1 style={{ fontSize: '12px', fontFamily: 'Dm Sans', marginTop: '10px', textAlign: 'center' }}><FaRegCheckCircle style={{ color: 'green', marginTop: '-3px' }} /> OTP Sent Successfully.</h1>
                                                        :
                                                        ''
                                            }
                                            {
                                                vendorLoginLoading ?
                                                    <Spinner animation="border" role="status" className="mx-auto d-block">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner> :
                                                    <Button id="login_btn" className="mx-auto d-block" onClick={LoginHandle}>Login</Button>

                                            }

                                        </>
                                        :
                                        <>
                                            <input type="text" name="contact" placeholder="Enter Your Contact" style={{ borderRadius: '20px', textAlign: 'center', lineHeight: 2, border: '1px solid black', width: '50%', fontFamily: 'DM Sans' }} className="mx-auto d-block" onChange={e => setContact(e.target.value)} value={contact} />
                                            {contactError ? <h1 style={{ fontSize: '12px', fontFamily: 'Dm Sans', marginTop: '10px', textAlign: 'center' }}><FaExclamationTriangle style={{ color: 'red', marginTop: '-3px' }} /> Invalid Contact Number</h1> : ''}
                                            <Button id="login_btn" className="mx-auto d-block" onClick={generateVendorOtp}>Send OTP</Button>
                                        </>
                                }

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}></Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )
}
