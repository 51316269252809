import React from 'react'
import { Container, Row, Col, Card, Image } from 'react-bootstrap'
import './componentCss/ShopByCategory.css'
import Himalaya from '../img/himalaya.png'
import Mama from '../img/mama.png'
import Vlcc from '../img/vlcc.png'
import Bio from '../img/bio.png'
import Emami from '../img/emami.png'
import Nivea from '../img/nivea.png'
import joy from '../img/joy.png'
import wow from '../img/wow.png'
import lakeme from '../img/Lakmé.png'
import logodove from '../img/logodove.png'
import Loreal from '../img/Loreal.png'
import Gartner from '../img/gartner.png'
import { useNavigate } from 'react-router-dom'

export default function ShopByCategory() {

  const navigate = useNavigate();
  const handleClick = (id) => navigate('/productByBrandName/' + id);

  return (
    <>
      <Container fluid>
        <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#0b1219', fontSize: '27px', marginTop: '10px' }}> Shop By Top Cosmetic Brands </p>
        <Row>
          <Col md={2} xs={4}>
            <Card className="CategoryCard" onClick={() => handleClick('HIMALAYA')}>
              <Card.Body>
                <Image src={Himalaya} className="categoryImg" />
                <h1 style={{ fontSize: 12, fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'center' }}>Himalaya</h1>
              </Card.Body>
            </Card>
          </Col>
          <Col md={2} xs={4}>
            <Card className="CategoryCard" onClick={() => handleClick('MAMAEARTH')}>
              <Card.Body>
                <Image variant="top" src={Mama} className="categoryImg" />
                <h1 style={{ fontSize: 12, fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'center' }}>
                  Mama Earth
                </h1>
              </Card.Body>
            </Card>
          </Col>
          <Col md={2} xs={4}>
            <Card className="CategoryCard" onClick={() => handleClick('VLCC')}>
              <Card.Body>
                <Image variant="top" src={Vlcc} className="categoryImg" />
                <h1 style={{ fontSize: 12, fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'center' }}>
                  VLCC
                </h1>
              </Card.Body>
            </Card>
          </Col>
          <Col md={2} xs={4}>

            <Card className="CategoryCard" onClick={() => handleClick('BIOTIQUE')}>
              <Card.Body>
                <Image variant="top" src={Bio} className="categoryImg" />
                <h1 style={{ fontSize: 12, fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'center' }}>
                  Biotique
                </h1>
              </Card.Body>
            </Card>

          </Col>
          <Col md={2} xs={4}>
            <Card className="CategoryCard" onClick={() => handleClick('EMAMI')}>
              <Card.Body>
                <Image variant="top" src={Emami} className="categoryImg" />
                <h1 style={{ fontSize: 12, fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'center' }}>
                  Emami
                </h1>
              </Card.Body>
            </Card>
          </Col>
          <Col md={2} xs={4}>
            <Card className="CategoryCard" onClick={() => handleClick('NIVEA')}>
              <Card.Body>
                <Image variant="top" src={Nivea} className="categoryImg" />
                <h1 style={{ fontSize: 12, fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'center' }}>
                  Nivea
                </h1>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={2} xs={4}>
            <Card className="CategoryCard" onClick={() => handleClick('JOY')}>
              <Card.Body>
                <Image variant="top" src={joy} className="categoryImg" />
                <h1 style={{ fontSize: 12, fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'center' }}>
                  Joy
                </h1>
              </Card.Body>
            </Card>
          </Col>
          <Col md={2} xs={4}>
            <Card className="CategoryCard" onClick={() => handleClick('WOW')}>
              <Card.Body>
                <Image variant="top" src={wow} className="categoryImg" />
                <h1 style={{ fontSize: 12, fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'center' }}>
                  Wow
                </h1>
              </Card.Body>
            </Card>
          </Col>
          <Col md={2} xs={4}>
            <Card className="CategoryCard" onClick={() => handleClick('LAKME')}>
              <Card.Body>
                <Image variant="top" src={lakeme} className="categoryImg" />
                <h1 style={{ fontSize: 12, fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'center' }}>
                  Lakme
                </h1>
              </Card.Body>
            </Card>
          </Col>
          <Col md={2} xs={4}>
            <Card className="CategoryCard" onClick={() => handleClick('DOVE')}>
              <Card.Body>
                <Image variant="top" src={logodove} className="categoryImg" />
                <h1 style={{ fontSize: 12, fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'center' }}>
                  Dove
                </h1>
              </Card.Body>
            </Card>
          </Col>
          <Col md={2} xs={4}>
            <Card className="CategoryCard" onClick={() => handleClick('LOREAL')}>
              <Card.Body>
                <Image variant="top" src={Loreal} className="categoryImg" />
                <h1 style={{ fontSize: 12, fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'center' }}>
                  Loreal
                </h1>
              </Card.Body>
            </Card>
          </Col>
          <Col md={2} xs={4}>
            <Card className="CategoryCard" onClick={() => handleClick('GARTNER')}>
              <Card.Body>
                <Image variant="top" src={Gartner} className="categoryImg" />
                <h1 style={{ fontSize: 12, fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'center' }}>
                  Garnter
                </h1>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}
