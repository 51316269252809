import React, { useState } from 'react'
import { Nav, Tab, Row, Col, Button } from 'react-bootstrap';
import './css/adminDashboard.css'
import { Outlet, Link, Navigate } from 'react-router-dom'
import { FaHome, FaCartPlus, FaUpload, FaBoxOpen, FaFileExcel, FaUsersCog, FaMotorcycle, FaPlusCircle, FaSignOutAlt, FaPeopleArrows  , FaWpforms} from "react-icons/fa";

export default function AdminDashboard() {

    const [logout, setLogout] = useState(false)
    const adminToken = localStorage.getItem('adminToken')

    if (!adminToken) {
        return <Navigate to='/adminLogin' />
    }

    if (logout) {
        return <Navigate to="/adminLogout" />
    }
    return (
        <>
            <h1 style={{ textAlign: 'center', background: '#000', padding: '20px', color: '#fff', fontFamily: 'Dm Sans', marginBottom: '-10px' }}>Admin Dashboard</h1>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col md={2} style={{ background: "#000", minHeight: '700px' }}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link><Link to="/" className="sidebarOptions" style={{ textDecoration: 'none', fontSize: '12px' }}><FaHome style={{ marginTop: '-4px' }} /> Home</Link></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} to={'/adminDashboard/userOrders'} className="sidebarOptions" style={{ fontSize: '12px' }}><FaCartPlus style={{ marginTop: '-4px' }} /> Orders</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} to={'/adminDashboard/chooseCategory'} className="sidebarOptions" style={{ fontSize: '12px' }}><FaUpload style={{ marginTop: '-4px' }} /> Upload Product</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} to={'/adminDashboard/products'} className="sidebarOptions" style={{ fontSize: '12px' }}><FaBoxOpen style={{ marginTop: '-4px' }} /> All Products</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} to={'/adminDashboard/import'} className="sidebarOptions" style={{ fontSize: '12px' }}><FaFileExcel style={{ marginTop: '-4px' }} /> Import Excel</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} to={'/adminDashboard/allUsers'} className="sidebarOptions" style={{ fontSize: '12px' }}><FaUsersCog style={{ marginTop: '-4px' }} /> All Users</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} to={'/adminDashboard/addDeliveryBoy'} className="sidebarOptions" style={{ fontSize: '12px' }}><FaMotorcycle style={{ marginTop: '-4px' }} /> Add Delivery Boy</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} to={'/adminDashboard/allDeliveryBoy'} className="sidebarOptions" style={{ fontSize: '12px' }}><FaPlusCircle style={{ marginTop: '-4px' }} /> All Delivery Boy</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} to={'/adminDashboard/Contact'} className="sidebarOptions" style={{ fontSize: '12px' }}><FaWpforms style={{ marginTop: '-4px' }} /> Contact Request</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} to={'/adminDashboard/coupons'} className="sidebarOptions" style={{ fontSize: '12px' }}><FaWpforms style={{ marginTop: '-4px' }} /> Coupons</Nav.Link>
                            </Nav.Item>
                            <Button className="Sidebarlogoutbtn" onClick={() => setLogout(true)} style={{ fontSize: '12px' }}>Log Out <FaSignOutAlt style={{ marginTop: '-3px' }} /></Button>
                        </Nav>
                    </Col>
                    <Col md={10}>
                        <Outlet />
                    </Col>
                </Row>
            </Tab.Container>
        </>
    )
}


