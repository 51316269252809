

import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import './componentCss/ShopByCategory.css'
import Himalaya from '../img/himalaya.png'
import Mama from '../img/mama.png'
import Vlcc from '../img/vlcc.png'
import Bio from '../img/bio.png'
import Emami from '../img/emami.png'
import Nivea from '../img/nivea.png'
import stomachcare from '../img/digene.png'
import firstaid from '../img/First-Aid.png'
import healthyheart from '../img/healthyheart.png'
import respirotry from '../img/anesthesia.png'
import kideny from '../img/kideny.png'
import livcare from '../img/livcare.png'
import { useNavigate } from 'react-router-dom'

export default function HealthTreatment() {

  const navigate = useNavigate();
  const handleClick = (id) => navigate('/productByBrandName/' + id);

  return (
    <>
      <Container fluid>
        <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#0b1219', fontSize: '27px', marginTop: '10px' }}>Health Treatments </p>
        <Row>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12} xs={12}>
                <Card className="CategoryCard" onClick={() => handleClick('HIMALAYA')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={stomachcare} className="categoryImg" /></center>
                    <Card.Text className="CardText">
                      Stomach Care
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12} xs={12}>
                <Card className="CategoryCard" onClick={() => handleClick('MAMAEARTH')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={firstaid} className="categoryImg" /></center>
                    <Card.Text className="CardText">
                     First Aid
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12} xs={12}>
                <Card className="CategoryCard" onClick={() => handleClick('VLCC')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={healthyheart} className="categoryImg" /></center>
                    <Card.Text className="CardText">
                   Healthy Heart
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12} xs={12}>
                <Card className="CategoryCard" onClick={() => handleClick('BIOTIQUE')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={respirotry} className="categoryImg" /></center>
                    <Card.Text className="CardText">
                      Respiratory Health 
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12} xs={12}>
                <Card className="CategoryCard" onClick={() => handleClick('EMAMI')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={livcare} className="categoryImg" /></center>
                    <Card.Text className="CardText">
                      Liver Care
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12} xs={12}>
                <Card className="CategoryCard" onClick={() => handleClick('NIVEA')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={kideny} className="categoryImg" /></center>
                    <Card.Text className="CardText">
                      Kidney Care
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        
      </Container>
    </>
  )
}

