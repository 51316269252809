import { IconContext } from 'react-icons'
import { BsCheck } from 'react-icons/bs'

export default function CheckIcon() {
  return (
    <IconContext.Provider value={{ color: 'green', size: '1.5rem' }}>
      <BsCheck />
    </IconContext.Provider>
  )
}
