import React, { useState, useEffect } from 'react'
import TopHeader from './Top_Header'
import Navigation from './Navigation'
import { Container, Row, Col, Card, Button, Form, Modal, Image, Table, Spinner } from 'react-bootstrap'
import './componentCss/checkout.css'
import { useLocation } from "react-router-dom";
import { useMutation, useQuery } from '@apollo/client'
import { MUTATION_CREATE_ORDER, QUERY_ADD_ADDRESS, QUERY_ORDER_BY_USERID, MUTATION_REMOVE_CARTITEM_BY_USERID, QUERY_CHECK_PENDING_CART_PRODUCT_FOR_USER, MUTATION_CHECK_COUPON } from './Queries'
import OrderCompleted from './OrderCompleted'

import Secure from '../img/payment/secure.png'
import Visa from '../img/payment/visa.jpg'
import MasterCard from '../img/payment/mastercard.png'
import Rupay from '../img/payment/rupay.png'
import UPI from '../img/payment/upi.png'


export default function Checkout(props) {
    const userId = localStorage.getItem('userId')
    const location = useLocation()
    const totalCartAmount = location.state.totalAmount
    const cartData = location.state.cartData
    const cart = location.state.cart

    console.log('location', location.state.imageKey)

    const [validated, setValidated] = useState(false);
    const [validated2, setValidated2] = useState(false);

    const [paymentDone, setPaymentDone] = useState(false);
    const [paymentMethodOption, setPaymentMethodOption] = useState('')

    const [couponValue, setCouponValue] = useState('');
    const [couponNotFound, setCouponNotFound] = useState(false);
    const [couponFound, setCouponFound] = useState(false);
    const [couponStop, setCouponStop] = useState(false);

    const [totalAmountState, setTotalAmountState] = useState(totalCartAmount)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [fullName, setFullName] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    const [pincode, setPincode] = useState('');
    const [address, setAddress] = useState('');

    const [productDetailsState, setProductDetailsState] = useState([])

    useEffect(() => {
        cart.map(cartDetail => {
            setProductDetailsState(prevState => [...prevState, {
                "uploaderId": cartDetail.uploaderId,
                "productId": cartDetail.id,
                "productName": cartDetail.name,
                "productPrice": cartDetail.mrp,
                "productImage": cartDetail.image[0],
                "quantity": cartDetail.amount,
            }]);
        })
    }, [])

    const [createOrder] = useMutation(MUTATION_CREATE_ORDER, {
        refetchQueries: [
            QUERY_ORDER_BY_USERID
        ]
    });

    const [removeCart] = useMutation(MUTATION_REMOVE_CARTITEM_BY_USERID, {
        refetchQueries: [
            QUERY_ORDER_BY_USERID,
            QUERY_CHECK_PENDING_CART_PRODUCT_FOR_USER
        ]
    });

    const [verifyCoupon, { data: couponData, loading: couponLoading }] = useMutation(MUTATION_CHECK_COUPON, {
        onError(error) {
            setCouponNotFound(true);
            setCouponFound(false);
            console.log(error)
        },
    });

    const loadScript = (src) => {
        return new Promise((resovle) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resovle(true);
            };
            script.onerror = () => {
                resovle(false);
            };
            document.body.appendChild(script);
        });
    };

    const { data, loading } = useQuery(QUERY_ADD_ADDRESS, {
        variables: {
            "userId": `${userId}`
        }
    })

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            setValidated(false);
            handleShow()


        }
    };

    async function paymentProceed() {

        if (paymentMethodOption === '') {
            alert('Please select any payment method!!!')
        }
        else if (paymentMethodOption === 'online') {
            handleClose()
            const res = await loadScript(
                "https://checkout.razorpay.com/v1/checkout.js"
            );
            if (!res) {
                alert("You are offline... Failed to load Payment Gateway");
                return;
            }
            const options = {
                key: "rzp_live_lb1bbFkW2JKt8n",
                currency: "INR",
                amount: totalAmountState * 100,
                name: "Bharat Pharma",
                description: "Payment for Order Products",
                // image: `${Logo}`,

                handler: function (response) {
                    console.log(response)
                    createOrder({
                        variables: {
                            "orderInput": {
                                "productDetails": productDetailsState,
                                "userId": `${userId}`,
                                "paymentId": `${response.razorpay_payment_id}`,
                                "paymentMethod": 'Online',
                                "fullName": `${fullName}`,
                                "contact": `${mobileNo}`,
                                "email": `${email}`,
                                "city": `${city}`,
                                "pincode": `${pincode}`,
                                "fullAddress": `${address}`,
                                "prescriptionImage": `${location.state.imageKey}`,
                                "totalPrice": `${totalAmountState}`,
                                "deliveryId": null,
                                "deliveryName": null,
                            }
                        }
                    })
                        .then(() => {
                            removeCart({
                                variables: {
                                    "userId": `${userId}`
                                }
                            })
                            setFullName('');
                            setMobileNo('');
                            setEmail('');
                            setCity('');
                            setPincode('');
                            setAddress('');
                            setPaymentDone(true);
                        })
                },
                prefill: {
                    name: "Bharat Pharma",
                },
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        }
        else {
            createOrder({
                variables: {
                    "orderInput": {
                        "productDetails": productDetailsState,
                        "userId": `${userId}`,
                        "paymentId": null,
                        "paymentMethod": 'Cash On Delivery',
                        "fullName": `${fullName}`,
                        "contact": `${mobileNo}`,
                        "email": `${email}`,
                        "city": `${city}`,
                        "pincode": `${pincode}`,
                        "fullAddress": `${address}`,
                        "prescriptionImage": `${location.state.imageKey}`,
                        "totalPrice": `${totalAmountState}`,
                        "deliveryId": null,
                        "deliveryName": null,
                    }
                }
            }).then(() => {
                removeCart({
                    variables: {
                        "userId": `${userId}`
                    }
                })
                setFullName('');
                setMobileNo('');
                setEmail('');
                setCity('');
                setPincode('');
                setAddress('');
                setPaymentDone(true);
            })
        }
    }


    if (paymentDone) {
        return <OrderCompleted />
    }

    function addAddressHandle(userData) {
        setFullName(userData.name)
        setMobileNo(userData.mobile)
        setEmail(userData.email)
        setCity(userData.city)
        setPincode(userData.pincode)
        setAddress(userData.address)
        handleClose1()
    }


    const handleSubmit2 = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated2(true);
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setValidated2(false);
            setCouponStop(false)
            event.preventDefault();
            setCouponNotFound(false)
            verifyCoupon({
                variables: {
                    "couponName": `${couponValue}`
                }
            })
            setCouponValue('')
        }
    };

    if (couponData && couponStop === false) {
        let percen = parseInt(couponData.checkCoupon.couponPercentage);
        console.log(percen)
        let discountAmount = (totalAmountState * percen) / 100;
        let finalDiscountAmount = totalAmountState - discountAmount
        setTotalAmountState((finalDiscountAmount).toFixed(2))

        setCouponFound(true);
        setCouponStop(true);
    }

    return (
        <>
            <TopHeader />
            <Navigation />
            <Container>
                <Row>
                    <Card className='checkoutCard'>
                        <h1 style={{ color: '#2B97A4', fontSize: '18px', fontFamily: 'Dm Sans', fontWeight: 'bold', textAlign: 'center', marginTop: 20 }}>Delivery Address</h1>
                        <hr />
                        <Card.Body>
                            <Row>
                                <Col md={6}>
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Control required type="text" placeholder="Full Name" className="checkoutInput" onChange={e => setFullName(e.target.value)} value={fullName} style={{ fontSize: 12 }} />
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Control required type="number" placeholder="Mobile Number" className="checkoutInput" onChange={e => setMobileNo(e.target.value)} value={mobileNo} style={{ fontSize: 12 }} />
                                            </Form.Group>
                                        </Row>

                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Control required type="email" placeholder="Email" className="checkoutInput" onChange={e => setEmail(e.target.value)} value={email} style={{ fontSize: 12 }} />
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Control required type="text" placeholder="City" className="checkoutInput" onChange={e => setCity(e.target.value)} value={city} style={{ fontSize: 12 }} />
                                            </Form.Group>
                                        </Row>

                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Control required type="number" placeholder="Pincode" className="checkoutInput" onChange={e => setPincode(e.target.value)} value={pincode} style={{ fontSize: 12 }} />
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Control required as="textarea" rows={1} placeholder="Full Address" className="checkoutInput" onChange={e => setAddress(e.target.value)} value={address} style={{ fontSize: 12 }} />
                                            </Form.Group>
                                        </Row>
                                        <hr />
                                        <Button type="submit" className="placeOrderBtn mx-auto d-block">Place Order</Button>
                                    </Form>
                                </Col>
                                <Col md={6}>
                                    <Row>
                                        <Col></Col>
                                        <Col>
                                            <Button className="mx-auto d-block" style={{ fontSize: '12px', fontFamily: 'DM Sans', marginTop: 10 }} onClick={handleShow1} >Existing Address</Button>
                                            <Form noValidate validated={validated2} onSubmit={handleSubmit2} style={{ marginTop: 10 }}>
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                        <Form.Label style={{ fontSize: 12, fontFamily: 'DM Sans', fontWeight: 'bold' }}>Enter Coupon Code</Form.Label>
                                                        <Form.Control required type="text" placeholder="Code" style={{ fontSize: 12, fontFamily: 'DM Sans' }} onChange={e => setCouponValue(e.target.value)} value={couponValue} />
                                                    </Form.Group>
                                                </Row>
                                                {
                                                    couponNotFound ?
                                                        <h1 style={{ fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'center', color: 'red', fontSize: 10 }}>Invalid Coupon Code</h1> : ""
                                                }
                                                {
                                                    couponFound ?
                                                        <h1 style={{ fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'center', color: 'green', fontSize: 10 }}>Coupon Applied Successfully</h1> : ""
                                                }
                                                {
                                                    couponLoading ?
                                                        <Spinner animation="border" role="status" className="mx-auto d-block" style={{ marginBottom: '5px' }}>
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner> :
                                                        <Button type="submit" style={{ fontSize: 12, fontFamily: 'DM Sans' }} variant="success" className="mx-auto d-block">Check</Button>
                                                }
                                            </Form>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h1 style={{ fontSize: '16px', fontFamily: 'DM Sans', fontWeight: 'bold', color: '#E4524B', textAlign: 'center', marginTop: 20 }}>Cart Total: <span style={{ fontWeight: 'bold', color: 'black' }}>Rs. {totalAmountState}</span></h1>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Row>
            </Container>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <Container>
                        <h1 style={{ fontFamily: 'DM Sans', fontSize: '20px', textAlign: 'center', fontWeight: 'bold' }}>Select Payment Method</h1>
                        <Col style={{ marginTop: '20px' }}>
                            <input type="radio" name="onlinecod" value="online" onChange={e => setPaymentMethodOption(e.target.value)} />
                            <label for="onlinecod" style={{ fontFamily: 'DM Sans', marginLeft: '5px' }}>Online Payment</label><br /><br />
                            <input type="radio" name="onlinecod" value="cod" onChange={e => setPaymentMethodOption(e.target.value)} />
                            <label for="onlinecod" style={{ fontFamily: 'DM Sans', marginLeft: '5px' }}>Cash on delivery (COD)</label><br></br>
                        </Col>
                        <Button variant='outline-primary' className='mx-auto d-block' style={{ marginTop: '30px', borderRadius: '0px', fontFamily: 'DM Sans' }} onClick={() => paymentProceed()}>Proceed</Button>
                        <Row style={{ marginTop: '40px' }}>
                            <Col>
                                <Image src={Secure} style={{ width: '60px', height: '30px' }} />
                            </Col>
                            <Col>
                                <Image src={Visa} style={{ width: '60px', height: '30px' }} />
                            </Col>
                            <Col>
                                <Image src={MasterCard} style={{ width: '50px', height: '30px' }} />
                            </Col>
                            <Col>
                                <Image src={Rupay} style={{ width: '50px', height: '30px' }} />
                            </Col>
                            <Col>
                                <Image src={UPI} style={{ width: '50px', height: '20px' }} />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            <Modal show={show1} onHide={handleClose1} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className='mx-auto d-block' style={{ textAlign: 'center' }}>Your Exsisting Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table bordered responsive style={{ marginTop: '15px' }}>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Mobile No.</th>
                                <th>Email</th>
                                <th>City</th>
                                <th>Your Address</th>
                                <th>Pincode</th>
                                <th>Add</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ?
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner> :
                                    data && data.getAddAddress.slice().reverse().map(userData =>
                                        <tr>
                                            <td>{userData.name}</td>
                                            <td>{userData.mobile}</td>
                                            <td>{userData.email}</td>
                                            <td>{userData.city}</td>
                                            <td>{userData.address}</td>
                                            <td>{userData.pincode}</td>
                                            <td><Button style={{ height: '30px', width: '50px', textAlign: 'center', fontSize: 10 }} onClick={() => addAddressHandle(userData)}>Add</Button></td>
                                        </tr>
                                    )
                            }
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>

        </>
    )
}




