import { Col, Row, Button, Form, Card } from 'react-bootstrap'
import { useState } from 'react'
import countryList from './countryList'
import { useMutation, useQuery } from '@apollo/client'
import { MUTATION_ACTIVE_VENDOR } from '../../Queries'
import { GET_COMPANY_DETAILS } from './FormQueries'
import { useNavigate } from 'react-router-dom'

function CompanyDetails() {
  const navigate = useNavigate()
  const [validatedStep1, setValidatedStep1] = useState(false)
  const [showSubmitButton, setShowSubmitButton] = useState(true)
  const vendorId = localStorage.getItem('vendorId')

  const { data } = useQuery(GET_COMPANY_DETAILS, {
    variables: { vendorId },
  })

  const companyData = data?.getVendorById

  const [formData, setFormData] = useState({
    contact: companyData.contact || '',
    fullName: companyData.fullName || '',
    email: companyData.email || '',
    companyName: companyData.companyName || '',
    companyAddress: companyData.companyAddress?.split('|')[0] || '',
    companyAddress2: companyData.companyAddress?.split('|')[1] || '',
    companyCity: companyData.companyCity || '',
    companyCountry: companyData.companyCountry || '',
    companyPincode: companyData.companyPincode || '',
  })

  const {
    contact,
    fullName,
    email,
    companyName,
    companyAddress,
    companyAddress2,
    companyCity,
    companyCountry,
    companyPincode,
  } = formData

  const [vendorActiveMutation] = useMutation(MUTATION_ACTIVE_VENDOR)

  //handle step1
  const handleSubmitStep1 = (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()

      vendorActiveMutation({
        variables: {
          vendorActiveInput: {
            vendorId,
            contact,
            fullName,
            email,
            companyName,
            companyAddress: companyAddress + ' | ' + companyAddress2,
            companyCity,
            companyCountry,
            companyPincode,
          },
        },
        refetchQueries: [
          { query: GET_COMPANY_DETAILS },
          'CompanyGetVendorById',
        ],
      })
      setShowSubmitButton(false)
    }

    setValidatedStep1(true)
  }

  const onChange = (e) => {
    setFormData((previousState) => ({
      ...previousState,
      [e.target.id]: e.target.value,
    }))
    setShowSubmitButton(true)
  }

  return (
    <>
      <Card style={{ marginTop: '10px', marginBottom: '20px' }}>
        <Card.Body>
          <Form
            noValidate
            validated={validatedStep1}
            onSubmit={handleSubmitStep1}
          >
            <h1
              style={{
                fontSize: '25px',
                fontFamily: 'DM Sans',
                fontWeight: 'bold',
              }}
            >
              Personal Details
            </h1>
            <Row className='mb-3'>
              <Form.Group as={Col} md='6'>
                <Form.Label className='formLabel1'>Mobile Number</Form.Label>
                <Form.Control
                  required
                  type='number'
                  id='contact'
                  placeholder='Mobile Number'
                  className='formInput1'
                  onChange={onChange}
                  value={contact}
                />
                <Form.Control.Feedback className='formGood1'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Enter Mobile Number.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md='6'>
                <Form.Label className='formLabel1'>Full Name</Form.Label>
                <Form.Control
                  required
                  type='text'
                  id='fullName'
                  placeholder='Full Name'
                  className='formInput1'
                  onChange={onChange}
                  value={fullName}
                />
                <Form.Control.Feedback className='formGood1'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Enter Full Name.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} md='6'>
                <Form.Label className='formLabel1'>Email</Form.Label>
                <Form.Control
                  required
                  type='email'
                  id='email'
                  placeholder='Email'
                  className='formInput1'
                  onChange={onChange}
                  value={email}
                />
                <Form.Control.Feedback className='formGood1'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Enter Email.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md='6'>
                <Form.Label className='formLabel1'>Company Name</Form.Label>
                <Form.Control
                  required
                  type='text'
                  id='companyName'
                  placeholder='Company Name'
                  className='formInput1'
                  onChange={onChange}
                  value={companyName}
                />
                <Form.Control.Feedback className='formGood1'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Enter Company Name.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <h1
              style={{
                fontSize: '25px',
                fontFamily: 'DM Sans',
                fontWeight: 'bold',
              }}
            >
              Company Address
            </h1>
            <Row className='mb-3'>
              <Form.Group as={Col} md='6'>
                <Form.Label className='formLabel1'>Address Line 1</Form.Label>
                <Form.Control
                  required
                  as='textarea'
                  id='companyAddress'
                  placeholder='Address Line 1'
                  className='formInput1'
                  onChange={onChange}
                  value={companyAddress}
                />
                <Form.Control.Feedback className='formGood1'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Enter Address Line 1.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md='6'>
                <Form.Label className='formLabel1'>Address Line 2</Form.Label>
                <Form.Control
                  required
                  as='textarea'
                  id='companyAddress2'
                  placeholder='Address Line 2'
                  className='formInput1'
                  onChange={onChange}
                  value={companyAddress2}
                />
                <Form.Control.Feedback className='formGood1'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Enter Address Line 2.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} md='4'>
                <Form.Label className='formLabel1'>City</Form.Label>
                <Form.Control
                  required
                  type='text'
                  id='companyCity'
                  placeholder='City'
                  className='formInput1'
                  onChange={onChange}
                  value={companyCity}
                />
                <Form.Control.Feedback className='formGood1'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Enter City.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md='4'>
                <Form.Label className='formLabel1'>Pincode</Form.Label>
                <Form.Control
                  required
                  type='number'
                  id='companyPincode'
                  placeholder='Pincode'
                  className='formInput1'
                  onChange={onChange}
                  value={companyPincode}
                />
                <Form.Control.Feedback className='formGood1'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Enter Pincode.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md='4'>
                <Form.Label className='formLabel1'>Country</Form.Label>
                <Form.Control
                  required
                  as='select'
                  id='companyCountry'
                  className='formInput1'
                  onChange={onChange}
                  value={companyCountry}
                >
                  <option value='' selected='selected' disabled='disabled'>
                    Choose Country
                  </option>
                  {countryList.map((country) => (
                    <option value={country} key={country}>
                      {country}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback className='formOk'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Choose Country
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            {showSubmitButton ? (
              <Button type='submit' className='nextBtn mx-auto d-block'>
                Submit
              </Button>
            ) : (
              <Button
                type='button'
                className='nextBtn mx-auto d-block'
                onClick={() => navigate('bank-details')}
              >
                Next
              </Button>
            )}
          </Form>
        </Card.Body>
      </Card>
    </>
  )
}

export default CompanyDetails
