import React, { useState } from 'react';
import Button from '@restart/ui/esm/Button';
import { Container, Row, Col, Card, Form, Spinner, FormControl } from 'react-bootstrap';
import '../UserDashboard/UserDashboardCss/YourAccount.css';
import { useMutation, useQuery } from '@apollo/client'
import { MUTATION_UPDATE_USER, QUERY_GET_USER_BY_ID } from '../Queries'
import swal from 'sweetalert';

export default function YourAccount() {

    const [validated, setValidated] = useState(false)

    const userId = localStorage.getItem('userId');
    console.log(userId)
    const [updateUser, { loading: updateLoading }] = useMutation(MUTATION_UPDATE_USER, {
        refetchQueries: [
            QUERY_GET_USER_BY_ID
        ]
    });

    const { data, loading } = useQuery(QUERY_GET_USER_BY_ID, {
        variables: {
            "userId": `${userId}`,
        }
    });
    console.log(data)
    const [name, setName] = useState(data && data.getUserById.fullName);
    const [contact, setContact] = useState(data && data.getUserById.contact);
    const [email, setEmail] = useState(data && data.getUserById.email);
    const [address, setAddress] = useState(data && data.getUserById.address);
    const [city, setCity] = useState(data && data.getUserById.city);
    const [state, setState] = useState(data && data.getUserById.state);
    const [pincode, setPincode] = useState(data && data.getUserById.pincode);


    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.stopPropagation();
        }
        else {
            setValidated(false);
            event.preventDefault();

            updateUser({
                variables: {
                    "userUpdateInput": {
                        "userId": `${userId}`,
                        "fullName": `${name}`,
                        "contact": `${contact}`,
                        "email": `${email}`,
                        "address": `${address}`,
                        "city": `${city}`,
                        "state": `${state}`,
                        "pincode": `${pincode}`
                    }
                }
            }).then(() => {
                swal({
                    title: "Success",
                    text: "Account Update successfully",
                    icon: "success",
                })
            })
        }

    };


    return (
        <Container style={{ marginBottom: '20px' }} >
            <Row>
                <Col md={7} style={{ marginTop: '50px' }}>
                    <Card className="UserAccountCard" style={{ padding: '20px' }} >
                        <Card.Title className='mx-auto d-block'> Add Your Address</Card.Title>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="mb-4">
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <FormControl style={{ fontFamily: 'Dm Sans' }} className="UserAccountData" placeholder="Full Name" onChange={(e) => setName(e.target.value)} value={name} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <FormControl style={{ fontFamily: 'Dm Sans' }} className="UserAccountData" placeholder="Email" onChange={(e) => setEmail(e.target.value)} value={email} />
                                </Form.Group>
                            </Row>
                            <Row className="mb-4">
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <FormControl style={{ fontFamily: 'Dm Sans' }} className="UserAccountData" placeholder="Address" onChange={(e) => setAddress(e.target.value)} value={address} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <FormControl style={{ fontFamily: 'Dm Sans' }} className="UserAccountData" placeholder="State" onChange={(e) => setState(e.target.value)} value={state} />
                                </Form.Group>
                            </Row>
                            <Row className="mb-4">
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <FormControl style={{ fontFamily: 'Dm Sans' }} className="UserAccountData" placeholder="City" onChange={(e) => setCity(e.target.value)} value={city} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <FormControl style={{ fontFamily: 'Dm Sans' }} className="UserAccountData" placeholder="Pincode" onChange={(e) => setPincode(e.target.value)} value={pincode} />
                                </Form.Group>
                            </Row>
                            {
                                updateLoading ?
                                    <Spinner animation="border" role="status" className='mx-auto d-block'>
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner> :
                                    <Button type="submit" className='Useraddressbtn mx-auto d-block'>Update</Button>
                            }
                        </Form>
                    </Card>
                </Col>
                <Col md={5} style={{ marginTop: '50px' }}>
                    <Card className="UserDetailsCard">
                        {/* <Row>
                            <Col md={12}>
                                <Image className="mx-auto d-block" src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260" roundedCircle style={{ width: '100px', height: '100px', marginTop: '-50px' }} />
                            </Col>
                        </Row> */}
                        <Row>
                            <p style={{ color: '#2c3e50', fontFamily: 'DM Sans', fontSize: '17px', textAlign: 'center', marginTop: '20px' }}><strong>Full Name:</strong> {data && data.getUserById.fullName}</p>
                            <p style={{ color: '#2c3e50', fontFamily: 'DM Sans', fontSize: '17px', textAlign: 'center' }}><strong>Contact:</strong> {data && data.getUserById.contact}</p>
                            <p style={{ color: '#2c3e50', fontFamily: 'DM Sans', fontSize: '17px', textAlign: 'center' }}><strong>Email:</strong> {data && data.getUserById.email}</p>
                            <p style={{ color: '#2c3e50', fontFamily: 'DM Sans', fontSize: '17px', textAlign: 'center' }}><strong>Address:</strong> {data && data.getUserById.address}, {data && data.getUserById.city}, {data && data.getUserById.state}, {data && data.getUserById.pincode}</p>
                        </Row>
                    </Card>
                </Col>
            </Row>

        </Container>
    )
}
