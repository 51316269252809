
import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import './componentCss/ShopByCategory.css'
import fatcut from '../img/fatcut.png'
import mens from '../img/mens.png'
import femalehygiene from '../img/femalehygine.png'
import haircare from '../img/gh.png'
import skincare from '../img/skincare.png'
import immunity from '../img/Immunity.png'

import { useNavigate } from 'react-router-dom'

export default function PersonalCare() {

  const navigate = useNavigate();
  const handleClick = (id) => navigate('/productByBrandName/' + id);

  return (
    <>
      <Container fluid>
        <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#0b1219', fontSize: '27px', marginTop: '10px' }}>Beauty & Personal Care</p>
        <Row>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12} xs={12}>
                <Card className="CategoryCard" onClick={() => navigate('/SkinCare')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={skincare} className="categoryImg" /></center>
                    <Card.Text className="CardText">
                      Skin Care
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12} xs={12}>
                <Card className="CategoryCard" onClick={() => navigate('/HairCare')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={haircare} className="categoryImg" fluid/></center>
                    <Card.Text className="CardText">
                      Hair Care
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12} xs={12}>
                <Card className="CategoryCard" onClick={() => navigate('/Immunity')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={immunity} className="categoryImgIm"/></center>
                    <Card.Text className="CardText">
                      Immunity Booster
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12} xs={12}>
                <Card className="CategoryCard" onClick={() => navigate('/FemaleHy')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={femalehygiene} className="categoryImg" /></center>
                    <Card.Text className="CardText">
                      Female Hygiene
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12} xs={12}>
                <Card className="CategoryCard" onClick={() => navigate('/FatRe')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={fatcut} className="categoryImg" /></center>
                    <Card.Text className="CardText">
                      Fat Reducing
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12} xs={12}>
                <Card className="CategoryCard" onClick={() => navigate('/MensGroom')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={mens} className="categoryImg" /></center>
                    <Card.Text className="CardText">
                      Mens Grooming
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

      </Container>
    </>
  )
}


