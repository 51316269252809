import React, { useState } from 'react'
import { Container, Row, Col, Tabs, Nav, Tab, Button } from 'react-bootstrap'
import '../VendorDashboard/VendorDashboardcss/VendorDashboard.css'
import { Navigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { QUERY_VENDOR_BY_ID } from '../Queries'
import ActiveVendor from './ActiveVendor'
import PendingVendorBox from './PendingVendorBox'
import Main from './Main'
import ChooseCatagory from './ChooseCategory'
import AllProducts from './Products'

export default function VendorDashboard() {
  const [logout, setLogout] = useState(false)
  const vendorToken = localStorage.getItem('vendorToken')
  const vendorId = localStorage.getItem('vendorId')

  const { data: vendorByIdData } = useQuery(QUERY_VENDOR_BY_ID, {
    variables: {
      vendorId: `${vendorId}`,
    },
    notifyOnNetworkStatusChange: true,
  })

  if (!vendorToken) {
    return <Navigate to='/vendorLogin' />
  }

  if (logout) {
    return <Navigate to='/vendorLogout' />
  }

  if (vendorByIdData && vendorByIdData.getVendorById.status === 'tempVendor') {
    return <Navigate to='/ActiveVendor' />
  }

  if (vendorByIdData && vendorByIdData.getVendorById.status === 'pending') {
    return <PendingVendorBox />
  }

  return (
    <div>
      <Container fluid>
        <Container fluid className='sideTabsClass'>
          <Tab.Container defaultActiveKey='uploadProduct'>
            <Row>
              <Col md={3}>
                <div id='sidebarUser'>
                  <Container
                    fluid
                    style={{ backgroundColor: 'black', width: '100%' }}
                  >
                    <p
                      style={{
                        fontFamily: 'DM Sans',
                        fontWeight: '700',
                        color: '#2B97A4',
                        fontSize: '25px',
                        marginTop: '15px',
                        textAlign: 'center',
                      }}
                    >
                      {' '}
                      Vendor Dashboard{' '}
                    </p>
                    <p
                      style={{
                        fontFamily: 'DM Sans',
                        color: '#16a085',
                        fontSize: '18px',
                        textAlign: 'center',
                        marginTop: '-10px',
                      }}
                    >
                      {' '}
                      Hello, Vendor{' '}
                    </p>
                    <Nav activeKey='first'>
                      <Nav.Item>
                        <Nav.Link eventKey='home' className='sidebarOptions'>
                          Home
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey='uploadProduct'
                          className='sidebarOptions'
                        >
                          Upload Product
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey='allProducts'
                          className='sidebarOptions'
                        >
                          All Products
                        </Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item>
                        <Nav.Link eventKey="fourth" className="sidebarOptions">Pending Medicine</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fifth" className="sidebarOptions">Reject Medicine</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="sixth" className="sidebarOptions">Edit/Delete Medicine</Nav.Link>
                      </Nav.Item> */}
                      <Button
                        className='Sidebarlogoutbtn'
                        onClick={() => setLogout(true)}
                      >
                        Log Out
                      </Button>
                    </Nav>
                  </Container>
                </div>
              </Col>
              <Col md={9}>
                <Tab.Content>
                  <Tab.Pane eventKey='uploadProduct'>
                    <ChooseCatagory />
                  </Tab.Pane>
                  <Tab.Pane eventKey='allProducts'>
                    <AllProducts />
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="second" >
                    <UploadMedicine />
                  </Tab.Pane>
                  <Tab.Pane eventKey="third" >
                    <ActiveProduct />
                  </Tab.Pane>
                  <Tab.Pane eventKey="fourth" >
                    <PendingProduct />
                  </Tab.Pane>
                  <Tab.Pane eventKey="fifth" >
                    <RejectProduct />
                  </Tab.Pane>
                  <Tab.Pane eventKey="sixth" >
                    <MedicineEdit />
                  </Tab.Pane> */}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>

        {/* <Container className="tabsClass" fluid>
          <Container style={{ background: 'black' }} fluid>
            <h1 style={{ fontFamily: 'DM Sans', fontSize: '25px', fontWeight: 'bold', textAlign: 'center', color: 'skyBlue', padding: '10px' }}>Vendor Dashboard</h1>
          </Container>
          <Tabs defaultActiveKey="upload" transition={false} className="mb-3">
            <Tab eventKey="upload" title="Upload Medicine">
              <Button className="Sidebarlogoutbtn mx-auto d-block" style={{ marginBottom: '-60px' }} onClick={() => setLogout(true)}>Logout</Button>
              <UploadMedicine />
            </Tab>
            <Tab eventKey="active" title="Active Medicine">
              <Button className="Sidebarlogoutbtn mx-auto d-block" style={{ marginBottom: '-60px', marginBottom: '10px' }} onClick={() => setLogout(true)}>Logout</Button>
              <ActiveProduct />
            </Tab>
            <Tab eventKey="pending" title="Pending Medicine">
              <Button className="Sidebarlogoutbtn mx-auto d-block" style={{ marginBottom: '-60px', marginBottom: '10px' }} onClick={() => setLogout(true)}>Logout</Button>
              <PendingProduct />
            </Tab>
            <Tab eventKey="reject" title="Reject Medicine">
              <Button className="Sidebarlogoutbtn mx-auto d-block" style={{ marginBottom: '-60px', marginBottom: '10px' }} onClick={() => setLogout(true)}>Logout</Button>
              <RejectProduct />
            </Tab>
            <Tab eventKey="edit" title="Edit Medicine">
              <Button className="Sidebarlogoutbtn mx-auto d-block" style={{ marginBottom: '-60px', marginBottom: '10px' }} onClick={() => setLogout(true)}>Logout</Button>
              <MedicineEdit />
            </Tab>

          </Tabs>
        </Container> */}
      </Container>
    </div>
  )
}
