import React, { useState } from 'react'
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap'
import { useMutation, useQuery } from '@apollo/client'
import { MUTATION_CREATE_COUPON, QUERY_COUPONS, MUTATION_DELETE_COUPON } from '../Queries'
import swal from 'sweetalert';
import { FaTrash } from "react-icons/fa";

export default function Coupons() {

    let count = 1
    const [validated, setValidated] = useState(false);

    const [createCoupon] = useMutation(MUTATION_CREATE_COUPON, {
        refetchQueries: [
            QUERY_COUPONS
        ]
    })
    const [deleteCoupon] = useMutation(MUTATION_DELETE_COUPON, {
        refetchQueries: [
            QUERY_COUPONS
        ]
    })
    const { data: couponData, loading: couponLoading } = useQuery(QUERY_COUPONS)
    const [name, setName] = useState('')
    const [per, setPer] = useState('')

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setValidated(false);
            event.preventDefault();
            createCoupon({
                variables: {
                    "couponInput": {
                        "couponName": `${name}`,
                        "couponPercentage": `${per}`
                    }
                }
            }).then(() => {
                setName('')
                setPer('')
                swal({ title: "Successful", text: "Coupon Added Successfully!!!", icon: "success" })
            })
        }
    };

    function deleteCouponHandle(id) {
        deleteCoupon({
            variables: {
                "couponId": `${id}`
            }
        })
        swal({ title: "Deleted", text: "Coupon Deleted Successfully!!!", icon: "success" })
    }

    return (
        <>
            <Container style={{ marginTop: 20 }}>
                <Row>
                    <Col md={6}>
                        <h1 style={{ fontSize: 30, fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'center' }}>Add Coupon</h1>

                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Label style={{ fontSize: 12, fontFamily: 'DM Sans', fontWeight: 'bold' }}>Coupon Name</Form.Label>
                                    <Form.Control required type="text" placeholder="Enter Coupon Name" style={{ fontSize: 12, fontFamily: 'DM Sans' }} onChange={(e) => setName(e.target.value)} value={name} />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Label style={{ fontSize: 12, fontFamily: 'DM Sans', fontWeight: 'bold' }}>Percentage</Form.Label>
                                    <Form.Control required type="number" placeholder="Enter Percentage" style={{ fontSize: 12, fontFamily: 'DM Sans' }} onChange={(e) => setPer(e.target.value)} value={per} />
                                </Form.Group>
                            </Row>
                            <Button type="submit" style={{ fontSize: 12, fontFamily: 'DM Sans' }} className="mx-auto d-block">Add</Button>
                        </Form>
                    </Col>
                    <Col md={6}>
                        <h1 style={{ fontSize: 30, fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'center' }}>All Coupon</h1>
                        <div style={{ height: 400, overflow: "scroll" }}>
                            <Table bordered style={{ fontSize: 12, fontFamily: 'DM Sans' }}>
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Coupon Name</th>
                                        <th>Percentage</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        couponData && couponData.getCoupon.slice().reverse().map(data =>
                                            <tr>
                                                <td>{count++}</td>
                                                <td>{data.couponName}</td>
                                                <td>{data.couponPercentage}%</td>
                                                <td><Button variant='danger' size='sm' onClick={() => deleteCouponHandle(data.id)}><FaTrash style={{ marginTop: '-4px' }} /></Button></td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
