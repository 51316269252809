import React, { useState } from 'react'
import { Col, Container, Row, Form, InputGroup, FormControl, Card, Button, Table, Spinner, Modal } from 'react-bootstrap'
import '../UserDashboard/UserDashboardCss/YourAccount.css';
import { useMutation, useQuery } from '@apollo/client';
import { QUERY_ADD_ADDRESS, MUTATION_ADD_ADDRESS, MUTATION_DELETE_ADDRESS } from '../Queries'
import swal from 'sweetalert';

export default function UserAddAddress() {

    const userId = localStorage.getItem('userId')

    const { data, loading } = useQuery(QUERY_ADD_ADDRESS, {
        variables: {
            "userId": `${userId}`
        }
    })

    const [deleteAdd] = useMutation(MUTATION_DELETE_ADDRESS, {
        refetchQueries: [
            QUERY_ADD_ADDRESS
        ]
    })

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const [viewData, setViewData] = useState();

    const [validated, setValidated] = useState(false)
    const [name, setName] = useState("")
    const [mobile, setMobile] = useState("")
    const [email, setEmail] = useState("")
    const [city, setCity] = useState("")
    const [address, setAddress] = useState("")
    const [pincode, setPincode] = useState("")

    const [createAddAddress] = useMutation(MUTATION_ADD_ADDRESS, {
        refetchQueries: [
            QUERY_ADD_ADDRESS
        ]
    })

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        }
        else {
            setValidated(false);
            event.preventDefault();
            createAddAddress({
                variables: {
                    "addAddressInput": {
                        "userId": `${userId}`,
                        "name": `${name}`,
                        "email": `${email}`,
                        "address": `${address}`,
                        "city": `${city}`,
                        "mobile": `${mobile}`,
                        "pincode": `${pincode}`
                    }
                }
            })
            swal({
                title: "Success",
                text: "Address Add successfully",
                icon: "success",
            })
            setName('')
            setMobile('')
            setEmail('')
            setCity('')
            setAddress('')
            setPincode('')

        }
    }


    function viewClick(data) {
        setViewData(data)
        handleShow()
        console.log(data)
    }


    // const showViewHandel = (id) => {
    //     handleShow();
    //     setOrderId(id)
    //     orderById({
    //         variables: {
    //             "orderId": `${id}`
    //         }
    //     })
    // }

    function deleteClick(id) {
        deleteAdd({
            variables: {
                "addressId": `${id}`
            }
        }).then(() => {
            swal({
                title: "Success",
                text: "Delete Address successfully",
                icon: "success",
            })
        })
    }

    return (
        <>
            <Container style={{ marginBottom: '20px' }}>
                <Row>
                    <Col md={7} style={{ marginTop: '50px' }}>
                        <Card className="UserAccountCard" style={{ padding: '20px' }} >
                            <Card.Title className='mx-auto d-block'> Add Your Address</Card.Title>
                            <Form style={{ marginTop: '25px', marginBottom: '10px' }} noValidate validated={validated} onSubmit={handleSubmit}>
                                <Row>
                                    <Col md={6}>
                                        <InputGroup className="mb-1 ">
                                            <FormControl required style={{ fontFamily: 'Dm Sans' }} className="UserAccountData" placeholder="Full Name" onChange={(e) => setName(e.target.value)} value={name} />
                                        </InputGroup>
                                    </Col>
                                    <Col md={6} >
                                        <InputGroup className="mb-1 ">
                                            <FormControl required style={{ fontFamily: 'Dm Sans' }} className="UserAccountData" placeholder="Mobile No." onChange={(e) => setMobile(e.target.value)} value={mobile} />
                                        </InputGroup>
                                    </Col>
                                    <Row>
                                        <Col md={6} >
                                            <InputGroup className="mb-1 ">
                                                <FormControl required style={{ fontFamily: 'Dm Sans', marginTop: '20px' }} className="UserAccountData" placeholder="Email" onChange={(e) => setEmail(e.target.value)} value={email} />
                                            </InputGroup>
                                        </Col>
                                        <Col md={6} >
                                            <InputGroup className="mb-1 ">
                                                <FormControl required style={{ fontFamily: 'Dm Sans', marginTop: '20px' }} className="UserAccountData" placeholder="City" onChange={(e) => setCity(e.target.value)} value={city} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} >
                                            <InputGroup className="mb-1 ">
                                                <FormControl required style={{ fontFamily: 'Dm Sans', marginTop: '20px' }} className="UserAccountData" placeholder="Address" onChange={(e) => setAddress(e.target.value)} value={address} />
                                            </InputGroup>
                                        </Col>
                                        <Col md={6} >
                                            <InputGroup className="mb-1 ">
                                                <FormControl required style={{ fontFamily: 'Dm Sans', marginTop: '20px' }} className="UserAccountData" placeholder="Pincode" onChange={(e) => setPincode(e.target.value)} value={pincode} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Row>
                                <Button className='Useraddressbtn mx-auto d-block' type="submit" >Add Address</Button>
                            </Form>
                        </Card>

                    </Col>
                    <Col md={5} style={{ marginTop: '50px' }}>
                        <Card className="UserAccountCard" style={{ padding: '20px' }} >
                            <Card.Title className='mx-auto d-block' style={{ fontFamily: 'Dm Sans', fontWeight: 'bold', fontSize: 15 }}> Your Address</Card.Title>
                            <div style={{ overflow: 'auto', maxHeight: '300px' }}>
                                <Table bordered style={{ marginTop: '12px', fontFamily: 'Dm Sans', fontSize: 12 }}>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Address</th>
                                            <th>View</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            loading ?
                                                <Spinner animation="border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner> :
                                                data && data.getAddAddress.slice().reverse().map(userData =>
                                                    <tr>
                                                        <td>{userData.name}</td>
                                                        <td>{userData.address}</td>
                                                        <td><Button size="sm" style={{ fontSize: '12px' }} onClick={() => viewClick(userData)}>View</Button></td>
                                                        <td><Button size="sm" variant="danger" style={{ fontSize: '12px' }} onClick={() => deleteClick(userData.id)}>Delete</Button></td>
                                                    </tr>
                                                )
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Body>
                    <h1 style={{ fontSize: '15px', fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'center' }}>Your All Address's Details</h1>
                    <Row>
                        <Col md={6}>
                            <Table style={{ marginTop: '15px' }}>
                                <thead>
                                    <tr>
                                        <td>Your Name :- {viewData && viewData.name}</td>
                                    </tr>
                                </thead>
                            </Table>
                        </Col>
                        <Col md={6}>
                            <Table style={{ marginTop: '15px' }}>
                                <thead>
                                    <tr>
                                        <td>Your Mobile No. :- {viewData && viewData.mobile}</td>
                                    </tr>
                                </thead>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Table style={{ marginTop: '15px' }}>
                                <thead>
                                    <tr>
                                        <td>Your Email :- {viewData && viewData.email}</td>
                                    </tr>
                                </thead>
                            </Table>
                        </Col>
                        <Col md={6}>
                            <Table style={{ marginTop: '15px' }}>
                                <thead>
                                    <tr>
                                        <td>Your City :- {viewData && viewData.city}</td>
                                    </tr>
                                </thead>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Table style={{ marginTop: '15px' }}>
                                <thead>
                                    <tr>
                                        <td>Your Address :- {viewData && viewData.address}</td>
                                    </tr>
                                </thead>
                            </Table>
                        </Col>
                        <Col md={6}>
                            <Table style={{ marginTop: '15px' }}>
                                <thead>
                                    <tr>
                                        <td>Your Pincode :- {viewData && viewData.pincode}</td>
                                    </tr>
                                </thead>
                            </Table>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}
