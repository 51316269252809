import React from "react";
import { Container, Row, Spinner ,Table, Col } from 'react-bootstrap';
import { useQuery, useMutation } from '@apollo/client'
import {QUERY_GET_CONTACT , QUERY_DELIVERY_BOY } from '../Queries'


export const Contact = () => {

    var count = 1

    const { data: contactData , loading : contactLoading } = useQuery(QUERY_GET_CONTACT , {
        refetchQueries : [
            QUERY_GET_CONTACT
        ]
    })

    return(
        <Container style={{ marginTop: '50px' }}>
            <Row>
                <Col>
                    <h1 style={{ fontFamily: 'DM Sans', fontSize: 20, textAlign: 'center', marginBottom: 20, fontWeight: 'bold' }}>Contact Request</h1>
                    <Table bordered style={{ fontFamily: 'DM Sans', fontSize: '12px' }} responsive>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Full Name</th>
                                <th>Email</th>
                                <th>Contact</th>
                                <th>Subject</th>
                                <th>Message</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                contactLoading ?
                                    <Spinner animation="border" role="status" className="mx-auto d-block">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner> :

                                    contactData?.getContact.map((contactData , indx) => (
                                        <tr>
                                            <td>{indx++ +1}</td>
                                            <td>{contactData.fullName}</td>
                                            <td>{contactData.email}</td>
                                            <td>{contactData.contact}</td>
                                            <td>{contactData.subject}</td>
                                            <td>{contactData.message}</td>
                                        </tr>                
                                    ))
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    )
}