import React, { useState } from 'react'
import { Container, Row, Col, Button, Alert, Card, Spinner, Form, Image } from 'react-bootstrap'
import './UserDashboardCss/LogIn.css'
import Navigation from '../Navigation'
import TopHeader from '../Top_Header';
import Footer from '../Footer';
import { FaExclamationTriangle, FaRegCheckCircle } from "react-icons/fa";
import Login from '../../img/login.jpg'

import { Navigate } from 'react-router-dom'

import { useMutation, useLazyQuery } from '@apollo/client'
import { MUTATION_USER_OTP_LOGIN, MUTATION_SEND_OTP } from '../Queries'

export default function LogIn() {

    const userToken = localStorage.getItem('userToken')

    const [contact, setContact] = useState('')
    const [contactError, setContactError] = useState(false)
    const [otpSendClick, setOtpSendClick] = useState(false)
    const [otpState, setOtpState] = useState('')
    const [otpError, setOtpError] = useState(false)
    const [otpResend, setOtpResend] = useState(false)

    const [userLoginDone, setUserLoginDone] = useState(false)

    const [userLoginMutation, { data: userLoginData, loading: userLoginLoading }] = useMutation(MUTATION_USER_OTP_LOGIN);
    const [sendOtpLoginLazyQuery, { data: otpData, error: otpsmsError }] = useMutation(MUTATION_SEND_OTP);

    console.log("otpData", otpData)
    console.log("otpError", otpsmsError)
    if (userLoginDone) {
        return <Navigate to='/userDashboard/yourOrders' />
    }

    if (userToken) {
        return <Navigate to='/userDashboard/yourOrders' />
    }

    function generateUserOtp() {
        if (contact === '' || contact.length < 10 || contact.length > 10) {
            setContactError(true)
        }
        else {
            const otp = Math.floor(1000 + Math.random() * 9000);
            setContactError(false)
            localStorage.removeItem("userOtp");
            localStorage.setItem('userOtp', otp);
            sendOtpLoginLazyQuery({
                variables: {
                    contact: `${contact}`,
                    otp: `${otp}`
                }
            })
            setOtpSendClick(true);
        }
    }

    async function LoginHandle() {
        const otp = localStorage.getItem('userOtp');
        if (otp === otpState) {
            await userLoginMutation({
                variables: {
                    contact: `${contact}`,
                }
            })
        }
        else {
            setOtpError(true)
        }
    }

    function contactEnterAgain() {
        localStorage.removeItem("userOtp")
        setOtpError(false)
        setContact('')
        setOtpState('')
        setOtpSendClick(false)
    }

    function otpSendAgain() {
        setOtpResend(true)
        setOtpError(false)
        localStorage.removeItem("userOtp")
        setOtpState('')
        generateUserOtp()
    }


    if (userLoginData) {
        localStorage.setItem('userId', userLoginData.userOtpLogin.userId)
        localStorage.setItem('userToken', userLoginData.userOtpLogin.userToken)
        localStorage.setItem('userTokenExpiration', userLoginData.userOtpLogin.userTokenExpiration)
        localStorage.removeItem("userOtp")
        setUserLoginDone(true)
    }


    return (
        <div>
            <TopHeader />
            <Navigation />
            <Container>
                <Row>
                    <Col md={3}></Col>
                    <Col md={6}>
                        <Card style={{ boxShadow: '5px 5px 10px #bdc3c7', borderRadius: '20px', marginTop: '30px' }}>
                            <Card.Body>
                                <Container>
                                    <Row>
                                        <Col md={6} className='d-none d-lg-block'>
                                            <Image src={Login} style={{ width: '100%' }} />
                                        </Col>
                                        <Col md={6} style={{ marginTop: '50px' }}>
                                            <h4 style={{ textAlign: 'center', fontFamily: 'DM Sans', color: '#2c3e50', fontWeight: '500', fontSize: '30px' }}>User Login</h4>


                                            {
                                                otpSendClick ?
                                                    <>
                                                        <p style={{ fontFamily: 'Dm Sans', textAlign: 'center', fontSize: '15px' }}>Please OTP !!!</p>
                                                        <input type="number" name="contact" placeholder="Enter OTP" style={{ borderRadius: '20px', textAlign: 'center', lineHeight: 2, border: '1px solid black', width: '50%', fontFamily: 'DM Sans' }} className="mx-auto d-block" onChange={e => setOtpState(e.target.value)} value={otpState} />
                                                        {
                                                            otpError ?
                                                                <>
                                                                    <h1 style={{ fontSize: '12px', fontFamily: 'Dm Sans', marginTop: '10px', textAlign: 'center' }}><FaExclamationTriangle style={{ color: 'red', marginTop: '-3px' }} /> Invalid OTP</h1>
                                                                    <Row>
                                                                        <Col style={{ textAlign: 'center', fontSize: '13px', fontFamily: 'DM Sans', fontWeight: 'bold', cursor: 'pointer', color: '#2c3e50' }} onClick={otpSendAgain}>Send Again</Col>
                                                                        <Col style={{ textAlign: 'left', fontSize: '13px', fontFamily: 'DM Sans', fontWeight: 'bold', cursor: 'pointer', color: '#2c3e50' }} onClick={contactEnterAgain}>Enter Contact Again</Col>
                                                                    </Row>
                                                                </>
                                                                :
                                                                otpResend ?
                                                                    <h1 style={{ fontSize: '12px', fontFamily: 'Dm Sans', marginTop: '10px', textAlign: 'center' }}><FaRegCheckCircle style={{ color: 'green', marginTop: '-3px' }} /> OTP Sent Successfully.</h1>
                                                                    :
                                                                    ''
                                                        }
                                                        {
                                                            userLoginLoading ?
                                                                <Spinner animation="border" role="status" className="mx-auto d-block">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </Spinner> :
                                                                <Button style={{ marginTop: '30px', background: '#2B97A4', borderColor: '#2B97A4', borderRadius: '25px' }} className="mx-auto d-block" onClick={LoginHandle}>Login</Button>

                                                        }

                                                    </>
                                                    :
                                                    <>
                                                        <p style={{ fontFamily: 'Dm Sans', textAlign: 'center', fontSize: '15px' }}>Please enter your contact number !!!</p>
                                                        <input type="number" name="contact" placeholder="Enter Your Contact" style={{ borderRadius: '20px', textAlign: 'center', lineHeight: 2, border: '1px solid black', width: '100%', fontFamily: 'DM Sans' }} className="mx-auto d-block" onChange={e => setContact(e.target.value)} value={contact} />
                                                        {contactError ? <h1 style={{ fontSize: '12px', fontFamily: 'Dm Sans', marginTop: '10px', textAlign: 'center' }}><FaExclamationTriangle style={{ color: 'red', marginTop: '-3px' }} /> Invalid Contact Number</h1> : ''}
                                                        <Button style={{ marginTop: '30px', background: '#2B97A4', borderColor: '#2B97A4', borderRadius: '25px' }} className="mx-auto d-block" onClick={generateUserOtp}>Send OTP</Button>
                                                    </>
                                            }
                                        </Col>
                                    </Row>
                                </Container>


                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}></Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )
}
