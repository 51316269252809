import React from 'react'
import Navigation from './Navigation'
import Top_Header from './Top_Header'
import { Container, Row, Col } from 'react-bootstrap'
import Footer from './Footer'

export default function PrivacyPolicy() {
    return (
        <>
            {
                window.scrollTo(0, 0)
            }
            <Top_Header />
            <Navigation />

            <Container fluid>
                <Row style={{ textAlign: 'center' }}>
                    <u>
                        <h1 style={{ fontSize: 36, color: '#2980b9' }}>
                            PRIVACY POLICY   </h1>
                    </u>

                </Row>
                <Row>
                    <Col md={12}>
                        <h3 style={{ fontSize: 26, color: '#7f8c8d' }}>
                            1. Scope and Purpose
                        </h3>
                        <p style={{ fontSize: 15, color: '#2c3e50' }}>
                            1.1. This Privacy Policy governs what information Nanopharm Healthcare Solutions Private Limited (“Bharat Pharma”/ “We”/ “Us”/ “Our”/ “the Company”), as the owner and operator of the domain name www.bharatpharma.in, and internet based platform, and “Bharat Pharma”, a mobile application (each individually, “Website” and “App” respectively, and together, “the Platforms”), collects from a user (“You”/ “Your” / “User”) of the Platforms in the course of providing Services
                            (as defined in the Terms of Use available at https://www.bharatpharma.in and how We use, process, store, deal, handle or disclose such information.
                        </p>

                        <p style={{ fontSize: 15, color: '#2c3e50' }}>
                            1.2. Bharat Pharma is fully committed to respecting confidentiality, protecting your privacy and ensuring the security of any personal information received from You or a registered medical practitioner authorized by You. We strictly adhere to the applicable laws on data protection India, and this Privacy Policy is especially published in accordance with:
                            <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                a. Section 43A of the Information Technology Act, 2000 (“IT Act”);
                            </p>
                            <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                b. Rule 4 of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 (“RSP Rules”);
                            </p>
                            <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                c. Rule 3(1) of the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021 (“Intermediary Guidelines”); and,
                            </p>
                            <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                d. Appendix 5 (Telemedicine Practice Guidelines) to the Indian Medical Council (Professional Conduct, Etiquette and Ethics) Regulations, 2002 introduced in 2020 (“Telemedicine Guidelines”) .
                            </p>
                        </p>

                        <p style={{ fontSize: 15, color: '#2c3e50' }}>
                            1.3. This Privacy Policy is effective from 20th December, 2021. Any word capitalized and not defined in this Privacy Policy shall derive its meaning from the Terms of Use.
                        </p>
                        <p style={{ fontSize: 26, color: '#7f8c8d' }}>
                            2. General Terms
                            <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                2.1. Please read this Privacy Policy carefully before using the Website or App.
                            </p>

                            <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                2.2. By clicking “I Accept”, or when otherwise accessing or using the Platforms, You confirm that you have read, understood and agree to be bound the terms of this Privacy Policy including any terms incorporated by reference and expressly consent to the collection, use, storage, handling and disclosure of Your information as per this Privacy Policy.

                            </p>
                            <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                2.3. You hereby explicitly represent that any information provided by You on our Platforms or otherwise is voluntary and subject to this Privacy Policy. You agree that We will not be required to validate any information provided by You, unless otherwise offered by Us or required under applicable law.
                            </p>
                            <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                2.4. By accessing or using the Platforms, you hereby confirm that You have the capacity to enter into a legally binding contract under Indian law, and in particular, the Indian Contract Act, 1872.
                            </p>
                            <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                2.5. You represent that You are duly authorized as per applicable law by any third party (including a person under the age of 18 years) whose information you share with us. You hereby agree that Bharat Pharma shall be acting as per your representation of authority and shall not make any independent enquiries to ascertain the genuineness of your authorization. Any liability in relation to your acts and omissions in this regard (including in sharing information) or resulting from consequent actions taken by us pursuant to your acts and omissions in accordance with this Privacy Policy shall also solely be borne by you.

                            </p>
                            <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                2.6. If You are accessing or using our Services on the Platforms from a location outside India, You do so at your own risk, and Bharat Pharma shall not be liable for compliance with any applicable local laws.
                            </p>
                            <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                2.7. If You do not agree with any of the terms and conditions of this Privacy Policy, please do not proceed further to use the Website or the App or any Services.
                            </p>



                        </p>
                        <p style={{ fontSize: 26, color: '#7f8c8d' }}>
                            3. Information that We collect
                            <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                3.1. A User may have limited access to the Platforms and Services provided by Bharat Pharma without creating an account on the Website or App. In order to access all features and benefits of the Services, a User is required to first create an account on the Platforms. The information required for registration is provided in the Terms of Use but may be updated from time to time. Other information requested on the registration page which is optional shall be indicated as such.
                            </p>

                            <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                3.2. During the course of You accessing or using the Platforms and availing Services, You may provide, or We may collect personal information, i.e., information which, either directly or indirectly, in combination with other information, is capable of identifying You or some other individual. Some of this personal information also qualifies as sensitive personal data or information (“SPDI”) as defined under the RSP Rules. Given below are the types of information that we may collect.
                            </p>
                            <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                3.3. Information You give Us: We receive and store information provide by You on the Platforms in connection with Our Services. This includes:

                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    a. Contact Information: Name, Delivery Address, Phone Number, Email ID;
                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    b. Demographic Information: Gender, Age, Date of Birth, Nationality, Marital Status;
                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    c. Medical Information: Medical records and history, details of your prescribing doctor, prescriptions uploaded and any other physical, physiological or mental health condition which you voluntarily provide Us, Our personnel or the Third Party RMPs;
                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    d. Usage History: Search history, history of teleconsultation appointments made on the Platforms, history of medicines ordered (completed, cancelled or returned) including those which are substitute drugs and any other detail relating to the same as voluntarily provided by You;

                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    e. Surveys: Any information provided by you in relation to surveys, contests or referrals;

                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    f. Call records: Any call records relating to the rendering of Services or teleconsultation (discussed below); and

                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    g. Any additional information that you voluntarily choose to provide to Bharat Pharma or the Third Party RMPs through any mode of communication or during any interaction.
                                </p>
                            </p>
                            <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                3.4. Information from Other Sources: We receive information about You from third parties viz. Our partners, advertisers, Third Party RMPs, and Third Party Delivery Partners etc. such as order details and other information that You share with such third parties.

                            </p>
                            <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                3.5. Cookies and tracking technologies: “Cookies” are small data files (usually in a text format) that are placed by network operators and web platforms on a user’s internal device storage (e.g. hard drive, memory card etc.) to collect information about activity on the web platform. Like most websites and apps, We also utilize cookies to analyze data about Our Platforms traffic, which helps save Your preference for future visits. Tracking technologies may record information such as internet domain and host names, internet protocol (IP) addresses, browser software and operating system types, stream patterns, and dates and times that Website or App is accessed. Our use of cookies and other tracking technologies allows Us to improve the Platforms as well as Your experience.
                            </p>
                            <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                3.6. Our cookies do not collect any SPDI from You, except that voluntarily provided by You. Most browsers/mobile settings allow You to control cookies, including whether or not to accept them and how to remove them. You may set most browsers/mobile application to notify you if you receive a cookie, or You may choose to block cookies with your browser/mobile applications. However, by refusing or blocking cookies in relation to our Website or App, You may not be able to use certain features on the Platforms or take full advantage of our Services.
                            </p>
                            <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                3.7. Third party tools and software: The App utilizes third party software developer kits (SDKs) for its architecture and infrastructure, with the majority being various payment options by which a User can make a payment for an order.
                            </p>
                            <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                3.8. Automatic technical information: During your visit to our Platforms, We may automatically receive technical information about your computer/device, such as your Internet Protocol (IP) address, your computer operating system type and version, time-zone, browser type and plug-in details due to certain communication protocol settings on the Internet. We may also receive information about Your location and Your mobile device such as a unique identification number for your device.
                            </p>
                            <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                3.9. Teleconsultation: Pursuant to the Telemedicine Guidelines, it is incumbent on a registered medical practitioner to maintain –
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    (i) log or record of Telemedicine interaction (e.g. Phone logs, email records, chat/ text record, video interaction logs etc),
                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    (ii) Patient records, reports, documents, images, diagnostics, data etc. (Digital or non-Digital) utilized in the telemedicine consultation, and
                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    (iii) specifically, in case a prescription is shared with the patient, the prescription records. As the Third Party RMPs are obliged to follow the applicable law, such information provided by You shall also be collected and stored. We also reserve the right to record, store or supervise the teleconsultation session between You and the Third Party RMPs for internal quality control purposes as well as store your e-prescription, and by accessing or using Our Services You hereby explicitly consent to the same.
                                </p>
                            </p>
                        </p>
                        <p style={{ fontSize: 26, color: '#7f8c8d' }}>
                            4. How We use Your Information
                            <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                4.1. Bharat Pharma collects and uses Your information only to the extent necessary for the below listed purposes
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    a. To register You for the purpose of receiving Our Services, identification, communication, notification and for performance of Our obligations or obligations of the Third Party RMPs or Third Party Delivery Partners arising out of or in relation to the Terms of Use such as, inter alia, ordering, arranging teleconsultation, delivery, billing;
                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    b. To improve functionality of Our Platforms and Services, and customize Your future experience with us so as to be most effective for You;
                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    c. To address Your requests, queries and complaints, if any, pertaining to Our Services, and other customer care related activities;
                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    d. To analyse data, track trends, build algorithms, create databases for rating systems, recommendation engines, to optimize our Services;
                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    e. To conduct audits and quality control or assessment procedures;
                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    f. For non-targeting reasons such as frequency capping, compliance, billing, market research, or product development purposes;
                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    g. For research purposes;
                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    h. To send notices, communications, alerts, messages, new offers relevant to use of the Services offered by Bharat Pharma;
                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    i. For the purpose of contacting you to provide you with information on new Services and offers, taking feedback, assisting you with completion of transactions or other issues relating to the use of Services;
                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    j. To send you information about special promotions or offers (either offered by Bharat Pharma or by its business partners), new features or products both of the Bharat Pharma as well as third-party offers or products with whom Bharat Pharma has a tie-up;
                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    k. To conduct surveys, contests or referral programs;
                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    l. To the extent necessary for our internal business purposes; or m. To comply with applicable law.
                                </p>
                            </p>
                            <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                4.2. Bharat Pharma may utilize cookies and other information that is automatically collected to
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    (i) personalize Our services, such as remembering Your information so that You will not have to re-enter it during your visit or the next time you visit the Service;
                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    (ii) provide customized content and information;
                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    (iii) monitor and analyze the effectiveness of the Service and third-party marketing activities;
                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    (iv) monitor aggregate site usage metrics such as total number of visitors and pages viewed; and
                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    (v) track Your entries, submissions, and status in any promotions or other activities on the Service. A designated Bharat Pharma personnel may also use Your personal information for data analysis or for understanding other trends about your lifestyle and You hereby authorize us to share the same with the Third Party RMP for the limited purpose of their understanding and consideration. Bharat Pharma shall exclusively own such data, unless You opt to purchase the same for a consideration payable to Us.
                                </p>
                            </p>
                            <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                4.4. We may collect, analyze, use, publish, create and sell to third parties non-personally identifiable information in an aggregated form for internal research, statistical analysis and business intelligence purposes including those for the purposes of determining the number of visitors and transactional details.
                            </p>
                        </p>
                        <p style={{ fontSize: 26, color: '#7f8c8d' }}>
                            5. Access, Disclosure and Sharing of Your Information
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                5.1. Bharat Pharma treats Your personal information with the highest regard for Your privacy and the same is dealt with by Bharat Pharma employees only on a need-to-know basis and after such employees are bound to confidentiality obligations. We do not sell, rent or exchange any personally identifiable information that You provide to Us through the Platforms with any third party for commercial reasons. However, we share Your personal information with Our Third Party RMPs and Third Party Delivery Partners to the extent required by our Services as well as online payment service partners to perform payment processing and authorization. Pursuant to applicable law, such information is only shared after taking best efforts to ensure that such third party has implemented measures to assure data protection measures that are either subject to this Privacy Policy or follow practices to the greatest extent possible which are as protective as those described in this Privacy Policy. By using the Platforms, You accept the terms hereof and hereby consent to the storage and processing of the personal information and SPDI by the said third parties.
                            </p>
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                5.2. As with any business, it is possible that as Our business develops, We might sell or buy other businesses or assets. In such transactions, user information is typically one of the transferred business assets. Consequently, any third party to which We transfer or sell Our assets, merge or consolidate with, will have the right to continue to use the information (including SPDI) provided to Us by You, in accordance with the Terms of Use and this Privacy Policy. We may disclose information to Our partners, affiliates, subsidiaries, group entities, investors, stakeholders or potential associates in an anonymized and aggregate manner, so that they too may understand how users use Our Site or App and enable Us to create a better overall experience for You.
                            </p>
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                5.3. Bharat Pharma may release account and other personal information when We believe in good faith that such release is appropriate to comply with applicable law including:
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    (i) pursuant to an order under the law for the time being in force,
                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    (ii) in response to enquiries by Government agencies for the purpose of verification of identity, or for prevention, detection, investigation including cyber incidents, prosecution, and punishment of offences,
                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    (iii) to protect and defend the rights or property of Bharat Pharma
                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    (iv) to fight fraud and credit risk or otherwise address technical issues;
                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    (v) to enforce the Terms of Use or Privacy Policy; or
                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    vi) when Bharat Pharma deems it necessary in order to protect its rights or the rights of others.
                                </p>
                            </p>
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                5.4. Notwithstanding the aforesaid, but subject to applicable law, We may at Our sole discretion, transfer personal information, including SPDI, to any other body corporate that has taken best efforts to ensure at least the same level of data protection as is provided by Us under the terms hereof, located in India or any other country. By accessing or using the Platforms, You accept the terms hereof and explicitly consent to Us sharing and/or processing Your personal information, including SPDI, with third parties and in any of location outside India. We will make best efforts to ensure that the third party or the location to which the SPDI is transferred accords same level of data protection as would be afforded under Indian law.
                            </p>
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                5.5. To the extent necessary to provide Users with the services on the Website and app (iOS & Android), Bharat Pharma may provide their Personal Information to third party contractors who work on behalf of or with Bharat Pharma to provide Users with such services, to help Bharat Pharma communicate with Users or to maintain the Website and app (iOS & Android). Generally, these contractors do not have any independent right to share this information, however certain contractors who provide services on the Website and app (iOS & Android), including the providers of online communications services, will have rights to use and disclose the personal information collected in connection with the provision of these services in accordance with their own privacy policies.
                            </p>
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                5.6. Non-personally identifiable information may be disclosed to third parties such as technology vendors and research firms and Bharat Pharma may also share its aggregate findings (not specific information) based on information relating to the User’s internet use to prospective, investors, strategic partners, sponsors and others in order to help growth of Bharat Pharma’ business.
                            </p>

                        </p>
                        <p style={{ fontSize: 26, color: '#7f8c8d' }}>
                            6. Retention of Information
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                6.1. We store Your personal information in accordance with the applicable laws and such information shall be secure with Us as long as it is necessary to offer You Our services. Such information in Our possession or under Our control, shall be destroyed and/or anonymized as soon as it is reasonable to assume that:
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    (i) the purposes for which your information has been collected have been fulfilled; and
                                </p>
                                <p style={{ fontSize: 15, color: '#2c3e50' }}>
                                    (ii) retention is no longer necessary for any other reason, or under any applicable law. We keep non-personally identifiable information for research and statistical purposes for a longer period.
                                </p>
                            </p>
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                6.2. In case You do not want to avail Our services and wish to withdraw or cancel your registration, as per the applicable laws, We are obligated to retain Your information for a further period of one hundred and eighty days after such withdrawal or cancellation. We are also obliged to keep such information for 180 days after receiving actual knowledge of any storage or hosting of information described in rule 3(1)(d) read with rule 3(1)(k) of the Intermediaries Guidelines. We may also keep Your information for a longer period of time if so required by any order of a Court or a direction of a competent authority or Government agency as per applicable law, or for any other purpose as required under applicable law.
                            </p>
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                6.3. Notwithstanding anything in the foregoing, the storage and retention of information shall be subject to applicable law, including but not limited to rule 3(1) of the Intermediaries Guidelines and the Telemedicine Guidelines.
                            </p>

                        </p>
                        <p style={{ fontSize: 26, color: '#7f8c8d' }}>
                            7. Our Security Policy
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                7.1. Bharat Pharma has taken reasonable precautions to treat personal information as confidential and to protect it from unauthorized access, improper use, disclosure or modification, and unlawful destruction or accidental loss.
                            </p>
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                7.2. To prevent unauthorized access, We have put in place the industry-standard security technology and procedures to safeguard the information We collect data on the Platforms or via email, messages etc. In particular, Your personal information, including any records of your e-prescription or teleconsultation, is encrypted and is protected with [AWS Security Protocols]. We store your personally information on the computer servers placed in a secure environment.
                            </p>
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                7.3. Even though We have taken significant steps to protect Your personally information, no company, including Bharat Pharma, can fully eliminate security risks associated with the storage and retention of personal information.
                            </p>
                        </p>.
                        <p style={{ fontSize: 26, color: '#7f8c8d' }}>
                            8. Your rights in relation to Your Information
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                8.1. You have the right to withdraw Your consent at any time to use Our Services at any time, or delete Your account. However, such withdrawal of consent will not be retroactive, and Your personal information will nonetheless be subject to retention obligations under applicable law as noted in clause 6.2.
                            </p>
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                8.2. You may review, correct, update, change the information that You have provided such as e-mail and contact preferences etc. by logging into your account or contacting customer support or through any other mode made available from time to time. If certain information is incorrect, You may request Us to modify or delete the same by emailing us at nanopharmheathcaresolutions@gmail.com. Bharat Pharma will take all reasonable measures to ensure that the information is modified and used for rendering Services to You and as otherwise in compliance with laws. We reserve the right to verify and authenticate Your identity and Your personal information in order to ensure accurate delivery of products and Services. Access to or correction, updating or deletion of your personal information may be denied or limited by Us if it would violate another person’s rights and/or is not otherwise permitted by applicable law, or would require unreasonable technical efforts.
                            </p>
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                8.3. If a User, as a casual visitor who has not registered, has inadvertently browsed through the Platforms prior to reading the Privacy Policy and the Terms of Use, and such User does not agree with the manner in which such information is obtained, the act uninstalling of the App or quitting the web browser application should ordinarily clear all temporary cookies installed by Bharat Pharma. All visitors, however, are encouraged to use the "clear cookies" functionality of their browsers, or similar functionality on their mobile devices to ensure such clearing/ deletion, as Bharat Pharma cannot guarantee, predict or provide for the behavior of the equipment of all its visitors.
                            </p>
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                8.4. In case You do not provide Your information or consent for usage of personal information or subsequently withdraws Your consent for usage of the personal information so collected, Bharat Pharma reserves the right to discontinue the services for which the said information was sought subject to applicable law.
                            </p>
                        </p>.
                        <p style={{ fontSize: 26, color: '#7f8c8d' }}>
                            9. Updates to the Privacy Policy & Promotional Communication
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                9.1. As per applicable law, We shall periodically inform our registered Users, at least once every year, that in case of non-compliance with this Privacy Policy or Terms of Use, we have the right to terminate our Services immediately or remove non-compliant information or both.
                            </p>
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                9.2. As per applicable law, We shall periodically, and at least once in a year, inform our registered Users of the Privacy Policy or Terms of Use or any change thereto.
                            </p>
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                9.3. Bharat Pharma does not make any unsolicited calls or otherwise market any products or services, except for in relation to the purpose for which such information has been provided or taking any feedback or addressing the complaints. However, Bharat Pharma may periodically send You emails about new products, special offers or other information of a promotional nature. If You do not wish to receive promotional information from Us, You can, at any time, choose to opt out of receiving the same by way of links provided at the bottom of each mail or by writing to us at nanopharmhealthcaresolutions@gmail.com.
                            </p>

                        </p>.
                        <p style={{ fontSize: 26, color: '#7f8c8d' }}>
                            10. Miscellaneous
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                10.1. The Platforms may include hyperlinks to various external websites, and may also include advertisements, and hyperlinks to applications, content or resources (“Third Party Links”). We have no control over such Third Party Links present on the Platforms, and You agree that We are not responsible for any collection or disclosure of Your information by any external sites, applications, companies or persons thereof.
                            </p>
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                10.2. Third Party Links may place their own cookies or other files on the Users' computer, collect data or solicit personal information from the Users, for which Bharat Pharma is not responsible or liable. Accordingly, Bharat Pharma does not make any representations concerning the privacy practices or policies of such third parties or terms of use of such websites, nor does Bharat Pharma  guarantee the accuracy, integrity, or quality of the information, data, text, software, sound, photographs, graphics, videos, messages or other materials available on such Third Party Links. The presence of any Third Party Links on our Platforms, cannot be construed as a recommendation, endorsement or solicitation for the same, or any other material on or available via such Third Party Links.
                            </p>
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                10.3. You further acknowledge and agree that We are not liable for any loss or damage which may be incurred by You as a result of the collection and/or disclosure of your information via Third Party Links, as a result of any reliance placed by You on the completeness, accuracy or existence of any advertising, products services, or other materials on, or available via such Third Party Links.
                            </p>
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                10.4. We recommend that You review the applicable privacy policies available on the websites or applications of such Third Party Links.
                            </p>
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                10.5. In accordance with applicable law, You hereby agree that You shall not host, display, upload, modify, publish, transmit, store, update or share any information on the Platform that:

                                <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                    a. belongs to another person and to which You do not have any right;
                                </p>
                                <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                    b. is defamatory, obscene, pornographic, paedophilic, invasive of another’s privacy, including bodily privacy, insulting or harassing on the basis of gender, libellous, racially or ethnically objectionable, relating or encouraging money laundering or gambling, or otherwise inconsistent with or contrary to the laws in force;
                                </p>
                                <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                    c. is harmful to child;
                                </p>

                                <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                    d. infringes any patent, trademark, copyright or other proprietary rights;
                                </p>
                                <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                    f. deceives or misleads the addressee about the origin of the message or knowingly and intentionally communicates any information which is patently false or misleading in nature but may reasonably be perceived as a fact;
                                </p>
                                <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                    g. impersonates another person;
                                </p>
                                <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                    h. threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign States, or public order, or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting other nation;
                                </p>
                                <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                    i. contains software virus or any other computer code, file or program designed to interrupt, destroy or limit the functionality of any computer resource;
                                </p>
                                <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                    j. is patently false and untrue, and is written or published in any form, with the intent to mislead or harass a person, entity or agency for financial gain or to cause any injury to any person.
                                </p>
                            </p>
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                10.5 committed by You, Bharat Pharma reserves the right to take appropriate actions as per applicable law.
                            </p>
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                10.6. In the event of a breach of clause
                            </p>

                        </p>.
                        <p style={{ fontSize: 26, color: '#7f8c8d' }}>
                            11. Contact Information & Grievances
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                11.1. If You have any questions regarding this Privacy Policy you may contact
                                Hritik Pathak.
                                You can also reach out to our support team via our mobile number at
                                8085587055
                                (Between 9 am - 9 pm) or email Us at nanopharmhealthcaresolutions@gmail.com.

                            </p>
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                11.2. In accordance with the IT Act, and the rules and regulations thereunder, the contact details of the Grievance Officer are provided as below:
                                Name: Hritik Pathak
                                Email: nanopharmhealthcaresolutions@gmail.com
                                Address: Besides bhuteshwar Mandir Sant Ravidas Ward Sagar 470002
                                Contact No: 8085587055

                            </p>
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                11.3. Grievance Officer working window: Monday to Friday (9:00 a.m. to 6:00 p.m.).
                                26th January, 15th August, 2nd October, and major festivals in India will be considered non-business days.

                            </p>
                            <p style={{ fontSize: 15, color: '#7f8c8d' }}>
                                11.4. In case You have any complaints or grievances on the Platforms, please contact the Grievance Officer with a thorough description of Your complaint. We shall acknowledge any complaint made within twenty-four hours, and shall try to resolve the same within a period of fifteen days from the date of its receipt. We shall also receive and acknowledge any order, notice or direction issued by the Government or any competent authority or court of competent jurisdiction.


                            </p>
                        </p>.




                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}
