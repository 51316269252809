import React, { useState, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { QUERY_COSMETIC, QUERY_PRODUCT_BY_ID, MUTATION_DELETE_PRODUCT, MUTATION_UPDATE_COSMETIC, MUTATION_UPDATE_IMAGES } from './Queries'

export default function InfiniteScrollComp() {

    useEffect(() => {
        fetchData()
    }, [])

    const [items, setItems] = useState([])

    function fetchData() {
        const requestBody = {
            query: `
            query Query {
                getCosmetic {
                  id
                  vendorId
                  name
                  brandName
                  manufacturer
                  marketedBy
                  storageContainer
                  expireTime
                  isHeatSensitive
                  variation
                  skuNo
                  countryOfOrigin
                  taxCode
                  shipping
                  yourPrice
                  mrp
                  stockAvailable
                  stockType
                  productQuantity
                  productQuantityType
                  hsnCode
                  gst
                  isYourProductContaineBattery
                  composition
                  ingredients
                  highlights
                  description
                  uses
                  howToUse
                  benefits
                  safetyWarning
                  productDimension
                  productWeight
                  dosage
                  flavour
                  medicineCatagory
                  menuCatagory
                  image
                  uploadingDateTime
                  uploader
                  catagory
                  status
                  medCatagory
                  prescriptionRequire
                }
              }
            `
        };

        fetch('http://localhost:4000/', {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('Failed');
                }
                return res.json();
            })
            .then(resData => {
                console.log(resData)
                setItems([...items, resData]);
            })
            .catch(err => {
                console.log(err);
            });
    }

    console.log("items", items)
    return (
        <>
            <InfiniteScroll
                dataLength={items.length}
                next={fetchData}
                hasMore={true}
                loader={<h4>Loading...</h4>}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                        <b>Yay! You have seen it all</b>
                    </p>
                }
            >
                {items && items.map((item) => {
                    <h1>{item}</h1>
                })}
            </InfiniteScroll>
        </>
    )
}
