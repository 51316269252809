import React from 'react'
import { Container, Col, Row, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import ImageGallery from 'react-image-gallery';

import Test2 from './Test2'

const images = [
    {
        original: 'https://picsum.photos/id/1018/1000/600/',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        original: 'https://picsum.photos/id/1015/1000/600/',
        thumbnail: 'https://picsum.photos/id/1015/250/150/',
    },
    {
        original: 'https://picsum.photos/id/1019/1000/600/',
        thumbnail: 'https://picsum.photos/id/1019/250/150/',
    },
];

export default function Test() {


    return (
        <div>
            <ImageGallery items={images} disableThumbnailScroll={false} showThumbnails={true} showNav={true}
                isRTL={false} autoPlay={false} thumbnailPosition="left"
                stopPropagation={true} />;
        </div>
    )
}
