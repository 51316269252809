import React from 'react'
import { Routes, Route, Link } from 'react-router-dom'
import './App.css'
import Home from './Components/Home'
import UserLogin from './Components/UserDashboard/UserLogin'
import VendorLogin from './Components/VendorDashboard/VendorLogin'
import AdminLogin from './Components/admin/AdminLogin'
import VendorDashboard from './Components/VendorDashboard/VendorDashboard'
import ActiveVendor from './Components/VendorDashboard/ActiveVendor'
import CompanyDetails from './Components/VendorDashboard/VendorRegisterationForms/CompanyDetails'
import BankDetails from './Components/VendorDashboard/VendorRegisterationForms/BankDetails'
import BusinessDetails from './Components/VendorDashboard/VendorRegisterationForms/BusinessDetails'
import UploadDocuments from './Components/VendorDashboard/VendorRegisterationForms/UploadDocuments'
import UserDashboard from './Components/UserDashboard/UserDashboard'
import AdminDashboard from './Components/admin/AdminDashboard'
import AdminLogout from './Components/admin/AdminLogout'
import VendorLogout from './Components/VendorDashboard/VendorLogout'
import UserLogout from './Components/UserDashboard/UserLogout'
import Test from './Components/Test'
import Cart from './Components/Cart'
import ProductDetails from './Components/ProductDetails'
import ProductPage from './Components/ProductPage'
import Checkout from './Components/Checkout'
import ExportCsv from './Components/admin/ExportCsv'
import ProductByBrandName from './Components/ProductByBrandName'
import Infinite from './Components/InfiniteScrollComp'
import FitnessOtc from './Components/otcProduct/FitnessOtc'
import BeautyOtc from './Components/otcProduct/BeautyOtc'
import BabyOtc from './Components/otcProduct/BabyOtc'
import WomenCareOtc from './Components/otcProduct/WomenCareOtc'
import DiabeticOtc from './Components/otcProduct/DiabeticOtc'
import CardicCareOtc from './Components/otcProduct/CardicCareOtc'
import HealthCareOtc from './Components/otcProduct/HealthCareOtc'
import HealthFoodOtc from './Components/otcProduct/HealthFoodOtc'
import VitaminsOtc from './Components/otcProduct/VitaminsOtc'
import AyurvedaOtc from './Components/otcProduct/AyurvedaOtc'
import SexualOtc from './Components/otcProduct/SexualOtc'

import SkinCare from './Components/BeautyPersonalCare/SkinCare'
import HairCare from './Components/BeautyPersonalCare/HairCare'
import Immunity from './Components/BeautyPersonalCare/Immunity'
import FemaleHy from './Components/BeautyPersonalCare/FemaleHy'
import FatRe from './Components/BeautyPersonalCare/FatRe'
import MensGroom from './Components/BeautyPersonalCare/MensGroom'

import Signup from './Components/UserDashboard/Signup'
import PrivacyPolicy from './Components/PrivacyPolicy'
import Terms from './Components/Terms'

import YourOrders from './Components/UserDashboard/YourOrders'
import YourAccount from './Components/UserDashboard/YourAccount'

import SearchProduct from './Components/SearchProduct'

import PendingVendor from './Components/admin/PendingVendor';
import ProductRequest from './Components/admin/ProductRequest';
import Products from './Components/admin/Products';
import ChooseCategory from './Components/admin/ChooseCategory';
import Import from './Components/admin/ImportExcel';
import UserOrders from './Components/admin/UserOrders'
import AllUsers from './Components/admin/AllUsers';
import AddDeliveryBoy from './Components/admin/DeliveryBoy/AddDeliveryBoy'
import AllDeliveryBoy from './Components/admin/DeliveryBoy/AllDeliveryBoy'
import { Contact } from './Components/admin/Contact'
import About from './Components/About'
import ContactUs from './Components/ContactUs'
import Coupons from './Components/admin/Coupons'

import { ApolloClient, ApolloLink, InMemoryCache, split, ApolloProvider } from "@apollo/client";
import { getMainDefinition } from '@apollo/client/utilities';
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from '@apollo/client/link/ws';

import UserAddAddress from './Components/UserDashboard/UserAddAddress'

import Down from './Down'

function App() {

  const httpLink = new HttpLink({
    uri: "https://bharatpharma-back.vercel.app/graphql",
  });


  const wsLink = new WebSocketLink({
    uri: "wss://bharatpharma-back.vercel.app/graphql",
    options: {
      reconnect: true
    }
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  );

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([splitLink]),
  });

  return (
    <ApolloProvider client={client}>
      <Routes>
        {/* <Route path='/' exact={true} element={<Down />} /> */}
        <Route path='/' exact={true} element={<Home />} />
        <Route path='/userLogin' element={<UserLogin />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/vendorLogin' element={<VendorLogin />} />
        <Route path='/adminLogin' element={<AdminLogin />} />
        <Route path='/VendorDashboard' element={<VendorDashboard />} />
        <Route path='/about' element={<About />} />
        <Route path='/contactUs' element={<ContactUs />} />

        <Route path='/ActiveVendor' element={<ActiveVendor />}>
          <Route index element={<CompanyDetails />} />
          <Route path='bank-details' element={<BankDetails />} />
          <Route path='business-details' element={<BusinessDetails />} />
          <Route path='upload-documents' element={<UploadDocuments />} />
        </Route>

        <Route path='/UserDashboard' element={<UserDashboard />}>
          <Route path="yourOrders" element={<YourOrders />} />
          <Route path="yourAccount" element={<YourAccount />} />
          <Route path="addaddress" element={<UserAddAddress />} />
        </Route>
        <Route path='/adminDashboard' element={<AdminDashboard />}>
          <Route path='chooseCategory' element={<ChooseCategory />} />
          <Route path='products' element={<Products />} />
          <Route path='productRequest' element={<ProductRequest />} />
          <Route path='activeVendor' element={<ActiveVendor />} />
          <Route path='pendingVendor' element={<PendingVendor />} />
          <Route path='import' element={<Import />} />
          <Route path='userOrders' element={<UserOrders />} />
          <Route path='allUsers' element={<AllUsers />} />
          <Route path='addDeliveryBoy' element={<AddDeliveryBoy />} />
          <Route path='allDeliveryBoy' element={<AllDeliveryBoy />} />
          <Route path='Contact' element={<Contact />} />
          <Route path='coupons' element={<Coupons />} />
        </Route>
        <Route path='/adminLogout' element={<AdminLogout />} />
        <Route path='/vendorLogout' element={<VendorLogout />} />
        <Route path='/userLogout' element={<UserLogout />} />
        <Route path='/test' element={<Test />} />
        <Route path='/cart' element={<Cart />} />
        <Route path='/productDetails/:id' element={<ProductDetails />} />
        <Route path='/productName/:name' element={<ProductPage />} />
        <Route path='/productByBrandName/:brandname' element={<ProductByBrandName />} />
        <Route path='/checkout' element={<Checkout />} />
        <Route path='/export' element={<ExportCsv />} />
        <Route path='/import' element={<Import />} />
        <Route path='/infinite' element={<Infinite />} />
        <Route path='/fitnessOtc' element={<FitnessOtc />} />
        <Route path='/beautyOtc' element={<BeautyOtc />} />
        <Route path='/babyOtc' element={<BabyOtc />} />
        <Route path='/womenCareOtc' element={<WomenCareOtc />} />
        <Route path='/diabeticOtc' element={<DiabeticOtc />} />
        <Route path='/cardicCareOtc' element={<CardicCareOtc />} />
        <Route path='/HealthCareOtc' element={<HealthCareOtc />} />
        <Route path='/HealthFoodOtc' element={<HealthFoodOtc />} />
        <Route path='/VitaminsOtc' element={<VitaminsOtc />} />
        <Route path='/AyurvedaOtc' element={<AyurvedaOtc />} />
        <Route path='/SexualOtc' element={<SexualOtc />} />

        <Route path='/SkinCare' element={<SkinCare />} />
        <Route path='/HairCare' element={<HairCare />} />
        <Route path='/Immunity' element={<Immunity />} />
        <Route path='/FemaleHy' element={<FemaleHy />} />
        <Route path='/FatRe' element={<FatRe />} />
        <Route path='/MensGroom' element={<MensGroom />} />


        <Route path='/searchProduct/:name' element={<SearchProduct />} />
        <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
        <Route path='/Terms' element={<Terms />} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </ApolloProvider>
  )
}

function PageNotFound() {
  return (
    <div>
      <h1>It Is 404 Page Not Found</h1>
      <center>
        <h5 id='returnToHome'>
          Return to <Link to='/'>Home</Link>
        </h5>
      </center>
    </div>
  )
}

export default App

//http://localhost:4000/graphql
//https://bharatpharma-back.vercel.app/graphql
