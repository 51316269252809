import { Navbar, Container, Nav, Col, Row } from 'react-bootstrap'
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom'
import './VendorDashboardcss/ActiveVendor.css'
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'

export default function ActiveVendor() {
  const navigate = useNavigate()
  const location = useLocation().pathname

  return (
    <div>
      <Navbar bg='light' expand='lg'>
        <Container fluid>
          <Navbar.Toggle aria-controls='navbarScroll' />
          <Navbar.Collapse id='navbarScroll'>
            <Nav
              className='me-auto my-2 my-lg-0'
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              <Link to='/' className='headerLinkDrop'>
                Home
              </Link>
              <Link to='/vendorLogout' className='headerLinkDrop'>
                Logout
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container>
        <Row>
          <Col>
            <h1
              style={{
                textAlign: 'center',
                marginTop: '20px',
                fontFamily: 'DM Sans',
                fontWeight: 'bold',
                color: '#1abc9c',
              }}
            >
              Complete profile first
            </h1>
          </Col>
        </Row>
        <Row className='barRow'>
          <Col
            md={3}
            className={
              location === '/ActiveVendor' ? 'barTextOk' : 'barTextNot'
            }
            onClick={() => navigate('')}
          >
            Company Details{' '}
            {location === '/ActiveVendor' ? (
              <FaCheckCircle />
            ) : (
              <FaTimesCircle />
            )}
          </Col>
          <Col
            md={3}
            className={
              location === '/ActiveVendor/bank-details'
                ? 'barTextOk'
                : 'barTextNot'
            }
            onClick={() => navigate('bank-details')}
          >
            Bank Details{' '}
            {location === '/ActiveVendor/bank-details' ? (
              <FaCheckCircle />
            ) : (
              <FaTimesCircle />
            )}
          </Col>
          <Col
            md={3}
            className={
              location === '/ActiveVendor/business-details'
                ? 'barTextOk'
                : 'barTextNot'
            }
            onClick={() => navigate('business-details')}
          >
            Business Details{' '}
            {location === '/ActiveVendor/business-details' ? (
              <FaCheckCircle />
            ) : (
              <FaTimesCircle />
            )}
          </Col>
          <Col
            md={3}
            className={
              location === '/ActiveVendor/upload-documents'
                ? 'barTextOk'
                : 'barTextNot'
            }
            onClick={() => navigate('upload-documents')}
          >
            Upload Documents{' '}
            {location === '/ActiveVendor/upload-documents' ? (
              <FaCheckCircle />
            ) : (
              <FaTimesCircle />
            )}
          </Col>
        </Row>
        <Container>
          <Outlet />
        </Container>
      </Container>
    </div>
  )
}
