import React, { useState } from 'react'
import { Container, Navbar, Nav, NavDropdown, Row, Col } from 'react-bootstrap'
import { FaBars } from "react-icons/fa";
import './componentCss/Navigation.css'
import { Link } from "react-router-dom";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";


export default function Navigation() {
    const [show, setShow] = useState(false);
    const showDropdown = (e) => {
        setShow(!show);
    }
    const hideDropdown = e => {
        setShow(false);
    }
    return (
        <>

            <Container fluid>
                <Navbar bg="light" expand="lg">
                    <Container>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <Link to="/" className="headerLink" style={{ marginTop: '8px' }}>Home</Link>
                                <Nav.Link className="headerLink" id="navbarLine" disabled>|</Nav.Link>
                                <NavDropdown title="Covid-19" id="basic-nav-dropdown" renderMenuOnMount={true} className="headerLink">
                                    <Container style={{ width: '250px' }}>
                                        <Row>
                                            <Col>
                                                <Link to="/productName/Covid_Personal & Home Essential" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Personal & Home Essential</Link><br />
                                                <Link to="/productName/Covid_Mask,Gloves & Protective Equipment" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Mask,Gloves & Protective Equipment</Link><br />
                                                <Link to="/productName/Covid_Immunity Booster" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Immunity Booster</Link><br />
                                                <Link to="/productName/Covid_Other Essential" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Other Essential</Link>
                                            </Col>
                                        </Row>
                                    </Container>
                                </NavDropdown>
                                <Nav.Link href="#action1" className="headerLink" id="navbarLine" disabled>|</Nav.Link>
                                <NavDropdown title="Beauty & Personal Care" id="basic-nav-dropdown" renderMenuOnMount={true} className="headerLink">
                                    <Container style={{ width: '400px' }}>
                                        <Row>
                                            <Col md={6}>
                                                <h1 style={{ fontSize: '14px', fontWeight: 'bold' }}>Elderly Care</h1>
                                                <Link to="/productName/Beauty & Personal Care_ElderlyCare_Adult diapers" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Adult diapers</Link><br />
                                                <Link to="/productName/Beauty & Personal Care_ElderlyCare_Bone and Joint Health" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Bone and Joint Health</Link><br />
                                                <Link to="/productName/Beauty & Personal Care_ElderlyCare_Living and Safety Aids" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Living and Safety Aids</Link><br />
                                                <Link to="/productName/Beauty & Personal Care_ElderlyCare_Orthopedic Support" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Orthopedic Support</Link>
                                            </Col>
                                            <Col md={6}>
                                                <h1 style={{ fontSize: '14px', fontWeight: 'bold' }}>Hair Care</h1>
                                                <Link to="/productName/Beauty & Personal Care_HairCare_Hair Styling" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Hair Styling</Link><br />
                                                <Link to="/productName/Beauty & Personal Care_HairCare_Hair Oil" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Hair Oil</Link><br />
                                                <Link to="/productName/Beauty & Personal Care_HairCare_Hair Shampoo" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Hair Shampoo</Link><br />
                                                <Link to="/productName/Beauty & Personal Care_HairCare_Hair Colour" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Hair Colour</Link><br />
                                                <Link to="/productName/Beauty & Personal Care_HairCare_Self Treatment" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Self Treatment</Link><br />
                                                <Link to="/productName/Beauty & Personal Care_HairCare_Hairs Tools & Accessories" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Hairs Tools & Accessories</Link>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <h1 style={{ fontSize: '14px', fontWeight: 'bold' }}>Skin Care</h1>
                                                <Link to="/productName/Beauty & Personal Care_SkinCare_Creams" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Creams</Link><br />
                                                <Link to="/productName/Beauty & Personal Care_SkinCare_Cleanser" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Cleanser/Facewash</Link><br />
                                                <Link to="/productName/Beauty & Personal Care_SkinCare_Masks" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Masks</Link><br />
                                                <Link to="/productName/Beauty & Personal Care_SkinCare_Moisturizer" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Moisturizer</Link><br />
                                                <Link to="/productName/Beauty & Personal Care_SkinCare_Sun’s Cream" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Sun’s Cream</Link><br />
                                                <Link to="/productName/Beauty & Personal Care_SkinCare_Eye Care" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Eye Care</Link><br />
                                                <Link to="/productName/Beauty & Personal Care_SkinCare_Toners & Serums" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Toners & Serums</Link><br />
                                                <Link to="/productName/Beauty & Personal Care_SkinCare_Face Skin" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Face Skin</Link><br />
                                                <Link to="/productName/Beauty & Personal Care_SkinCare_Bath & Showers" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Bath & Showers</Link>
                                            </Col>
                                            <Col md={6}>
                                                <h1 style={{ fontSize: '14px', fontWeight: 'bold' }}>Skin Care Makeup</h1>
                                                <Link to="/productName/Beauty & Personal Care_SkinCare_Makeup_Lips" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Lips</Link><br />
                                                <Link to="/productName/Beauty & Personal Care_SkinCare_Makeup_Eyes" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Eyes</Link><br />
                                                <Link to="/productName/Beauty & Personal Care_SkinCare_Makeup_Nails" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Nails</Link><br />
                                                <Link to="/productName/Beauty & Personal Care_SkinCare_Makeup_Face Makeup" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Face Makeup</Link><br />
                                                <Link to="/productName/Beauty & Personal Care_SkinCare_Makeup_Makeup Tools & Brushes" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Makeup Tools & Brushes</Link>
                                                <h1 style={{ fontSize: '14px', fontWeight: 'bold' }}>Men's Grooming</h1>
                                                <Link to="/productName/Beauty & Personal Care_MensGrooming_Shavin" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Shaving</Link><br />
                                                <Link to="/productName/Beauty & Personal Care_MensGrooming_Beard Car" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Beard Care</Link>
                                            </Col>
                                        </Row>
                                    </Container>
                                </NavDropdown>
                                <Nav.Link href="#action1" className="headerLink" id="navbarLine" disabled>|</Nav.Link>
                                <NavDropdown title="Surgical" id="basic-nav-dropdown" renderMenuOnMount={true} className="headerLink">
                                    <Container style={{ width: '400px' }}>
                                        <Row>
                                            <Col>
                                                <h1 style={{ fontSize: '14px', fontWeight: 'bold' }}>Surgical</h1>
                                                <Link to="/productName/Surgical_Dressing" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Dressing</Link><br />
                                                <Link to="/productName/Surgical_G.I. Care" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>G.I. Care</Link><br />
                                                <Link to="/productName/Surgical_I.V. Infection" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>I.V. Infection</Link><br />
                                                <Link to="/productName/Surgical_Respiratory Supplies" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Respiratory Supplies</Link><br />
                                                <Link to="/productName/Surgical_Surgical Consumable" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Surgical Consumable</Link><br />
                                                <Link to="/productName/Surgical_Instruments" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Instruments</Link><br />
                                                <Link to="/productName/Surgical_Urinary Care" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Urinary Care</Link>
                                            </Col>
                                            <Col>
                                                <h1 style={{ fontSize: '14px', fontWeight: 'bold' }}>Support & Braces</h1>
                                                <Link to="/productName/Surgical_Support&Braces_Neck & Shoulder Support" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Neck & Shoulder Support</Link><br />
                                                <Link to="/productName/Surgical_Support&Braces_Knee & Leg Support" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Knee & Leg Support</Link><br />
                                                <Link to="/productName/Surgical_Support&Braces_Back & Abdomen Support" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Back & Abdomen Support</Link><br />
                                                <Link to="/productName/Surgical_Support&Braces_Ankle & Foot Support" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Ankle & Foot Support</Link><br />
                                                <Link to="/productName/Surgical_Support&Braces_Hand & Wrist Braces" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Hand & Wrist Braces</Link><br />
                                                <Link to="/productName/Surgical_Support&Braces_Arm & Elbow support" className="headerLink" style={{ marginTop: '8px' }}>Arm & Elbow support</Link><br />
                                                <Link to="/productName/Surgical_Support&Braces_Cervical Pillow" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Cervical Pillow</Link><br />
                                                <Link to="/productName/Surgical_Support&Braces_Compression Support & Sleeves" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Compression Support & Sleeves</Link><br />
                                                <Link to="/productName/Surgical_Support&Braces_Heels Support" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Heels Support</Link>
                                            </Col>
                                        </Row>
                                    </Container>
                                </NavDropdown>
                                <Nav.Link href="#action1" className="headerLink" id="navbarLine" disabled>|</Nav.Link>
                                <NavDropdown title="Fitness" id="basic-nav-dropdown" renderMenuOnMount={true} className="headerLink">
                                    <Container style={{ width: '250px' }}>
                                        <Row>
                                            <Col>
                                                <Link to="/productName/Fitness_Vitamin & Suppliments" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Vitamin & Suppliments</Link><br />
                                                <Link to="/productName/Fitness_Family & Nutrition" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Family & Nutrition</Link><br />
                                                <Link to="/productName/Fitness_Health Food & Drink" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Health Food & Drink</Link><br />
                                                <Link to="/productName/Fitness_Ayurvedic Supplements" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Ayurvedic Supplements</Link><br />
                                                <Link to="/productName/Fitness_Sports Suppliments" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Sports Suppliments</Link><br />
                                                <Link to="/productName/Fitness_Smoking Cessation Support" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Smoking Cessation Support</Link><br />
                                                <Link to="/productName/Fitness_Weight Management" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Weight Management</Link><br />
                                                <Link to="/productName/Fitness_Immunity Booster" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Immunity Booster</Link>
                                            </Col>
                                        </Row>
                                    </Container>
                                </NavDropdown>
                                <Nav.Link href="#action1" className="headerLink" id="navbarLine" disabled>|</Nav.Link>
                                <NavDropdown title="Baby Care" id="basic-nav-dropdown" renderMenuOnMount={true} className="headerLink">
                                    <Container style={{ width: '400px' }}>
                                        <Row>
                                            <Col>
                                                <h1 style={{ fontSize: '14px', fontWeight: 'bold' }}>Baby Care</h1>
                                                <Link to="/productName/Baby Care_Lotion" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Lotion</Link><br />
                                                <Link to="/productName/Baby Care_Supplement" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Supplement</Link><br />
                                                <Link to="/productName/Baby Care_Diaper & Wipes" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Diaper & Wipes</Link><br />
                                                <Link to="/productName/Baby Care_Baby Safety" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Baby Safety</Link>
                                            </Col>
                                            <Col>
                                                <h1 style={{ fontSize: '14px', fontWeight: 'bold' }}>Baby Skin Care</h1>
                                                <Link to="/productName/Baby Care_BabySkinCare_Creams" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Creams</Link><br />
                                                <Link to="/productName/Baby Care_BabySkinCare_Lotions" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Lotions</Link><br />
                                                <Link to="/productName/Baby Care_BabySkinCare_Oil" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Oil</Link><br />
                                                <Link to="/productName/Baby Care_BabySkinCare_Bathing Essential" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Bathing Essential</Link>
                                            </Col>
                                        </Row>
                                    </Container>
                                </NavDropdown>
                                <Nav.Link href="#action1" className="headerLink" id="navbarLine" disabled>|</Nav.Link>
                                <NavDropdown title="Devices" id="basic-nav-dropdown" renderMenuOnMount={true} className="headerLink">
                                    <Container style={{ width: '250px' }}>
                                        <Row>
                                            <Col>
                                                <Link to="/productName/Devices_Mask" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Mask</Link><br />
                                                <Link to="/productName/Devices_B.P. Monitor" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>B.P. Monitor</Link><br />
                                                <Link to="/productName/Devices_Nebulizer" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Nebulizer</Link><br />
                                                <Link to="/productName/Devices_Vaporizer" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Vaporizer</Link><br />
                                                <Link to="/productName/Devices_Oximeter" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Oximeter</Link><br />
                                                <Link to="/productName/Devices_Pedometers" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Pedometers</Link><br />
                                                <Link to="/productName/Devices_Vital signs monitors & wearable" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Vital signs monitors & wearable</Link><br />
                                                <Link to="/productName/Devices_Oxygen concentrator" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Oxygen concentrator</Link><br />
                                                <Link to="/productName/Devices_Weigh Scale" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Weigh Scale</Link><br />
                                                <Link to="/productName/Devices_Thermometers" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Thermometers</Link><br />
                                                <Link to="/productName/Devices_Body Massagers" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Body Massagers</Link><br />
                                                <Link to="/productName/Devices_Diabetic Monitor" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Diabetic Monitor</Link><br />
                                                <Link to="/productName/Devices_Exercise Equipments" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Exercise Equipments</Link>
                                            </Col>
                                        </Row>
                                    </Container>
                                </NavDropdown>
                                <Nav.Link href="#action1" className="headerLink" id="navbarLine" disabled>|</Nav.Link>
                                <NavDropdown title="Sexual Wellness" id="basic-nav-dropdown" renderMenuOnMount={true} className="headerLink">
                                    <Container style={{ width: '250px' }}>
                                        <Row>
                                            <Col>
                                                <Link to="/productName/Sexual Wellness_Lubricants" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Lubricants</Link><br />
                                                <Link to="/productName/Sexual Wellness_Massager/Vibrator" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Massager/Vibrator</Link><br />
                                                <Link to="/productName/Sexual Wellness_Sprays/Gels" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Sprays/Gels</Link><br />
                                                <Link to="/productName/Sexual Wellness_Condom" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Condom</Link><br />
                                                <Link to="/productName/Sexual Wellness_Sexual Health Supplements" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Sexual Health Supplements</Link>
                                            </Col>
                                        </Row>
                                    </Container>
                                </NavDropdown>
                                <Nav.Link href="#action1" className="headerLink" id="navbarLine" disabled>|</Nav.Link>
                                <NavDropdown title="Ayush" id="basic-nav-dropdown" renderMenuOnMount={true} className="headerLink">
                                    <Container style={{ width: '250px' }}>
                                        <Row>
                                            <Col>
                                                <Link to="/productName/Ayush_Homeopathic" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Homeopathic</Link><br />
                                                <Link to="/productName/Ayush_Ayurvedic" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Ayurvedic</Link><br />
                                                <Link to="/productName/Ayush_Yunani" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Yunani</Link>
                                            </Col>
                                        </Row>
                                    </Container>
                                </NavDropdown>
                                <Nav.Link href="#action1" className="headerLink" id="navbarLine" disabled>|</Nav.Link>
                                <NavDropdown title="Women Corner" id="basic-nav-dropdown" renderMenuOnMount={true} className="headerLink">
                                    <Container style={{ width: '200px' }}>
                                        <Row>
                                            <Col>
                                                <Link to="/productName/Women Corner_Female Hygiene" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Female Hygiene</Link><br />
                                                <Link to="/productName/Women Corner_Maternity Care" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Maternity Care</Link><br />
                                                <Link to="/productName/Women Corner_Maternity accessories" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Maternity accessories</Link><br />
                                                <Link to="/productName/Women Corner_Other" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Other</Link>
                                            </Col>
                                        </Row>
                                    </Container>
                                </NavDropdown>
                                <Nav.Link href="#action1" className="headerLink" id="navbarLine" disabled>|</Nav.Link>
                                <NavDropdown title="Medicine" id="basic-nav-dropdown" renderMenuOnMount={true} className="headerLink">
                                    <Container style={{ width: '250px' }}>
                                        <Row>
                                            <Col>
                                                <Link to="/productName/ANTI DIABETIC" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>ANTI DIABETIC</Link><br />
                                                <Link to="/productName/ANTI INFECTIVES" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>ANTI INFECTIVES</Link><br />
                                                <Link to="/productName/ANTI MALARIALS" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>ANTI MALARIALS</Link><br />
                                                <Link to="/productName/ANTI NEOPLASTICS" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>ANTI NEOPLASTICS</Link><br />
                                                <Link to="/productName/BLOOD RELATED" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>BLOOD RELATED</Link><br />
                                                <Link to="/productName/CARDIAC" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>CARDIAC</Link><br />
                                                <Link to="/productName/DERMA" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>DERMA</Link><br />
                                                <Link to="/productName/GASTRO INTESTINAL" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>GASTRO INTESTINAL</Link><br />
                                                <Link to="/productName/GYNAECOLOGICAL" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>GYNAECOLOGICAL</Link><br />
                                                <Link to="/productName/HORMONES" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>HORMONES</Link><br />
                                                <Link to="/productName/INSOMNIA JET LEG" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>INSOMNIA JET LEG</Link><br />
                                                <Link to="/productName/NEURO CNS" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>NEURO CNS</Link><br />
                                                <Link to="/productName/OBESITY" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>OBESITY</Link><br />
                                                <Link to="/productName/OPHTHAL" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>OPHTHAL</Link><br />
                                                <Link to="/productName/OPHTHAL OTOLOGICALS" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>OPHTHAL OTOLOGICALS</Link><br />
                                                <Link to="/productName/OTOLOGICALS" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>OTOLOGICALS</Link><br />
                                                <Link to="/productName/PAIN ANALGESICS" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>PAIN ANALGESICS</Link><br />
                                                <Link to="/productName/RESPIRATORY" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>RESPIRATORY</Link><br />
                                                <Link to="/productName/SEX STIMULANTS REJUVENATORS" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>SEX STIMULANTS REJUVENATORS</Link><br />
                                                <Link to="/productName/STOMATOLOGICALS" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>STOMATOLOGICALS</Link><br />
                                                <Link to="/productName/UROLOGY" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>UROLOGY</Link><br />
                                                <Link to="/productName/VACCINES" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>VACCINES</Link><br />
                                                <Link to="/productName/VITAMINS MINERALS NUTRIENTS" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>VITAMINS MINERALS NUTRIENTS</Link><br />
                                            </Col>
                                        </Row>
                                    </Container>
                                </NavDropdown>
                                <Nav.Link href="#action1" className="headerLink" id="navbarLine" disabled>|</Nav.Link>
                                <NavDropdown title="Diabetes" id="basic-nav-dropdown" renderMenuOnMount={true} className="dropcc headerLink">
                                    <Container style={{ width: '150px' }}>
                                        <Row>
                                            <Col>
                                                <h1 style={{ fontSize: '14px', fontWeight: 'bold' }}>Diabetes</h1>
                                                <Link to="/productName/Diabetes_Glucometer" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Glucometer</Link><br />
                                                <Link to="/productName/Diabetes_Test strips & Lancets" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Test strips & Lancets</Link><br />
                                                <Link to="/productName/Diabetes_Sugar Substitutes" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Sugar Substitutes</Link><br />
                                                <Link to="/productName/Diabetes_Diabetic Management Suppliment" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Diabetic Management Suppliment</Link>
                                                <h1 style={{ fontSize: '14px', fontWeight: 'bold' }}>Diabetes Care</h1>
                                                <Link to="/productName/Diabetes_diabetesCare_Ayurvedic" className="headerLink" style={{ fontWeight: 'normal', fontSize: '12px' }}>Ayurvedic</Link>
                                            </Col>
                                        </Row>
                                    </Container>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Container>
        </>
    )
}

