import React, { useState } from 'react';
import TopHeader from '../Top_Header';
import Navigation from '../Navigation';
import { Container, Row, Col, Card, Button, Spinner, Image } from 'react-bootstrap'
import '../componentCss/ProductPage.css';
import { useQuery } from '@apollo/client';
import { QUERY_OTC_PRODUCTS } from '../Queries'
import Empty from '../../img/nocart.jpg';
import { useNavigate } from 'react-router-dom'

export default function HairCare() {
    const navigate = useNavigate();
    const { data: fitnessData, loading: fitnessLoading } = useQuery(QUERY_OTC_PRODUCTS, {
        variables: {
            "categoryName": "HairCare"
        }
    })

    const handleClick = (id) => navigate('/productDetails/' + id);

    return (
        <>
            <TopHeader />
            <Navigation />

            {
                window.scrollTo(0, 0)
            }

            <Container fluid style={{ marginTop: '15px' }}>
                <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '20px', textAlign: 'center' }}>Hair Care</p>
                <hr />

                <Row>
                    {
                        fitnessLoading ?
                            < Spinner animation="border" role="status" className='mx-auto d-block'>
                                <span className="visually-hidden">Loading...</span>
                            </Spinner> :
                            fitnessData && fitnessData.getOtcProduct.length === 0 || fitnessData && fitnessData.getOtcProduct === undefined ?
                                <Container>
                                    <Row>
                                        <Col>
                                            <Image src={Empty} style={{ width: '350px' }} className='mx-auto d-block' />
                                            <h1 style={{ fontSize: '25px', fontFamily: 'DM Sans', textAlign: 'center', fontWeight: 'bold' }}>No Product Found!!!</h1>
                                        </Col>
                                    </Row>
                                </Container>
                                :
                                fitnessData && fitnessData.getOtcProduct.map(activeMData => {
                                    return (
                                        <Col md={3}>
                                            <Card className="PageProductCard">
                                                <Card.Body style={{ padding: '0px' }}>
                                                    {
                                                        activeMData.image.length === 0 ?
                                                            <Card.Img variant="top" src={`https://images.pexels.com/photos/139398/thermometer-headache-pain-pills-139398.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1`} style={{ width: '100%', height: '300px' }} />
                                                            :
                                                            <Card.Img variant="top" src={`https://bharatpharmamain.s3.us-east-2.amazonaws.com/${activeMData.image[0]}`} onClick={() => handleClick(activeMData.id)} style={{ width: '100%', height: '300px' }} />
                                                    }

                                                    <Card.Text onClick={() => handleClick(activeMData.id)}>
                                                        {activeMData.name.length > 25 ? <h1 style={{ fontFamily: 'DM Sans', color: '#212121', fontSize: '13px', cursor: 'pointer' }}>{activeMData.name.substring(0, 50)}.....</h1> : <h1 style={{ fontFamily: 'DM Sans', color: '#212121', fontSize: '13px', cursor: 'pointer' }}>{activeMData.name}</h1>}
                                                    </Card.Text>
                                                    <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#34495e', fontSize: '18px' }}>₹{activeMData.mrp.replace(/[&\/\\#,+₹()$~%'":*?<>{} ]/g, '')}/-</p>

                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                })
                    }
                </Row>
            </Container>
        </>
    )
}
