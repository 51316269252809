/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Row, Card, Col, Spinner, Table, Image, Container } from 'react-bootstrap'
import '../UserDashboard/UserDashboardCss/YourOrders.css'
import { useQuery } from '@apollo/client'
import { QUERY_ORDER_BY_USERID } from '../Queries'
import Moment from 'react-moment'
import Empty from '../../img/nocart.jpg'


export default function YourOrders() {
    const userId = localStorage.getItem('userId');

    const { data: orderData, loading: orderLoading } = useQuery(QUERY_ORDER_BY_USERID, {
        variables: {
            "userId": `${userId}`
        },
        pollInterval: 300
    });
    return (
        <Container style={{ marginTop: '30px' }}>
            {
                orderLoading ?
                    <Spinner animation="border" role="status" className="mx-auto d-block">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    :
                    orderData && orderData.getOrderByUserId.length === 0 ?
                        <Container>
                            <Row>
                                <Col>
                                    {/* <Image src={Empty} style={{ width: '300px' }} className='mx-auto d-block' /> */}
                                    <h1 style={{ fontSize: '25px', fontFamily: 'DM Sans', textAlign: 'center', fontWeight: 'bold' }}>No Order Found!!!</h1>
                                </Col>
                            </Row>
                        </Container>
                        :
                        orderData && orderData.getOrderByUserId.slice().reverse().map(data =>
                            <Card style={{ marginBottom: '70px' }}>
                                <Card.Body>
                                    <Row>
                                        <Col md={8}>
                                            <p style={{ color: '#8D448B', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '12px' }}> Order Number :- <span style={{ color: '#d35400', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '12px' }}>{data.orderId}</span></p>
                                            <Row>
                                                <Col>
                                                    <Table hover responsive style={{ fontFamily: 'DM Sans', textAlign: 'center', marginTop: '10px', fontSize: '12px' }}>
                                                        <thead>
                                                            <tr>
                                                                <th>Product</th>
                                                                <th>Price</th>
                                                                <th>Quantity</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data.productDetails.map(pData =>
                                                                    <tr>
                                                                        <td>{pData.productName}</td>
                                                                        <td>₹{pData.productPrice}</td>
                                                                        <td>{pData.quantity}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                            <tr>
                                                                <td>Total Price: {data.totalPrice}</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={4}>
                                            <Row>
                                                <Col md={9}>
                                                    <p style={{ color: '#ff7f50', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '12px' }}>Order Date: <Moment format="DD/MM/YYYY, hh:mm a">{data.createdDateTime}</Moment></p>
                                                    <Row>
                                                        <p style={{ color: '#2c3e50', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '12px' }}>Address: {data.fullAddress}</p>
                                                    </Row>
                                                    <Row>
                                                        <p style={{ color: '#2c3e50', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '12px' }}>Contact: {data.contact}</p>
                                                    </Row>
                                                    <Row>
                                                        <p style={{ color: '#2c3e50', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '12px' }}>Status: {data.status}</p>
                                                    </Row>
                                                    <Row>
                                                        <a href={`https://bharatpharmauserprescription.s3.amazonaws.com/${data.prescriptionImage}`} style={{ fontFamily: 'DM Sans', fontSize: 12, textDecoration: 'none', fontWeight: 'bold' }}>Download Prescription</a>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        )
            }
        </Container>
    )
}
