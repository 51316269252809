/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Row, Col, Button, Container, Tabs, Tab, Image, Spinner } from 'react-bootstrap'
import { useParams, Link, useNavigate } from 'react-router-dom'
import './componentCss/SingleProduct.css'
import Top_Header from './Top_Header'
import Navigation from './Navigation'
import { useQuery, useMutation } from '@apollo/client'
import { QUERY_PRODUCT_BY_ID, MUTATION_ADD_TO_CART_PRODUCT, QUERY_CHECK_CART_PRODUCT, QUERY_CHECK_PENDING_CART_PRODUCT_FOR_USER } from './Queries'
import Footer from './Footer'
import { useDispatch } from 'react-redux'
import { addItem } from '../redux/actions'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaStar, FaCheckCircle, FaTimesCircle, FaUserNurse, FaTemperatureLow } from "react-icons/fa";


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, background: "#7f8c8d", borderRadius: '50px' }}
            onClick={onClick}
        />
    );
}


function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, background: "#7f8c8d", borderRadius: '50px' }}
            onClick={onClick}
        />
    );
}

export default function ProductDetails() {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { id } = useParams();
    const userToken = localStorage.getItem('userToken')
    const userId = localStorage.getItem('userId')

    const { data: productByIdData, loading: productByIdLoading } = useQuery(QUERY_PRODUCT_BY_ID, {
        variables: {
            productId: `${id}`
        },
    });
    console.log("productByIdData", productByIdData)
    const { data: checkCartProductData, loading: checkCartProductLoading } = useQuery(QUERY_CHECK_CART_PRODUCT, {
        variables: {
            userId: `${userId}`,
            productId: `${id}`
        },
    });


    const [addToCartMutation, { data: cartMutation, loading: addToCartMutationLoading }] = useMutation(MUTATION_ADD_TO_CART_PRODUCT, {
        refetchQueries: [
            QUERY_CHECK_CART_PRODUCT,
            QUERY_CHECK_PENDING_CART_PRODUCT_FOR_USER
        ],
    });


    function addToCartHandle(
        id,
        vendorId,
        productExcelId,
        name,
        brandName,
        manufacturer,
        manufacturerAddress,
        marketedBy,
        introduction,
        description,
        benefit,
        howToUse,
        howWork,
        safetyAdvise,
        packaging,
        packagingType,
        packInfo,
        saltComposition,
        medicineType,
        inStock,
        stock,
        stockAvailability,
        stockType,
        mrp,
        bestPrice,
        prescriptionRequired,
        primaryUse,
        storage,
        countryOfOrigin,
        therapeuticClass,
        sideEffects,
        ifMiss,
        ingredients,
        alternateBrands,
        category,
        subCategory,
        taxCode,
        expireTime,
        skuNo,
        shipping,
        productQuantity,
        productQuantityType,
        hsnCode,
        gst,
        highlights,
        catagory,
        menuCatagory,
        image
    ) {
        if (!userToken) {
            navigate('/userLogin')
        }
        else {
            let today = new Date();
            addToCartMutation({
                variables: {
                    cartItemInput: {
                        "userId": `${userId}`,
                        "uploaderId": `${vendorId}`,
                        "productId": `${id}`,
                        "amount": 1,
                        "productExcelId": `${productExcelId}`,
                        "name": `${name}`,
                        "brandName": `${brandName}`,
                        "manufacturer": `${manufacturer}`,
                        "manufacturerAddress": `${manufacturerAddress}`,
                        "marketedBy": `${marketedBy}`,
                        "introduction": `${introduction}`,
                        "description": `${description}`,
                        "benefit": `${benefit}`,
                        "howToUse": `${howToUse}`,
                        "howWork": `${howWork}`,
                        "safetyAdvise": `${safetyAdvise}`,
                        "packaging": `${packaging}`,
                        "packagingType": `${packagingType}`,
                        "packInfo": `${packInfo}`,
                        "saltComposition": `${saltComposition}`,
                        "medicineType": `${medicineType}`,
                        "inStock": `${inStock}`,
                        "stock": `${stock}`,
                        "stockAvailability": `${stockAvailability}`,
                        "stockType": `${stockType}`,
                        "mrp": `${mrp}`,
                        "bestPrice": `${bestPrice}`,
                        "prescriptionRequired": `${prescriptionRequired}`,
                        "primaryUse": `${primaryUse}`,
                        "storage": `${storage}`,
                        "countryOfOrigin": `${countryOfOrigin}`,
                        "therapeuticClass": `${therapeuticClass}`,
                        "sideEffects": `${sideEffects}`,
                        "ifMiss": `${ifMiss}`,
                        "ingredients": `${ingredients}`,
                        "alternateBrands": `${alternateBrands}`,
                        "category": `${category}`,
                        "subCategory": `${subCategory}`,
                        "taxCode": `${taxCode}`,
                        "expireTime": `${expireTime}`,
                        "skuNo": `${skuNo}`,
                        "shipping": `${shipping}`,
                        "productQuantity": `${productQuantity}`,
                        "productQuantityType": `${productQuantityType}`,
                        "hsnCode": `${hsnCode}`,
                        "gst": `${gst}`,
                        "highlights": `${highlights}`,
                        "catagory": `${catagory}`,
                        "menuCatagory": `${menuCatagory}`,
                        "image": image,
                        "uploadingDateTime": `${today}`,
                        "status": "pending"
                    }
                }
            })
        }
    }

    return (
        <div>
            {
                window.scrollTo(0, 0)
            }
            <Top_Header />
            <Navigation />
            {
                productByIdLoading ?
                    <Spinner animation="border" role="status" className="mx-auto d-block" style={{ marginTop: '20px' }}></Spinner>
                    :
                    <>
                        <Container style={{ marginTop: '50px' }}>
                            <Row>
                                <Col md={5}>
                                    <Slider {...settings}>
                                        {
                                            productByIdData && productByIdData.getProductById.image.length === 0 ?
                                                <div>
                                                    <Image src={`https://images.pexels.com/photos/139398/thermometer-headache-pain-pills-139398.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1`} style={{ width: '450px', height: '450px' }} fluid />
                                                </div>
                                                :
                                                productByIdData && productByIdData.getProductById.image.map(iData =>
                                                    <div>
                                                        <Image src={`https://bharatpharmamain.s3.us-east-2.amazonaws.com/${iData}`} style={{ width: '450px', height: '450px' }} fluid />
                                                    </div>
                                                )
                                        }
                                    </Slider>
                                </Col>
                                <Col md={7}>
                                    <Row>
                                        <Col md={1}></Col>
                                        <Col md={11}>
                                            <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2c3e50', fontSize: '20px', textAlign: 'left', marginTop: '10px' }}>{productByIdData && productByIdData.getProductById.name} <span style={{ color: '#fff', background: '#2ecc71', fontSize: '15px', padding: '3px', borderRadius: '3px' }}>4.2 <FaStar style={{ marginTop: '-4px' }} /></span></p>
                                            <hr />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={1}></Col>
                                        <Col md={11}>
                                            <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '18px', marginTop: '10px' }}>Best Price <span style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#e74c3c', fontSize: '23px', marginTop: '15px' }}>Rs.{productByIdData && productByIdData.getProductById.mrp}</span></p>
                                            <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '12px', marginTop: '-15px' }}>MRP <span style={{ fontFamily: 'DM Sans', fontWeight: '700', color: 'Red', fontSize: '12px', marginTop: '-15px' }}><strike>Rs.{productByIdData && productByIdData.getProductById.mrp}</strike></span> <span style={{ fontFamily: 'DM Sans', fontWeight: '700', color: 'Red', fontSize: '12px', marginTop: '-15px' }}><mark>Rs.{productByIdData && productByIdData.getProductById.mrp}</mark></span></p>
                                            <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '12px', marginTop: '-15px' }}>(Inclusive of all taxes)</p>
                                            {
                                                productByIdData && productByIdData.getProductById.prescriptionRequired === 'Prescription Required' ?
                                                    <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#3498db', fontSize: '12px' }}><FaUserNurse style={{ marginTop: '-4px', color: '#9b59b6' }} /> Prescription Required: <FaCheckCircle style={{ color: "#2ecc71" }} /></p>
                                                    :
                                                    <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#3498db', fontSize: '12px' }}><FaUserNurse style={{ marginTop: '-4px' }} />Prescription Required: <FaTimesCircle style={{ color: "#e74c3c" }} /></p>
                                            }
                                            <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#f39c12', fontSize: '12px' }}><FaTemperatureLow style={{ marginTop: '-4px', color: '#e74c3c' }} />  Storage: <span style={{ color: '#fd79a8' }}>{productByIdData && productByIdData.getProductById.storage}</span></p>
                                            <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '12px' }}>This product cannot be returned for a refund or exchange.</p>
                                            <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#e74c3c', fontSize: '12px', marginTop: '-15px' }}>Mkt: <span style={{ color: '#34495e' }}>{productByIdData && productByIdData.getProductById.manufacturer}</span></p>
                                            <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#e74c3c', fontSize: '12px', marginTop: '-15px' }}>Country of Origin: <span style={{ color: '#34495e' }}>{productByIdData && productByIdData.getProductById.countryOfOrigin}</span></p>
                                            <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#e74c3c', fontSize: '12px', marginTop: '-15px' }}>Delivery charges if applicable will be applied at checkout*</p>
                                            {
                                                !userToken ?
                                                    <Link to="/userLogin"> <Button id="singleProduct_btn">Add To Cart</Button></Link> :
                                                    checkCartProductLoading ?
                                                        <Spinner animation="border" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner> :
                                                        addToCartMutationLoading ?
                                                            <Spinner animation="border" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </Spinner> :
                                                            checkCartProductData && checkCartProductData.checkCartProduct === null ?
                                                                <Button id="singleProduct_btn" onClick={() => {
                                                                    addToCartHandle(
                                                                        productByIdData && productByIdData.getProductById.id,
                                                                        productByIdData && productByIdData.getProductById.vendorId,
                                                                        productByIdData && productByIdData.getProductById.productExcelId,
                                                                        productByIdData && productByIdData.getProductById.name,
                                                                        productByIdData && productByIdData.getProductById.brandName,
                                                                        productByIdData && productByIdData.getProductById.manufacturer,
                                                                        productByIdData && productByIdData.getProductById.manufacturerAddress,
                                                                        productByIdData && productByIdData.getProductById.marketedBy,
                                                                        productByIdData && productByIdData.getProductById.introduction,
                                                                        productByIdData && productByIdData.getProductById.description,
                                                                        productByIdData && productByIdData.getProductById.benefit,
                                                                        productByIdData && productByIdData.getProductById.howToUse,
                                                                        productByIdData && productByIdData.getProductById.howWork,
                                                                        productByIdData && productByIdData.getProductById.safetyAdvise,
                                                                        productByIdData && productByIdData.getProductById.packaging,
                                                                        productByIdData && productByIdData.getProductById.packagingType,
                                                                        productByIdData && productByIdData.getProductById.packInfo,
                                                                        productByIdData && productByIdData.getProductById.saltComposition,
                                                                        productByIdData && productByIdData.getProductById.medicineType,
                                                                        productByIdData && productByIdData.getProductById.inStock,
                                                                        productByIdData && productByIdData.getProductById.stock,
                                                                        productByIdData && productByIdData.getProductById.stockAvailability,
                                                                        productByIdData && productByIdData.getProductById.stockType,
                                                                        productByIdData && productByIdData.getProductById.mrp,
                                                                        productByIdData && productByIdData.getProductById.bestPrice,
                                                                        productByIdData && productByIdData.getProductById.prescriptionRequired,
                                                                        productByIdData && productByIdData.getProductById.primaryUse,
                                                                        productByIdData && productByIdData.getProductById.storage,
                                                                        productByIdData && productByIdData.getProductById.countryOfOrigin,
                                                                        productByIdData && productByIdData.getProductById.therapeuticClass,
                                                                        productByIdData && productByIdData.getProductById.sideEffects,
                                                                        productByIdData && productByIdData.getProductById.ifMiss,
                                                                        productByIdData && productByIdData.getProductById.ingredients,
                                                                        productByIdData && productByIdData.getProductById.alternateBrands,
                                                                        productByIdData && productByIdData.getProductById.category,
                                                                        productByIdData && productByIdData.getProductById.subCategory,
                                                                        productByIdData && productByIdData.getProductById.taxCode,
                                                                        productByIdData && productByIdData.getProductById.expireTime,
                                                                        productByIdData && productByIdData.getProductById.skuNo,
                                                                        productByIdData && productByIdData.getProductById.shipping,
                                                                        productByIdData && productByIdData.getProductById.productQuantity,
                                                                        productByIdData && productByIdData.getProductById.productQuantityType,
                                                                        productByIdData && productByIdData.getProductById.hsnCode,
                                                                        productByIdData && productByIdData.getProductById.gst,
                                                                        productByIdData && productByIdData.getProductById.highlights,
                                                                        productByIdData && productByIdData.getProductById.catagory,
                                                                        productByIdData && productByIdData.getProductById.menuCatagory,
                                                                        productByIdData && productByIdData.getProductById.image,
                                                                        productByIdData && productByIdData.getProductById.uploadingDateTime,
                                                                        productByIdData && productByIdData.getProductById.status,
                                                                    ); dispatch(addItem(productByIdData))
                                                                }}>Add To Cart</Button>
                                                                :
                                                                <Link to="/cart"><Button id="singleProduct_btn">Proceed To Cart</Button></Link>
                                            }

                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: '-90px' }}>
                                        <Col md={1}></Col>
                                        <Col md={11} className="ProductLine">
                                            <Row>
                                                {/* <Col md={6}>
                                                <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '15px', marginTop: '10px' }}>Check Availability & Expiry </p>
                                            </Col> */}
                                                {/* <Col md={5}>
                                                    <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '13px', marginTop: '10px' }}>Expiry Date: <span style={{ color: '#34495e' }}>{productByIdData && productByIdData.getProductById.expireTime}</span></p>
                                                </Col>
                                                <Col md={5}>
                                                    <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '13px', marginTop: '10px' }}>Expected Delivery: <span style={{ color: '#34495e' }}>24 Aug, Monday</span></p>
                                                </Col> */}
                                            </Row>
                                            <Row>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                        {
                            productByIdData && productByIdData.getProductById.catagory === 'medicine' ?

                                <Container style={{ marginTop: '30px', background: '#f8f8f8' }} fluid>
                                    <Container style={{ padding: '50px' }} >
                                        <h1 style={{ fontSize: '25px', fontFamily: 'DM Sans', color: '#2B97A4', fontWeight: 'bold' }}>More Details</h1>
                                        <Tabs>
                                            <Tab eventKey="details" title="Introduction">
                                                <h1 style={{ marginTop: '10px', fontSize: '15px', fontFamily: 'Dm Sans' }}>{productByIdData && productByIdData.getProductById.introduction}</h1>
                                            </Tab>
                                            <Tab eventKey="ingredients" title="Description">
                                                <h1 style={{ marginTop: '10px', fontSize: '15px', fontFamily: 'Dm Sans' }}>{productByIdData && productByIdData.getProductById.description}</h1>
                                            </Tab>
                                            <Tab eventKey="benefits" title="Benefits">
                                                <h1 style={{ marginTop: '10px', fontSize: '15px', fontFamily: 'Dm Sans' }}>{productByIdData && productByIdData.getProductById.benefit}</h1>
                                            </Tab>
                                            <Tab eventKey="howToUse" title="How to Use">
                                                <h1 style={{ marginTop: '10px', fontSize: '15px', fontFamily: 'Dm Sans' }}>{productByIdData && productByIdData.getProductById.howToUse}</h1>
                                            </Tab>
                                            <Tab eventKey="howToUse2" title="Salt Composition">
                                                <h1 style={{ marginTop: '10px', fontSize: '15px', fontFamily: 'Dm Sans' }}>{productByIdData && productByIdData.getProductById.saltComposition}</h1>
                                            </Tab>
                                        </Tabs>
                                    </Container>
                                </Container>

                                :

                                <Container style={{ marginTop: '30px', background: '#f8f8f8' }} fluid>
                                    <Container style={{ padding: '50px' }} >
                                        <h1 style={{ fontSize: '25px', fontFamily: 'DM Sans', color: '#2B97A4', fontWeight: 'bold' }}>More Details</h1>
                                        <Tabs>
                                            <Tab eventKey="details" title="Details">
                                                <h1 style={{ marginTop: '10px', fontSize: '15px', fontFamily: 'Dm Sans' }}>{productByIdData && productByIdData.getProductById.description}</h1>
                                            </Tab>
                                            <Tab eventKey="ingredients" title="Ingredients">
                                                <h1 style={{ marginTop: '10px', fontSize: '15px', fontFamily: 'Dm Sans' }}>{productByIdData && productByIdData.getProductById.ingredients}</h1>
                                            </Tab>
                                            <Tab eventKey="benefits" title="Benefits">
                                                <h1 style={{ marginTop: '10px', fontSize: '15px', fontFamily: 'Dm Sans' }}>{productByIdData && productByIdData.getProductById.benefits}</h1>
                                            </Tab>
                                            <Tab eventKey="howToUse" title="How to Use">
                                                <h1 style={{ marginTop: '10px', fontSize: '15px', fontFamily: 'Dm Sans' }}>{productByIdData && productByIdData.getProductById.howToUse}</h1>
                                            </Tab>
                                        </Tabs>
                                    </Container>
                                </Container>
                        }

                        {/* <Container>
                            <Row>
                                <Col>
                                    <h1 style={{ fontSize: '25px', fontFamily: 'DM Sans', color: '#2B97A4', fontWeight: 'bold', marginTop: '30px', marginBottom: '30px' }}>Customers who bought this item also bought</h1>
                                    <Slider {...settings2}>

                                        <Card style={{ width: '150px' }}>
                                            <Card.Body>
                                                <Row>
                                                    <Col>
                                                        <center><Card.Img variant="top" src={`https://bharatpharmamain.s3.us-east-2.amazonaws.com/${productByIdData && productByIdData.getProductById.image[0]}`} style={{ width: '200px', height: '200px' }} /></center>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Card.Text style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '13px' }}>
                                                            {productByIdData && productByIdData.getProductById.name.length > 25 ? <h1 style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '15px', cursor: 'pointer' }}>{productByIdData && productByIdData.getProductById.name.substring(0, 25)}.....</h1> : <h1 style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '15px', cursor: 'pointer' }}>{productByIdData && productByIdData.getProductById.name}</h1>}
                                                        </Card.Text>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '18px' }}>₹ {productByIdData && productByIdData.getProductById.yourPrice}</p>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>

                                        <Card style={{ width: '150px' }}>
                                            <Card.Body>
                                                <Row>
                                                    <Col>
                                                        <center><Card.Img variant="top" src={`https://bharatpharmamain.s3.us-east-2.amazonaws.com/${productByIdData && productByIdData.getProductById.image[0]}`} style={{ width: '200px', height: '200px' }} /></center>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Card.Text style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '13px' }}>
                                                            {productByIdData && productByIdData.getProductById.name.length > 25 ? <h1 style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '15px', cursor: 'pointer' }}>{productByIdData && productByIdData.getProductById.name.substring(0, 25)}.....</h1> : <h1 style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '15px', cursor: 'pointer' }}>{productByIdData && productByIdData.getProductById.name}</h1>}
                                                        </Card.Text>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '18px' }}>₹ {productByIdData && productByIdData.getProductById.yourPrice}</p>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                        <Card style={{ width: '150px' }}>
                                            <Card.Body>
                                                <Row>
                                                    <Col>
                                                        <center><Card.Img variant="top" src={`https://bharatpharmamain.s3.us-east-2.amazonaws.com/${productByIdData && productByIdData.getProductById.image[0]}`} style={{ width: '200px', height: '200px' }} /></center>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Card.Text style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '13px' }}>
                                                            {productByIdData && productByIdData.getProductById.name.length > 25 ? <h1 style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '15px', cursor: 'pointer' }}>{productByIdData && productByIdData.getProductById.name.substring(0, 25)}.....</h1> : <h1 style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '15px', cursor: 'pointer' }}>{productByIdData && productByIdData.getProductById.name}</h1>}
                                                        </Card.Text>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '18px' }}>₹ {productByIdData && productByIdData.getProductById.yourPrice}</p>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                        <Card style={{ width: '150px' }}>
                                            <Card.Body>
                                                <Row>
                                                    <Col>
                                                        <center><Card.Img variant="top" src={`https://bharatpharmamain.s3.us-east-2.amazonaws.com/${productByIdData && productByIdData.getProductById.image[0]}`} style={{ width: '200px', height: '200px' }} /></center>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Card.Text style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '13px' }}>
                                                            {productByIdData && productByIdData.getProductById.name.length > 25 ? <h1 style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '15px', cursor: 'pointer' }}>{productByIdData && productByIdData.getProductById.name.substring(0, 25)}.....</h1> : <h1 style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '15px', cursor: 'pointer' }}>{productByIdData && productByIdData.getProductById.name}</h1>}
                                                        </Card.Text>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '18px' }}>₹ {productByIdData && productByIdData.getProductById.mrp}</p>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Slider>
                                </Col>
                            </Row>
                        </Container> */}
                    </>
            }
            <Footer />
        </div >
    )
}

// const [addToCartMutation] = useMutation(MUTATION_ADD_TO_CART_PRODUCT, {
//     refetchQueries: [
//         QUERY_ACTIVE_VENDORS,
//         'getActiveVendors',
//         QUERY_PENDING_VENDORS,
//         'getPendingVendors',
//     ],
// });


function Detailss() {
    return (
        <h1>Details</h1>
    )
}