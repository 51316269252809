import React, { useState } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import ChooseSubCategory from './ChooseSubCategory'

export default function ChooseCategory() {


    const [categoryState, setCategoryState] = useState(null)
    const [catClick, setCatClick] = useState(false)

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            setCatClick(true)
        }

        setValidated(true);
    };
   
    if(catClick){
        return <ChooseSubCategory categoryState={categoryState}/>
    }
    return (
        <>
            <Container style={{ marginTop: '100px' }}>
                <Row>
                    <Col>
                        <h1 style={{ fontFamily: 'DM Sans', fontSize: '30px', fontWeight: 'bold', marginBottom: '30px' }}>Choose Category</h1>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="4">
                                    <Form.Control required as="select" onChange={(e) => setCategoryState(e.target.value)} style={{ borderRadius: '0px', fontFamily: 'DM Sans', fontSize: '15px' }}>
                                        <option value="" selected="selected" disabled="disabled" style={{ fontFamily: 'DM Sans' }}>Choose Category</option>
                                        <option value="Covid-19">Covid-19</option>
                                        <option value="Beauty & Personal Care">Beauty & Personal Care</option>
                                        <option value="Surgical">Surgical</option>
                                        <option value="Fitness">Fitness</option>
                                        <option value="Baby Care">Baby Care</option>
                                        <option value="Devices">Devices</option>
                                        <option value="Sexual Wellness">Sexual Wellness</option>
                                        <option value="Ayush">Ayush</option>
                                        <option value="Women Corner">Women Corner</option>
                                        <option value="Medicine">Medicine</option>
                                        <option value="Diabetes">Diabetes</option>
                                    </Form.Control>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">Please choose any Category.</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Button type="submit" style={{ fontFamily: 'DM Sans', fontSize: '15px', borderRadius: '0px', background: '#6c5ce7', borderColor: '#6c5ce7' }}>Proceed</Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
