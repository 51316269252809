import React, { useState, useEffect } from 'react';
import TopHeader from './Top_Header';
import Navigation from './Navigation';
import { useParams, Link, useNavigate } from 'react-router-dom'
import { Container, Row, Col, Card, Button, Spinner, Image } from 'react-bootstrap'
import './componentCss/HealthcareProduct.css'
import { useQuery, useLazyQuery } from '@apollo/client'
import { QUERY_PRODUCT_FOR_USER, QUERY_PRODUCT_FOR_MENU, QUERY_LIST_PRODUCT_COUNT } from './Queries'
import './componentCss/ProductPage.css'
import Empty from '../img/nocart.jpg'
import ReactPagination from 'react-paginate';

const PAGE_SIZE = 100;

export default function ProductPage() {

  const { name } = useParams();

  const [page, setPage] = useState(0)

  useEffect(() => {
    refetch()
  }, [name])


  const [pageProduct, { data: productForMenuData, loading: productForMenuLoading, refetch }] = useLazyQuery(QUERY_PRODUCT_FOR_MENU)

  useEffect(() => {
    pageProduct({
      variables: {
        "menuCatagory": `${name}`,
        "first": PAGE_SIZE,
        "offset": page * PAGE_SIZE
      }
    })
  }, [name])



  const { data: productCountData, loading: productCountLoading } = useQuery(QUERY_LIST_PRODUCT_COUNT, {
    variables: {
      "menuCatagory": `${name}`
    }
  });

  const navigate = useNavigate();

  const handelPageClick = (data) => {
    pageProduct({
      variables: {
        "menuCatagory": `${name}`,
        "first": PAGE_SIZE,
        "offset": data.selected * PAGE_SIZE
      }
    })
  }

  const handleClick = (id) => navigate('/productDetails/' + id);

  return (
    <>
      <TopHeader />
      <Navigation />
      <Container fluid style={{ marginTop: '15px' }}>
        <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '20px', textAlign: 'center' }}>{name}</p>
        <Row>
          <Col></Col>
          <Col>
            {

              <ReactPagination
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                pageCount={Math.round((productCountData && productCountData.getListProductCount) / 100)}
                marginPagesDisplayed={4}
                pageRangeDisplayed={6}
                onPageChange={handelPageClick}
                className={'pagination'}
                pageLinkClassName={'page-link'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                breakLinkClassName={'page-link'}
                activeLinkClassName={'page-item active'}
                activeClassName={'page-item active'}
              />
            }
          </Col>
          <Col></Col>
        </Row>
        <Row>
          {
            productForMenuLoading ?
              <Spinner animation="border" role="status" className='mx-auto d-block'>
                <span className="visually-hidden">Loading...</span>
              </Spinner> :
              productForMenuData && productForMenuData.getProductForMenu.length === 0 ?
                <Container>
                  <Row>
                    <Col>
                      <Image src={Empty} style={{ width: '350px' }} className='mx-auto d-block' />
                      <h1 style={{ fontSize: '25px', fontFamily: 'DM Sans', textAlign: 'center', fontWeight: 'bold' }}>No Product Found!!!</h1>
                    </Col>
                  </Row>
                </Container>
                :
                productForMenuData && productForMenuData.getProductForMenu.map(activeMData => {
                  return (
                    <Col md={3}>
                      <Card className="PageProductCard">
                        <Card.Body style={{ padding: '0px' }}>
                          {
                            activeMData.image.length === 0 ?
                              <Card.Img variant="top" src={`https://images.pexels.com/photos/139398/thermometer-headache-pain-pills-139398.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1`} onClick={() => handleClick(activeMData.id)} style={{ width: '100%', height: '300px' }} />
                              :
                              <Card.Img variant="top" src={`https://bharatpharmamain.s3.us-east-2.amazonaws.com/${activeMData.image[0]}`} onClick={() => handleClick(activeMData.id)} style={{ width: '100%', height: '300px' }} />
                          }

                          <Card.Text onClick={() => handleClick(activeMData.id)}>
                            {activeMData.name.length > 25 ? <h1 style={{ fontFamily: 'DM Sans', color: '#212121', fontSize: '13px', cursor: 'pointer' }}>{activeMData.name.substring(0, 50)}.....</h1> : <h1 style={{ fontFamily: 'DM Sans', color: '#212121', fontSize: '13px', cursor: 'pointer' }}>{activeMData.name}</h1>}
                          </Card.Text>
                          <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#34495e', fontSize: '18px' }}>₹{activeMData.mrp.replace(/[&\/\\#,+₹()$~%'":*?<>{} ]/g, '')}/-</p>
                        </Card.Body>
                      </Card>
                    </Col>
                  )
                })
          }
        </Row>
        <Row>
          <Col></Col>
          <Col>
            {
              <ReactPagination
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                pageCount={Math.round((productCountData && productCountData.getListProductCount) / 100)}
                marginPagesDisplayed={4}
                pageRangeDisplayed={6}
                onPageChange={handelPageClick}
                className={'pagination'}
                pageLinkClassName={'page-link'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                breakLinkClassName={'page-link'}
                activeLinkClassName={'page-item active'}
                activeClassName={'page-item active'}
              />
            }
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </>

  );
}


//https://images.pexels.com/photos/139398/thermometer-headache-pain-pills-139398.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1