import React, { useState } from 'react'
import { Container, Row, Col, Modal, Button, Spinner, Table, Image, Form } from 'react-bootstrap'
import { useQuery, useMutation } from '@apollo/client'
import { QUERY_ALL_DELIVERYBOY, MUTATION_DELETE_DELIVERY_BOY, MUTATION_UPDATE_DELIVERY_BOY } from '../../Queries'
import swal from 'sweetalert';
import { SRLWrapper } from "simple-react-lightbox";

import { FaEye, FaPen, FaTrash } from "react-icons/fa";

export default function AllDeliveryBoy() {
    let count = 1

    const [validated, setValidated] = useState(false);

    const [deliveryBoyIdState, setDeliveryBoyIdState] = useState('')
    const [fName, setName] = useState('')
    const [lName, setLName] = useState('')
    const [contact, setContact] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [pincode, setPincode] = useState('')
    const [bankName, setBankName] = useState('')
    const [bankIfsc, setBankIfsc] = useState('')
    const [bankAccountNo, setBankAccountNo] = useState('')
    const [bankAccountHolderName, setBankAccountHolderName] = useState('')

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showEdit, setShowEdit] = useState(false);
    const handleEditClose = () => setShowEdit(false);
    const handleEditShow = () => setShowEdit(true);


    const [dbData, setDbData] = useState('')

    const { data, loading } = useQuery(QUERY_ALL_DELIVERYBOY, {
        fetchPolicy: 'no-cache'
    })
    const [dbDelete] = useMutation(MUTATION_DELETE_DELIVERY_BOY, {
        refetchQueries: [
            QUERY_ALL_DELIVERYBOY
        ]
    })
    const [dbUpdate, { loading: dbUpdateLoading }] = useMutation(MUTATION_UPDATE_DELIVERY_BOY, {
        refetchQueries: [
            QUERY_ALL_DELIVERYBOY
        ]
    })

    function clickViewHandel(data) {
        setDbData(data)
        handleShow()
    }

    function deleteDeliveryBoyHandel(dbId) {
        dbDelete({
            variables: {
                "deliveryBoyId": `${dbId}`
            }
        }).then(() => {
            swal({
                title: 'Deleted',
                text: 'Delivery Boy Deleted Successfully!!!',
                icon: 'success',
            });
        })
    }

    function clickEditHandel(data) {
        setDeliveryBoyIdState(data.id)
        setName(data.fName);
        setLName(data.lName);
        setContact(data.contact);
        setEmail(data.email);
        setAddress(data.address);
        setCity(data.city);
        setState(data.state);
        setPincode(data.pincode);
        setBankName(data.bankName);
        setBankIfsc(data.bankIfsc);
        setBankAccountNo(data.bankAccountNo);
        setBankAccountHolderName(data.bankAccountHolderName);
        handleEditShow()
    }


    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setValidated(false);
            event.preventDefault();
            dbUpdate({
                variables: {
                    "deliveryboyUpdateInput": {
                        "deliveryBoyId": `${deliveryBoyIdState}`,
                        "fName": `${fName}`,
                        "lName": `${lName}`,
                        "contact": `${contact}`,
                        "email": `${email}`,
                        "address": `${address}`,
                        "city": `${city}`,
                        "state": `${state}`,
                        "pincode": `${pincode}`,
                        "bankName": `${bankName}`,
                        "bankIfsc": `${bankIfsc}`,
                        "bankAccountNo": `${bankAccountNo}`,
                        "bankAccountHolderName": `${bankAccountHolderName}`,
                    }
                }
            }).then(() => {
                swal({
                    title: 'Updated',
                    text: 'Delivery Boy Updated Successfully!!!',
                    icon: 'success',
                });
                handleEditClose()
            })
        }
    };


    console.log("dbData", dbData)
    return (
        <Container style={{ marginTop: '50px' }}>
            <Row>
                <Col>
                    <h1 style={{ fontFamily: 'DM Sans', fontSize: 20, textAlign: 'center', marginBottom: 20, fontWeight: 'bold' }}>All Delivery Boys</h1>
                    {
                        loading ?
                            <Spinner animation="border" role="status" className="mx-auto d-block"></Spinner> :
                            <Table bordered responsive style={{ fontFamily: 'DM Sans', fontSize: 12 }}>
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Unique Id</th>
                                        <th>Full Name</th>
                                        <th>Contact</th>
                                        <th>Email</th>
                                        <th>Username</th>
                                        <th>Password</th>
                                        <th>View</th>
                                        <th>Edit</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data && data.getDeliveryBoy.slice().reverse().map(dbData =>
                                            <tr>
                                                <td>{count++}</td>
                                                <td>{dbData.uniqueId}</td>
                                                <td>{dbData.fName} {dbData.lName}</td>
                                                <td>{dbData.contact}</td>
                                                <td>{dbData.email}</td>
                                                <td>{dbData.uniqueId}</td>
                                                <td>{dbData.password}</td>
                                                <td><Button size="sm" variant="outline-primary" onClick={() => clickViewHandel(dbData)}><FaEye style={{ marginTop: '-4px' }} /></Button></td>
                                                <td><Button size="sm" variant="outline-warning" onClick={() => clickEditHandel(dbData)}><FaPen style={{ marginTop: '-4px' }} /></Button></td>
                                                <td><Button size="sm" variant="outline-danger" onClick={() => deleteDeliveryBoyHandel(dbData.id)}><FaTrash style={{ marginTop: '-4px' }} /></Button></td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                    }

                </Col>
            </Row>

            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Body>
                    <Container>
                        <Row>
                            <h1 style={{ fontSize: 20, fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'center' }}>{dbData.fName} {dbData.lName}</h1>
                        </Row>
                        <Row>
                            <Col>
                                <Table style={{ fontSize: 12, fontFamily: 'DM Sans' }} responsive>
                                    <tbody>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Unique Id</td>
                                            <td>{dbData.uniqueId}</td>
                                            <td style={{ fontWeight: 'bold' }}>Contact</td>
                                            <td>{dbData.contact}</td>
                                            <td style={{ fontWeight: 'bold' }}>Email</td>
                                            <td>{dbData.email}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Address</td>
                                            <td colspan={7}>{dbData.address}, {dbData.city}, {dbData.state}, {dbData.pincode}</td>
                                        </tr>
                                        <tr>
                                            <td colspan={8} style={{ fontWeight: 'bold', textAlign: 'center' }}>Bank Details</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Account Holder Name</td>
                                            <td>{dbData.bankAccountHolderName}</td>
                                            <td style={{ fontWeight: 'bold' }}>Bank Name</td>
                                            <td>{dbData.bankName}</td>
                                            <td style={{ fontWeight: 'bold' }}>IFSC Code</td>
                                            <td>{dbData.bankIfsc}</td>
                                            <td style={{ fontWeight: 'bold' }}>Account No</td>
                                            <td>{dbData.bankAccountNo}</td>
                                        </tr>
                                        <tr>
                                            <td colspan={8} style={{ fontWeight: 'bold', textAlign: 'center' }}>Documents</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Aadhar Front</td>
                                            <td>
                                                <SRLWrapper>
                                                    <Image src={`https://bharatpharmadeliveryboy.s3.us-east-2.amazonaws.com/${dbData.aadharImgFront}`} style={{ width: '50px', height: '50px' }} />
                                                </SRLWrapper>
                                            </td>
                                            <td style={{ fontWeight: 'bold' }}>Aadhar Back</td>
                                            <td>
                                                <SRLWrapper>
                                                    <Image src={`https://bharatpharmadeliveryboy.s3.us-east-2.amazonaws.com/${dbData.aadharImgBack}`} style={{ width: '50px', height: '50px' }} />
                                                </SRLWrapper>
                                            </td>
                                            <td style={{ fontWeight: 'bold' }}>Pan Card</td>
                                            <td>
                                                <SRLWrapper>
                                                    <Image src={`https://bharatpharmadeliveryboy.s3.us-east-2.amazonaws.com/${dbData.panCardImg}`} style={{ width: '50px', height: '50px' }} />
                                                </SRLWrapper>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>


            <Modal show={showEdit} onHide={handleEditClose} size="lg">
                <Modal.Body>
                    <Container style={{ marginTop: '10px' }}>
                        <Row>
                            <Col>
                                <h1 style={{ fontFamily: 'DM sans', fontWeight: 'bold', textAlign: 'center', fontSize: '20px' }}>Edit Delivery Boy</h1>
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <h1 style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '15px' }}>Personal Details</h1>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="3">
                                            <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>First name</Form.Label>
                                            <Form.Control required type="text" placeholder="First name" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setName(e.target.value)} value={fName} />
                                        </Form.Group>
                                        <Form.Group as={Col} md="3">
                                            <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>Last name</Form.Label>
                                            <Form.Control required type="text" placeholder="Last name" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setLName(e.target.value)} value={lName} />
                                        </Form.Group>
                                        <Form.Group as={Col} md="3">
                                            <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>Contact</Form.Label>
                                            <Form.Control required type="number" placeholder="Contact" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setContact(e.target.value)} value={contact} />
                                        </Form.Group>
                                        <Form.Group as={Col} md="3">
                                            <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>Email</Form.Label>
                                            <Form.Control required type="email" placeholder="Email" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setEmail(e.target.value)} value={email} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="3">
                                            <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>Address</Form.Label>
                                            <Form.Control as="textarea" rows={1} placeholder="Address" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setAddress(e.target.value)} value={address} />
                                        </Form.Group>
                                        <Form.Group as={Col} md="3">
                                            <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>State</Form.Label>
                                            <Form.Control type="text" placeholder="State" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setState(e.target.value)} value={state} />
                                        </Form.Group>
                                        <Form.Group as={Col} md="3">
                                            <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>City</Form.Label>
                                            <Form.Control type="text" placeholder="City" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setCity(e.target.value)} value={city} />
                                        </Form.Group>
                                        <Form.Group as={Col} md="3">
                                            <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>Pincode</Form.Label>
                                            <Form.Control type="text" placeholder="Pincode" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setPincode(e.target.value)} value={pincode} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <h1 style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '15px' }}>Bank Details</h1>
                                        <Form.Group as={Col} md="3">
                                            <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>Bank Name</Form.Label>
                                            <Form.Control type="text" rows={1} placeholder="Bank Name" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setBankName(e.target.value)} value={bankName} />
                                        </Form.Group>
                                        <Form.Group as={Col} md="3">
                                            <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>IFSC Code</Form.Label>
                                            <Form.Control type="text" placeholder="IFSC Code" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setBankIfsc(e.target.value)} value={bankIfsc} />
                                        </Form.Group>
                                        <Form.Group as={Col} md="3">
                                            <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>Account No</Form.Label>
                                            <Form.Control type="text" placeholder="Account No" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setBankAccountNo(e.target.value)} value={bankAccountNo} />
                                        </Form.Group>
                                        <Form.Group as={Col} md="3">
                                            <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>Account Holder Name</Form.Label>
                                            <Form.Control type="text" placeholder="Account Holder Name" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setBankAccountHolderName(e.target.value)} value={bankAccountHolderName} />
                                        </Form.Group>
                                    </Row>
                                    {
                                        dbUpdateLoading ?
                                            <Button disabled size="sm" variant="outline-primary" className="mx-auto d-block">
                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                            </Button> :
                                            <Button type="submit" size="sm" variant="outline-primary" className="mx-auto d-block" style={{ fontFamily: 'DM sans', fontSize: '12px' }}>Add</Button>
                                    }
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </Container>
    )
}
