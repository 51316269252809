import { Col, Row, Button, Form, Card } from 'react-bootstrap'
import { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { MUTATION_ACTIVE_VENDOR } from '../../Queries'
import { GET_DOCUMENT_NAMES } from './FormQueries'
import swal from 'sweetalert'
import AWS from 'aws-sdk'
import { v4 } from 'uuid'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Spinner from '../../common/Spinner'
import CheckIcon from '../../common/CheckIcon'

function UploadDocuments() {
  const navigate = useNavigate()
  const [validatedStep4, setValidatedStep4] = useState(false)
  const [uploading, setUploading] = useState([])
  const vendorId = localStorage.getItem('vendorId')

  const { data } = useQuery(GET_DOCUMENT_NAMES, {
    variables: { vendorId },
  })

  const documentsData = data?.getVendorById

  const [vendorActiveMutation] = useMutation(MUTATION_ACTIVE_VENDOR)

  //handle step4
  const handleSubmitStep4 = async (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()

      swal({
        title: 'Successfull!',
        text: 'Your profile is in reviewed and active later by admin!',
        icon: 'success',
      }).then(() => window.location.reload())
    }

    setValidatedStep4(true)
  }

  const onChange = async (e) => {
    const s3 = new AWS.S3({
      region: 'us-west-2',
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      signatureVersion: 'v4',
    })

    const Key = v4()

    const params = {
      Bucket: 'myfirstawsbucketsoftseekers',
      Key,
      Expires: 60,
    }

    const uploadURL = await s3.getSignedUrlPromise('putObject', params)

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }

    const file = e.target.files[0]

    // If file is not selected
    if (!file) {
      return
    }

    setUploading(uploading.concat(e.target.name))
    axios.put(uploadURL, file, config).then(() => {
      vendorActiveMutation({
        variables: {
          vendorActiveInput: {
            vendorId,
            [e.target.name]: Key,
          },
        },
        refetchQueries: [
          { query: GET_DOCUMENT_NAMES },
          'DocumentsGetVendorById',
        ],
      })
      setUploading(uploading.splice(uploading.indexOf(e.target.id), 1))
    })
  }

  return (
    <>
      <Card style={{ marginTop: '10px', marginBottom: '20px' }}>
        <Card.Body>
          <Form
            noValidate
            validated={validatedStep4}
            onSubmit={handleSubmitStep4}
          >
            <h1
              style={{
                fontSize: '25px',
                fontFamily: 'DM Sans',
                fontWeight: 'bold',
              }}
            >
              Business Details
            </h1>
            <Row className='mb-3'>
              <Form.Group as={Col} md='6' controlId='validationCustom01'>
                <Form.Label className='formLabel1'>
                  GST Certificate
                  {uploading.some((a) => a === 'gstCertificateImg') ? (
                    <Spinner />
                  ) : (
                    documentsData['gstCertificateImg'] !== null && <CheckIcon />
                  )}
                </Form.Label>
                <Form.Control
                  required
                  type='file'
                  className='formInput1'
                  onChange={onChange}
                  name='gstCertificateImg'
                />
                <Form.Control.Feedback className='formGood1'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Upload GST Certificate.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md='6' controlId='validationCustom01'>
                <Form.Label className='formLabel1'>
                  PAN Card{' '}
                  {uploading.some((a) => a === 'panCardImg') ? (
                    <Spinner />
                  ) : (
                    documentsData['panCardImg'] !== null && <CheckIcon />
                  )}
                </Form.Label>
                <Form.Control
                  required
                  type='file'
                  className='formInput1'
                  onChange={onChange}
                  name='panCardImg'
                />
                <Form.Control.Feedback className='formGood1'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Upload PAN Card.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} md='6' controlId='validationCustom01'>
                <Form.Label className='formLabel1'>
                  Cancelled Cheque{' '}
                  {uploading.some((a) => a === 'cancelledChequeImg') ? (
                    <Spinner />
                  ) : (
                    documentsData['cancelledChequeImg'] !== null && (
                      <CheckIcon />
                    )
                  )}
                </Form.Label>
                <Form.Control
                  required
                  type='file'
                  className='formInput1'
                  onChange={onChange}
                  name='cancelledChequeImg'
                />
                <Form.Control.Feedback className='formGood1'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Upload Cancelled Cheque.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md='6' controlId='validationCustom01'>
                <Form.Label className='formLabel1'>
                  Drug Lisense{' '}
                  {uploading.some((a) => a === 'drugLicenseImg') ? (
                    <Spinner />
                  ) : (
                    documentsData['drugLicenseImg'] !== null && <CheckIcon />
                  )}
                </Form.Label>
                <Form.Control
                  required
                  type='file'
                  className='formInput1'
                  onChange={onChange}
                  name='drugLicenseImg'
                />
                <Form.Control.Feedback className='formGood1'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Upload Drug Lisense.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} md='6' controlId='validationCustom01'>
                <Form.Label className='formLabel1'>
                  Electricity Bill{' '}
                  {uploading.some((a) => a === 'electricityBillImg') ? (
                    <Spinner />
                  ) : (
                    documentsData['electricityBillImg'] !== null && (
                      <CheckIcon />
                    )
                  )}
                </Form.Label>
                <Form.Control
                  required
                  type='file'
                  className='formInput1'
                  onChange={onChange}
                  name='electricityBillImg'
                />
                <Form.Control.Feedback className='formGood1'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Upload Electricity Bill.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md='6' controlId='validationCustom01'>
                <Form.Label className='formLabel1'>
                  Shop Image{' '}
                  <span style={{ fontSize: '10px', color: 'red' }}>
                    (Medical should display){' '}
                  </span>
                  {uploading.some((a) => a === 'shopImage') ? (
                    <Spinner />
                  ) : (
                    documentsData['shopImage'] !== null && <CheckIcon />
                  )}
                </Form.Label>
                <Form.Control
                  required
                  type='file'
                  className='formInput1'
                  onChange={onChange}
                  name='shopImage'
                />
                <Form.Control.Feedback className='formGood1'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Upload Shop Image.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Col md={4}></Col>
              <Col md={2}>
                <Button
                  className='preBtn mx-auto d-block'
                  onClick={() => navigate('../business-details')}
                >
                  Previous
                </Button>
              </Col>
              <Col md={2}>
                <Button type='submit' className='nextBtn mx-auto d-block'>
                  Final Submit
                </Button>
              </Col>
              <Col md={4}></Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  )
}

export default UploadDocuments
