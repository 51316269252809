import { Col, Row, Button, Form, Card } from 'react-bootstrap'
import { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { MUTATION_ACTIVE_VENDOR } from '../../Queries'
import { GET_BANK_DETAILS } from './FormQueries'
import { useNavigate } from 'react-router-dom'

function BankDetails() {
  const navigate = useNavigate()
  const [validatedStep2, setValidatedStep2] = useState(false)
  const [showSubmitButton, setShowSubmitButton] = useState(true)
  const vendorId = localStorage.getItem('vendorId')

  const { data } = useQuery(GET_BANK_DETAILS, {
    variables: { vendorId },
  })

  const bankData = data?.getVendorById

  const [formData, setFormData] = useState({
    bankName: bankData?.bankName || '',
    bankAccountNo: bankData?.bankAccountNo || '',
    bankBeneficiaryName: bankData?.bankBeneficiaryName || '',
    bankIfsc: bankData?.bankIfsc || '',
    accountType: bankData?.accountType || '',
  })

  const {
    bankAccountNo,
    bankName,
    bankBeneficiaryName,
    bankIfsc,
    accountType,
  } = formData

  const [vendorActiveMutation] = useMutation(MUTATION_ACTIVE_VENDOR)

  //handle step2
  const handleSubmitStep2 = (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()

      vendorActiveMutation({
        variables: {
          vendorActiveInput: {
            vendorId,
            bankName,
            bankAccountNo,
            bankIfsc,
            bankBeneficiaryName,
            accountType,
          },
        },
        refetchQueries: [{ query: GET_BANK_DETAILS }, 'BankGetVendorById'],
      })
      setShowSubmitButton(false)
    }

    setValidatedStep2(true)
  }

  const onChange = (e) => {
    setFormData((previousState) => ({
      ...previousState,
      [e.target.name]: e.target.value,
    }))
    setShowSubmitButton(true)
  }

  return (
    <>
      <Card style={{ marginTop: '10px', marginBottom: '20px' }}>
        <Card.Body>
          <Form
            noValidate
            validated={validatedStep2}
            onSubmit={handleSubmitStep2}
          >
            <h1
              style={{
                fontSize: '25px',
                fontFamily: 'DM Sans',
                fontWeight: 'bold',
              }}
            >
              Bank Details
            </h1>
            <Row className='mb-3'>
              <Form.Group as={Col} md='6' controlId='validationCustom01'>
                <Form.Label className='formLabel1'>Bank Name</Form.Label>
                <Form.Control
                  required
                  type='text'
                  placeholder='Bank Name'
                  className='formInput1'
                  name='bankName'
                  onChange={onChange}
                  value={bankName}
                />
                <Form.Control.Feedback className='formGood1'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Enter Bank Name.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md='6'>
                <Form.Label className='formLabel1'>A/C Number</Form.Label>
                <Form.Control
                  required
                  type='text'
                  name='bankAccountNo'
                  placeholder='A/C Number'
                  className='formInput1'
                  onChange={onChange}
                  value={bankAccountNo}
                />
                <Form.Control.Feedback className='formGood1'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Enter A/C Number.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} md='6'>
                <Form.Label className='formLabel1'>IFSC Number</Form.Label>
                <Form.Control
                  required
                  type='text'
                  name='bankIfsc'
                  placeholder='IFSC Number'
                  className='formInput1'
                  onChange={onChange}
                  value={bankIfsc}
                />
                <Form.Control.Feedback className='formGood1'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Enter IFSC Number.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md='6'>
                <Form.Label className='formLabel1'>Beneficiary Name</Form.Label>
                <Form.Control
                  required
                  type='text'
                  name='bankBeneficiaryName'
                  placeholder='Benificery Name'
                  className='formInput1'
                  onChange={onChange}
                  value={bankBeneficiaryName}
                />
                <Form.Control.Feedback className='formGood1'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Enter Benificery Name.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} md='12'>
                <Form.Label className='formLabel1'>Type of Account</Form.Label>
                <Form.Control
                  required
                  as='select'
                  name='accountType'
                  className='formInput1'
                  onChange={onChange}
                  value={accountType}
                >
                  <option value='' selected='selected' disabled='disabled'>
                    Choose Type of Account
                  </option>
                  <option value='Saving'>Savings</option>
                  <option value='Current'>Current</option>
                </Form.Control>
                <Form.Control.Feedback className='formGood1'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Choose Type of Account.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Col md={4}></Col>
              <Col md={2}>
                <Button
                  className='preBtn mx-auto d-block'
                  onClick={() => navigate('../')}
                >
                  Previous
                </Button>
              </Col>
              <Col md={2}>
                {showSubmitButton ? (
                  <Button type='submit' className='nextBtn mx-auto d-block'>
                    Submit
                  </Button>
                ) : (
                  <Button
                    type='button'
                    className='nextBtn mx-auto d-block'
                    onClick={() => navigate('../business-details')}
                  >
                    Next
                  </Button>
                )}
              </Col>
              <Col md={4}></Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  )
}

export default BankDetails
