import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Table, Button, Spinner, Modal, Image } from 'react-bootstrap'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { QUERY_GET_ALL_USER, QUERY_GET_USER_COUNT, QUERY_GET_USERS, MUTATION_DELETE_USER } from '../Queries'
import { SRLWrapper } from "simple-react-lightbox";
import Moment from 'react-moment'
import ReactPagination from 'react-paginate';

const PAGE_SIZE = 50;

export default function AllUsers() {
    let count = 1
    const [page, setPage] = useState(0)

    const [deleteUser] = useMutation(MUTATION_DELETE_USER, {
        refetchQueries: [
            QUERY_GET_USERS
        ]
    });

    const { data: userCount, loading: userCountLoading } = useQuery(QUERY_GET_USER_COUNT)

    const [pageMed, { data: userData, loading: userLoading }] = useLazyQuery(QUERY_GET_USERS);

    const [show, setShow] = useState(false);
    const [viewData, setViewData] = useState();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function viewClick(data) {
        setViewData(data)
        handleShow()
    }

    useEffect(() => {
        pageMed({
            variables: {
                "first": PAGE_SIZE,
                "offset": page * PAGE_SIZE
            }
        })
    }, [])

    const handelPageClick = (data) => {
        pageMed({
            variables: {
                "first": PAGE_SIZE,
                "offset": data.selected * PAGE_SIZE
            }
        })
    }

    function deleteHandle(id) {
        deleteUser({
            variables: {
                "userId": `${id}`
            }
        })
    }
    console.log("count", userCount)
    return (
        <>
            <Container style={{ marginTop: '60px' }}>
                <Row>
                    <Col></Col>
                    <Col>
                        {
                            userCountLoading ?
                                <Spinner animation="border" role="status" className="mx-auto d-block">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner> :
                                <ReactPagination
                                    previousLabel={'previous'}
                                    nextLabel={'next'}
                                    breakLabel={'...'}
                                    pageCount={Math.round((userCount && userCount.getUserCount) / 50)}
                                    marginPagesDisplayed={4}
                                    pageRangeDisplayed={6}
                                    onPageChange={handelPageClick}
                                    className={'pagination'}
                                    pageLinkClassName={'page-link'}
                                    previousLinkClassName={'page-link'}
                                    nextLinkClassName={'page-link'}
                                    breakLinkClassName={'page-link'}
                                    activeLinkClassName={'page-item active'}
                                    activeClassName={'page-item active'}
                                />
                        }
                    </Col>
                    <Col></Col>
                </Row>

                <Row>
                    <Col>
                        <Table bordered style={{ fontFamily: 'DM Sans', fontSize: '12px' }} responsive>
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Full Name</th>
                                    <th>Contact</th>
                                    <th>Date/Time</th>
                                    <th>View</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    userLoading ?
                                        <Spinner animation="border" role="status" className="mx-auto d-block">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner> :

                                        userData && userData.getUsers.map(userData =>
                                            <tr>
                                                <td>{count++}</td>
                                                <td>{userData.fullName}</td>
                                                <td>{userData.contact}</td>
                                                <td><Moment format="DD/MM/YYYY, hh:mm a">{userData.createdDateTime}</Moment></td>
                                                <td><Button variant="outline-primary" size="sm" onClick={() => viewClick(userData)}>View</Button></td>
                                                <td><Button variant="outline-danger" size="sm" onClick={() => deleteHandle(userData.id)}>Delete</Button></td>
                                            </tr>
                                        )
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Body>
                    <h1 style={{ fontSize: '20px', fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'center' }}>User Detail</h1>
                    <Table bordered style={{ fontSize: '12px', fontFamily: 'DM Sans' }}>
                        <tbody>
                            <tr>
                                <td colSpan={6}>
                                    <SRLWrapper>
                                        <Image src={`https://bharatpharmamain.s3.us-east-2.amazonaws.com/${viewData && viewData.userImg}`} style={{ width: '150px', height: '150px' }} className="mx-auto d-block" />
                                    </SRLWrapper>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Full Name</td>
                                <td>{viewData && viewData.fullName}</td>
                                <td style={{ fontWeight: 'bold' }}>Contact</td>
                                <td>+91-{viewData && viewData.contact}</td>
                                <td style={{ fontWeight: 'bold' }}>Email</td>
                                <td>{viewData && viewData.email}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Address</td>
                                <td>{viewData && viewData.address}, {viewData && viewData.city}, {viewData && viewData.state}, {viewData && viewData.pincode}</td>
                                <td style={{ fontWeight: 'bold' }}>Registration Data/Time</td>
                                <td><Moment format="DD/MM/YYYY, hh:mm A">{viewData && viewData.registrationDateTime}</Moment></td>
                                <td style={{ fontWeight: 'bold' }}>Status</td>
                                <td>{viewData && viewData.status}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Age</td>
                                <td>{viewData && viewData.age}</td>
                                <td style={{ fontWeight: 'bold' }}>Gender</td>
                                <td>{viewData && viewData.gender}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        </>
    )
}
