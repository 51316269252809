import * as types from './actionTypes'

export const getCartTotal = () => ({
    type: types.GET_TOTALS, 
})

export const increase = (id) => ({
    type: types.INCREASE, 
    payload:id
})

export const decrease = (id) => ({
    type: types.DECREASE, 
    payload:id
})

export const remove = (id) => ({
    type: types.REMOVE, 
    payload:id
})

export const addItem = (itemData) => ({
    type: types.ADD, 
    payload:itemData
})

export const clearItem = () => ({
    type: types.CLEAR_ITEMS, 
})

export const getCart = (cartData) => ({
    type: types.GET_CART, 
    payload:cartData
})
