import React from 'react'
import { Container, Row, Col, Navbar, Nav, Card, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FaClock } from "react-icons/fa";

export default function PendingVendorBox() {
    return (
        <div>
            <Navbar bg="light" expand="lg">
                <Container fluid>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
                            <Link to="/" className="headerLinkDrop">Home</Link>
                            <Link to="/vendorLogout" className="headerLinkDrop">Logout</Link>
                        </Nav>

                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container>
                <Row>
                    <Col md={3}></Col>
                    <Col md={6} className='mx-auto d-block'>
                        <Card style={{ marginTop: '20px' }}>
                            <Card.Body>
                                <Alert variant='primary' style={{ textAlign: 'center', fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                                    <FaClock style={{ fontSize: '80px' }} />
                                    <p style={{ marginTop: '20px' }}>Your profile is <span style={{ color: '#e74c3c' }}>under review !!!</span></p>
                                    <p>Please wait <span style={{ color: '#27ae60' }}>24 hours.</span></p>
                                </Alert>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}></Col>
                </Row>
            </Container>
        </div>
    )
}

