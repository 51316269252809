import React, { useState } from 'react'
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap'
import { useMutation } from '@apollo/client'
import { MUTATION_ADD_DELIVERYBOY, QUERY_ALL_DELIVERYBOY } from '../../Queries'
import swal from 'sweetalert';
import AWS from 'aws-sdk';
import uniqid from 'uniqid';

const ID = 'AKIAYSBXIAITVZOA3FGO';
const SECRET = '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';
const BUCKET_NAME = 'bharatpharmadeliveryboy';

const s3 = new AWS.S3({
    accessKeyId: ID,
    secretAccessKey: SECRET,
});


export default function AddDeliveryBoy() {

    const [fName, setName] = useState('')
    const [lName, setLName] = useState('')
    const [contact, setContact] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [pincode, setPincode] = useState('')
    const [bankName, setBankName] = useState('')
    const [bankIfsc, setBankIfsc] = useState('')
    const [bankAccountNo, setBankAccountNo] = useState('')
    const [bankAccountHolderName, setBankAccountHolderName] = useState('')

    const [aadharFSelected, setAadharFSelected] = useState('')
    const [aadharFKey, setAadharFKey] = useState('')
    const [aadharFrontUploadLoading, setAadharFrontUploadLoading] = useState(false)
    const [aadharFrontUploadDone, setAadharFrontUploadDone] = useState(false)

    const [aadharBSelected, setAadharBSelected] = useState('')
    const [aadharBKey, setAadharBKey] = useState('')
    const [aadharBackUploadLoading, setAadharBackUploadLoading] = useState(false)
    const [aadharBackUploadDone, setAadharBackUploadDone] = useState(false)

    const [panCardSelected, setPanCardSelected] = useState('')
    const [panCardKey, setPanCardKey] = useState('')
    const [panCardUploadLoading, setPanCardUploadLoading] = useState(false)
    const [panCardUploadDone, setPanCardUploadDone] = useState(false)
    console.log('aadharBSelected', aadharBSelected.name)
    const [validated, setValidated] = useState(false);

    const [addDb, { loading }] = useMutation(MUTATION_ADD_DELIVERYBOY, {
        refetchQueries: [
            QUERY_ALL_DELIVERYBOY,
            'getDeliveryBoy',
        ],
    })

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setValidated(false);
            event.preventDefault();
            addDb({
                variables: {
                    "deliveryboyInput": {
                        "fName": `${fName}`,
                        "lName": `${lName}`,
                        "contact": `${contact}`,
                        "email": `${email}`,
                        "address": `${address}`,
                        "city": `${city}`,
                        "state": `${state}`,
                        "pincode": `${pincode}`,
                        "bankName": `${bankName}`,
                        "bankIfsc": `${bankIfsc}`,
                        "bankAccountNo": `${bankAccountNo}`,
                        "bankAccountHolderName": `${bankAccountHolderName}`,
                        "aadharImgFront": `${aadharFKey}`,
                        "aadharImgBack": `${aadharBKey}`,
                        "panCardImg": `${panCardKey}`,
                    }
                }
            })
            swal({ title: "Completed!", text: "Delivery Boy Added successfully!", icon: "success" }).then(() => {
                setName('');
                setLName('');
                setContact('');
                setEmail('');
                setAddress('');
                setCity('');
                setState('');
                setPincode('');
                setBankName('');
                setBankIfsc('');
                setBankAccountNo('');
                setBankAccountHolderName('');
                setAadharFrontUploadDone(false);
                setAadharBackUploadDone(false);
                setPanCardUploadDone(false);
                setAadharFKey('');
                setAadharBKey('');
                setPanCardKey('');
            })
        }

    };


    const handleAadharFrontImage = (e) => {
        try {
            setAadharFrontUploadLoading(true)
            const uniqueId = uniqid();
            var regex = new RegExp('[^.]+$');
            var extension = aadharFSelected.name.match(regex);
            var uniqueFileName = "DBAadahrFront" + '-' + uniqueId + '-' + uniqueId + '.' + extension[0];
            const params = {
                Bucket: BUCKET_NAME,
                Key: uniqueFileName,
                Body: aadharFSelected,
            };
            s3.upload(params, function (err, data) {
                if (err) {
                    throw err;
                } else {
                    setAadharFKey(uniqueFileName)
                    swal({
                        title: 'Successfull!!!',
                        text: 'Aadhar Front Uploaded Successfully',
                        icon: 'success',
                    });
                    setAadharFSelected('')
                    setAadharFrontUploadLoading(false)
                    setAadharFrontUploadDone(true)
                }
            });
        } catch (err) {
            swal({
                title: 'Error!!!',
                text: 'Error please try again',
                icon: 'error',
            });
            console.log(err);
            setAadharFrontUploadLoading(false)
        }

    };
    const handleAadharBackImage = (e) => {
        try {
            setAadharBackUploadLoading(true)
            const uniqueId = uniqid();
            var regex = new RegExp('[^.]+$');
            var extension = aadharBSelected.name.match(regex);
            var uniqueFileName = "DBAadahrBack" + '-' + uniqueId + '-' + uniqueId + '.' + extension[0];
            const params = {
                Bucket: BUCKET_NAME,
                Key: uniqueFileName,
                Body: aadharBSelected,
            };
            s3.upload(params, function (err, data) {
                if (err) {
                    throw err;
                } else {
                    setAadharBKey(uniqueFileName)
                    swal({
                        title: 'Successfull!!!',
                        text: 'Aadhar Back Uploaded Successfully',
                        icon: 'success',
                    });
                    setAadharBSelected('')
                    setAadharBackUploadLoading(false)
                    setAadharBackUploadDone(true)
                }
            });
        } catch (err) {
            swal({
                title: 'Error!!!',
                text: 'Error please try again',
                icon: 'error',
            });
            console.log(err);
            setAadharBackUploadLoading(false)
        }

    };
    const handlePanCardImage = (e) => {
        try {
            setPanCardUploadLoading(true)
            const uniqueId = uniqid();
            var regex = new RegExp('[^.]+$');
            var extension = panCardSelected.name.match(regex);
            var uniqueFileName = "DBPanCard" + '-' + uniqueId + '-' + uniqueId + '.' + extension[0];
            const params = {
                Bucket: BUCKET_NAME,
                Key: uniqueFileName,
                Body: panCardSelected,
            };
            s3.upload(params, function (err, data) {
                if (err) {
                    throw err;
                } else {
                    setPanCardKey(uniqueFileName)
                    swal({
                        title: 'Successfull!!!',
                        text: 'Pan Card Uploaded Successfully',
                        icon: 'success',
                    });
                    setPanCardSelected('')
                    setPanCardUploadLoading(false)
                    setPanCardUploadDone(true)
                }
            });
        } catch (err) {
            swal({
                title: 'Error!!!',
                text: 'Error please try again',
                icon: 'error',
            });
            console.log(err);
            setPanCardUploadLoading(false)
        }

    };

    return (
        <Container style={{ marginTop: '50px' }}>
            <Row>
                <Col>
                    <h1 style={{ fontFamily: 'DM sans', fontWeight: 'bold', textAlign: 'center', fontSize: '20px' }}>Add Delivery Boy</h1>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <h1 style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '15px' }}>Personal Details</h1>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="3">
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>First name</Form.Label>
                                <Form.Control required type="text" placeholder="First name" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setName(e.target.value)} value={fName} />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>Last name</Form.Label>
                                <Form.Control required type="text" placeholder="Last name" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setLName(e.target.value)} value={lName} />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>Contact</Form.Label>
                                <Form.Control required type="number" placeholder="Contact" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setContact(e.target.value)} value={contact} />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>Email</Form.Label>
                                <Form.Control required type="email" placeholder="Email" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setEmail(e.target.value)} value={email} />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} md="3">
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>Address</Form.Label>
                                <Form.Control as="textarea" rows={1} placeholder="Address" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setAddress(e.target.value)} value={address} />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>State</Form.Label>
                                <Form.Control type="text" placeholder="State" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setState(e.target.value)} value={state} />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>City</Form.Label>
                                <Form.Control type="text" placeholder="City" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setCity(e.target.value)} value={city} />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>Pincode</Form.Label>
                                <Form.Control type="text" placeholder="Pincode" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setPincode(e.target.value)} value={pincode} />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <h1 style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '15px' }}>Bank Details</h1>
                            <Form.Group as={Col} md="3">
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>Bank Name</Form.Label>
                                <Form.Control type="text" rows={1} placeholder="Bank Name" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setBankName(e.target.value)} value={bankName} />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>IFSC Code</Form.Label>
                                <Form.Control type="text" placeholder="IFSC Code" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setBankIfsc(e.target.value)} value={bankIfsc} />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>Account No</Form.Label>
                                <Form.Control type="text" placeholder="Account No" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setBankAccountNo(e.target.value)} value={bankAccountNo} />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>Account Holder Name</Form.Label>
                                <Form.Control type="text" placeholder="Account Holder Name" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setBankAccountHolderName(e.target.value)} value={bankAccountHolderName} />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <h1 style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '15px' }}>Upload Documents</h1>
                            <Form.Group as={Col} md="3">
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>Aadhar Card Front</Form.Label>
                                <Form.Control required type="file" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setAadharFSelected(e.target.files[0])} accept="image/*" />
                                {
                                    aadharFrontUploadDone ?
                                        <h1 style={{ fontSize: '12px', fontFamily: 'DM Sans', color: 'green' }}>Aadhar Front Uploaded Successfully</h1> : ""
                                }
                                {
                                    aadharFrontUploadLoading ?
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner> :
                                        aadharFSelected === '' ?
                                            <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }} disabled>Upload</Button> :
                                            <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }} onClick={() => handleAadharFrontImage()}>Upload</Button>
                                }
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>Aadhar Card Back</Form.Label>
                                <Form.Control required type="file" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setAadharBSelected(e.target.files[0])} accept="image/*" />
                                {
                                    aadharBackUploadDone ?
                                        <h1 style={{ fontSize: '12px', fontFamily: 'DM Sans', color: 'green' }}>Aadhar Front Uploaded Successfully</h1> : ""
                                }
                                {
                                    aadharBackUploadLoading ?
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner> :
                                        aadharBSelected === '' ?
                                            <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }} disabled>Upload</Button> :
                                            <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }} onClick={() => handleAadharBackImage()}>Upload</Button>
                                }
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '12px' }}>Pan Card</Form.Label>
                                <Form.Control required type="file" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setPanCardSelected(e.target.files[0])} accept="image/*" />
                                {
                                    panCardUploadDone ?
                                        <h1 style={{ fontSize: '12px', fontFamily: 'DM Sans', color: 'green' }}>Aadhar Front Uploaded Successfully</h1> : ""
                                }
                                {
                                    panCardUploadLoading ?
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner> :
                                        panCardSelected === '' ?
                                            <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }} disabled>Upload</Button> :
                                            <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }} onClick={() => handlePanCardImage()}>Upload</Button>
                                }
                            </Form.Group>
                        </Row>
                        {
                            loading ?
                                <Button disabled size="sm" variant="outline-primary" className="mx-auto d-block">
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                </Button> :
                                <Button type="submit" size="sm" variant="outline-primary" className="mx-auto d-block" style={{ fontFamily: 'DM sans', fontSize: '12px' }}>Add</Button>
                        }
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}
