import React, { useState } from 'react'
import { Table, Button, Spinner, Modal, Col, Row, Container } from 'react-bootstrap'
import './css/pendingVendor.css'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { QUERY_PENDING_VENDORS, QUERY_VENDOR_BY_ID, MUTATION_ACTIVE_VENDOR_ADMIN, QUERY_ACTIVE_VENDORS } from '../Queries'
import swal from 'sweetalert';

export default function PendingVendor() {

    const [vendorIdState, setVendorIdState] = useState('')

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showConfirm, setShowConfirm] = useState(false);
    const handleCloseConfirm = () => setShowConfirm(false);
    const handleShowConfirm = () => setShowConfirm(true);

    const { data: pendingVendorData, loading: pendingVendorLoading } = useQuery(QUERY_PENDING_VENDORS)

    const [vendorByIdLazy, { data: getVendorByIdLazyData, loading: getVendorByIdLazyLoading }] = useLazyQuery(QUERY_VENDOR_BY_ID);

    const [activeVendorMutation] = useMutation(MUTATION_ACTIVE_VENDOR_ADMIN, {
        refetchQueries: [
            QUERY_PENDING_VENDORS,
            'getPendingVendors',
            QUERY_ACTIVE_VENDORS,
            'getActiveVendors',
        ],
    });

    function ViewHandle(vendorId) {
        vendorByIdLazy({ variables: { vendorId: `${vendorId}` } })
        handleShow();
    }

    function ConfirmBoxHandle(vendorId) {
        handleShowConfirm()
        setVendorIdState(vendorId)
    }

    function activeHandle() {
        activeVendorMutation({
            variables: {
                vendorId:`${vendorIdState}`
            }
        })
        handleCloseConfirm()
        swal({ title: "Completed!", text: "Vendor Active successfully!", icon: "success" })
    }

    console.log(pendingVendorData)
    return (
        <div style={{ marginTop: '100px' }}>
            <Table bordered hover responsive>
                <thead>
                    <tr style={{ fontSize: '15px', fontFamily: 'DM Sans' }}>
                        <th>Name</th>
                        <th>Medical Name</th>
                        <th>Drug Lisense No</th>
                        <th>View</th>
                        <th>Active</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        pendingVendorLoading ?
                            <Spinner animation="border" role="status" className='mx-auto d-block'>
                                <span className="visually-hidden">Loading...</span>
                            </Spinner> :
                            pendingVendorData && pendingVendorData.getPendingVendors.map(pendingData =>
                                <tr style={{ fontSize: '15px', fontFamily: 'DM Sans' }}>
                                    <td>{pendingData.fullName}</td>
                                    <td>{pendingData.companyName}</td>
                                    <td>{pendingData.drugLicenseNo}</td>
                                    <td><Button className="adminViewBtn" onClick={() => ViewHandle(pendingData.id)}>View</Button></td>
                                    <td><Button className="adminActiveBtn" onClick={() => ConfirmBoxHandle(pendingData.id)}>Active</Button></td>
                                </tr>
                            )

                    }
                </tbody>
            </Table>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    {
                        getVendorByIdLazyLoading ?
                            <Spinner animation="border" role="status" className='mx-auto d-block'>
                                <span className="visually-hidden">Loading...</span>
                            </Spinner> :
                            <Container>
                                <Row>
                                    <Col>
                                        <h1 style={{ fontSize: '20px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>Company Details</h1>
                                        <Table responsive>
                                            <tbody style={{ fontSize: '12px', fontFamily: 'DM Sans' }}>
                                                <tr>
                                                    <td><strong>Mobile</strong></td>
                                                    <td>{getVendorByIdLazyData && getVendorByIdLazyData.getVendorById.contact}</td>
                                                    <td><strong>Full Name</strong></td>
                                                    <td>{getVendorByIdLazyData && getVendorByIdLazyData.getVendorById.fullName}</td>
                                                    <td><strong>Email</strong></td>
                                                    <td>{getVendorByIdLazyData && getVendorByIdLazyData.getVendorById.Email}</td>
                                                    <td><strong>Company Name</strong></td>
                                                    <td>{getVendorByIdLazyData && getVendorByIdLazyData.getVendorById.companyName}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h1 style={{ fontSize: '20px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>Company Address</h1>
                                        <Table responsive>
                                            <tbody style={{ fontSize: '12px', fontFamily: 'DM Sans' }}>
                                                <tr>
                                                    <td><strong>Address</strong></td>
                                                    <td>{getVendorByIdLazyData && getVendorByIdLazyData.getVendorById.companyAddress}</td>
                                                    <td><strong>Pincode</strong></td>
                                                    <td>{getVendorByIdLazyData && getVendorByIdLazyData.getVendorById.companyPincode}</td>
                                                    <td><strong>City</strong></td>
                                                    <td>{getVendorByIdLazyData && getVendorByIdLazyData.getVendorById.companyCity}</td>
                                                    <td><strong>Country</strong></td>
                                                    <td>{getVendorByIdLazyData && getVendorByIdLazyData.getVendorById.companyCountry}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h1 style={{ fontSize: '20px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>Bank Details</h1>
                                        <Table responsive>
                                            <tbody style={{ fontSize: '12px', fontFamily: 'DM Sans' }}>
                                                <tr>
                                                    <td><strong>Bank Name</strong></td>
                                                    <td>{getVendorByIdLazyData && getVendorByIdLazyData.getVendorById.bankName}</td>
                                                    <td><strong>A/C No.</strong></td>
                                                    <td>{getVendorByIdLazyData && getVendorByIdLazyData.getVendorById.bankAccountNo}</td>
                                                    <td><strong>IFSC No.</strong></td>
                                                    <td>{getVendorByIdLazyData && getVendorByIdLazyData.getVendorById.bankIfsc}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Benificery Name</strong></td>
                                                    <td>{getVendorByIdLazyData && getVendorByIdLazyData.getVendorById.bankBeneficiaryName}</td>
                                                    <td><strong>Type of Account</strong></td>
                                                    <td>{getVendorByIdLazyData && getVendorByIdLazyData.getVendorById.accountType}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h1 style={{ fontSize: '20px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>Business Details</h1>
                                        <Table responsive>
                                            <tbody style={{ fontSize: '12px', fontFamily: 'DM Sans' }}>
                                                <tr>
                                                    <td><strong>City where medical located</strong></td>
                                                    <td>{getVendorByIdLazyData && getVendorByIdLazyData.getVendorById.cityMedicalLocate}</td>
                                                    <td><strong>Total years of pharmacy in business</strong></td>
                                                    <td>{getVendorByIdLazyData && getVendorByIdLazyData.getVendorById.totalYearInBusiness}</td>
                                                    <td><strong>Cold Storage Facility</strong></td>
                                                    <td>{getVendorByIdLazyData && getVendorByIdLazyData.getVendorById.coldStorage}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Type of Partner</strong></td>
                                                    <td>{getVendorByIdLazyData && getVendorByIdLazyData.getVendorById.typeOfParter}</td>
                                                    <td><strong>Power Backup</strong></td>
                                                    <td>{getVendorByIdLazyData && getVendorByIdLazyData.getVendorById.powerBackup}</td>
                                                    <td><strong>Drug Lisense No.</strong></td>
                                                    <td>{getVendorByIdLazyData && getVendorByIdLazyData.getVendorById.drugLicenseNo}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Container>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showConfirm} onHide={handleCloseConfirm} centered>

                <Modal.Body>
                    <h1 style={{ fontSize: '25px', textAlign: 'center', fontFamily: 'DM Sans', fontWeight: 'bold' }}>Are you sure to Active this Vendor ?</h1>
                    <Container style={{ marginTop: '30px' }}>
                        <Row>
                            <Col><Button className='mx-auto d-block' variant='info' style={{ fontSize: '12px', fontFamily: 'DM Sans', width: '100px' }} onClick={activeHandle}>Yes</Button></Col>
                            <Col><Button className='mx-auto d-block' variant='warning' style={{ fontSize: '12px', fontFamily: 'DM Sans', width: '100px' }} onClick={handleCloseConfirm}>No</Button></Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

        </div>
    )
}
