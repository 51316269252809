import React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import MedicineCatagory from './allProducts/MedicineCatagory'
import CosmeticCatagory from './CosmeticCatagory'
import SurgicalCatagory from './SurgicalCatagory'
import BabyCare from './allProducts/BabyCareCatagory'

export default function Products() {
    return (
        <>
            <Tabs defaultActiveKey="medicine" id="uncontrolled-tab-example" className="mb-3" style={{ marginTop: '80px', fontSize: '12px' }} >
                <Tab eventKey="covid" title="Covid-19">
                    <Test />
                </Tab>
                <Tab eventKey="cosmetic" title="Cosmetic">
                    <CosmeticCatagory />
                </Tab>
                <Tab eventKey="surgical" title="Surgical">
                    <Test />
                </Tab>
                <Tab eventKey="fitness" title="Fitness">
                    <Test />
                </Tab>
                <Tab eventKey="babyCare" title="Baby Care">
                    <BabyCare />
                </Tab>
                <Tab eventKey="devices" title="Devices">
                    <Test />
                </Tab>
                <Tab eventKey="sexualWellness" title="Sexual Wellness">
                    <Test />
                </Tab>
                <Tab eventKey="ayush" title="Ayush">
                    <Test />
                </Tab>
                <Tab eventKey="womenCorner" title="Women Corner">
                    <Test />
                </Tab>
                <Tab eventKey="medicine" title="Medicine">
                    <MedicineCatagory />
                </Tab>
                <Tab eventKey="diabetes" title="Diabetes">
                    <Test />
                </Tab>

            </Tabs>
        </>
    )
}

function Test() {
    return (
        <h1 style={{ fontSize: '20px', fontFamily: 'DM Sans', fontWeight: 'bold', color: '#0984e3' }}>Under Maintenance </h1>
    )
}