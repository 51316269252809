import React from 'react'
import './componentCss/Carosuel_Header.css'
import { Container, Col, Row, Carousel, Card, Image } from 'react-bootstrap'
import freeShiping from '../img/freeShiping.png'
import freeTreat from '../img/freeTreat.png'
import shopLocator from '../img/shopLocator.png'
import shopWithSecure from '../img/shopWithSecurity.png'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function Carosuel_Header() {

  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div>
      <Container fluid>
        <Carousel>
          <Carousel.Item>
            <img className="d-block w-100" src="https://demo.wpthemego.com/themes/sw_mallon/wp-content/uploads/2021/06/slider-22.jpg" alt="First slide" style={{ height: '250px' }} />
            <Carousel.Caption>
              <Row >
                <Col sm={6} >
                  {/* <p style={{fontFamily:'DM Sans', fontSize:'50px', fontWeight:'bold', color:'#fff'}}>Save 25% OFF <br/> This Weekend </p> */}
                </Col>
              </Row>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src="https://demo.wpthemego.com/themes/sw_mallon/wp-content/uploads/2021/06/slider-21.jpg" alt="Second slide" style={{ height: '250px' }} />
            <Carousel.Caption>
              <Row>
                <Col sm={6} >
                  {/* <p style={{fontFamily:'DM Sans', fontSize:'15px', fontWeight:'bold', color:'#fff', textAlign:'left'}}>This Session Greetings</p>
      <p style={{fontFamily:'DM Sans', fontSize:'50px', fontWeight:'bold', color:'#fff',textAlign:'left'}}>Save 25% OFF</p>
      <p style={{fontFamily:'DM Sans', fontSize:'50px', fontWeight:'bold', color:'#fff',textAlign:'left', marginTop:'-25px'}}>This Weekend</p>
      <p style={{fontFamily:'DM Sans', fontSize:'15px', fontWeight:'bold', color:'#fff',textAlign:'left'}}>Pure & Natural Eucalyptus Oil</p>
       */}
                </Col>
              </Row>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </Container>
      <Container fluid>
        <Container fluid style={{ marginTop: '30px' }} >
          <Row style={{ justifyContent: 'center' }}>
            <Col md={3} xs={6}>
              <Card className="feature_card d-none d-lg-block" style={{ textAlign: 'center', marginTop: '10px' }}>
                <Card.Body style={{ width: '100%', height: '80px' }}>
                  <Row>
                    <Col sm={3} xs={3}>
                      <div style={{ textAlign: 'left' }}>
                        <Image src={freeShiping} style={{ width: '50px', height: '50px' }} />
                      </div>
                    </Col>
                    <Col sm={9} xs={9} style={{ textAlign: 'left' }}>
                      <p style={{ color: '#2c3e50', fontSize: '14px', fontWeight: 'bold', fontFamily: 'Dm Sans' }}>Free Shipping </p>
                      <p style={{ color: '#1abc9c', fontSize: '12px', fontWeight: 'bold', fontFamily: 'Dm Sans', marginTop: '-15px' }}> above 250/-</p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} xs={6}>
              <Card className="feature_card d-none d-lg-block" style={{ textAlign: 'center', marginTop: '10px' }}>
                <Card.Body style={{ width: '100%', height: '80px' }}>
                  <Row>
                    <Col sm={3} xs={3} className="d-none d-lg-block">
                      <div style={{ textAlign: 'left' }}>
                        <Image src={shopWithSecure} style={{ width: '60px', height: '60px' }} />
                      </div>
                    </Col>
                    <Col sm={9} xs={9} style={{ textAlign: 'left' }}>
                      <p style={{ color: '#2c3e50', fontSize: '14px', fontWeight: 'bold', fontFamily: 'Dm Sans' }}>Security</p>
                      <p style={{ color: '#1abc9c', fontSize: '12px', fontWeight: 'bold', fontFamily: 'Dm Sans', marginTop: '-15px' }}>100% secure</p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} xs={6}>
              <Card className="feature_card d-none d-lg-block" style={{ textAlign: 'center', marginTop: '10px' }}>
                <Card.Body style={{ width: '100%', height: '80px' }}>
                  <Row>
                    <Col sm={3} className="d-none d-lg-block">
                      <div style={{ textAlign: 'left' }}>
                        <Image src={freeTreat} style={{ width: '60px', height: '60px' }} />
                      </div>
                    </Col>
                    <Col sm={9} xs={12} style={{ textAlign: 'left' }}>
                      <p style={{ color: '#2c3e50', fontSize: '14px', fontWeight: 'bold', fontFamily: 'Dm Sans' }}> Free Treat </p>
                      <p style={{ color: '#1abc9c', fontSize: '12px', fontWeight: 'bold', fontFamily: 'Dm Sans', marginTop: '-15px' }}>Treat with order</p>

                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} xs={6}>
              <Card className="feature_card d-none d-lg-block" style={{ textAlign: 'center', marginTop: '10px' }}>
                <Card.Body style={{ width: '100%', height: '80px' }}>
                  <Row>
                    <Col sm={3} className="d-none d-lg-block">
                      <div style={{ textAlign: 'left' }}>
                        <Image src={shopLocator} style={{ width: '50px', height: '50px' }} />
                      </div>
                    </Col>
                    <Col sm={9} style={{ textAlign: 'left' }}>
                      <p style={{ color: '#2c3e50', fontSize: '14px', fontWeight: 'bold', fontFamily: 'Dm Sans' }}> Store Locator </p>
                      <p style={{ color: '#1abc9c', fontSize: '12px', fontWeight: 'bold', fontFamily: 'Dm Sans', marginTop: '-15px' }}>Shop Near You</p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>

  )
}
