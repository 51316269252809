import React, { useState } from 'react'
import Top_Header from './Top_Header'
import Navigation from './Navigation'
import { Container, Col, Row, Form, Button, Spinner } from 'react-bootstrap'
import { useMutation } from '@apollo/client'
import { MUTATION_CREATE_CONTACT_US, QUERY_GET_CONTACT } from './Queries'
import { useNavigate } from 'react-router-dom'
import swal from 'sweetalert';
import Footer from './Footer'
import { FaPencilAlt, FaCheckDouble, FaSearchLocation } from "react-icons/fa";

export default function ContactUs() {

    let navigate = useNavigate();

    const [createContact, { loading }] = useMutation(MUTATION_CREATE_CONTACT_US, {
        refetchQueries: [
            QUERY_GET_CONTACT
        ]
    });

    const [spin, setSpin] = useState(false);
    const [fullname, setfullname] = useState('');
    const [contact, setContact] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setsubject] = useState('');
    const [message, setmessage] = useState('');
    const [validated, setValidated] = useState(false);



    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setValidated(false);
            event.preventDefault();
            createContact({
                variables: {
                    "contactInput": {
                        "fullName": `${fullname}`,
                        "contact": `${contact}`,
                        "email": `${email}`,
                        "subject": `${subject}`,
                        "message": `${message}`
                    }
                }
            })
            swal({ title: "Successfully!", text: "Sent Successfully!", icon: "success" });
            navigate("/")
        }

    };

    return (
        <>
            {
                window.scrollTo(0, 0)
            }
            <Top_Header />
            <Navigation />
            <Container>
                <Row>
                    <Col md={6}>
                        <h1 style={{ fontSize: '25px', fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'center', marginTop: '20px' }}>CONTACT US</h1>
                        <Form noValidate validated={validated} onSubmit={handleSubmit} className="mx-auto d-block"  >

                            <Form.Group className="mx-auto d-block" as={Col} md="12" >
                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>Full Name</Form.Label>
                                <Form.Control required type="text" placeholder="Full Name" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={e => setfullname(e.target.value)} value={fullname} />
                            </Form.Group>

                            <Form.Group className="mx-auto d-block" as={Col} md="12">
                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>Email</Form.Label>
                                <Form.Control required type="email" placeholder="Email" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={e => setEmail(e.target.value)} value={email} />
                            </Form.Group>

                            <Form.Group className="mx-auto d-block" as={Col} md="12">
                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>Contact</Form.Label>
                                <Form.Control required type="number" placeholder="Contact" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={e => setContact(e.target.value)} value={contact} />
                            </Form.Group>

                            <Form.Group className="mx-auto d-block" as={Col} md="12">
                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>Subject</Form.Label>
                                <Form.Control required type="text" placeholder="Subject" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={e => setmessage(e.target.value)} value={message} />
                            </Form.Group>

                            <Form.Group className="mx-auto d-block" as={Col} md="12">
                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>Message</Form.Label>
                                <Form.Control required as="textarea" placeholder="Message" rows={1} style={{ fontFamily: 'DM Sans', fontSize: '12px', height: "90px" }} onChange={e => setsubject(e.target.value)} value={subject} />
                            </Form.Group>
                            <br></br>
                            {
                                loading ?
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner> :
                                    spin ?
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner> :
                                        <Button className="mx-auto d-block" type="submit" style={{ fontFamily: 'DM Sans', borderRadius: '0px', background: '#2B97A4', borderColor: '#2B97A4' }}>Submit</Button>
                            }
                        </Form>
                    </Col>

                    <Col md={6} style={{ borderLeft: '1px solid #95a5a6' }}>
                        <h1 style={{ fontSize: '25px', fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'center', marginTop: '20px' }}>Corporate Details</h1>
                        <h1 style={{ fontFamily: 'DM Sans', fontSize: 15, fontWeight: 'bold', lineHeight: 2 }}><FaPencilAlt style={{ marginLeft: 20, marginRight: 10, color: '#2B97A4' }} /> Mail us at: <span style={{ fontWeight: 'normal' }}>nanopharmhealthcaresolution@gmail.com</span></h1>
                        <h1 style={{ fontFamily: 'DM Sans', fontSize: 15, fontWeight: 'bold', lineHeight: 2 }}><FaCheckDouble style={{ marginLeft: 20, marginRight: 10, color: '#2B97A4' }} /> CIN: <span style={{ fontWeight: 'normal' }}>U24246MP2022PTC059791</span></h1>
                        <h1 style={{ fontFamily: 'DM Sans', fontSize: 15, fontWeight: 'bold', lineHeight: 2 }}><FaSearchLocation style={{ marginLeft: 20, marginRight: 10, color: '#2B97A4' }} /> Our Location: </h1>
                        <h1 style={{ fontFamily: 'DM Sans', fontSize: 15, lineHeight: 1, marginLeft: 50 }}>Registered Address: </h1>
                        <h1 style={{ fontFamily: 'DM Sans', fontSize: 14, lineHeight: 0.5, marginLeft: 50 }}>216 Near Bhuteshwar Mandir Sant Ravidas Ward Sagar, 470002 (M.P.)</h1>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}
