import { Col, Row, Button, Form, Card } from 'react-bootstrap'
import { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { MUTATION_ACTIVE_VENDOR } from '../../Queries'
import { GET_BUSINESS_DETAILS } from './FormQueries'
import { useNavigate } from 'react-router-dom'

function BusinessDetails() {
  const navigate = useNavigate()
  const [validatedStep3, setValidatedStep3] = useState(false)
  const [showSubmitButton, setShowSubmitButton] = useState(true)
  const vendorId = localStorage.getItem('vendorId')
  const { data } = useQuery(GET_BUSINESS_DETAILS, {
    variables: { vendorId },
  })

  const businessData = data.getVendorById

  const [formData, setFormData] = useState({
    cityMedicalLocate: businessData.cityMedicalLocate || '',
    totalYearInBusiness: businessData.totalYearInBusiness || '',
    coldStorage: businessData.coldStorage || '',
    typeOfParter: businessData.typeOfParter || '',
    powerBackup: businessData.powerBackup || '',
    drugLicenseNo: businessData.drugLicenseNo || '',
    typeOfBusiness: businessData.typeOfBusiness || '',
  })

  const {
    cityMedicalLocate,
    totalYearInBusiness,
    coldStorage,
    typeOfParter,
    powerBackup,
    drugLicenseNo,
    typeOfBusiness,
  } = formData

  const [vendorActiveMutation] = useMutation(MUTATION_ACTIVE_VENDOR)

  //handle step3
  const handleSubmitStep3 = (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()
      vendorActiveMutation({
        variables: {
          vendorActiveInput: {
            vendorId,
            cityMedicalLocate,
            totalYearInBusiness,
            coldStorage,
            typeOfParter,
            powerBackup,
            drugLicenseNo,
            typeOfBusiness,
          },
        },
        refetchQueries: [
          { query: GET_BUSINESS_DETAILS },
          'BusinessGetVendorById',
        ],
      })
      setShowSubmitButton(false)
    }

    setValidatedStep3(true)
  }

  const onChange = (e) => {
    setFormData((previousState) => ({
      ...previousState,
      [e.target.name]: e.target.value,
    }))
    setShowSubmitButton(true)
  }

  return (
    <>
      <Card style={{ marginTop: '10px', marginBottom: '20px' }}>
        <Card.Body>
          <Form
            noValidate
            validated={validatedStep3}
            onSubmit={handleSubmitStep3}
          >
            <h1
              style={{
                fontSize: '25px',
                fontFamily: 'DM Sans',
                fontWeight: 'bold',
              }}
            >
              Business Details
            </h1>
            <Row className='mb-3'>
              <Form.Group as={Col} md='6' controlId='validationCustom01'>
                <Form.Label className='formLabel1'>
                  City where medical located
                </Form.Label>
                <Form.Control
                  required
                  type='text'
                  name='cityMedicalLocate'
                  placeholder='City where medical located'
                  className='formInput1'
                  onChange={onChange}
                  value={cityMedicalLocate}
                />
                <Form.Control.Feedback className='formGood1'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Enter City where medical located.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md='6'>
                <Form.Label className='formLabel1'>
                  Total years of pharmacy in business
                </Form.Label>
                <Form.Control
                  required
                  type='text'
                  name='totalYearInBusiness'
                  placeholder='Total years of pharmacy in business'
                  className='formInput1'
                  onChange={onChange}
                  value={totalYearInBusiness}
                />
                <Form.Control.Feedback className='formGood1'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Enter Total years of pharmacy in business.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} md='6'>
                <Form.Label className='formLabel1'>
                  Cold Storage Facility
                </Form.Label>
                <Form.Control
                  required
                  as='select'
                  name='coldStorage'
                  className='formInput1'
                  onChange={onChange}
                  value={coldStorage}
                >
                  <option value='' selected='selected' disabled='disabled'>
                    Choose Cold Storage Facility
                  </option>
                  <option value='Yes'>Yes</option>
                  <option value='No'>No</option>
                </Form.Control>
                <Form.Control.Feedback className='formGood1'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Enter Cold Storage Facility.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md='6'>
                <Form.Label className='formLabel1'>Type of Partner</Form.Label>
                <Form.Control
                  required
                  as='select'
                  name='typeOfParter'
                  className='formInput1'
                  onChange={onChange}
                  value={typeOfParter}
                >
                  <option value='' selected='selected' disabled='disabled'>
                    Choose Type of Partner
                  </option>
                  <option value='Propritor'>Proprietor</option>
                  <option value='Company'>Company</option>
                  <option value='HUF'>HUF</option>
                  <option value='Partnership'>Partnership</option>
                </Form.Control>
                <Form.Control.Feedback className='formGood1'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Enter Type of Partner.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} md='6'>
                <Form.Label className='formLabel1'>Power Backup</Form.Label>
                <Form.Control
                  required
                  as='select'
                  name='powerBackup'
                  className='formInput1'
                  onChange={onChange}
                  value={powerBackup}
                >
                  <option value='' selected='selected' disabled='disabled'>
                    Choose Power Backup
                  </option>
                  <option value='Yes'>Yes</option>
                  <option value='No'>No</option>
                </Form.Control>
                <Form.Control.Feedback className='formGood1'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Enter Power Backup.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md='6'>
                <Form.Label className='formLabel1'>
                  Drug Lisense Number
                </Form.Label>
                <Form.Control
                  required
                  type='text'
                  name='drugLicenseNo'
                  placeholder='Drug Lisense Number'
                  className='formInput1'
                  onChange={onChange}
                  value={drugLicenseNo}
                />
                <Form.Control.Feedback className='formGood1'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Enter Drug Lisense Number.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} md='6'>
                <Form.Label className='formLabel1'>Type of Business</Form.Label>
                <Form.Control
                  required
                  as='select'
                  name='typeOfBusiness'
                  className='formInput1'
                  onChange={onChange}
                  value={typeOfBusiness}
                >
                  <option value='' selected='selected' disabled='disabled'>
                    Choose Type of Account
                  </option>
                  <option value='Retailer'>Retailer</option>
                  <option value='Wholeseller'>Wholeseller</option>
                  <option value='Pathalogy'>Pathalogy</option>
                  <option value='Doctor'>Doctor</option>
                </Form.Control>
                <Form.Control.Feedback className='formGood1'>
                  Looks good!
                </Form.Control.Feedback>
                <Form.Control.Feedback type='invalid' className='formInvalid1'>
                  Choose Type of Account.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Col md={4}></Col>
              <Col md={2}>
                <Button
                  className='preBtn mx-auto d-block'
                  onClick={() => navigate('../bank-details')}
                >
                  Previous
                </Button>
              </Col>
              <Col md={2}>
                {showSubmitButton ? (
                  <Button type='submit' className='nextBtn mx-auto d-block'>
                    Submit
                  </Button>
                ) : (
                  <Button
                    type='button'
                    className='nextBtn mx-auto d-block'
                    onClick={() => navigate('../upload-documents')}
                  >
                    Next
                  </Button>
                )}
              </Col>
              <Col md={4}></Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  )
}

export default BusinessDetails
