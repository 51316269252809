import React, { useState } from 'react';
import TopHeader from './Top_Header';
import Navigation from './Navigation';
import { useParams, Link, useNavigate } from 'react-router-dom'
import { Container, Row, Col, Card, Button, Spinner, Image } from 'react-bootstrap'
import './componentCss/HealthcareProduct.css'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { QUERY_ACTIVE_MEDICINE, QUERY_PRODUCT_BY_BRANDNAME, MUTATION_ADD_TO_CART_PRODUCT, QUERY_CHECK_CART_PRODUCT, QUERY_PRODUCT_FOR_USER, QUERY_PRODUCT_FOR_MENU } from './Queries'
import './componentCss/ProductPage.css'
import Empty from '../img/nocart.jpg'

export default function ProductByBrandName() {

    var productIdArray = []
    var carProductArray = []
    var sameProductIdArray = []

    const userToken = localStorage.getItem('userToken')
    const userId = localStorage.getItem('userId')

    const { brandname } = useParams();

    const navigate = useNavigate();

    const { data: activeMedicineData, loading: activeMedicineLoading } = useQuery(QUERY_ACTIVE_MEDICINE)



    const { data: productForUserData } = useQuery(QUERY_PRODUCT_FOR_USER, {
        variables: {
            userId: `${userId}`
        },
    });
    const { data: productForMenuData, loading: productForMenuLoading } = useQuery(QUERY_PRODUCT_BY_BRANDNAME, {
        variables: {
            "brandName": `${brandname}`
        },
    });




    const [addToCartMutation] = useMutation(MUTATION_ADD_TO_CART_PRODUCT, {
        refetchQueries: [
            QUERY_CHECK_CART_PRODUCT,
            'checkCartProduct',
        ],
    });

    function addToCartHandle(productId, uploaderId, name, brandName, manufacturer, marketedBy, shipping, yourPrice, mrp, stockAvailable, hsnCode, gst, description, medicineCatagory, menuCatagory, image, catagory) {
        if (!userToken) {
            navigate('/userLogin')
        }
        else {
            let today = new Date();
            addToCartMutation({
                variables: {
                    cartItemInput: {
                        userId: `${userId}`,
                        uploaderId: `${uploaderId}`,
                        productId: `${productId}`,
                        dateTimeForCreate: `${today}`,
                        status: "pending",
                        name: `${name}`,
                        brandName: `${brandName}`,
                        manufacturer: `${manufacturer}`,
                        marketedBy: `${marketedBy}`,
                        shipping: `${shipping}`,
                        yourPrice: `${yourPrice}`,
                        mrp: `${mrp}`,
                        stockAvailable: `${stockAvailable}`,
                        hsnCode: `${hsnCode}`,
                        gst: `${gst}`,
                        description: `${description}`,
                        medicineCatagory: `${medicineCatagory}`,
                        menuCatagory: `${menuCatagory}`,
                        image: image,
                        catagory: `${catagory}`
                    }
                }
            })
            window.location.reload();
            navigate('/cart')
        }

    }

    const handleClick = (id) => navigate('/productDetails/' + id);

    if (userId) {
        if (activeMedicineData) {
            activeMedicineData && activeMedicineData.getActiveMedicines.map(ac => productIdArray.push(ac.id))
        }
        if (productForUserData) {
            productForUserData && productForUserData.cartProductForUser.map(cp => carProductArray.push(cp.productId))
        }

        productIdArray.forEach(val => carProductArray.includes(val) && sameProductIdArray.push(val));
    }

    // console.log("productForMenuData", productForMenuData.getProductForMenu.length)

    // console.log("productIdArray", productIdArray)
    // console.log("carProductArray", carProductArray)
    // console.log("sameProductIdArray", sameProductIdArray)
    console.log("productForUserData", productForMenuData)

    return (
        <>
            <TopHeader />
            <Navigation />
            {
                window.scrollTo(0, 0)
            }

            <Container fluid style={{ marginTop: '15px' }}>
                <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '20px', textAlign: 'center' }}>{brandname}</p>
                <Row>
                    {
                        productForMenuLoading ?
                            < Spinner animation="border" role="status" className='mx-auto d-block'>
                                <span className="visually-hidden">Loading...</span>
                            </Spinner> :
                            productForMenuData.getProductByBrandName.length === 0 ?
                                <Container>
                                    <Row>
                                        <Col>
                                            <Image src={Empty} style={{ width: '350px' }} className='mx-auto d-block' />
                                            <h1 style={{ fontSize: '25px', fontFamily: 'DM Sans', textAlign: 'center', fontWeight: 'bold' }}>No Product Found!!!</h1>
                                        </Col>
                                    </Row>
                                </Container>
                                :
                                productForMenuData && productForMenuData.getProductByBrandName.map(activeMData => {
                                    return (
                                        <Col md={3}>
                                            <Card className="PageProductCard">
                                                <Card.Body style={{ padding: '0px' }}>
                                                    <Card.Img variant="top" src={`https://bharatpharmamain.s3.us-east-2.amazonaws.com/${activeMData.image[0]}`} onClick={() => handleClick(activeMData.id)} style={{ width: '100%', height: '300px' }} />
                                                    <Card.Text onClick={() => handleClick(activeMData.id)}>
                                                        {activeMData.name.length > 25 ? <h1 style={{ fontFamily: 'DM Sans', color: '#212121', fontSize: '13px', cursor: 'pointer' }}>{activeMData.name.substring(0, 50)}.....</h1> : <h1 style={{ fontFamily: 'DM Sans', color: '#212121', fontSize: '13px', cursor: 'pointer' }}>{activeMData.name}</h1>}
                                                    </Card.Text>
                                                    <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#34495e', fontSize: '18px' }}>₹{activeMData.mrp}/-</p>

                                                    {/* {
                                                        sameProductIdArray.includes(activeMData && activeMData.id) ?
                                                            <Link to="/cart">
                                                                <Button id="cart_btn">Proceed To Cart</Button></Link>
                                                            :
                                                            <Button id="cart_btn" onClick={() => addToCartHandle(
                                                                activeMData && activeMData.id,
                                                                activeMData && activeMData.vendorId,
                                                                activeMData && activeMData.name,
                                                                activeMData && activeMData.brandName,
                                                                activeMData && activeMData.manufacturer,
                                                                activeMData && activeMData.marketedBy,
                                                                activeMData && activeMData.shipping,
                                                                activeMData && activeMData.yourPrice,
                                                                activeMData && activeMData.mrp,
                                                                activeMData && activeMData.stockAvailable,
                                                                activeMData && activeMData.hsnCode,
                                                                activeMData && activeMData.gst,
                                                                activeMData && activeMData.description,
                                                                activeMData && activeMData.medicineCatagory,
                                                                activeMData && activeMData.menuCatagory,
                                                                activeMData && activeMData.image,
                                                                activeMData && activeMData.catagory
                                                            )}>Add To Cart</Button>
                                                    } */}
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                })
                    }
                </Row>
            </Container>
        </>
    );
}
