import React, { useState } from 'react'
import { Nav, Tab, Row, Col, Container, Navbar, Button } from 'react-bootstrap';
import './UserDashboardCss/UserDashboard.css'

import { Navigate, Outlet, Link } from 'react-router-dom'

export default function UserDashboard() {

  const [logout, setLogout] = useState(false)
  const userToken = localStorage.getItem('userToken')
  const userId = localStorage.getItem('userId')

  if (!userToken) {
    return <Navigate to='/userLogin' />
  }

  if (logout) {
    return <Navigate to="/userLogout" />
  }

  return (
    <>
      <h1 style={{ textAlign: 'center', background: '#000', padding: '20px', color: '#fff', fontFamily: 'Dm Sans', marginBottom: '-10px' }}>User Dashboard</h1>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <Col md={2} style={{ background: "#000", minHeight: '700px' }}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link as={Link} to={'/'} style={{ padding: '10px', fontSize: '12px', fontFamily: 'DM Sans', color: '#fff', fontWeight: 'bold' }}>
                  Home
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to={'/UserDashboard/yourOrders'} style={{ padding: '10px', fontSize: '12px', fontFamily: 'DM Sans', color: '#fff', fontWeight: 'bold' }}>
                  Your Orders
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to={'/UserDashboard/addaddress'} style={{ padding: '10px', fontSize: '12px', fontFamily: 'DM Sans', color: '#fff', fontWeight: 'bold' }}>
                  Add Address
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to={'/UserDashboard/yourAccount'} style={{ padding: '10px', fontSize: '12px', fontFamily: 'DM Sans', color: '#fff', fontWeight: 'bold' }}>
                  Your Account
                </Nav.Link>
              </Nav.Item>
              <Button className="Sidebarlogoutbtn" style={{ fontSize: '12px', width: '100%' }} onClick={() => setLogout(true)}>Log Out</Button>
            </Nav>
          </Col>
          <Col md={10}>
            <Outlet />
          </Col>
        </Row>
      </Tab.Container>
    </>
  )
}
