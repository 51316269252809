import { gql } from '@apollo/client'

/* +++++++++++++++++++++++++++++++++++++++++++++++++++ Ouery ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const QUERY_ALL_VENDORS = gql`
  query Query {
    getAllVendors {
      id
      contact
      fullName
      email
      companyName
      companyAddress
      companyState
      companyDistrict
      companyCity
      companyCountry
      companyPincode
      panCard
      gstin
      bankName
      bankAccountNo
      bankIfsc
      bankBeneficiaryName
      accountType
      typeOfBusiness
      cityMedicalLocate
      totalYearInBusiness
      coldStorage
      facilityAvailable
      typeOfParter
      powerBackup
      drugLicenseNo
      gstCertificateImg
      panCardImg
      cancelledChequeImg
      drugLicenseImg
      electricityBillImg
      shopImage
      registrationDateTime
      emailVerify
      contactVerify
      status
    }
  }
`

export const QUERY_ALL_PRODUCT = gql`
  query Query {
    getAllProduct {
      id
      vendorId
      name
      brandName
      manufacturer
      storageContainer
      expireTime
      isHeatSensitive
      variation
      skuNo
      countryOfOrigin
      taxCode
      shipping
      yourPrice
      mrp
      stockAvailable
      stockType
      productQuantity
      productQuantityType
      productQuantity
      productQuantityType
      hsnCode
      isYourProductContaineBattery
      composition
      description
      uses
      safetyWarning
      productDimension
      productWeight
      dosage
      flavour
      medicineForm
      menuCatagory
      image
      uploadingDateTime
      uploader
      status
      medCatagory
      prescriptionRequire
      saltComposition
      alternativeMedicine
      habitForming
    }
  }
`

export const QUERY_PRODUCT_BY_ID = gql`
  query GetProductById($productId: ID!) {
  getProductById(productId: $productId) {
    id
    vendorId
    productExcelId
    name
    brandName
    manufacturer
    manufacturerAddress
    marketedBy
    introduction
    description
    benefit
    howToUse
    howWork
    safetyAdvise
    packaging
    packagingType
    packInfo
    saltComposition
    medicineType
    inStock
    stock
    stockAvailability
    stockType
    mrp
    bestPrice
    prescriptionRequired
    primaryUse
    storage
    countryOfOrigin
    therapeuticClass
    sideEffects
    ifMiss
    ingredients
    alternateBrands
    category
    subCategory
    taxCode
    expireTime
    skuNo
    shipping
    productQuantity
    productQuantityType
    hsnCode
    gst
    highlights
    catagory
    menuCatagory
    image
    uploadingDateTime
    uploader
    status
  }
}
`

export const QUERY_VENDOR_BY_ID = gql`
  query GetVendorById($vendorId: ID!) {
    getVendorById(vendorId: $vendorId) {
      id
      contact
      fullName
      email
      companyName
      companyAddress
      companyState
      companyDistrict
      companyCity
      companyCountry
      companyPincode
      panCard
      gstin
      bankName
      bankAccountNo
      bankIfsc
      bankBeneficiaryName
      accountType
      typeOfBusiness
      cityMedicalLocate
      totalYearInBusiness
      coldStorage
      facilityAvailable
      typeOfParter
      powerBackup
      drugLicenseNo
      gstCertificateImg
      panCardImg
      cancelledChequeImg
      drugLicenseImg
      electricityBillImg
      shopImage
      registrationDateTime
      emailVerify
      contactVerify
      status
    }
  }
`

export const QUERY_ACTIVE_VENDORS = gql`
  query GetActiveVendors {
    getActiveVendors {
      id
      contact
      fullName
      email
      companyName
      companyAddress
      companyState
      companyDistrict
      companyCity
      companyCountry
      companyPincode
      panCard
      gstin
      bankName
      bankAccountNo
      bankIfsc
      bankBeneficiaryName
      accountType
      typeOfBusiness
      cityMedicalLocate
      totalYearInBusiness
      coldStorage
      facilityAvailable
      typeOfParter
      powerBackup
      drugLicenseNo
      gstCertificateImg
      panCardImg
      cancelledChequeImg
      drugLicenseImg
      electricityBillImg
      shopImage
      registrationDateTime
      emailVerify
      contactVerify
      status
    }
  }
`

export const QUERY_PENDING_VENDORS = gql`
  query GetPendingVendors {
    getPendingVendors {
      id
      contact
      fullName
      email
      companyName
      companyAddress
      companyState
      companyDistrict
      companyCity
      companyCountry
      companyPincode
      panCard
      gstin
      bankName
      bankAccountNo
      bankIfsc
      bankBeneficiaryName
      accountType
      typeOfBusiness
      cityMedicalLocate
      totalYearInBusiness
      coldStorage
      facilityAvailable
      typeOfParter
      powerBackup
      drugLicenseNo
      gstCertificateImg
      panCardImg
      cancelledChequeImg
      drugLicenseImg
      electricityBillImg
      shopImage
      registrationDateTime
      emailVerify
      contactVerify
      status
    }
  }
`

export const QUERY_ACTIVE_PRODUCT = gql`
  query GetActiveProduct {
    getActiveProduct {
      id
      vendorId
      name
      brandName
      manufacturer
      marketedBy
      storageContainer
      expireTime
      isHeatSensitive
      variation
      skuNo
      countryOfOrigin
      taxCode
      shipping
      yourPrice
      mrp
      stockAvailable
      stockType
      productQuantity
      productQuantityType
      hsnCode
      gst
      isYourProductContaineBattery
      composition
      ingredients
      highlights
      description
      uses
      howToUse
      benefits
      safetyWarning
      productDimension
      productWeight
      dosage
      flavour
      medicineCatagory
      menuCatagory
      image
      uploadingDateTime
      uploader
      catagory
      status
      medCatagory
      menuCatagory
      prescriptionRequire
      saltComposition
      alternativeMedicine
      habitForming
    }
  }
`
export const QUERY_PENDING_PRODUCT = gql`
  query GetPendingProduct {
    getPendingProduct {
      id
      vendorId
      name
      brandName
      manufacturer
      marketedBy
      storageContainer
      expireTime
      isHeatSensitive
      variation
      skuNo
      countryOfOrigin
      taxCode
      shipping
      yourPrice
      mrp
      stockAvailable
      stockType
      productQuantity
      productQuantityType
      hsnCode
      gst
      isYourProductContaineBattery
      composition
      ingredients
      highlights
      description
      uses
      howToUse
      benefits
      safetyWarning
      productDimension
      productWeight
      dosage
      flavour
      medicineCatagory
      menuCatagory
      image
      uploadingDateTime
      uploader
      catagory
      status
      medCatagory
      menuCatagory
      prescriptionRequire
      saltComposition
      alternativeMedicine
      habitForming
    }
  }
`
export const QUERY_REJECT_PRODUCT = gql`
  query GetRejectProduct {
    getRejectProduct {
      id
      vendorId
      name
      brandName
      manufacturer
      marketedBy
      storageContainer
      expireTime
      isHeatSensitive
      variation
      skuNo
      countryOfOrigin
      taxCode
      shipping
      yourPrice
      mrp
      stockAvailable
      stockType
      productQuantity
      productQuantityType
      hsnCode
      gst
      isYourProductContaineBattery
      composition
      ingredients
      highlights
      description
      uses
      howToUse
      benefits
      safetyWarning
      productDimension
      productWeight
      dosage
      flavour
      medicineCatagory
      menuCatagory
      image
      uploadingDateTime
      uploader
      catagory
      status
      medCatagory
      menuCatagory
      prescriptionRequire
      saltComposition
      alternativeMedicine
      habitForming
    }
  }
`

export const QUERY_ACTIVE_MEDICINE = gql`
  query GetActiveMedicines {
    getActiveMedicines {
      id
      vendorId
      name
      brandName
      manufacturer
      marketedBy
      storageContainer
      expireTime
      isHeatSensitive
      variation
      skuNo
      countryOfOrigin
      taxCode
      shipping
      yourPrice
      mrp
      stockAvailable
      stockType
      productQuantity
      productQuantityType
      hsnCode
      gst
      isYourProductContaineBattery
      composition
      ingredients
      highlights
      description
      uses
      howToUse
      benefits
      safetyWarning
      productDimension
      productWeight
      dosage
      flavour
      medicineCatagory
      image
      uploadingDateTime
      uploader
      catagory
      status
      medCatagory
      menuCatagory
      prescriptionRequire
      saltComposition
      alternativeMedicine
      habitForming
    }
  }
`

export const QUERY_CHECK_CART_PRODUCT = gql`
  query CheckCartProduct($userId: String, $productId: String) {
    checkCartProduct(userId: $userId, productId: $productId) {
      id
      
    }
  }
`

export const QUERY_PRODUCT_FOR_USER = gql`
  query CartProductForUser($userId: String) {
    cartProductForUser(userId: $userId) {
      id
      userId
      uploaderId
      productId
      dateTimeForCreate
      status
      amount
      name
      brandName
      manufacturer
      marketedBy
      shipping
      yourPrice
      mrp
      stockAvailable
      hsnCode
      gst
      description
      medicineCatagory
      menuCatagory
      image
      catagory
    }
  }
`
export const QUERY_CHECK_PENDING_CART_PRODUCT_FOR_USER = gql`
  query Query($userId: String) {
    checkPendingCartProductForUser(userId: $userId) {
      id
    userId
    uploaderId
    productId
    amount
    productExcelId
    name
    brandName
    manufacturer
    manufacturerAddress
    marketedBy
    introduction
    description
    benefit
    howToUse
    howWork
    safetyAdvise
    packaging
    packagingType
    packInfo
    saltComposition
    medicineType
    inStock
    stock
    stockAvailability
    stockType
    mrp
    bestPrice
    prescriptionRequired
    primaryUse
    storage
    countryOfOrigin
    therapeuticClass
    sideEffects
    ifMiss
    ingredients
    alternateBrands
    category
    subCategory
    taxCode
    expireTime
    skuNo
    shipping
    productQuantity
    productQuantityType
    hsnCode
    gst
    highlights
    catagory
    menuCatagory
    image
    uploadingDateTime
    status
    }
  }
`
export const QUERY_PRODUCT_FOR_MENU = gql`
query GetProductForMenu($menuCatagory: String, $first: Int, $offset: Int) {
  getProductForMenu(MenuCatagory: $menuCatagory, first: $first, offset: $offset) {
    id
    vendorId
    productExcelId
    name
    brandName
    manufacturer
    manufacturerAddress
    marketedBy
    introduction
    description
    benefit
    howToUse
    howWork
    safetyAdvise
    packaging
    packagingType
    packInfo
    saltComposition
    medicineType
    inStock
    stock
    stockAvailability
    stockType
    mrp
    bestPrice
    prescriptionRequired
    primaryUse
    storage
    countryOfOrigin
    therapeuticClass
    sideEffects
    ifMiss
    ingredients
    alternateBrands
    category
    subCategory
    taxCode
    expireTime
    skuNo
    shipping
    productQuantity
    productQuantityType
    hsnCode
    gst
    highlights
    catagory
    menuCatagory
    image
    uploadingDateTime
    uploader
    status
  }
}
`
export const QUERY_MEDICINE = gql`
  query GetMedicine($first: Int, $offset: Int) {
  getMedicine(first: $first, offset: $offset) {
    id
    vendorId
    productExcelId
    name
    brandName
    manufacturer
    manufacturerAddress
    marketedBy
    introduction
    description
    benefit
    howToUse
    howWork
    safetyAdvise
    packaging
    packagingType
    packInfo
    saltComposition
    medicineType
    inStock
    stock
    stockAvailability
    stockType
    mrp
    bestPrice
    prescriptionRequired
    primaryUse
    storage
    countryOfOrigin
    therapeuticClass
    sideEffects
    ifMiss
    ingredients
    alternateBrands
    category
    subCategory
    taxCode
    expireTime
    skuNo
    shipping
    productQuantity
    productQuantityType
    hsnCode
    gst
    highlights
    catagory
    menuCatagory
    image
    uploadingDateTime
    uploader
    status
  }
}
`
export const QUERY_COSMETIC = gql`
  query Query {
    getCosmetic {
      id
      vendorId
      name
      brandName
      manufacturer
      marketedBy
      storageContainer
      expireTime
      isHeatSensitive
      variation
      skuNo
      countryOfOrigin
      taxCode
      shipping
      yourPrice
      mrp
      stockAvailable
      stockType
      productQuantity
      productQuantityType
      hsnCode
      gst
      isYourProductContaineBattery
      composition
      ingredients
      highlights
      description
      uses
      howToUse
      benefits
      safetyWarning
      productDimension
      productWeight
      dosage
      flavour
      medicineCatagory
      menuCatagory
      image
      uploadingDateTime
      uploader
      catagory
      status
      medCatagory
      prescriptionRequire
      saltComposition
      alternativeMedicine
      habitForming
    }
  }
`
export const QUERY_SURGICAL = gql`
  query Query {
    getSurgical {
      id
      vendorId
      name
      brandName
      manufacturer
      marketedBy
      storageContainer
      expireTime
      isHeatSensitive
      variation
      skuNo
      countryOfOrigin
      taxCode
      shipping
      yourPrice
      mrp
      stockAvailable
      stockType
      productQuantity
      productQuantityType
      hsnCode
      gst
      isYourProductContaineBattery
      composition
      ingredients
      highlights
      description
      uses
      howToUse
      benefits
      safetyWarning
      productDimension
      productWeight
      dosage
      flavour
      medicineCatagory
      menuCatagory
      image
      uploadingDateTime
      uploader
      catagory
      status
      medCatagory
      prescriptionRequire
      saltComposition
      alternativeMedicine
      habitForming
    }
  }
`
export const QUERY_BABYCARE = gql`
  query GetBabyCare {
    getBabyCare {
      id
      vendorId
      name
      brandName
      manufacturer
      marketedBy
      storageContainer
      expireTime
      isHeatSensitive
      variation
      skuNo
      countryOfOrigin
      taxCode
      shipping
      yourPrice
      mrp
      stockAvailable
      stockType
      productQuantity
      productQuantityType
      hsnCode
      gst
      isYourProductContaineBattery
      composition
      ingredients
      highlights
      description
      uses
      howToUse
      benefits
      safetyWarning
      productDimension
      productWeight
      dosage
      flavour
      medicineCatagory
      menuCatagory
      image
      uploadingDateTime
      uploader
      catagory
      status
      medCatagory
      prescriptionRequire
      saltComposition
      alternativeMedicine
      habitForming
    }
  }
`
export const QUERY_COSMETIC_BY_VENDOR_ID = gql`
  query GetProductById($vendorId: ID) {
    getCosmeticByVendorId(vendorId: $vendorId) {
      id
      vendorId
      name
      brandName
      manufacturer
      marketedBy
      storageContainer
      expireTime
      isHeatSensitive
      variation
      skuNo
      countryOfOrigin
      taxCode
      shipping
      yourPrice
      mrp
      stockAvailable
      stockType
      productQuantity
      productQuantityType
      hsnCode
      gst
      isYourProductContaineBattery
      composition
      ingredients
      highlights
      description
      uses
      howToUse
      benefits
      safetyWarning
      productDimension
      productWeight
      dosage
      flavour
      medicineCatagory
      menuCatagory
      image
      uploadingDateTime
      uploader
      catagory
      status
      medCatagory
      prescriptionRequire
      saltComposition
      alternativeMedicine
      habitForming
    }
  }
`
export const QUERY_PRODUCT_BY_BRANDNAME = gql`
  query Query($brandName: String) {
    getProductByBrandName(brandName: $brandName) {
      id
      vendorId
      name
      brandName
      manufacturer
      marketedBy
      storageContainer
      expireTime
      isHeatSensitive
      variation
      skuNo
      countryOfOrigin
      taxCode
      shipping
      yourPrice
      mrp
      stockAvailable
      stockType
      productQuantity
      productQuantityType
      hsnCode
      gst
      isYourProductContaineBattery
      composition
      ingredients
      highlights
      description
      uses
      howToUse
      benefits
      safetyWarning
      productDimension
      productWeight
      dosage
      flavour
      medicineCatagory
      menuCatagory
      image
      uploadingDateTime
      uploader
      catagory
      status
      medCatagory
      prescriptionRequire
      saltComposition
      alternativeMedicine
      habitForming
    }
  }
`
export const QUERY_PRODUCT_COUNT = gql`
query Query {
  getProductCount
}
`
export const QUERY_LIST_PRODUCT_COUNT = gql`
query Query($menuCatagory: String) {
  getListProductCount(menuCatagory: $menuCatagory)
}
`
export const QUERY_ORDER_BY_USERID = gql`
query Query($userId: ID) {
  getOrderByUserId(userId: $userId) {
    id
    productDetails {
      id
      uploaderId
      productId
      productName
      productPrice
      productImage
      quantity
    }
    userId
    paymentId
    orderId
    fullName
    contact
    email
    city
    pincode
    fullAddress
    prescriptionImage
    createdDateTime
    status
    totalPrice
  }
}
`
export const QUERY_ALL_ORDER = gql`
query Query {
  getAllOrders {
    id
    productDetails {
      id
      uploaderId
      productId
      productName
      productPrice
      productImage
      quantity
    }
    userId
    paymentId
    orderId
    fullName
    contact
    email
    city
    pincode
    fullAddress
    prescriptionImage
    createdDateTime
    status
    totalPrice
    deliveryId
    deliveryName
  }
}
`
export const QUERY_ORDER_BY_ID = gql`
query GetOrderById($orderId: ID) {
  getOrderById(orderId: $orderId) {
    id
    productDetails {
      id
      uploaderId
      productId
      productName
      productPrice
      productImage
      quantity
    }
    userId
    paymentId
    orderId
    fullName
    contact
    email
    city
    pincode
    fullAddress
    prescriptionImage
    createdDateTime
    status
    totalPrice
  }
}
`
export const QUERY_OTC_PRODUCTS = gql`
query GetOtcProduct($categoryName: String) {
  getOtcProduct(categoryName: $categoryName) {
    id
    vendorId
    name
    brandName
    manufacturer
    marketedBy
    storageContainer
    expireTime
    isHeatSensitive
    variation
    skuNo
    countryOfOrigin
    taxCode
    shipping
    yourPrice
    mrp
    stockAvailable
    stockType
    productQuantity
    productQuantityType
    hsnCode
    gst
    isYourProductContaineBattery
    composition
    ingredients
    highlights
    description
    uses
    howToUse
    benefits
    safetyWarning
    productDimension
    productWeight
    dosage
    flavour
    medicineCatagory
    menuCatagory
    image
    uploadingDateTime
    uploader
    catagory
    status
    medCatagory
    prescriptionRequire
    saltComposition
    alternativeMedicine
    habitForming
  }
}
`
export const QUERY_SEARCH_PRODUCTS = gql`
query SearchProduct($name: String) {
  searchProduct(name: $name) {
    id
    vendorId
    productExcelId
    name
    brandName
    manufacturer
    manufacturerAddress
    marketedBy
    introduction
    description
    benefit
    howToUse
    howWork
    safetyAdvise
    packaging
    packagingType
    packInfo
    saltComposition
    medicineType
    inStock
    stock
    stockAvailability
    stockType
    mrp
    bestPrice
    prescriptionRequired
    primaryUse
    storage
    countryOfOrigin
    therapeuticClass
    sideEffects
    ifMiss
    ingredients
    alternateBrands
    category
    subCategory
    taxCode
    expireTime
    skuNo
    shipping
    productQuantity
    productQuantityType
    hsnCode
    gst
    highlights
    catagory
    menuCatagory
    image
    uploadingDateTime
    uploader
    status
  }
}
`
export const QUERY_GET_USER_BY_ID = gql`
query Query($userId: ID) {
  getUserById(userId: $userId) {
    id
    fullName
    contact
    email
    address
    city
    state
    pincode
    registrationDateTime
    emailVerify
    contactVerify
    createdDateTime
    status
  }
}
`
export const QUERY_GET_ALL_USER = gql`
query Query {
  getAllUser {
    id
    fullName
    age
    gender
    contact
    email
    address
    city
    state
    pincode
    registrationDateTime
    emailVerify
    contactVerify
    userImg
    createdDateTime
    status
  }
}
`
export const QUERY_GET_USER_COUNT = gql`
query Query {
  getUserCount
}
`

export const QUERY_GET_USERS = gql`
query GetUsers($first: Int, $offset: Int) {
  getUsers(first: $first, offset: $offset) {
    id
    fullName
    age
    gender
    contact
    email
    address
    city
    state
    pincode
    registrationDateTime
    emailVerify
    contactVerify
    userImg
    createdDateTime
    status
  }
}
`
export const QUERY_ALL_DELIVERYBOY = gql`
query GetDeliveryBoy {
  getDeliveryBoy {
    id
    uniqueId
    fName
    lName
    contact
    email
    username
    password
    address
    city
    state
    pincode
    bankName
    bankIfsc
    bankAccountNo
    bankAccountHolderName
    profileImg
    longitude
    latitude
    heading
    aadharImgFront
    aadharImgBack
    panCardImg
    deviceToken
    createdDateTime
    status
  }
}
`
export const QUERY_ADD_ADDRESS = gql`
query Query($userId: ID) {
  getAddAddress(userId: $userId) {
    id
    userId
    name
    mobile
    email
    city
    address
    pincode
    createdDateTime
    status
  }
}
`
export const QUERY_DELIVERY_BOY = gql`
query Query {
  getDeliveryBoy {
    id
    uniqueId
    fName
    lName
    contact
    email
    username
    password
    address
    city
    state
    pincode
    bankName
    bankIfsc
    bankAccountNo
    bankAccountHolderName
    profileImg
    longitude
    latitude
    heading
    deviceToken
    createdDateTime
    status
  }
}
`

export const QUERY_GET_CONTACT = gql`
query Query {
  getContact {
    id
    fullName
    email
    contact
    subject
    message
    createdDateTime
  }
}
`

export const QUERY_DELIVERY_BOY_BY_ID = gql`
query GetDeliveryBoyById($deliveryId: ID) {
  getDeliveryBoyById(deliveryId: $deliveryId) {
    id
    uniqueId
    fName
    lName
    contact
    email
    username
    password
    address
    city
    state
    pincode
    bankName
    bankIfsc
    bankAccountNo
    bankAccountHolderName
    profileImg
    longitude
    latitude
    heading
    aadharImgFront
    aadharImgBack
    panCardImg
    deviceToken
    createdDateTime
    status
  }
}`

export const QUERY_COUPONS = gql`
query Query {
  getCoupon {
    couponName
    couponPercentage
    createDateTime
    status
    id
  }
}
`


/* +++++++++++++++++++++++++++++++++++++++++++++++++++ Mutation ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

export const MUTATION_ADMIN_OTP_LOGIN = gql`
  mutation AdminOtpLogin($contact: String!) {
    adminOtpLogin(contact: $contact) {
      adminId
      adminToken
      adminTokenExpiration
    }
  }
`
export const MUTATION_VENDOR_OTP_LOGIN = gql`
  mutation VendorOtpLogin($contact: String!) {
    vendorOtpLogin(contact: $contact) {
      vendorId
      vendorToken
      vendorTokenExpiration
    }
  }
`
export const MUTATION_USER_OTP_LOGIN = gql`
  mutation UserOtpLogin($contact: String!) {
    userOtpLogin(contact: $contact) {
      userId
      userToken
      userTokenExpiration
    }
  }
`

export const MUTATION_CREATE_PRODUCT = gql`
  mutation CreateProduct($productInput: productInput) {
  createProduct(ProductInput: $productInput) {
    id
  }
}
`

export const MUTATION_DELETE_PRODUCT = gql`
  mutation DeleteProduct($productId: ID!) {
    deleteProduct(productId: $productId) {
      id
    }
  }
`

export const MUTATION_UPDATE_PRODUCT = gql`
  mutation UpdateProduct($productUpdateInput: productUpdateInput) {
    updateProduct(ProductUpdateInput: $productUpdateInput) {
      id
    }
  }
`

export const MUTATION_ACTIVE_VENDOR = gql`
  mutation Mutation($vendorActiveInput: vendorActiveInput) {
    vendorActive(VendorActiveInput: $vendorActiveInput) {
      id
    }
  }
`

export const MUTATION_ACTIVE_VENDOR_ADMIN = gql`
  mutation ActiveVendor($vendorId: ID!) {
    activeVendor(vendorId: $vendorId) {
      id
    }
  }
`
export const MUTATION_INACTIVE_VENDOR_ADMIN = gql`
  mutation InactiveVendor($vendorId: ID!) {
    inactiveVendor(vendorId: $vendorId) {
      id
    }
  }
`
export const MUTATION_ACTIVE_VANDOR_PRODUCT = gql`
  mutation ActiveVendorProduct($productId: ID!) {
    activeVendorProduct(productId: $productId) {
      id
      status
    }
  }
`
export const MUTATION_ADD_TO_CART_PRODUCT = gql`
  mutation AddToCart($cartItemInput: cartItemInput) {
    addToCart(CartItemInput: $cartItemInput) {
      id
    }
  }
`
export const MUTATION_INCREASE_CART_PRODUCT = gql`
  mutation Mutation($increaseCartAmountId: ID) {
    increaseCartAmount(id: $increaseCartAmountId) {
      id
      amount
      userId
      uploaderId
      productId
    }
  }
`
export const MUTATION_DECREASE_CART_PRODUCT = gql`
  mutation Mutation($decreaseCartAmountId: ID) {
    decreaseCartAmount(id: $decreaseCartAmountId) {
      id
      amount
      userId
      uploaderId
      productId
    }
  }
`
export const MUTATION_REMOVE_CART_ITEM = gql`
  mutation RemoveCartItem($removeCartItemId: ID) {
    removeCartItem(id: $removeCartItemId) {
      id
    }
  }
`
export const MUTATION_UPDATE_COSMETIC = gql`
  mutation Mutation($cosmeticUpdateInput: cosmeticUpdateInput) {
    updateCosmetic(CosmeticUpdateInput: $cosmeticUpdateInput) {
      id
    }
  }
`

export const MUTATION_UPDATE_IMAGES = gql`
  mutation Mutation($productId: ID, $imageArray: [String]) {
    updateImage(productId: $productId, imageArray: $imageArray) {
      id
      image
    }
  }
`
export const MUTATION_CREATE_ORDER = gql`
mutation CreateOrder($orderInput: orderInput) {
  createOrder(OrderInput: $orderInput) {
    id
  }
}
`
export const MUTATION_CHANGE_ORDER_STATUS = gql`
mutation ChangeOrderStatus($orderId: ID, $status: String) {
  changeOrderStatus(orderId: $orderId, status: $status) {
    id
  }
}
`
export const MUTATION_UPDATE_USER = gql`
mutation UpdateUser($userUpdateInput: userUpdateInput) {
  updateUser(UserUpdateInput: $userUpdateInput) {
    id
  }
}
`
export const MUTATION_USER_SIGNUP = gql`
mutation Mutation($userInput: userInput) {
  signUpUser(UserInput: $userInput) {
    id
  }
}
`
export const MUTATION_DELETE_USER = gql`
mutation Mutation($userId: ID) {
  deleteUser(userId: $userId) {
    id
  }
}
`
export const MUTATION_ADD_DELIVERYBOY = gql`
mutation Mutation($deliveryboyInput: deliveryboyInput) {
  createDeliveryBoy(DeliveryboyInput: $deliveryboyInput) {
    id
  }
}
`
export const MUTATION_ADD_ADDRESS = gql`
mutation Mutation($addAddressInput: addaddressInput) {
  createAddAddress(AddAddressInput: $addAddressInput) {
    id
    name
    mobile
    email
    city
    address
    pincode
    createdDateTime
    status
  }
}

`
export const MUTATION_ASSIGN_DELIVERY_BOY = gql`
mutation Mutation($orderId: ID, $deliveryBoyId: ID, $deliveryBoyName: String) {
  assignOrderToDeliveryBoy(orderId: $orderId, deliveryBoyId: $deliveryBoyId, deliveryBoyName: $deliveryBoyName) {
    id
  }
}

`
export const MUTATION_DELETE_DELIVERY_BOY = gql`
mutation DeleteDeliveryBoy($deliveryBoyId: ID) {
  deleteDeliveryBoy(deliveryBoyId: $deliveryBoyId) {
    id
  }
}
`
export const MUTATION_UPDATE_DELIVERY_BOY = gql`
mutation DeliveryBoyUpdate($deliveryboyUpdateInput: deliveryboyUpdateInput) {
  deliveryBoyUpdate(DeliveryboyUpdateInput: $deliveryboyUpdateInput) {
    id
  }
}
`
export const MUTATION_DELETE_ADDRESS = gql`
mutation Mutation($addressId: ID) {
  deleteAddress(addressId: $addressId) {
    id
  id
  }
}
`

export const MUTATION_CREATE_CONTACT_US = gql`
mutation CreateContact($contactInput: contactInput) {
  createContact(ContactInput: $contactInput) {
    id
    fullName
    email
    contact
    subject
    message
    createdDateTime
    status
  }
}
`
export const MUTATION_REMOVE_CARTITEM_BY_USERID = gql`
mutation Mutation($userId: ID) {
  removeCartByUserId(userId: $userId) {
    id
  }
}
`
export const MUTATION_CREATE_COUPON = gql`
mutation CreateCoupon($couponInput: couponInput) {
  createCoupon(CouponInput: $couponInput) {
    id
  }
}
`
export const MUTATION_DELETE_COUPON = gql`
mutation Mutation($couponId: ID) {
  deleteCoupon(couponId: $couponId) {
    id
  }
}
`
export const MUTATION_CHECK_COUPON = gql`
mutation Mutation($couponName: String) {
  checkCoupon(couponName: $couponName) {
    id
    couponName
    couponPercentage
    createDateTime
    status
  }
}
`
export const MUTATION_SEND_OTP = gql`
mutation Mutation($contact: String!, $otp: String!) {
  sendLoginOtp(contact: $contact, otp: $otp)
}
`
