import React, { useState, useEffect } from 'react'
import TopHeader from './Top_Header'
import Navigation from './Navigation'
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import Lottie from 'react-lottie';
import * as Success from './Animation/success.json';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getCart, clearItem } from '../redux/actions'

export default function OrderCompleted() {
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(clearItem())
    })
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Success,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <>
            <TopHeader />
            <Navigation />
            <Container>
                <Row>
                    <Col md={6} className="mx-auto d-block">
                        <Card style={{ marginTop: '50px', boxShadow: '0 0 10px 0 rgba(100, 100, 100, 0.26)', borderRadius: '10px' }}>
                            <Card.Body>
                                <h1 style={{ textAlign: 'center', fontFamily: 'DM Sans', fontWeight: 'bold' }}>Thank You</h1>
                                <Lottie options={defaultOptions}
                                    height={150}
                                    width={150}
                                />
                                <h1 style={{ textAlign: 'center', fontFamily: 'DM Sans', fontSize: '20px' }}>You order is completed successfully</h1>
                                <Link to="/userDashboard/yourOrders" style={{ textDecoration: 'none' }}><h1 style={{ textAlign: 'center', fontFamily: 'DM Sans', fontSize: '15px' }}>View Order</h1></Link>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
