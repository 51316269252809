import React from 'react'
import { useQuery } from '@apollo/client'
import { QUERY_SEARCH_PRODUCTS } from './Queries'
import Top_Header from './Top_Header'
import Navigation from './Navigation'
import Empty from '../img/nocart.jpg'
import { Container, Row, Col, Card, Image, Spinner } from 'react-bootstrap'

import { useNavigate, useParams } from 'react-router-dom'

export default function SearchProduct() {
    
    const { name } = useParams();
    const { data: searchData, loading: searchLoading } = useQuery(QUERY_SEARCH_PRODUCTS, {
        variables: {
            "name": `${name}`
        }
    });

    const navigate = useNavigate();

    console.log("searchData", searchData)

    const handleClick = (id) => navigate('/productDetails/' + id);

    return (
        <>
            {
                window.scrollTo(0, 0)
            }
            <Top_Header />
            <Navigation />
            <Container fluid style={{ marginTop: '15px' }}>
                <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#2B97A4', fontSize: '20px', textAlign: 'center' }}>Search Results</p>
                <Row>
                    {
                        searchLoading ?
                            <Spinner animation="border" role="status" className='mx-auto d-block'>
                                <span className="visually-hidden">Loading...</span>
                            </Spinner> :
                            searchData && searchData.searchProduct.length === 0 || searchData && searchData.searchProduct === undefined ?
                                <Container>
                                    <Row>
                                        <Col>
                                            <Image src={Empty} style={{ width: '350px' }} className='mx-auto d-block' />
                                            <h1 style={{ fontSize: '25px', fontFamily: 'DM Sans', textAlign: 'center', fontWeight: 'bold' }}>No Product Found!!!</h1>
                                        </Col>
                                    </Row>
                                </Container>
                                :
                                searchData && searchData.searchProduct.map(activeMData => {
                                    return (
                                        <Col md={3}>
                                            <Card className="PageProductCard">
                                                <Card.Body style={{ padding: '0px' }}>
                                                    {
                                                        activeMData.image.length === 0 ?
                                                            <Card.Img variant="top" src={`https://images.pexels.com/photos/139398/thermometer-headache-pain-pills-139398.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1`} onClick={() => handleClick(activeMData.id)} style={{ width: '100%', height: '300px' }} />
                                                            :
                                                            <Card.Img variant="top" src={`https://bharatpharmamain.s3.us-east-2.amazonaws.com/${activeMData.image[0]}`} onClick={() => handleClick(activeMData.id)} style={{ width: '100%', height: '300px' }} />
                                                    }

                                                    <Card.Text onClick={() => handleClick(activeMData.id)}>
                                                        {activeMData.name.length > 25 ? <h1 style={{ fontFamily: 'DM Sans', color: '#212121', fontSize: '13px', cursor: 'pointer' }}>{activeMData.name.substring(0, 50)}.....</h1> : <h1 style={{ fontFamily: 'DM Sans', color: '#212121', fontSize: '13px', cursor: 'pointer' }}>{activeMData.name}</h1>}
                                                    </Card.Text>
                                                    <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#34495e', fontSize: '18px' }}>₹{activeMData.mrp.replace(/[&\/\\#,+₹()$~%'":*?<>{} ]/g, '')}/-</p>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                })
                    }
                </Row>
            </Container>
        </>
    )
}
