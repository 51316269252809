import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, InputGroup, FormControl, Spinner } from 'react-bootstrap'
import { FaPhoneAlt, FaSearch, FaShoppingCart, FaUserCircle } from 'react-icons/fa';
import './componentCss/Top_Header.css'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { QUERY_CHECK_PENDING_CART_PRODUCT_FOR_USER } from './Queries'
import { useDispatch, useSelector } from 'react-redux'
import { getCart, getCartTotal } from '../redux/actions'

import { useNavigate } from 'react-router-dom'

export default function Top_Header() {
    const dispatch = useDispatch();
    const { cart, totalCount } = useSelector(state => state.cart)
    const [name, setName] = useState('Wow');
    const userToken = localStorage.getItem('userToken')
    const adminToken = localStorage.getItem('adminToken')
    const navigate = useNavigate();
    const handleClick = () => navigate('/searchProduct/' + name);
    const userId = localStorage.getItem('userId')

    const { data: pendingCartProductForUserData, loading: pendingCartProductForUserLoading } = useQuery(QUERY_CHECK_PENDING_CART_PRODUCT_FOR_USER, {
        variables: {
            userId: `${userId}`
        },
    });

    useEffect(() => {
        if (userToken) {
            if (pendingCartProductForUserData) {
                dispatch(getCart(pendingCartProductForUserData))
            }
        }
    }, [pendingCartProductForUserData])

    useEffect(() => {
        dispatch(getCartTotal())
    }, [cart])

    return (
        <div>
            <Container fluid className="Top_Container">
                <Row>
                    <Col md={6} className="HeaderTopText" >
                        Welcome to our Pharmacy Online store!
                    </Col>
                </Row>
                <Row>
                    <Col md={4} className="BharatText">
                        <h1 style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '30px' }}>Bharat Pharma</h1>
                    </Col>
                    <Col md={4}>
                        <InputGroup style={{ marginTop: '-7px' }}>
                            <FormControl style={{ fontFamily: 'DM Sans', fontSize: '12px' }} className="input_search" placeholder="Search here..." onChange={(e) => setName(e.target.value)} onKeyDown={e => e.key === 'Enter' && handleClick()} />
                            <Button id="search_btn" onClick={() => handleClick()}><FaSearch style={{ marginTop: '-3px', marginLeft: '-5px' }} /></Button>
                        </InputGroup>
                    </Col>
                    <Col md={4} style={{ textAlign: 'Right' }}>
                        <Row>
                            {/* <Col md={3} style={{ cursor: 'pointer', color: 'white', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '12px' }}>
                                <FaFileAlt style={{ marginTop: '-2px' }} /> Upload Rx
                            </Col> */}
                            <Col md={4} style={{ cursor: 'pointer', color: 'white', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '12px' }}>
                                {
                                    pendingCartProductForUserLoading ?
                                        <Spinner animation="border" role="status" size='sm'>
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner> :
                                        <Link to="/cart" style={{ cursor: 'pointer', color: 'white', fontFamily: 'DM Sans', fontWeight: 'bold', textDecoration: 'none', fontSize: '12px' }}><FaShoppingCart style={{ marginTop: '-2px' }} /> Cart <span style={{ background: '#2c3e50', padding: '2px', fontSize: '10px', borderRadius: '50px' }}>{totalCount}</span></Link>
                                }

                                {/* <Link to="/about" style={{ cursor: 'pointer', color: 'white', fontFamily: 'DM Sans', fontWeight: 'bold', textDecoration: 'none', fontSize: '12px'  , marginLeft :"20px"}}><FaUserCircle style={{ marginTop: '-2px' }} /> About</Link> */}


                            </Col>
                            {
                                userToken ?
                                    <Col md={3} style={{ marginTop: '-4px' }}>
                                        <Link to="/userDashboard/yourOrders" style={{ cursor: 'pointer', color: 'white', fontFamily: 'DM Sans', fontWeight: 'bold', textDecoration: 'none', fontSize: '12px' }}><FaUserCircle style={{ marginTop: '-2px' }} /> My Orders</Link>
                                    </Col>
                                    :
                                    <Col md={4} style={{ marginTop: '-4px' }}>

                                        <Link to="/userLogin" style={{ cursor: 'pointer', color: 'white', fontFamily: 'DM Sans', fontWeight: 'bold', textDecoration: 'none', fontSize: '12px' }}><FaUserCircle style={{ marginTop: '-2px' }} /> Signin</Link>
                                        <Link to="/signup" style={{ cursor: 'pointer', color: 'white', fontFamily: 'DM Sans', fontWeight: 'bold', textDecoration: 'none', fontSize: '12px', marginLeft: '10px' }}><FaUserCircle style={{ marginTop: '-2px' }} /> Signup</Link>
                                    </Col>
                            }

                            {
                                adminToken ?
                                    <Col md={4} style={{ marginTop: '-4px' }}>
                                        <Link to="/adminDashboard/userOrders" style={{ cursor: 'pointer', color: 'white', fontFamily: 'DM Sans', fontWeight: 'bold', textDecoration: 'none', fontSize: '12px' }}><FaUserCircle style={{ marginTop: '-2px' }} /> Admin Profile</Link>
                                    </Col>
                                    :
                                    <Col md={4} style={{ marginTop: '-4px' }}>
                                        <Link to="/adminLogin" style={{ cursor: 'pointer', color: 'white', fontFamily: 'DM Sans', fontWeight: 'bold', textDecoration: 'none', fontSize: '12px' }}><FaUserCircle style={{ marginTop: '-2px' }} /> Admin Login</Link>
                                    </Col>

                            }
                        </Row>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}
<Row>
    <Col md={3}>
        <FaPhoneAlt style={{ fontSize: '20px' }} />
    </Col>
    <Col md={9}>
        <p style={{ fontSize: '15px', fontFamily: 'Dm Sans' }} >9151485749 </p>

    </Col>
</Row>