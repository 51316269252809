import React from 'react'
import banner5 from '../img/offer-banner5.jpg'
import './componentCss/Footer.css'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import GooglePlay from '../img/playstore.png'
export default function Footer() {
    return (
        <div className="footer" style={{ marginTop: '40px' }}>
            <Container className="footer_container">
                <Row className="footer_row">
                    <Col sm={3}>
                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', color: '#fff', fontSize: '20px' }}>Our Policies</p>
                        <p style={{ fontFamily: 'DM Sans', color: '#fff' }}><Link to="/terms" style={{ textDecoration: 'none', color: '#fff' }}>Terms and Conditions</Link></p>
                        <p style={{ fontFamily: 'DM Sans', color: '#fff' }}><Link to="/privacyPolicy" style={{ textDecoration: 'none', color: '#fff' }}>Privacy Policy</Link></p>
                    </Col>
                    <Col sm={3}>
                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', color: '#fff', fontSize: '20px' }}>Know Us</p>
                        <p style={{ fontFamily: 'DM Sans', color: '#fff' }}><Link to="/about" style={{ textDecoration: 'none', color: '#fff' }}>About Us</Link></p>
                        <p style={{ fontFamily: 'DM Sans', color: '#fff' }}><Link to="/contactUs" style={{ textDecoration: 'none', color: '#fff' }}>Contact Us</Link></p>
                    </Col>
                    <Col sm={3}>
                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', color: '#fff', fontSize: '20px' }}>Connect</p>
                        <a style={{ textDecoration: 'none' }} href="https://www.facebook.com/bharatpharmasagar" target="_blank"><p style={{ fontFamily: 'DM Sans', color: '#fff' }}>Facebook</p></a>
                        <a style={{ textDecoration: 'none' }} href="https://instagram.com/bharat_pharm" target="_blank"><p style={{ fontFamily: 'DM Sans', color: '#fff' }}>Instagram</p></a>
                    </Col>
                    <Col sm={3}>
                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', color: '#fff', fontSize: '20px' }}>Download our App</p>
                        <a href="https://play.google.com/store/apps/details?id=com.bharatpharma.app" target="_blank">
                            <Image src={GooglePlay} style={{ width: 140, height: 40, cursor: 'pointer' }} />
                        </a>
                    </Col>
                </Row>

                <Row className="footer__copyright">
                    <Col me={12} className="footer__copyright__text" style={{ textAlign: 'center' }}>
                        <p>Copyright &copy; All rights reserved | Developed & Maintained by <a href="https://softseekersinfotech.com" target='_blank' style={{ color: '#bdc3c7', textDecoration: 'none' }}>Softseekers Infotech Pvt. Ltd.</a></p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}  
