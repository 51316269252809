import React from 'react'
import { Container, Row, Col, Image, Card } from 'react-bootstrap'
import banner6 from '../img/offer-banner6.jpg'
import './componentCss/OTCProduct.css'
import Beautycare from '../img/BeautyPersonalcare.png'
import WomanCare from '../img/womancare.png'
import FitnessPic from '../img/1.png'
import BeautyPic from '../img/2.png'
import Diabetic from '../img/dietary-removebg-preview.png'
import BabyPic from '../img/3.png'
import Cardiac from '../img/cardiac.png'
import healthcaredevice from '../img/png_20220125_153233_0000-(1)-1643106828.png'
import Drinksfood from '../img/drinksfood.png'
import drinks from '../img/drinks-removebg-preview.png'
import Ayurveda from '../img/ayurveda.png'
import cardi from '../img/cardi.png'
import sexual from '../img/sexual.png'
import { useNavigate } from 'react-router-dom'

export default function OTCProduct() {
  const navigate = useNavigate();

  return (
    <>
      <Container fluid>
        <p style={{ fontFamily: 'DM Sans', fontWeight: '700', color: '#0b1219', fontSize: '27px', marginTop: '10px' }}>Shop By Category </p>
        <Row>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12}>
                <Card className="OTC-card" onClick={() => navigate('/FitnessOtc')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={FitnessPic} className="OTCImage" /></center>
                    <Card.Text className="CardText">
                      Fitness
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12}>
                <Card className="OTC-card" onClick={() => navigate('/beautyOtc')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={Beautycare} className="OTCImage" /></center>
                    <Card.Text className="CardText">
                      Beauty & Personal Care
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12}>
                <Card className="OTC-card" onClick={() => navigate('/babyOtc')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={BeautyPic} className="OTCImage" /></center>
                    <Card.Text className="CardText">
                      Baby Care
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12}>
                <Card className="OTC-card" onClick={() => navigate('/womenCareOtc')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={WomanCare} className="OTCImage" /></center>
                    <Card.Text className="CardText">
                      Women Care
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12}>
                <Card className="OTC-card" onClick={() => navigate('/diabeticOtc')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={Diabetic} className="OTCImage" /></center>
                    <Card.Text className="CardText">
                      Diabetic Supplements
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12}>
                <Card className="OTC-card" onClick={() => navigate('/cardicCareOtc')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={Cardiac} className="OTCImage" /></center>
                    <Card.Text className="CardText">
                      Cardiac Care
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12}>
                <Card className="OTC-card" onClick={() => navigate('/HealthCareOtc')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={healthcaredevice} className="OTCImage" /></center>
                    <Card.Text className="CardText">
                      Healthcare Devices
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12}>
                <Card className="OTC-card" onClick={() => navigate('/HealthFoodOtc')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={drinks} className="OTCImage" /></center>
                    <Card.Text className="CardText">
                      Health Food and Drinks
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12}>
                <Card className="OTC-card" onClick={() => navigate('/VitaminsOtc')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={Drinksfood} className="OTCImage" /></center>
                    <Card.Text className="CardText">
                      Vitamins & Protien Supplements
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12}>
                <Card className="OTC-card" onClick={() => navigate('/AyurvedaOtc')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={Ayurveda} className="OTCImage" /></center>
                    <Card.Text className="CardText">
                      Ayurveda
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12}>
                <Card className="OTC-card" onClick={() => navigate('/SexualOtc')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={sexual} className="OTCImage" /></center>
                    <Card.Text className="CardText">
                      Sexual Wellness
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={2} xs={6}>
            <Row>
              <Col md={12}>
                <Card className="OTC-card" onClick={() => navigate('/cardicCareOtc')}>
                  <Card.Body>
                    <center><Card.Img variant="top" src={cardi} className="OTCImage" /></center>
                    <Card.Text className="CardText">
                      Cardiac Care
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}
